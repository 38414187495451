import {Component, OnInit} from '@angular/core';
import {PagedResp, ProposalCustom, ProposalCustomSearch} from "../../api/types";
import {Router} from "@angular/router";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NzModalService} from "ng-zorro-antd/modal";
import {ToastrService} from "ngx-toastr";
import {ProposalCustomService} from "../proposal-custom.service";

@Component({
  selector: 'app-proposal-custom-list',
  templateUrl: './proposal-custom-list.component.html',
  styleUrls: ['./proposal-custom-list.component.less']
})
export class ProposalCustomListComponent implements OnInit {

    loading = false;

    customs: PagedResp<ProposalCustom>;

    search: ProposalCustomSearch = new ProposalCustomSearch();

    spinning: boolean = false;

    filterPopoverVisible = false;

    constructor(private router: Router,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private toastr: ToastrService,
                private proposalCustomService: ProposalCustomService,
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.proposalCustomService.customList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.customs = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.proposalCustomService.customList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.customs = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onReload() {
        this.search.pageNum = 1;
        this.loading = true;
        this.proposalCustomService.customList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.customs = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onResetSearch(): void {
        this.search = new ProposalCustomSearch();
        this.onReload();
    }

    onRetry(compare: ProposalCustom) {
        this.spinning = true;
        this.proposalCustomService.retry(compare.id)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (data) {
                        this.toastr.success('操作成功')
                    }
                },
                error => {
                    this.spinning = false;
                });
    }
}
