import { Component, OnInit } from '@angular/core';
import { ProductService } from "../product.service";
import { ProductStatistic } from "../../api/types";
import { OperatorService } from "../../operator/operator.service";

@Component({
    selector: 'app-product-home',
    templateUrl: './product-home.component.html',
    styleUrls: ['./product-home.component.less']
})
export class ProductHomeComponent implements OnInit {

    productStatistic: ProductStatistic;

    constructor(private productService: ProductService,
                private operatorService: OperatorService,) {
    }

    ngOnInit(): void {
        this.productService.statistic()
            .subscribe(
                data => {
                    this.productStatistic = data;
                },
                error => {
                });
    }

    hasAuthority(authority: string) : boolean {
        return this.operatorService.hasAuthority(authority);
    }

    hasAuthorityOr(...authorities: string[]) : boolean {
        for (let authority of authorities) {
            if (this.operatorService.hasAuthority(authority)) {
                return true;
            }
        }
        return false;
    }

}
