import { Component, Input, OnInit } from '@angular/core';
import { ProductPropertyGroup } from "../../api/types";
import { ProductService } from "../product.service";
import { isNotNullOrUndefined } from "../../common/utils";
import { ProductPropertyService } from "../product-property.service";

@Component({
    selector: 'app-product-property-detail',
    templateUrl: './product-property-detail.component.html',
    styleUrls: ['./product-property-detail.component.less']
})
export class ProductPropertyDetailComponent implements OnInit {

    innerCode: string = ''

    propertyGroups: ProductPropertyGroup[] = []

    constructor(private productService: ProductService,
                private productPropertyService: ProductPropertyService) {
    }

    ngOnInit(): void {
    }

    @Input()
    get code(): string {
        return this.innerCode;
    }

    set code(val) {
        this.innerCode = val;
        this.getProductPropertyDetail();
    }

    getProductPropertyDetail(): void {
        if (isNotNullOrUndefined(this.code)) {
            this.productPropertyService.productPropertyDetail(this.code)
                .subscribe(
                    data => {
                        this.propertyGroups = data
                    },
                    error => {
                    });
        }
    }

}
