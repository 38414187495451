import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productCompanyAgeFormula'
})
export class ProductCompanyAgeFormulaPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        let ageFormula = "";
        switch (value) {
            case 'NEXT_BIRTHDAY':
                ageFormula = "下一次生日";
                break;
            case 'LAST_BIRTHDAY':
                ageFormula = "上一次生日";
                break;
            case 'NEAREST_BIRTHDAY':
                ageFormula = "最近生日";
                break;
            case 'CURRENT_BIRTHDAY':
                ageFormula = "周岁";
                break;
        }
        return ageFormula;
    }

}
