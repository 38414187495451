import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicalRegion'
})
export class MedicalRegionPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let medicalRegion = "";
        switch (value) {
            case 'WORLDWIDE':
                medicalRegion = "全球";
                break;
            case 'WORLDWIDE_EXCEPT_US':
                medicalRegion = "全球除美国";
                break;
            case 'ASIA':
                medicalRegion = "亚洲";
                break;
            case 'GREATER_CHINA':
                medicalRegion = "大中華";
                break;
            case 'GREATER_CHINA_HMT':
                medicalRegion = "大中華（港澳台）";
                break;
        }
        return medicalRegion;
    }

}
