<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div style="height: 500px">

        <div style="height: 20px; font-size: 20px">
            操作臺
        </div>
        <div class="console-menu">
            <div *ngIf="!operator.xiumiOpenId" class="console-button" nz-tooltip nzTooltipTitle="绑定秀米账号"
                 appThrottleClick (throttleClick)="onXiuMiPartnerBind()">
                <i nz-icon nzType="link" nzTheme="outline" style="color: #07367c; font-size: 35px;"></i>
                <div style="margin-top: 10px; font-size: 12px">绑定秀米</div>
            </div>
        </div>

    </div>
</nz-spin>
