<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzSpinning]="isSpinning" [nzIndicator]="indicatorTemplate">
        <div>
            <div *ngFor="let replies of repliesData.replies;">
                <div class="reply-item">
                    <div class="list-top">
                        <nz-avatar *ngIf="replies.role === 'CENTRE'" [nzSize]="36" nzIcon="user"
                                   style="background-color: #2C7BE5; color: white;margin-right: 20px;"></nz-avatar>
                        <nz-avatar *ngIf="replies.role === 'USER'" class="list-top-avatar" style="margin-right: 20px;"
                                   [nzSize]="36" [nzSrc]="repliesData.initiatorInfo.avatar"></nz-avatar>
                        <div class="list-top-right">
                            <div class="reply-title">
                                <span class="list-name" *ngIf="replies.role === 'CENTRE'">{{ 'CENTRE' }}</span>
                                <span class="list-name" *ngIf="replies.role === 'USER'">{{ repliesData.initiatorInfo.accountName }}</span>
                                <span class="list-top-my-reply" *ngIf="replies.role === 'CENTRE'">{{ '本人' }}</span>
                                <span class="list-top-my-reply" *ngIf="replies.role === 'USER'">{{ repliesData.initiatorInfo.instance }}</span>
                            </div>
                            <div class="list-top-name" style="margin-right: 10px;">{{ replies.createTime }}</div>
                        </div>
                    </div>
<!--                    <div class="reply-title">-->
<!--                        <span style="margin-left: 10px;">{{replies.createTime | date: 'yyyy-MM-dd HH:mm:ss'}}</span>-->
<!--                    </div>-->
                    <div class="reply-content">
                        <div [innerHTML]="replies.content"></div>
                        <app-ticket-material [materials]="replies.files" [read]="true"></app-ticket-material>
                    </div>
                </div>
            </div>
        </div>
    </nz-spin>

    <div *ngIf="repliesData.status !== 'CLOSED'" style="display: block;height: max-content;">
        <div style="margin-bottom: 10px;">
            <div style="margin-bottom: 10px;">
                <span>{{'回复內容 *'}}</span>
            </div>
            <textarea rows="4" nz-input [(ngModel)]="reply.content"></textarea>
<!--            <app-tinymce-editor [title]="'回复內容 *'" [externalHeight]="260"-->
<!--                                [(value)]="reply.content" [editing]="true"></app-tinymce-editor>-->
        </div>
        <app-ticket-material [materials]="reply.files" [uploadReq]="uploadReq"
                             [spinning]="spinning"
                             (valueChange)="onFilesChange($event)"
                             (materialModified)="onFileModified($event)"></app-ticket-material>
        <div style="height: 50px;border-radius: 10px;margin-top: 15px;"
             class="footer-button-primary">
            <a nz-button nzType="link" nzBlock
               appThrottleClick (throttleClick)="onReply()">
                <em nz-icon nzType="comment" nzTheme="outline"></em>
                <span>回 复</span>
            </a>
        </div>
    </div>
</div>
