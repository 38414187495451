import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterModule } from "@angular/router";
import { AccountsComponent } from './accounts/accounts.component';
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { AccountAddComponent } from './accounts/account-add.component';
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
    declarations: [LoginComponent, AccountsComponent, AccountAddComponent],
    imports: [
        RouterModule,
        CommonModule,
        BrowserAnimationsModule,
        SharedModule,
        NzMenuModule,
        NzIconModule,
        NzGridModule,
        NzButtonModule,
        NzAffixModule,
        NzInputModule,
        NzToolTipModule,
        NzSpinModule,
        NzSelectModule
    ]
})
export class AuthModule {
}
