import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base/base.component";

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.less']
})
export class SystemComponent extends BaseComponent implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
