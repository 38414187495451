import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    Information,
    Knowledge,
    KnowledgeMediaType,
    Material,
    MaterialUploadReq,
    Operator,
    ProductSearch
} from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { InformationService } from "../../information/information.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AuthService } from "../../passport/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzDrawerRef, NzDrawerService } from "ng-zorro-antd/drawer";
import { MaterialService } from "../../shared/services/material.service";
import { ProductService } from "../../product/product.service";
import { ArticleSelectionComponent } from "../../article/article-selection/article-selection.component";
import { KnowledgeService } from "../knowledge.service";

@Component({
    selector: 'app-knowledge-create',
    templateUrl: './knowledge-create.component.html',
    styleUrls: ['./knowledge-create.component.less']
})
export class KnowledgeCreateComponent implements OnInit {

    drawerRef: NzDrawerRef<KnowledgeCreateComponent, string>;

    knowledge: Knowledge = new Knowledge();
    productSearch: ProductSearch = new ProductSearch();
    operator: Operator = new Operator();
    typeOptions: PropertySelectOption[] = [];
    companyOptions: PropertySelectOption[] = [];
    productOptions: PropertySelectOption[] = [];
    // 封面上传接收用
    material: Material = new Material();

    loading: boolean = false;
    productLoading: boolean = false;
    uploadReq: MaterialUploadReq = MaterialUploadReq.fromKnowledge(this.knowledge);
    tagsOptions: PropertySelectOption[] = []

    @Output()
    knowledgeCreated: EventEmitter<Knowledge> = new EventEmitter<Knowledge>();

    constructor(private knowledgeService: KnowledgeService,
                private notification: NzNotificationService,
                private authService: AuthService,
                private messageService: NzMessageService,
                private drawerService: NzDrawerService,
                private materialService: MaterialService,
                private productService: ProductService,
                private informationService: InformationService) {
    }

    ngOnInit(): void {
        this.operator = this.authService.getOperator();
        this.operator.nickname = "CENTRE"
        this.knowledge.mediaType = KnowledgeMediaType.HTML;
        this.knowledgeService.types()
            .subscribe(
                types => {
                    for (let type of types) {
                        this.typeOptions?.push(new PropertySelectOption(type.name, type.id));
                    }
                },
                error => {
                });

        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });
    }

    onImportFromArticle(): void {
        const drawerRef = this.drawerService.create<ArticleSelectionComponent, { value: any }, string>({
            nzWidth: 1024,
            nzContent: ArticleSelectionComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.drawerRef = drawerRef;
            component.articleSelected
                .subscribe(
                    article => {
                        this.knowledge.title = article.title;
                        this.knowledge.cover = article.cover;
                        this.knowledge.content = article.content;

                        this.material.filePath = article.cover;
                    })
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }
    onCreate() {
        this.knowledgeService.create(this.knowledge)
            .subscribe(
                data => {
                    this.notification.success('資訊創建成功', '');
                    this.knowledgeCreated.emit(data);
                    this.drawerRef?.close();
                },
                () => {
                });
    }

    onProductSearch(value: string) {
        this.productLoading = true;
        this.productSearch.pageNum = 1
        this.productSearch.name = value
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productLoading = false;
                    this.productOptions = [];
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name, product.code));
                    })
                },
                () => {
                    this.productLoading = false;
                    // this.loading = false;
                });
    }

    onCoverChange(material: Material) {
        this.knowledge.cover = material?.filePath
    }

    typeChange(typeId: number): void {
        console.log(typeId);
    }

    // 公司请求产品
    companyChange(value: any) {
        this.productLoading = true;
        this.knowledge.productCode = null;
        this.productSearch.pageNum = 1;
        this.productSearch.companyCode = value;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    this.productLoading = false;
                    this.productOptions = [];
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name, product.code));
                    })
                },
                () => {
                    this.productLoading = false;
                });
    }

    onProductNextPage(): void {
        this.productLoading = true;
        this.productSearch.pageNum = this.productSearch.pageNum + 1;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    this.productLoading = false;
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name, product.code));
                    })
                },
                error => {
                    this.productLoading = false;
                });
    }

}
