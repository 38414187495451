import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    ApiResponse,
    PagedResp, ProcessLog,
    Product,
    ProductPropertyGroup,
    ProductPropertyGroupSearch,
    ProductSearch
} from "../../api/types";
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import { ProductService } from "../product.service";
import { ProductPropertyService } from "../product-property.service";
import { ToastrService } from "ngx-toastr";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { Router } from "@angular/router";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { API } from "../../api/api";

@Component({
    selector: 'app-product-property-groups',
    templateUrl: './product-property-groups.component.html',
    styleUrls: ['./product-property-groups.component.less']
})
export class ProductPropertyGroupsComponent implements OnInit {

    loading = false;
    groups: PagedResp<ProductPropertyGroup>;
    search: ProductPropertyGroupSearch = new ProductPropertyGroupSearch();

    uploader: FileUploader;

    spinning: boolean = false;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor(private productPropertyService: ProductPropertyService,
                private router: Router,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.productPropertyService.groupList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.groups = data;
                },
                error => {
                    this.loading = false;
                });

        let httpHeaders = this.productPropertyService.getAuthorizationHeaders();
        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            url: this.productPropertyService.productUrl + "/property/import-from-excel",
            method: "POST",
            itemAlias: "file",
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: true
        });

        /// 需要bind(this)，才能在对应方法里使用this.
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.productPropertyService.groupList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.groups = data;
                },
                error => {
                    this.loading = false;
                });
    }

    selectedImportFileOnChanged(event: Event) {

    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        this.uploader.clearQueue();
        let apiResponse = JSON.parse(response) as ApiResponse<Boolean>;
        if (apiResponse.data) {
            this.toastr.success("導入成功");
        } else {
            this.toastr.success("導入失敗");
        }
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

}
