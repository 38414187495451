import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base/base.component";

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.less']
})
export class InformationComponent extends BaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

}
