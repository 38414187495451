<div class="property">
    <div class="property-title">
        <label>{{ title | nullable }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div *ngIf="value" class="property-value">
        <div class="i18n">
            <div class="i18n-title">简体：</div>
            <div class="i18n-value">{{ value.zhHansCN | nullable }}</div>
        </div>
        <div class="i18n">
            <div class="i18n-title">繁体：</div>
            <div class="i18n-value">{{ value.zhHantHK | nullable }}</div>
        </div>
        <div class="i18n">
            <div class="i18n-title">英文：</div>
            <div class="i18n-value">{{ value.enUS | nullable }}</div>
        </div>
    </div>
</div>
