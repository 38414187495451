import { Injectable } from '@angular/core';
import { BaseService } from "../base/base.service";
import { HttpService } from "../api/http.service";
import { Observable } from "rxjs/internal/Observable";
import { ApiResponse, Information, Knowledge, KnowledgeSearch, KnowledgeType, PagedResp } from "../api/types";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { plainToInstance } from "class-transformer";

@Injectable({
    providedIn: 'root'
})
export class KnowledgeService extends BaseService {

    constructor(private http: HttpService) {
        super();
    }

    /**
     * 获取知识库类型列表
     */
    types(): Observable<KnowledgeType[]> {
        return this.http.get<ApiResponse<KnowledgeType[]>>(this.knowledgeUrl + '/types', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    /**
     * 根据获取知识库列表（分页）
     * @param search
     */
    knowledges(search: KnowledgeSearch): Observable<PagedResp<Knowledge>> {

        let params = new HttpParams();
        if (!isNullOrUndefined(search.typeId)) {
            params = params.set('typeId', search.typeId.toString());
        }
        if (!isNullOrUndefined(search.companyCode)) {
            params = params.set('companyCode', search.companyCode.toString());
        }
        if (!isNullOrUndefined(search.keyword)) {
            params = params.set('keyword', search.keyword.toString());
        }
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        return this.http.get<ApiResponse<PagedResp<Knowledge>>>(this.knowledgeUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    /**
     * 知识库详情
     * @param id 知识库ID
     */
    knowledgeInfo(id: number): Observable<Knowledge> {
        return this.http.get<ApiResponse<Knowledge>>(this.knowledgeUrl + '/info/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    knowledgeDelete(id: number): Observable<string> {
        return this.http.delete<ApiResponse<string>>(this.knowledgeUrl + '/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    notify(id: number): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.knowledgeUrl + '/' + id + '/notify',  null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    create(knowledge: Knowledge): Observable<Knowledge> {
        return this.http.post<ApiResponse<Knowledge>>(this.knowledgeUrl + '/create', knowledge, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(Knowledge, data.body.data);
        }));
    }

    update(knowledge: Knowledge) {
        return this.http.put<ApiResponse<Knowledge>>(this.knowledgeUrl + '/update', knowledge, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(Knowledge, data.body.data);
        }));
    }
}
