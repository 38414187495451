import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productCompanyRegion'
})
export class ProductCompanyRegionPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let region = "";
        switch (value) {
            case 'HONGKONG':
                region = "香港 - HONGKONG";
                break;
            case 'MACAO':
                region = "澳门 - MACAO";
                break;
            case 'CHINA':
                region = "中国 - CHINA";
                break;
            case 'SINGAPORE':
                region = "新加坡 - SINGAPORE";
                break;
            case 'CANADA':
                region = "加拿大 - CANADA";
                break;
            case 'ASIA':
                region = "亞洲 - ASIA";
                break;
            case 'BERMUDA':
                region = "百慕大 - BERMUDA";
                break;
            case 'NEWYORK':
                region = "紐約 - NEWYORK";
                break;
            case 'USA':
                region = "美国 - USA";
                break;
        }
        return region;
    }

}
