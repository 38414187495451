import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Material, MaterialModifyReq, MaterialUploadReq, MaterialUploadTokenReq } from '../../api/types';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { DownloadService } from '../../shared/services/download.service';
import { MaterialService } from '../../shared/services/material.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ContentTypesService } from '../../shared/services/content-types.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from '../../common/utils';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';


@Component({
  selector: 'app-ticket-material',
  templateUrl: './ticket-material.component.html',
  styleUrls: ['./ticket-material.component.less']
})
export class TicketMaterialComponent implements OnInit, AfterViewInit {
    @Input()
    highPosition: boolean = false;
    @Input()
    read: boolean = false;

    @Input()
    spinning: boolean = false;

    @Input()
    uploadReq: MaterialUploadReq;

    @Input()
    materials: Material[] = [];

    _tags: MaterialTag[] = [];

    @Input()
    set tags(val: string[]) {
        if (val) {
            let newTags: MaterialTag[] = [];
            for (let tag of val) {
                let materialTag = new MaterialTag();
                materialTag.checked = this.isTagChecked(tag); // 缓存状态
                materialTag.tag = tag;
                newTags.push(materialTag);
            }
            this._tags = newTags;
            // 重新刷新列表
            this.refreshMaterialList();
        }
    }

    @Output()
    valueChange: EventEmitter<Material[]> = new EventEmitter<Material[]>();

    @Output()
    materialModified: EventEmitter<MaterialModifyReq> = new EventEmitter<MaterialModifyReq>();

    @Output()
    materialUploaded: EventEmitter<Material> = new EventEmitter<Material>();

    @Output()
    materialRemoved: EventEmitter<Material> = new EventEmitter<Material>();

    uploader: FileUploader;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    materialMap: Map<string, Material> = new Map();

    setTagsWhenUpload = false;

    editorLoading = false;

    constructor(
        private downloadService: DownloadService,
        private materialService: MaterialService,
        private modalService: NzModalService,
        private contentTypeService: ContentTypesService,
        private toastr: ToastrService,
        private httpClient: HttpClient) {
    }

    ngOnInit(): void {

        let httpHeaders = this.materialService.getAuthorizationHeaders();

        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        headers.push({name: 'x-oss-object-acl', value: 'private'});
        this.uploader = new FileUploader({
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: false,
            removeAfterUpload: false,
        });

        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

        this.makeAllVisible();
    }

    ngAfterViewInit(): void {
        // const options = this.uploader.options;
        // options.additionalParameter = {
        //     "uploadTo": this.uploadReq.uploadTo,
        //     "key": this.uploadReq.key
        // };
        // this.uploader.setOptions(options);
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    upload(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    uploadFileItem(fileItem: FileItem) {
        const req: MaterialUploadTokenReq = {
            uploadTo: this.uploadReq.uploadTo,
            key: this.uploadReq.key,
            fileName: fileItem.file.name
        }
        this.materialService.uploadToken(req)
            .subscribe(
                data => {
                    fileItem.method = "POST";
                    fileItem.url = data.host;
                    fileItem.onBuildForm = function (form) {
                        // form.append('x-oss-object-acl', data.material.accessControl.toLowerCase());
                        form.append('key', data.material.objectName);
                        form.append('policy', data.policy);
                        form.append('signature', data.signature);
                        form.append('OSSAccessKeyId', data.accessid);
                    };
                    fileItem.upload();
                    data.material.visible = true;
                    this.materialMap.set(fileItem.file.name, data.material);
                },
                error => {
                });
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
        this.uploadFileItem(fileItem);
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

        this.uploader.removeFromQueue(item);

        if (isNullOrUndefined(this.materials)) {
            this.materials = [];
        }
        const material = this.materialMap.get(item.file.name);
        if (!isNullOrUndefined(material)) {

            if (this.setTagsWhenUpload) {
                let selectedTags = this._tags.filter(tag => {
                    return tag.checked
                }).map(tag => {
                    return tag.tag
                });
                material.tags = selectedTags;
            }

            this.materials?.push(material);

            this.materialMap.delete(item.file.name);

            this.valueChange.emit(this.materials);

            if (this.materialUploaded) {
                this.materialUploaded.emit(material);
            }
        }

        // this.uploader.clearQueue();
        // console.info(response + " for " + item.file.name + " status " + status);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.uploader.removeFromQueue(item);

        // this.toastr.warning(response.);
        // this.uploader.clearQueue();
        console.info(response + " for " + item.file.name + " status " + status);
    }

    extension(filePath: string): string {
        if (isNullOrUndefined(filePath) || filePath.length === 0) return ""
        return filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase()
    }

    onDownload(material: Material) {
        let path = encodeURI(material.filePath);
        let fileName = encodeURI(material.fileName);
        this.downloadService.download(path, fileName);
        // this.downloadService.showOpenDialog(material.fileName);
    }

    onDelete(index: number) {

        if (isNullOrUndefined(this.materials)) {
            this.materials = [];
            return;
        }

        if (this.materials.length < index) {
            return;
        }

        this.modalService.confirm({
            nzTitle: '删除文件',
            nzContent: '<b style="color: #ff0048; font-size: 16px">是否确定删除' + this.materials[index].fileName + '？</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                if (this.materialRemoved) {
                    this.materialRemoved.emit(this.materials[index])
                }
                this.materials.splice(index, 1);
                if (this.valueChange) {
                    this.valueChange.emit(this.materials)
                }
            }
        });

    }

    canPreview(material: Material): boolean {
        let result;
        const fileName = material.fileName ?? '';
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'pdf':
                result = true;
                break;
            default:
                result = false;
                break;
        }
        return result;
    }

    onPreview(material: Material) {

        const fileName = material.fileName ?? '';
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'pdf':
                this.onMaterialPreview(material);
                break;
            default:
                let path = encodeURI(material.filePath)
                window.open(path).focus();
                break;
        }

    }

    onMaterialPreview(material: Material): void {
        let path = encodeURI(material.filePath);
        const contentType = this.contentTypeService.get(material.filePath);
        this.httpClient
            .get(path, {
                responseType: 'arraybuffer'
            }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)))
            .subscribe(
                byteArray => {
                    const url = window.URL.createObjectURL(new Blob([byteArray], {type: contentType}));
                    window.open(url);
                }
            );
    }

    onShowEditorModal(i: number, editorTitle: TemplateRef<any>, editorContent: TemplateRef<any>, editorFooter: TemplateRef<any>) {

        let file = _.cloneDeep(this.materials[i]);

        let modifyReq = new MaterialModifyReq();
        modifyReq.index = i;
        modifyReq.file = file;

        this.modalService.create({
            nzCentered: true,
            nzTitle: editorTitle,
            nzContent: editorContent,
            nzFooter: editorFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzComponentParams: modifyReq,
            nzOnOk: () => console.log('Click ok')
        });
    }

    onEditorConfirm(ref: NzModalRef, modifyReq: MaterialModifyReq) {
        ref.destroy();
        if (this.materialModified) {
            this.materialModified.emit(modifyReq);
        }
    }

    onTagCheckChanged(val: boolean, materialTag: MaterialTag) {
        materialTag.checked = val;
        if (!this.materials) {
            return;
        }
        this.refreshMaterialList();
    }

    refreshMaterialList(): void {

        console.log('refreshMaterialList');
        console.log(this._tags);

        let ts: string[] = [];
        for (let tag of this._tags) {
            if (tag.checked) {
                ts.push(tag.tag);
            }
        }

        if (ts.length <= 0) {
            this.makeAllVisible();
            return;
        }

        for (let material of this.materials) {
            material.visible = false;
        }

        for (let material of this.materials) {
            if (material.tags) {
                let visible = false;
                for (let tag of material.tags) {
                    for (let t of ts) {
                        if (t === tag) {
                            visible = true;
                            break;
                        }
                    }
                }
                material.visible = visible;
            }
        }
    }

    isTagChecked(tag: string): boolean {
        let val = this._tags.find(t => {
            return t.tag == tag
        });
        if (val === undefined) return false;
        return val.checked;
    }

    makeAllVisible(): void {
        if (this.materials) {
            for (let material of this.materials) {
                material.visible = true;
            }
        }
    }
}

export class MaterialTag {
    checked: boolean;
    tag: string;
}
