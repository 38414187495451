import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-property-tags',
    templateUrl: './property-tags.component.html',
    styleUrls: ['./property-tags.component.scss']
})
export class PropertyTagsComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() required: boolean = false;

    @Output()
    valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    innerValue: string[];

    @Input()
    get value() {
        return this.innerValue;
    }
    set value(val) {
        this.innerValue = val;
        this.valueChange.emit(this.innerValue);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
