<div style="height: 100%; padding-bottom: 60px">
    <div class="search-title">
        <i nz-icon nzType="search" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 20px;">公司搜索</span>
    </div>

    <app-property-text-edit [title]="'公司碼'" [(value)]="search.code"></app-property-text-edit>

    <app-property-text-edit [title]="'公司名稱'" [(value)]="search.name"></app-property-text-edit>

    <app-property-select-edit [title]="'地区'" [(value)]="search.region"
                              [options]="regionOptions"></app-property-select-edit>

    <app-property-select-edit [title]="'业务类型'" [(value)]="search.businessType"
                              [options]="businessTypeOptions"></app-property-select-edit>

    <app-property-switch [title]="'是否热门'" [(value)]="search.hot" [editing]="true"
                         [editable]="true"></app-property-switch>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onSearch()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzType="search" nzTheme="outline"></em>
                    <span>搜 索</span>
                </a>
            </div>
        </div>
    </div>
</div>
