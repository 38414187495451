import { Injectable, OnInit } from '@angular/core';

// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { App, BrowserWindow, ipcMain, ipcRenderer, remote, session, shell, webFrame } from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';

@Injectable({
    providedIn: 'root'
})
export class ElectronService implements OnInit {
    // app: App;
    ipcMain: typeof ipcMain;
    ipcRenderer: typeof ipcRenderer;
    webFrame: typeof webFrame;
    remote: typeof remote;
    session: typeof session;
    childProcess: typeof childProcess;
    fs: typeof fs;
    shell: typeof shell;

    // win: BrowserWindow;

    get isElectron(): boolean {
        return !!(window && window.process && window.process.type);
    }

    ngOnInit(): void {
    }

    constructor() {
        // Conditional imports
        if (this.isElectron) {

            let electron = window.require('electron');

            this.ipcMain = electron.ipcMain;
            this.ipcRenderer = electron.ipcRenderer;
            this.webFrame = electron.webFrame;

            this.remote = window.require('@electron/remote');

            this.session = this.remote.session;
            this.shell = this.remote.shell;

            this.childProcess = window.require('child_process');
            this.fs = window.require('fs');
        }
    }

    get win(): BrowserWindow {
        return this.remote.getCurrentWindow();
    }

    get app(): App {
        return this.remote.app;
    }

    openUrl(url: string): void {
        if (this.isElectron) {
            this.shell?.openExternal(url);
        } else {
            window.open(url);
        }
    }

}
