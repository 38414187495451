import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketComponent } from './ticket.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { TicketInfoComponent } from './ticket-info/ticket-info.component';
import { TicketMaterialComponent } from './ticket-material/ticket-material.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FileUploadModule } from 'ng2-file-upload';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
    declarations: [TicketComponent, TicketListComponent, TicketInfoComponent, TicketMaterialComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        SharedModule,
        NzIconModule,
        NzSpinModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzTagModule,
        NzButtonModule,
        NzAffixModule,
        NzSkeletonModule,
        NzEmptyModule,
        NzDrawerModule,
        NzCheckboxModule,
        FileUploadModule,
        NzProgressModule,
        NzAvatarModule,
        NzPopoverModule,
        NzDatePickerModule,
        NzInputModule
    ]
})
export class TicketModule { }
