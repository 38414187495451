import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from "../product.service";
import { PagedResp } from "../../api/types";
import { ProductCompanyInfoComponent } from "./product-company-info.component";
import { ToastrService } from "ngx-toastr";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ProductCompanyCreateComponent } from "./product-company-create.component";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../common/utils";
import { DataService } from "../../shared/services/data.service";
import { Router } from "@angular/router";
import { CompanySearchComponent } from "../components/company-search/company-search.component"
import { ProductCompany, ProductCompanySearch } from '../product-types';
import { NzModalService } from "ng-zorro-antd/modal";
import { OperatorService } from "../../operator/operator.service";

@Component({
    selector: 'app-product-company',
    templateUrl: './product-companies.component.html',
    styleUrls: ['./product-companies.component.less']
})
export class ProductCompaniesComponent implements OnInit {

    spinning = false;

    companies: PagedResp<ProductCompany>;
    search: ProductCompanySearch = new ProductCompanySearch();

    loading: boolean = false;

    showSearchDrawer: boolean = false;
    @ViewChild('company')
    companyInfoComponent: ProductCompanyInfoComponent;
    @ViewChild('search')
    companySearchComponent: CompanySearchComponent;

    constructor(private productService: ProductService,
                private drawerService: NzDrawerService,
                private router: Router,
                private dataService: DataService,
                private modalService: NzModalService,
                private toastr: ToastrService,
                private operatorService: OperatorService,) {
    }

    ngOnInit(): void {
        this.load();
    }

    load(): void {
        this.loading = true
        this.productService.companyList(this.search)
            .subscribe(
                data => {
                    this.loading = false
                    this.companies = data;
                },
                () => {
                    this.loading = false
                });
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum;
        this.load();
    }

    onReload() {
        this.search.pageNum = 1;
        this.load();
    }

    openInfoDrawer(code: string): void {

        this.productService.companyInfo(code)
            .subscribe(
                company => {
                    const drawerRef = this.drawerService.create<ProductCompanyInfoComponent, { value: string }, ProductCompany>({
                        nzWidth: 800,
                        nzContent: ProductCompanyInfoComponent,
                        nzContentParams: {
                            company
                        }
                    });

                    drawerRef.afterOpen.subscribe(() => {
                        const component = drawerRef.getContentComponent();
                        component.drawerRef = drawerRef;
                        component.companyUpdated
                            .subscribe(
                                () => {
                                    // this.onReload();
                                },
                                () => {
                                });
                    });

                    drawerRef.afterClose.subscribe(data => {
                        if (typeof data === 'string') {
                        }
                    });
                },
                error => {
                });
    }

    updateExchangeRate() {
        this.productService.updateExchangeRate()
            .subscribe(
                data => {
                    this.toastr.success('同步匯率任務提交成功，請耐心等待幾分鐘.');
                },
                error => {
                    this.toastr.success('同步匯率任務提交失敗.');
                });
    }

    onCreateCompany() {
        const drawerRef = this.drawerService.create<ProductCompanyCreateComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProductCompanyCreateComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.companyCreated
                .subscribe(
                    data => {
                        drawerRef.close();
                        this.openInfoDrawer(data.code);
                    },
                    error => {
                    });
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    checkAllEvent(checked: boolean) {
        if (isNotNullOrUndefined(this.companies.list)) {
            this.companies.list.forEach(productCompany =>
                productCompany.checked = checked
            )
        }
    }

    get selectedProductCompanies(): ProductCompany[] {
        let productCompanys: ProductCompany[] = []
        if (!isNullOrUndefined(this.companies)) {
            productCompanys = this.companies.list.filter(product => {
                return product.checked
            })
        }
        return productCompanys;
    }

    onProductInstanceSync() {
        this.dataService.productCompaniesToSync = this.selectedProductCompanies;
        this.router.navigate(['/operator/product/instance-sync-company']);
    }

    openSearchDrawer(): void {
        this.showSearchDrawer = true;
    }

    closeSearchDrawer(): void {
        this.showSearchDrawer = false;
        this.search.code = '';
        this.search.name = '';
    }

    /**
     * 公司搜索
     * @param productCompanySearch 搜索参数
     * @param resetPageNum 是否重置页数
     */
    onProductCompanySearch(productCompanySearch: ProductCompanySearch, resetPageNum: boolean = true): void {
        this.showSearchDrawer = false;
        this.search = productCompanySearch;
        if (resetPageNum) {
            this.search.pageNum = 1
        }
        this.load();
    }

    onDelete(company: ProductCompany): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除' + company.name + '？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎删除，删除后将无法恢复公司</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.productService.companyDelete(company.code)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.onSearch(this.search.pageNum);
                        },
                        error => {
                            this.loading = false;
                        });
            },
            nzOnCancel: () => {
            }
        });
    }

    exportAICompanyLinks() {
        this.spinning = true;
        this.productService.companyAIExportLinks()
            .subscribe(
                data => {
                    this.spinning = false;
                },
                error => {
                    this.spinning = false;
                });

    }

    hasAuthority(authority: string) : boolean {
        return this.operatorService.hasAuthority(authority);
    }

    hasAuthorityOr(...authorities: string[]) : boolean {
        for (let authority of authorities) {
            if (this.operatorService.hasAuthority(authority)) {
                return true;
            }
        }
        return false;
    }
}
