import { Component, OnInit } from '@angular/core';
import { OperatorService } from "./operator.service";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.less']
})
export class OperatorComponent implements OnInit {

    isCollapsed: boolean = false;

    version: string = ''
    env: string = ''

    constructor(private operatorService: OperatorService,
                private router: Router,
                private modalService: NzModalService,) {
    }

    ngOnInit(): void {
        let packageJson = require('../../../package.json');
        this.version = packageJson.version;
        this.env = this.operatorService.env;

        // this.operatorService.datetimeTest()
        //     .subscribe(data => {
        //         console.log(data);
        //     });
        // this.operatorService.datetimeTestGet()
        //     .subscribe(data => {
        //         console.log(data);
        //     });
    }

    logout() {

        this.modalService.confirm({
            nzTitle: '退出登录',
            nzContent: '<b style="color: #ff0048; font-size: 16px">是否退出登录？</b>',
            nzOkText: '退出',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.operatorService.removeOperator();
                this.router.navigate(['/auth/login']);
            },
            nzOnCancel: () => {
            }
        });
    }

    hasAuthority(authority: string): boolean {
        return this.operatorService.hasAuthority(authority);
    }

    hasAuthorityOr(...authorities: string[]): boolean {
        for (let authority of authorities) {
            if (this.operatorService.hasAuthority(authority)) {
                return true;
            }
        }
        return false;
    }
}
