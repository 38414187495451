import { Injectable } from '@angular/core';

import { API } from '../api/api';
import { BaseService } from '../base/base.service';
import { HttpService } from '../api/http.service';
import { ApiResponse, LoginByAccountRequest, LoginByEmailRequest, Operator } from "../api/types";

@Injectable()
export class AuthService extends BaseService {

    redirectUrl: string;

    constructor(private http: HttpService) {
        super();
    }

    loginByEmail(email: string, password: string,
        success: (result: any) => void,
        failure: (result: any) => void): void {

        const entity: LoginByEmailRequest = { email: email, password: password };

        this.http.post<ApiResponse<Operator>>(this.passportUrl + '/login/email', entity, { headers: this.getHeaders(), observe: 'response' })
            .subscribe(
                data => {
                    const operator = data.body.data;
                    this.setOperator(operator);
                    success(data);
                },
                error => {
                    failure(error);
                }
            );
        // return Observable.of(true).delay(1000).do(val => this.isLoggedIn = true);
    }

    loginByAccount(account: string, password: string,
                 success: (result: any) => void,
                 failure: (result: any) => void): void {

        const entity: LoginByAccountRequest = { account: account, password: password };

        this.http.post<ApiResponse<Operator>>(this.passportUrl + '/login/account', entity, { headers: this.getHeaders(), observe: 'response' })
            .subscribe(
                data => {
                    const operator = data.body.data;
                    this.setOperator(operator);
                    success(data);
                },
                error => {
                    failure(error);
                }
            );
        // return Observable.of(true).delay(1000).do(val => this.isLoggedIn = true);
    }

    logout(): void {
        this.removeOperator();
    }

}
