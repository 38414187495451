import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productRestrictionType'
})
export class ProductRestrictionTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let restrictionType = "";
        switch (value) {
            case 'AGE':
                restrictionType = "年齡";
                break;
            case 'PREMIUM':
                restrictionType = "保費";
                break;
            case 'SUM_INSURED':
                restrictionType = "保額";
                break;
            case 'WITHDRAWAL':
                restrictionType = "提取計劃";
                break;
        }
        return restrictionType;
    }

}
