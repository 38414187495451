import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNotNullOrUndefined } from "../../../common/utils";

@Component({
  selector: 'app-property-datetime',
  templateUrl: './property-datetime.component.html',
  styleUrls: ['./property-datetime.component.less']
})
export class PropertyDatetimeComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() format: string = 'yyyy-MM-dd HH:mm:ss';

    @Output()
    valueChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    get value() {
        return this.datetime?.getTime();
    }
    set value(val) {
        if (isNotNullOrUndefined(val)) {
            this.datetime = new Date(val);
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    datetime: Date

    onChange(date: Date) {
        this.datetime = date;
        // console.log(this.datetime.toISOString());
        this.valueChange.emit(this.datetime?.getTime());
    }
}
