import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tag-editor',
    templateUrl: './tag-editor.component.html',
    styleUrls: ['./tag-editor.component.less']
})
export class TagEditorComponent implements OnInit {

    @Input() editable: boolean = false;
    @Input() titleOfNew = '添加';
    inputVisible = false;
    inputValue = '';

    @ViewChild('inputElement', {static: false}) inputElement?: ElementRef;

    @Output()
    valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    innerValue: string[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }

    @Input()
    get value() {
        return this.innerValue || [];
    }

    set value(val) {
        this.innerValue = val;
        this.valueChange.emit(this.innerValue);
    }

    handleClose(removedTag: {}): void {
        this.value = this.value.filter(tag => tag !== removedTag);
    }

    sliceTagName(tag: string): string {
        if( tag === undefined || tag === null || tag === '' ) {
            return '';
        }
        const isLongTag = tag.length > 20;
        return isLongTag ? `${tag.slice(0, 20)}...` : tag;
    }

    showInput(): void {
        this.inputVisible = true;
        setTimeout(() => {
            this.inputElement?.nativeElement.focus();
        }, 10);
    }

    handleInputConfirm(): void {
        if (this.inputValue && this.value.indexOf(this.inputValue) === -1) {
            this.value = [...this.value, this.inputValue];
        }
        this.inputValue = '';
        this.inputVisible = false;
    }
}
