import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PagedResp, Product, ProductSearch, ProductStatus, ProductType } from "../../../api/types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { ProductService } from "../../product.service";
import { debounceTime } from "rxjs/operators";
import { isNullOrUndefined } from "../../../common/utils";

@Component({
    selector: 'app-product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.less']
})
export class ProductSelectionComponent implements OnInit, AfterViewInit {

    /**
     * single：单选
     * multiple：多选
     */
    @Input()
    mode: 'single' | 'multiple' = 'multiple';

    loading = false;
    products: PagedResp<Product>;

    searchParamChange: EventEmitter<any> = new EventEmitter<any>();

    search: ProductSearch = new ProductSearch();

    addedProducts: { [key: string]: Product; } = {};

    @Output()
    productsSelected: EventEmitter<Product[]> = new EventEmitter<Product[]>();

    @Output()
    productSelected: EventEmitter<Product> = new EventEmitter<Product>();

    typeOptions: PropertySelectOption[] = []
    statusOptions: PropertySelectOption[] = []
    categoryOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []


    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {

        this.typeOptions?.push(new PropertySelectOption('全部', null));
        this.typeOptions?.push(new PropertySelectOption('基本計劃', ProductType.BASIC));
        this.typeOptions?.push(new PropertySelectOption('附加計劃', ProductType.RIDER));
        this.statusOptions?.push(new PropertySelectOption('全部', null));
        this.statusOptions?.push(new PropertySelectOption('在售', ProductStatus.SELLING));
        this.statusOptions?.push(new PropertySelectOption('停售', ProductStatus.DISCONTINUED));
        this.productService.categories()
            .subscribe(
                data => {
                    this.categoryOptions?.push(new PropertySelectOption('全部險種', null));
                    data.forEach(category => {
                        this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
                    })
                },
                error => {
                });
        this.productService.companies()
            .subscribe(
                data => {
                    this.companyOptions?.push(new PropertySelectOption('全部公司', null));
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });

        this.searchParamChange.pipe(debounceTime(1000))
            .subscribe(
                data => {
                    this.onSearch(1);
                },
                error => {
                }
            )

    }

    ngAfterViewInit(): void {
    }

    onSearchParamChange() {
        this.searchParamChange.emit("");
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onSelected(product: Product) {
        switch (this.mode) {
            case "single":
                this.selectProduct(product);
                break;
            case "multiple":
                this.checkProduct(product);
                break;
        }
    }

    onAddProduct(product: Product) {
        this.addedProducts[product.code] = product;
        this.productsSelected.emit([product]);
    }

    checkProduct(product: Product) {
        product.checked = !product.checked;
    }

    selectProduct(product: Product) {
        this.productSelected.emit(product);
    }

    get selectedProducts() : Product[] {
        let products : Product[] = []
        if (!isNullOrUndefined(this.products) && !isNullOrUndefined(this.products.list)) {
            products = this.products.list.filter(product => { return product.checked})
        }
        return products;
    }

    onConfirm() {
        this.productsSelected.emit(this.selectedProducts);
    }

    setAddedProducts(products: Product[]) {
        this.addedProducts = {};
        products.forEach( product => {
            this.addedProducts[product.code] = product;
        })
    }

}
