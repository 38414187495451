<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

        <div *ngIf="!restrictions">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="restrictions" style="margin-top: 30px">
            <nz-table [nzBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="restrictions.list"
                      [nzPageIndex]="restrictions.pageNum"
                      [nzPageSize]="restrictions.pageSize"
                      [nzTotal]="restrictions.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)">
                <ng-template #tableHeader>
                    <!--            <div>-->
                    <!--                <i nz-icon nzType="search" nzTheme="outline" style="color: #95AAC9;"></i>-->
                    <!--            </div>-->
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">投保限制列表
                            </div>
                            <!--                    <input class="search-input" type="text" placeholder="產品名稱"/>-->
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
                                       (change)="selectedImportFileOnChanged($event)"/>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="上传投保限制"
                                        appThrottleClick (throttleClick)="selectImportFile()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-upload" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>
                                <!--                        <button nz-button nzType="default" nzSize="large" nzShape="circle" appThrottleClick (throttleClick)="openSearchDrawer()"-->
                                <!--                                style=" margin-left: 10px;">-->
                                <!--                            <i nz-icon nzType="search" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>-->
                                <!--                        </button>-->
                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ restrictions.totalCount }}</span> 條數據
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft>產品碼</th>
                    <th nzLeft>產品名稱</th>
                    <th>供款年期</th>
                    <th>限制類型</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let restriction of restrictions.list" appThrottleClick (throttleClick)="openInfoDrawer(restriction)">
                    <td nzLeft nzBreakWord>{{ restriction.code }}</td>
                    <td>{{ restriction.productName }}</td>
                    <td>{{ restriction.premiumTerm }}</td>
                    <td>{{ restriction.type | productRestrictionType }}</td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </nz-spin>
</div>
