<div class="login-container">

    <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
        <!--        <div nz-col [nzSpan]="10" class="login-column">-->
        <!--            <img width="75%" src="./assets/images/login_illustration.svg">-->
        <!--        </div>-->
        <div nz-col [nzSpan]="12" class="login-column">
            <form (ngSubmit)="loginByAccount()" #loginForm="ngForm" style="width: 100%" class="login-column">
                <img width="80" src="./assets/images/logo.png" style="margin-bottom: 20px">
                <div class="login-title">{{ '登录' }}</div>
                <div class="login-sub-title" style="margin-top: 10px; margin-bottom: 10px">{{ 'BEEFINTECH CENTRE'
                    }}
                </div>
                <div style="width: 50%; margin-top: 20px;">

                    <nz-input-group [nzSuffix]="suffixIconAccount" class="input-group">
                        <input required [(ngModel)]="loginByAccountReq.account" id="account" name="account"
                               placeholder="账号"
                               class="login-input"/>
                    </nz-input-group>
                    <ng-template #suffixIconAccount>
                        <!--                        <i style="font-size: 20px" nz-icon nzType="user-switch"></i>-->
                    </ng-template>

                </div>
                <div style="width: 50%; margin-top: 20px;">

                    <nz-input-group [nzSuffix]="suffixIconLogin" class="input-group">
                        <input required [(ngModel)]="loginByAccountReq.password" id="password" name="password"
                               type="password"
                               placeholder="密码" class="login-input"/>
                    </nz-input-group>
                    <ng-template #suffixIconLogin>
                        <!--                        <i style="font-size: 20px" nz-icon nzType="play-circle"></i>-->
                    </ng-template>

                </div>
                <div style="margin-top: 30px;">
                    <button style="width: 50px; height: 50px; margin-right: 10px;" type="button"
                            nz-tooltip nzTooltipTitle="切换账号" [disabled]="logging"
                            nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onAccounts()">
                        <i nz-icon nzType="user-switch" ></i>
                    </button>
                    <button style="width: 50px; height: 50px; margin-left: 10px;" type="submit" [nzLoading]="logging"
                            nz-tooltip nzTooltipTitle="立即登录"
                            nz-button nzType="default" nzShape="circle">
                        <i nz-icon nzType="login"></i>
                    </button>
                </div>
                <div style="width: 60%; margin-top: 20px;">
                    <!--<label nz-checkbox [(ngModel)]="rememberPassword" name="rememberPassword">记住密码</label>-->
                    <!--<input style="padding: 10px" type="checkbox" name="vehicle" value="Car" checked="checked" /> 记住密码-->
                </div>
            </form>
        </div>
    </div>
</div>
