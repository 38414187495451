<div *ngIf="knowledge">
    <div class="profile-large-title">
        <span style="font-weight: bold; width: 100%; font-size: 28px;">{{ knowledge.title }}</span>
    </div>

    <app-property-text [title]="'标题'" [(value)]="knowledge.title"
                       [editable]="true" [editing]="editing"></app-property-text>

    <app-property-datetime [title]="'发布時間 *'" [(value)]="knowledge.issueDate" [editable]="true" [editing]="editing"
                           format="yyyy-MM-dd"></app-property-datetime>

<!--    <app-property-text [title]="'发布时间'" [value]="knowledge.issueDate | date:'yyyy-MM-dd'"-->
<!--                       [editable]="false"></app-property-text>-->

    <app-property-select [title]="'類型 *'" [(value)]="knowledge.typeId" [editable]="true" [editing]="editing"
                              [options]="typeOptions" (valueChange)="typeChange($event)"></app-property-select>

    <app-property-select [title]="'標籤'" [editable]="true" [editing]="editing" [name]="knowledge.tags"
                         [(value)]="knowledge.tags" [mode]="'multiple'"
                         [options]="tagsOptions"></app-property-select>

    <app-property-text [type]="contentTextType" [title]="'内容'"
                         [(value)]="knowledge.content" [editable]="false"></app-property-text>

<!--    <div  [ngSwitch]="knowledge.mediaType">-->
<!--        <div *ngSwitchCase="'HTML'">-->
<!--            <app-property-text [title]="'内容'" [(value)]="knowledge.content"-->
<!--                               [editing]="editing" [editable]="true" [type]="contentTextType"></app-property-text>-->
<!--        </div>-->
<!--        <div *ngSwitchCase="'VIDEO'">-->
<!--            <video style="width: 100%" [src]="knowledge.content" controls="controls"></video>-->
<!--        </div>-->
<!--    </div>-->


    <div class="footer">
        <a *ngIf="!editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
            <span>编 辑</span>
        </a>
        <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
<!--            <div nz-col nzSpan="12" class="footer-button">-->
<!--                <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock>-->
<!--                    <i nz-icon nzType="close" nzTheme="outline"></i>-->
<!--                    <span>取 消</span>-->
<!--                </a>-->
<!--            </div>-->

            <div nz-col nzSpan="12" class="footer-button">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onImportFromArticle()" nzBlock nzSize="large">
                    <em nz-icon nzType="import" nzTheme="outline"></em>
                    <span>從文章庫中導入</span>
                </a>
            </div>

            <div nz-col nzSpan="12" class="footer-button-primary">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock>
                    <i nz-icon nzType="save" nzTheme="outline"></i>
                    <span>保 存</span>
                </a>
            </div>
        </div>
    </div>
</div>
