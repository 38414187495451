<div class="property">
    <div class="property-title">
        <label>{{ title | nullable }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div *ngIf="value" class="property-value">
        <div class="i18n-edit">
            <div class="i18n-title">简体：</div>
            <div class="i18n-value">
                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.zhHansCN" placeholder=""/>
            </div>
        </div>
        <div class="i18n-edit">
            <div class="i18n-title">繁体：</div>
            <div class="i18n-value">
                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.zhHantHK" placeholder=""/>
            </div>
        </div>
        <div class="i18n-edit">
            <div class="i18n-title">英文：</div>
            <div class="i18n-value">
                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.enUS" placeholder=""/>
            </div>
        </div>
    </div>
</div>
