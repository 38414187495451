<div style="height: 100%; padding-bottom: 60px">
    <div class="search-title">
        <i nz-icon nzType="search" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 20px;">產品搜索</span>
    </div>

    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

        <div style="margin-bottom: 60px">
            <app-property-text-edit [title]="'產品碼'" [(value)]="search.code"></app-property-text-edit>

            <app-property-text-edit [title]="'產品名称'" [(value)]="search.name"></app-property-text-edit>

            <app-property-select-edit [title]="'類型'" [(value)]="search.type"
                                      [options]="typeOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'地区'" [(value)]="search.region"
                                      [options]="regionOptions"
                                      (valueChange)="regionChange($event)"></app-property-select-edit>

            <app-property-select-edit [title]="'保險險種'" [(value)]="search.categoryCode"
                                      [options]="categoryOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'保險公司'" [(value)]="search.companyCode" [showSearch]="true"
                                      [options]="companyOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'状态'" [(value)]="search.status"
                                      [options]="statusOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否标准产品'" [(value)]="search.standard"
                                      [options]="standardOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否热门产品'" [(value)]="search.hot"
                                      [options]="hotOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否可對比'" [(value)]="search.comparable"
                                      [options]="comparableOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否可试算'" [(value)]="search.quotable"
                                      [options]="quotableOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否适用提取计划'" [(value)]="search.withdrawalable"
                                      [options]="withdrawalableOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否支持计划书机器人'" [(value)]="search.proposalRPASupported"
                                      [options]="proposalRPASupportedOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否支持製作規劃書'" [(value)]="search.financialPlanSupported"
                                      [options]="financialPlanSupportedOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否支持保費融資'" [(value)]="search.premiumFinancingSupported"
                                      [options]="premiumFinancingSupportedOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否支持保費扣除'" [(value)]="search.premiumOffsetSupported"
                                      [options]="premiumOffsetSupportedOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否支持計劃書預繳'" [(value)]="search.proposalPrepaymentSupported"
                                      [options]="proposalPrepaymentSupportedOptions"></app-property-select-edit>

            <app-property-select-edit [title]="'是否有小冊子'" [(value)]="search.hasBrochure"
                                      [options]="hasBrochureOptions"></app-property-select-edit>


        </div>
        <!--    <app-property-switch [title]="'是否可對比'" [(value)]="search.comparable" [editing]="true"-->
        <!--                         [editable]="true"></app-property-switch>-->

        <!--    <app-property-switch [title]="'是否热门产品'" [(value)]="search.hot" [editing]="true"-->
        <!--                         [editable]="true"></app-property-switch>-->

        <!--    <app-property-switch [title]="'是否可試算'" [(value)]="search.quotable" [editing]="true" [editable]="true">-->
        <!--    </app-property-switch>-->

        <!--    <app-property-switch [title]="'是否适用提取计划'" [(value)]="search.withdrawalable" [editing]="true" [editable]="true">-->
        <!--    </app-property-switch>-->

        <!--    <app-property-switch [title]="'是否支持计划书机器人'" [(value)]="search.proposalRPASupported" [editing]="true"-->
        <!--                         [editable]="true">-->
        <!--    </app-property-switch>-->

        <!--    <app-property-switch [title]="'是否支持规划书制作'" [(value)]="search.financialPlanSupported" [editing]="true"-->
        <!--                         [editable]="true">-->
        <!--    </app-property-switch>-->

    </nz-spin>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onSearch()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzType="search" nzTheme="outline"></em>
                    <span>搜 索</span>
                </a>
            </div>
        </div>
    </div>
</div>
