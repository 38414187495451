import { Component, OnInit } from '@angular/core';
import { ExcelError, ExcelReport } from "../../../api/types";

@Component({
    selector: 'app-excel-report',
    templateUrl: './excel-report.component.html',
    styleUrls: ['./excel-report.component.less']
})
export class ExcelReportComponent implements OnInit {

    excelReport: ExcelReport;

    constructor() {
    }

    ngOnInit(): void {
    }

}
