import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    I18n,
    Product,
    ProductCommonType,
    ProductFrequency,
    ProductStatus,
    ProductType,
    QuotationFormula
} from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { ProductService } from "../product.service";
import { MetadataService } from "../../shared/services/metadata.service";
import { ProductTypePipe } from "../../shared/pipes/product-type.pipe";
import { ProductStatusPipe } from "../../shared/pipes/product-status.pipe";
import { ProductFrequencyPipe } from "../../shared/pipes/product-frequency.pipe";
import { ProductQuotationFormulaPipe } from "../../shared/pipes/product-quotation-formula.pipe";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { isNullOrUndefined } from "../../common/utils";
import {ProductCommonTypePipe} from "../../shared/pipes/product-common-type.pipe";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { DataService } from "../../shared/services/data.service";

@Component({
    selector: 'app-product-product-create',
    templateUrl: './product-product-create.component.html',
    styleUrls: ['./product-product-create.component.scss']
})
export class ProductProductCreateComponent implements OnInit {

    product: Product = new Product();

    commonTypeOptions: PropertySelectOption[] = []
    typeOptions: PropertySelectOption[] = []
    statusOptions: PropertySelectOption[] = []
    categoryOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []
    frequencyOptions: PropertySelectOption[] = []
    currencyOptions: PropertySelectOption[] = []
    quotationFormulaOptions: PropertySelectOption[] = []

    @Output()
    productCreated: EventEmitter<Product> = new EventEmitter<Product>();

    constructor(private productService: ProductService,
                private metadataService: MetadataService,
                private productCommonTypePipe: ProductCommonTypePipe,
                private productTypePipe: ProductTypePipe,
                private productStatusPipe: ProductStatusPipe,
                private productFrequencyPipe: ProductFrequencyPipe,
                private productQuotationFormulaPipe: ProductQuotationFormulaPipe,
                private notification: NzNotificationService,
                public dataService: DataService,) {
    }

    ngOnInit(): void {

        this.product.nameI18n = new I18n();
        this.product.shortNameI18n = new I18n();
        this.product.complianceNameI18n = new I18n();

        let productCommonTypes: ProductCommonType[] = [ProductCommonType.LIFE, ProductCommonType.GI, ProductCommonType.MPF]
        this.commonTypeOptions = productCommonTypes.map(commonType => {
            return new PropertySelectOption(this.productCommonTypePipe.transform(commonType), commonType)
        })

        let productTypes: ProductType[] = [ProductType.BASIC, ProductType.RIDER]
        this.typeOptions = productTypes.map(productType => {
            return new PropertySelectOption(this.productTypePipe.transform(productType), productType)
        })

        let productStatuses: ProductStatus[] = [ProductStatus.SELLING, ProductStatus.DISCONTINUED]
        this.statusOptions = productStatuses.map(productStatus => {
            return new PropertySelectOption(this.productStatusPipe.transform(productStatus), productStatus)
        })

        let productFrequencies: ProductFrequency[] = [ProductFrequency.SINGLE_PAYMENT, ProductFrequency.MONTHLY, ProductFrequency.QUARTERLY, ProductFrequency.SEMI_ANNUALLY, ProductFrequency.ANNUALLY]
        this.frequencyOptions = productFrequencies.map(frequency => {
            return new PropertySelectOption(this.productFrequencyPipe.transform(frequency), frequency)
        })

        let quotationFormulas: QuotationFormula[] = [QuotationFormula.PREMIUM_RATE, QuotationFormula.PREMIUM_TABLE]
        this.quotationFormulaOptions = quotationFormulas.map(quotationFormula => {
            return new PropertySelectOption(this.productQuotationFormulaPipe.transform(quotationFormula), quotationFormula)
        })

        this.productService.categories()
            .subscribe(
                data => {
                    data.forEach(category => {
                        this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
                    })
                },
                error => {
                });
        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.metadataService.currencies()
            .subscribe(
                data => {
                    data.forEach(currency => {
                        this.currencyOptions?.push(new PropertySelectOption(currency, currency));
                    })
                },
                error => {
                });
    }

    _frequencyNames: string[] = []

    get frequencyNames(): string[] {

        if (this._frequencyNames.length > 0)
            return this._frequencyNames;

        if (isNullOrUndefined(this.product.frequencies))
            return [];

        this._frequencyNames = this.product.frequencies.map(level => {
            return this.productFrequencyPipe.transform(level)
        });

        return this._frequencyNames;
    }

    onCreate() {
        this.productService.productCreate(this.product)
            .subscribe(
                data => {
                    this.notification.success('產品創建成功', '');
                    this.productCreated.emit(data);
                },
                error => {
                });
    }

}
