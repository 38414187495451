import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from "../product.service";
import { I18n, ProductCategory } from "../../api/types";
import { ProductCategoryInfoComponent } from "./product-category-info.component";

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.less']
})
export class ProductCategoriesComponent implements OnInit {

    categories: ProductCategory[];

    spinning: boolean = false;

    showInfoDrawer: boolean = false;

    @ViewChild('category')
    categoryInfoComponent: ProductCategoryInfoComponent;

    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {
        this.productService.categories()
            .subscribe(
                data => {
                    this.categories = data;
                },
                error => {
                });
    }


    /**
     * 打開詳情抽屜
     * @param category 險種信息
     */
    openInfoDrawer(category: ProductCategory): void {
        this.spinning = true;
        this.productService.categoryInfo(category.code)
            .subscribe(
                data => {
                    this.spinning = false
                    if (data.nameI18n == null) {
                        data.nameI18n = new I18n();
                    }
                    this.showInfoDrawer = true;
                    this.categoryInfoComponent.editing = false;
                    this.categoryInfoComponent.category = data;
                },
                error => {
                    this.spinning = false
                });
    }

    closeInfoDrawer(): void {
        this.showInfoDrawer = false;
        this.categoryInfoComponent.editing = false;
    }

}
