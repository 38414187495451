import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NzModalService } from "ng-zorro-antd/modal";
import {
    ContractMasterRateCreateComponent
} from "../contract-master-rate-create/contract-master-rate-create.component";
import { ContractService } from "../contract.service";
import { ExcelReport, Material, PagedResp } from "../../api/types";
import { ToastrService } from "ngx-toastr";
import { DownloadService } from "../../shared/services/download.service";
import { isNullOrUndefined } from "../../common/utils";
import { MaterialService } from "../../shared/services/material.service";
import { ContractMasterRate, ContractMasterRateSearch } from "../contract-types";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ExcelReportComponent } from "../../shared/components/excel-report/excel-report.component";

@Component({
    selector: 'app-contract-master-rate-list',
    templateUrl: './contract-master-rate-list.component.html',
    styleUrls: ['./contract-master-rate-list.component.less']
})
export class ContractMasterRateListComponent implements OnInit {

    loading: boolean = false;
    spinning: boolean = false;

    search: ContractMasterRateSearch = new ContractMasterRateSearch();

    masterRates: PagedResp<ContractMasterRate>;

    filterPopoverVisible: boolean = false;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    masterRateUpload: ContractMasterRate;

    materialMap: Map<string, Material> = new Map();

    constructor(private viewContainerRef: ViewContainerRef,
                private modalService: NzModalService,
                private contractService: ContractService,
                private materialService: MaterialService,
                private downloadService: DownloadService,
                private drawerService: NzDrawerService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.load(this.search)
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    onCreate() {
        const modal = this.modalService.create({
            nzTitle: '合約创建',
            nzCentered: true,
            nzWidth: 750,
            nzContent: ContractMasterRateCreateComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzComponentParams: {},
            nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
            nzFooter: [
                {
                    label: '确定',
                    onClick: componentInstance => {
                        const masterRate = componentInstance.masterRate;
                        this.contractService.masterRateCreate(masterRate)
                            .subscribe(
                                data => {
                                    modal.close();
                                    this.onReload();
                                    this.toastr.success('创建合约成功')
                                },
                                error => {
                                });
                    }
                }
            ]
        });
        const instance = modal.getContentComponent();
        modal.afterOpen.subscribe(() => {
        });
        // Return a result when closed
        modal.afterClose.subscribe(result => {
        });

    }

    load(search: ContractMasterRateSearch): void {
        this.loading = true;
        this.search = search;
        this.contractService.masterRateList(search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.masterRates = data
                },
                error => {
                    this.loading = false;
                });
    }

    onReload(): void {
        this.search.pageNum = 1;
        this.load(this.search);
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.contractService.masterRateList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.masterRates = data
                },
                error => {
                    this.loading = false;
                });
    }

    onResetSearch(): void {
        this.search = new ContractMasterRateSearch();
        this.onReload();
    }

    onDelete(masterRate: ContractMasterRate): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除？',
            nzContent: '<b style="color: #a21f1f; font-size: 16px">请谨慎删除，删除后将无法恢复</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.contractService.masterRateDelete(masterRate.id)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.onSearch(this.search.pageNum);
                        },
                        error => {
                            this.loading = false;
                        });
            },
            nzOnCancel: () => {
            }
        });
    }

    onDownload(masterRate: ContractMasterRate): void {
        // let material = masterRate.attachment;
        // let path = encodeURI(material.filePath);
        // let fileName = encodeURI(material.fileName);
        // this.downloadService.download(path, fileName);
        this.loading = true;
        this.contractService.masterRateExport(masterRate)
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });

    }

    onUpload(masterRate: ContractMasterRate): void {
        console.log(masterRate);
        this.masterRateUpload = masterRate;
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file && this.masterRateUpload) {

            const fileName = file.name;

            const formData = new FormData();

            formData.append("file", file);

            this.loading = true;
            this.contractService.masterRateImport(this.masterRateUpload, formData)
                .subscribe(
                    data => {
                        this.loading = false;
                        this.onExcelReport(data);
                    },
                    error => {
                        this.loading = false;
                    });

            if (this.fileUpload) {
                // 重置文件选择，否则无法重复上传同一个文件
                this.fileUpload.nativeElement.value = ''
            }

        }

    }

    onInfo(masterRate: ContractMasterRate) {

    }

    onExcelReport(excelReport: ExcelReport) {
        const modal = this.modalService.create<ExcelReportComponent>({
            nzTitle: '合約导入报告',
            nzCentered: true,
            nzWidth: 750,
            nzContent: ExcelReportComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzComponentParams: {
                excelReport
            },
            nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
            nzFooter: [
                {
                    label: '确定',
                    onClick: componentInstance => {
                        modal.close();
                    }
                }
            ]
        });
        modal.afterOpen.subscribe(() => {
        });
        // Return a result when closed
        modal.afterClose.subscribe(result => {
        });
    }

    extension(filePath: string): string {
        if (isNullOrUndefined(filePath) || filePath.length === 0) return ""
        return filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase()
    }
}
