import { BaseService } from '../base/base.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { Subscriber } from "rxjs/internal-compatibility";
import { ToastrService } from "ngx-toastr";
import { throttleTime } from "rxjs/operators";
import { ApiResponse } from "./types";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { isNotNullOrUndefined } from "../common/utils";

@Injectable()
export class HttpService extends BaseService {

    unauthorizedEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient,
                private router: Router,
                private toastr: ToastrService,
                private notification: NzNotificationService) {
        super();
        this.unauthorizedEvent
            .pipe(throttleTime(1000))
            .subscribe(
                data => {
                    this.removeOperator();
                    this.toastr.warning('認證失效，請重新登錄');
                    this.router.navigate(['/auth/login']);
                },
                error => {
                });
    }

    /**
     * aspect Observable<T>
     * @param {Observable<T>} observable
     * @param {(data: T) => void} onNext
     * @param {(data: any) => void} onError
     * @param {() => void} onComplete
     * @returns {Observable<T>}
     */
    aspect<T>(observable: Observable<HttpResponse<T>>,
              onNext?: (data: HttpResponse<T>) => void,
              onError?: (data: any) => void,
              onComplete?: () => void): Observable<HttpResponse<T>> {
        return new Observable((subscriber: Subscriber<HttpResponse<T>>) => {
            observable.subscribe(
                next => {
                    let apiResponse = next.body as unknown as ApiResponse<any>;
                    if (apiResponse && apiResponse.code === 0) {
                        if (onNext != null) {
                            onNext(next);
                        }
                        subscriber.next(next);
                    } else {
                        if (onError != null) {
                            onError(next);
                        }
                        subscriber.error(next);
                    }
                },
                error => {
                    if (onError != null) {
                        onError(error);
                    }
                    subscriber.error(error);
                },
                () => {
                    if (onComplete != null) {
                        onComplete();
                    }
                    subscriber.complete();
                });
        });
    }

    get<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.get<T>(url, options)
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    download(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    }): Observable<HttpResponse<Blob>> {
        // const response: Observable<HttpResponse<Blob>> = this.http.get(url, options)
        // return this.aspect(response,
        //     next => {
        //     },
        //     error => {
        //         this.handleError(error);
        //     },
        //     () => {
        //     }
        // );
        return this.http.get(url, options);
    }

    saveResponseBlob(response: HttpResponse<Blob>): void {
        let headers = response.headers;
        let contentType= headers.get('content-type');
        let contentDisposition= headers.get('content-disposition');
        let filename = contentDisposition.split('filename=')[1];
        const link = document.createElement('a');
        const blob = new Blob([response.body], {type: contentType});
        const objectURL = window.URL.createObjectURL(blob);
        link.href = objectURL;
        link.download = decodeURI(filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectURL);
    }

    post<T>(url: string, body: any, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.post<T>(url, body, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    put<T>(url: string, body: any, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.put<T>(url, body, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    delete<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.delete<T>(url, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    patch<T>(url: string, body: any, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.patch<T>(url, body, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    head<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.head<T>(url, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    options<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<HttpResponse<T>> {
        const response: Observable<HttpResponse<T>> = this.http.options<T>(url, options);
        return this.aspect(response,
            next => {
            },
            error => {
                this.handleError(error);
            },
            () => {
            }
        );
    }

    handleError(error: HttpErrorResponse | HttpResponse<any>) {

        if (this.isHttpResponse(error)) {
            let httpResponse = error as HttpResponse<any>;
            let apiResponse = httpResponse.body as unknown as ApiResponse<any>;
            if (isNotNullOrUndefined(apiResponse)) {
                switch (apiResponse.code) {
                    // Unauthorized
                    case 401: {
                        this.unauthorizedEvent.emit("");
                    }
                        break;
                    default: {
                        this.toastr.show(apiResponse.msg, '错误码[' + apiResponse.code + ']');
                    }
                }
            }
        }

        if (this.isHttpErrorResponse(error)) {
            let httpResponse = error as HttpErrorResponse;
            this.toastr.error(httpResponse.message);

        }

    }

    isHttpResponse(error): boolean {
        return error.constructor.toString().indexOf("HttpResponse") > -1;
    }

    isHttpErrorResponse(error): boolean {
        return error.constructor.toString().indexOf("HttpErrorResponse") > -1;
    }

}
