import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-property-switch',
    templateUrl: './property-switch.component.html',
    styleUrls: ['./property-switch.component.less']
})
export class PropertySwitchComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;

    @Output()
    valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    property: boolean;

    @Input()
    get value() {
        return this.property;
    }
    set value(val) {
        this.property = val;
        this.valueChange.emit(this.property);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
