<div class="profile-large-title">
    <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>
    <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">創建資訊</span>
</div>
<div style="margin-top: 30px; padding-bottom: 60px;">
    <div class="property-title">封面</div>
    <div class="property-value">
        <app-upload-picture [material]="material" [uploadReq]="uploadReq"
                            (valueChange)="onPictureChange($event)"></app-upload-picture>
    </div>

<!--    <app-property-text [title]="'发布人'" [(value)]="operator.nickname" [editable]="false" [editing]="false"></app-property-text>-->

    <app-property-text [title]="'標題 *'" [(value)]="information.title" [editable]="true" [editing]="true"></app-property-text>

    <app-property-select-edit [title]="'類型'" [(value)]="information.type"
                              [options]="typeOptions" (valueChange)="typeChange($event)"></app-property-select-edit>

    <app-property-select [title]="'標籤'" [editable]="true" [editing]="true" [name]="information.tags"
                         [(value)]="information.tags" [mode]="'multiple'"
                         [options]="tagsOptions"></app-property-select>

    <nz-row [nzGutter]="15">
        <nz-col [nzSpan]="8">
            <app-property-datetime [title]="'資訊時間'" [(value)]="information.addTime" [editable]="true" [editing]="true" format="yyyy-MM-dd"></app-property-datetime>
        </nz-col>
        <nz-col [nzSpan]="8">
            <app-property-datetime [title]="'生效日期'" [(value)]="information.startEffectiveDate" [editable]="true" [editing]="true" format="yyyy-MM-dd"></app-property-datetime>
        </nz-col>
        <nz-col [nzSpan]="8">
            <app-property-datetime [title]="'失效日期'" [(value)]="information.endEffectiveDate" [editable]="true" [editing]="true" format="yyyy-MM-dd"></app-property-datetime>
        </nz-col>
    </nz-row>

    <app-property-select [title]="'公司类别 *'" [(value)]="information.companyCode" *ngIf="showProduct"
                         [editable]="true" [editing]="true" [options]="companyOptions" (valueChange)="companyChange($event)"></app-property-select>

    <app-property-select [title]="'保險產品 *'" [(value)]="information.productCodes" *ngIf="showProduct"
                         [editable]="true" [editing]="true" [options]="productOptions" [mode]="'multiple'"
                         (scrollToBottom)="onProductNextPage()"
                         [loading]="productLoading"
                         (searchChange)="onProductSearch($event)"></app-property-select>


    <!-- <editor [init]=editParam [(ngModel)]="releaseData.content"></editor> -->

    <app-tinymce-editor [title]="'資訊內容'" [(value)]="information.content" [editing]="true"></app-tinymce-editor>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="12" class="footer-button">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onImportFromArticle()" nzBlock nzSize="large">
                    <em nz-icon nzType="import" nzTheme="outline"></em>
                    <span>從文章庫中導入</span>
                </a>
            </div>
            <div nz-col nzSpan="12" class="footer-button-primary">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onCreate()" nzBlock nzSize="large">
                    <em nz-icon nzType="save" nzTheme="outline"></em>
                    <span>創 建</span>
                </a>
            </div>
        </div>
    </div>
</div>
