
<div *ngIf="!productTagList">
    <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
</div>
<div *ngIf="productTagList" style="margin-top: 30px">
    <nz-table [nzBordered]="true"
              [nzShowPagination]="true"
              [nzLoading]="loading"
              [nzFrontPagination]="false"
              [nzData]="productTagList.list"
              [nzPageIndex]="productTagList.pageNum"
              [nzPageSize]="productTagList.pageSize"
              [nzTotal]="productTagList.totalCount"
              [nzTitle]="tableHeader"
              [nzFooter]="tableFooter"
              (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
            <!--            <div>-->
            <!--                <i nz-icon nzType="search" nzTheme="outline" style="color: #95AAC9;"></i>-->
            <!--            </div>-->
            <nz-row>
                <nz-col [nzSpan]="12">
                    <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">產品标签列表</div>
                    <!--                    <input class="search-input" type="text" placeholder="產品名称"/>-->
                </nz-col>
                <nz-col [nzSpan]="12">
                    <div style="width: 100%; display: flex; justify-content: flex-end;">
                        <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                style=" margin-left: 10px;">
                            <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
                        </button>
<!--                        <button nz-button nzType="default" nzSize="large" nzShape="circle" appThrottleClick (throttleClick)="openSearchDrawer()"-->
<!--                                style=" margin-left: 10px;">-->
<!--                            <i nz-icon nzType="search" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>-->
<!--                        </button>-->
                    </div>
                </nz-col>
            </nz-row>
        </ng-template>
        <ng-template #tableFooter>
            <div class="table-title">
                共 <span style="color: #002140;">{{ productTagList.totalCount }}</span> 個標籤
            </div>
        </ng-template>

        <thead>
        <tr>
            <th nzLeft>標籤名稱</th>
            <th>KEY</th>
            <th>排序</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let productTag of productTagList.list" appThrottleClick (throttleClick)="openInfoDrawer(productTag)">
            <td nzLeft nzBreakWord>{{ productTag.name }}</td>
            <td>{{ productTag.key }}</td>
            <td>{{ productTag.rank }}</td>
        </tr>
        </tbody>
    </nz-table>
</div>
