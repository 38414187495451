import { Component, OnInit } from '@angular/core';
import { InstanceService } from "../../services/instance.service";
import { DataService } from "../../services/data.service";
import { Instance } from "../../../api/types";

@Component({
    selector: 'app-instance-selection',
    templateUrl: './instance-selection.component.html',
    styleUrls: ['./instance-selection.component.less']
})
export class InstanceSelectionComponent implements OnInit {

    instances: Instance[] = [];

    constructor(private instanceService: InstanceService, private dataService: DataService) {
    }

    ngOnInit(): void {
        this.instanceService.instances()
            .subscribe(
                data => {
                    this.instances = data;
                },
                error => {
                });

    }

    onConfirm() {

    }

    checkInstance(instance: Instance) {
        instance.checked = !instance.checked;
    }
}
