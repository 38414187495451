<div>
    <div class="profile-large-title">
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">{{ name }}所屬產品</span>
    </div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!products.list.length">
            <nz-empty nzNotFoundImage="simple"></nz-empty>
        </div>
    <div *ngIf="products.list?.length" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzFrontPagination]="false"
                  [nzData]="products.list"
                  [nzPageIndex]="products.pageNum"
                  [nzPageSize]="products.pageSize"
                  [nzTotal]="products.totalCount"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)"
                  [nzScroll]="{ x: '1150px'}">
            <ng-template #tableHeader>
                <nz-row>
                    <nz-col [nzSpan]="12">
                        <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">產品列表
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                               appThrottleClick (throttleClick)="onReload()">
                                <em nz-icon nzType="reload" nzTheme="outline"></em>
                            </a>
                        </div>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <div style="width: 100%; display: flex; justify-content: flex-end;">
                            <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                    nzTooltipTitle="產品搜索" style=" margin-left: 10px;"
                                    nz-popover [nzPopoverContent]="filterTemplate"
                                    [(nzPopoverVisible)]="filterPopoverVisible"
                                    nzPopoverTrigger="click"
                                    nzPopoverPlacement="bottomRight">
                                <i nz-icon nzType="search" nzTheme="outline"
                                   style="color: #07367c; font-size: 20px;"></i>
                            </button>

                        </div>
                    </nz-col>
                </nz-row>
            </ng-template>
            <ng-template #tableFooter>
                <div class="table-title">
                    共 <span style="color: #002140;">{{ products.totalCount }}</span> 個產品
                </div>
            </ng-template>

            <thead>
            <tr>
                <th nzLeft>產品碼</th>
                <th><span nz-tooltip nzTooltipTitle="產品默認名稱">產品默認名稱</span></th>
                <th><span nz-tooltip nzTooltipTitle="險種">險種</span></th>
                <th><span nz-tooltip nzTooltipTitle="公司">公司</span></th>
                <th><span nz-tooltip nzTooltipTitle="類型">類型</span></th>
                <th><span nz-tooltip nzTooltipTitle="狀態">狀態</span></th>
                <!--<th style="text-align: center">操作</th>-->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let product of products.list; let i = index">
                <td nzLeft>{{ product.code }}</td>
                <td nzBreakWord><span>{{ product.name }}</span></td>
                <td>{{ product.category?.name }}</td>
                <td>{{ product.company?.name }}</td>
                <td>{{ product.type | productType }}</td>
                <td>{{ product.status | productStatus }}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    </nz-spin>

</div>


<ng-template #filterTemplate>
    <div style="width: 300px; padding: 5px;">
        <div style="padding: 0 0 15px 0">搜索</div>
        <div class="property-bottom-line-blue"></div>
        <app-property-text-edit [title]="'產品名称'" [(value)]="search.name"></app-property-text-edit>
        <nz-row [nzGutter]="5">
            <!--      <nz-col nzSpan="12">-->
            <!--        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick-->
            <!--                (throttleClick)="onReload()">-->
            <!--          <span>{{'Reset' | translate}}</span>-->
            <!--        </button>-->
            <!--      </nz-col>-->
            <nz-col nzSpan="24">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                        (throttleClick)="onSearch(1)">
                    <span>確認</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
