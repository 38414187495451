import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productFrequency'
})
export class ProductFrequencyPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let frequency = "";
        switch (value) {
            case 'SINGLE_PAYMENT':
                frequency = "趸缴";
                break;
            case 'MONTHLY':
                frequency = "月缴";
                break;
            case 'QUARTERLY':
                frequency = "季缴";
                break;
            case 'SEMI_ANNUALLY':
                frequency = "半年缴";
                break;
            case 'ANNUALLY':
                frequency = "年缴";
                break;
        }
        return frequency;
    }

}
