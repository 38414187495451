import { Component, Input, OnInit } from '@angular/core';
import { I18n } from "../../../api/types";

@Component({
  selector: 'app-property-i18n-area-display',
  templateUrl: './property-i18n-area-display.component.html',
  styleUrls: ['./property-i18n-area-display.component.less']
})
export class PropertyI18nAreaDisplayComponent implements OnInit {

    @Input() title: string;
    @Input() value: I18n;

    constructor() {
    }

    ngOnInit(): void {
    }

}
