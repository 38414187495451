export class TableDisplayConfigColumn {
    name: string;           /// 列名稱
    tooltip: string;        /// 提示
    display: boolean;       /// 是否顯示
}

export class ProductTableDisplayConfig {
    zhHansCN: TableDisplayConfigColumn;
    zhHantHK: TableDisplayConfigColumn;
    enUS: TableDisplayConfigColumn;
    shortNameCN: TableDisplayConfigColumn;
    shortNameHK: TableDisplayConfigColumn;
    shortNameUS: TableDisplayConfigColumn;
    complianceNameCN: TableDisplayConfigColumn;
    complianceNameHK: TableDisplayConfigColumn;
    complianceNameUS: TableDisplayConfigColumn;
    category: TableDisplayConfigColumn;
    company: TableDisplayConfigColumn;
    type: TableDisplayConfigColumn;
    region: TableDisplayConfigColumn;
    policyPeriod: TableDisplayConfigColumn;
    status: TableDisplayConfigColumn;
    premiumTerms: TableDisplayConfigColumn;
    currencies: TableDisplayConfigColumn;
    frequencies: TableDisplayConfigColumn;
    standard: TableDisplayConfigColumn;
    hot: TableDisplayConfigColumn;
    comparable: TableDisplayConfigColumn;
    quotable: TableDisplayConfigColumn;
    withdrawalable: TableDisplayConfigColumn;
    proposalRPASupported: TableDisplayConfigColumn;
    financialPlanSupported: TableDisplayConfigColumn;
    hasBrochure: TableDisplayConfigColumn;
    rank: TableDisplayConfigColumn;
    internalRemark: TableDisplayConfigColumn;
}

export let DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG: ProductTableDisplayConfig = {
    zhHansCN: {name: '簡體名稱', tooltip: '簡體名稱', display: true},
    zhHantHK: {name: '繁體名稱', tooltip: '繁體名稱', display: true},
    enUS: {name: '英文名稱', tooltip: '英文名稱', display: true},
    shortNameCN: {name: '簡體簡稱', tooltip: '簡體簡稱', display: true},
    shortNameHK: {name: '繁體簡稱', tooltip: '繁體簡稱', display: true},
    shortNameUS: {name: '英文簡稱', tooltip: '英文簡稱', display: true},
    complianceNameCN: {name: '簡體合規名稱', tooltip: '簡體合規名稱', display: true},
    complianceNameHK: {name: '繁體合規名稱', tooltip: '繁體合規名稱', display: true},
    complianceNameUS: {name: '英文合規名稱', tooltip: '英文合規名稱', display: true},
    category: {name: '險種', tooltip: '險種', display: true},
    company: {name: '公司', tooltip: '公司', display: true},
    type: {name: '類型', tooltip: '類型', display: true},
    region: {name: '所在地區', tooltip: '所在地區', display: true},
    policyPeriod: {name: '保障年期', tooltip: '保障年期', display: true},
    status: {name: '狀態', tooltip: '狀態', display: true},
    premiumTerms: {name: '產品年期', tooltip: '產品年期', display: true},
    currencies: {name: '貨幣', tooltip: '貨幣', display: true},
    frequencies: {name: '付款頻率', tooltip: '付款頻率', display: true},
    standard: {name: '是否標準產品', tooltip: '是否標準產品', display: true},
    hot: {name: '是否热门產品', tooltip: '是否热门產品', display: true},
    comparable: {name: '是否可對比', tooltip: '是否可對比', display: true},
    quotable: {name: '是否可試算', tooltip: '是否可試算', display: true},
    withdrawalable: {name: '是否適用提取計劃', tooltip: '是否適用提取計劃', display: true},
    proposalRPASupported: {name: '是否支持計劃書機器人', tooltip: '是否支持計劃書機器人', display: true},
    financialPlanSupported: {name: '是否支持規劃書製作', tooltip: '是否支持規劃書製作', display: true},
    hasBrochure: {name: '有小冊子', tooltip: '有小冊子', display: true},
    rank: {name: '排序', tooltip: '排序（越小越靠前）', display: true},
    internalRemark: {name: '内部备注', tooltip: '内部备注', display: true}
};
