<div>
    <div class="product-instance-sync-container">
        <nz-row>
            <nz-col [nzSpan]="12">
                <div class="product-instance-column">
                    <div class="product-instance-add-product-header">
                        <div style="flex: 1">已選產品({{productsToSync.length}})</div>
                        <button nz-button nzType="default" nzSize="default" nzShape="circle" appThrottleClick (throttleClick)="onClear()" style="margin-right: 10px">
                            <i nz-icon nzType="clear" nzTheme="outline"></i>
                        </button>
                        <button nz-button nzType="default" nzSize="default" nzShape="circle" appThrottleClick (throttleClick)="onProductSelection()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i>
                        </button>
                    </div>
                    <div *ngFor="let product of productsToSync; let i = index;" style="margin-left: 10px; margin-right: 10px; padding-bottom: 5px; border-bottom: 1px solid #f8fbfd">
                        <div class="product-item">
                            <app-property-text [title]="product.code" [value]="product.name" style="flex: 1"></app-property-text>
                            <a nz-button nzType="link" nzSize="small" class="delete-button" appThrottleClick (throttleClick)="onProductDeleted(i)">
                                <em nz-icon nzType="delete" nzTheme="outline"></em>
                            </a>
                        </div>
                    </div>
                </div>
            </nz-col>
            <nz-col [nzSpan]="12" >
                <div class="product-instance-column" style="margin-left: 10px">
                    <div class="product-instance-add-instance-header">
                        <div style="flex: 1">請選擇要同步的客戶，已选({{instanceToSync.length}})</div>
<!--                        <button nz-button nzType="default" nzSize="default" nzShape="circle" appThrottleClick (throttleClick)="openInstanceSelectionDrawer()">-->
<!--                            <i nz-icon nzType="check" nzTheme="outline"></i>-->
<!--                        </button>-->

                        <label nz-checkbox (nzCheckedChange)="onCheckAllInstance($event)">全選</label>
<!--                        <input type="checkbox" ngModel (ngModelChange)="onCheckAllInstance($event)" appThrottleClick (throttleClick)="$event.stopPropagation();" class="check-box"/>-->

                    </div>
                    <div *ngIf="instances" style="margin-top: 10px">
                        <div *ngFor="let instance of instances">
                            <div class="instance-item" appThrottleClick (throttleClick)="checkInstance(instance)"
                                [ngStyle]="{'border':instance.checked ? '1px solid #85b6ff' : '1px solid #EDF2F9'}">
                                <app-property-text [title]="instance.name" [value]="instance.remark" style="flex: 1"></app-property-text>
                                <em nz-icon *ngIf="instance.checked" style="font-size: 20px; color: #2C7BE5" nzType="check-circle" nzTheme="outline"></em>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-col>
        </nz-row >
        <nz-row [nzGutter]="16">
            <div class="product-instance-sync-footer">
                <button nz-button nzType="default" nzSize="large" nzShape="round" [hidden]="productsToSync.length === 0 || instanceToSync.length === 0" appThrottleClick (throttleClick)="onDelete()"
                        style="margin-top: 20px; margin-right: 10px; height: 60px; color: #a71d5d; ">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                    <span >刪除產品</span>
                </button>
                <button nz-button nzType="default" nzSize="large" nzShape="round" [hidden]="productsToSync.length === 0 || instanceToSync.length == 0" appThrottleClick (throttleClick)="onSync()"
                        style="margin-top: 20px; height: 60px">
                    <i nz-icon nzType="cloud-sync" nzTheme="outline"></i>
                    <span >同步產品</span>
                </button>
            </div>
        </nz-row>
    </div>
</div>
