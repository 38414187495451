import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { ProcessLog, ProcessLogType } from "../../../api/types";

@Component({
    selector: 'app-process-log',
    templateUrl: './process-log-list.component.html',
    styleUrls: ['./process-log-list.component.less']
})
export class ProcessLogListComponent implements OnInit, AfterContentInit {

    @Input() logs: ProcessLog[] = []

    successLogs: ProcessLog[] = []
    failureLogs: ProcessLog[] = []

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterContentInit(): void {
        for (let log of this.logs) {
            if (log.type == ProcessLogType.SUCCESS || log.type == ProcessLogType.INFO) {
                this.successLogs.push(log);
            }
            if (log.type == ProcessLogType.WARN || log.type == ProcessLogType.ERROR) {
                this.failureLogs.push(log);
            }
        }
    }

    icon(log: ProcessLog): string {
        switch (log.type) {
            case ProcessLogType.INFO:
                return "info";
            case ProcessLogType.SUCCESS:
                return "check";
            case ProcessLogType.WARN:
                return "exclamation";
            case ProcessLogType.ERROR:
                return "close";
        }
    }

    style(log: ProcessLog): string {
        switch (log.type) {
            case ProcessLogType.INFO:
                return "background-color: #2C7BE5";
            case ProcessLogType.SUCCESS:
                return "background-color: #12C839";
            case ProcessLogType.WARN:
                return "background-color: #FAAD14";
            case ProcessLogType.ERROR:
                return "background-color: #F5222D";
        }
    }

}
