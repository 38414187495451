<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div style="height: 500px">

        <div style="height: 20px; font-size: 20px">
            操作臺
        </div>
        <div class="console-menu">

<!--            <div class="console-button" nz-tooltip nzTooltipTitle="發送同步指令給所有客戶，同步所有險種"-->
<!--                 appThrottleClick (throttleClick)="onProductCategorySync()">-->
<!--                <i nz-icon nzType="sync" nzTheme="outline" style="color: #07367c; font-size: 35px;"></i>-->
<!--                <div style="margin-top: 10px; font-size: 12px">同步險種</div>-->
<!--            </div>-->

<!--            <div class="console-button" nz-tooltip nzTooltipTitle="發送同步指令給所有客戶，同步所有公司"-->
<!--                 appThrottleClick (throttleClick)="onProductCompanySync()">-->
<!--                <i nz-icon nzType="sync" nzTheme="outline" style="color: #07367c; font-size: 35px;"></i>-->
<!--                <div style="margin-top: 10px; font-size: 12px">同步公司</div>-->
<!--            </div>-->

<!--            <div class="console-button" nz-tooltip nzTooltipTitle="發送同步指令給所有客戶，同步所有產品" appThrottleClick (throttleClick)="onProductSync()">-->
<!--                <i nz-icon nzType="sync" nzTheme="outline" style="color: #07367c; font-size: 35px;"></i>-->
<!--                <div style="margin-top: 10px; font-size: 12px">同步產品</div>-->
<!--            </div>-->

<!--            <div class="console-button" nz-tooltip nzTooltipTitle="转换资讯图片" appThrottleClick (throttleClick)="onInformationTranslateImages()">-->
<!--                <i nz-icon nzType="sync" nzTheme="outline"-->
<!--                   style="color: #07367c; font-size: 35px;"></i>-->
<!--                <div style="margin-top: 10px; font-size: 12px">转换资讯图片</div>-->
<!--            </div>-->

<!--            <div class="console-button" nz-tooltip nzTooltipTitle="转换知识库图片" appThrottleClick (throttleClick)="onKnowledgeTranslateImages()">-->
<!--                <i nz-icon nzType="sync" nzTheme="outline"-->
<!--                   style="color: #07367c; font-size: 35px;"></i>-->
<!--                <div style="margin-top: 10px; font-size: 12px">转换知识库图片</div>-->
<!--            </div>-->

        </div>

    </div>
</nz-spin>
