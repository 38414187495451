<div class="tag-editor">
    <nz-tag *ngFor="let tag of value; let i = index" [nzMode]="editable ? 'closeable' : 'default'"
            (nzOnClose)="handleClose(tag)">
        <span style="font-size: 13px">{{ sliceTagName(tag) }}</span>
    </nz-tag>
    <nz-tag *ngIf="!inputVisible && editable" class="editable-tag" nzNoAnimation appThrottleClick (throttleClick)="showInput()">
        <i nz-icon nzType="plus"></i>
        {{titleOfNew}}
    </nz-tag>
    <input
        #inputElement
        nz-input
        nzSize="small"
        *ngIf="inputVisible && editable"
        type="text"
        [(ngModel)]="inputValue"
        style="width: 78px;"
        (blur)="handleInputConfirm()"
        (keydown.enter)="handleInputConfirm()"
    />
</div>
