import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'releaseType'
})
export class ReleaseTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let releaseType = "";
        switch (value) {
            case 'ADMINISTRATION_NOTICE':
                releaseType = "行政通知";
                break;
            case 'PRODUCT_NEWS':
                releaseType = "產品動態";
                break;
            case 'GENERAL_INFO':
                releaseType = "一般資訊";
                break;
            case 'PRODUCT_PROMOTION':
                releaseType = "產品優惠";
                break;
        }
        return releaseType;
    }

}
