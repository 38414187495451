import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { I18n } from "../../../api/types";

@Component({
    selector: 'app-property-i18n',
    templateUrl: './property-i18n.component.html',
    styleUrls: ['./property-i18n.component.less']
})
export class PropertyI18nComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() required: boolean = false;

    @Output()
    valueChange: EventEmitter<I18n> = new EventEmitter<I18n>();

    i18n: I18n;

    @Input()
    get value() {
        return this.i18n;
    }
    set value(val) {
        this.i18n = val;
        this.valueChange.emit(this.i18n);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
