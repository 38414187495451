import { Component, Input, OnInit } from '@angular/core';
import { PropertyTextEditType } from "./property-text-edit.component";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ElectronService } from "../../../core/services";

@Component({
    selector: 'app-property-text-display',
    templateUrl: './property-text-display.component.html',
    styleUrls: ['./property-text-display.component.less']
})
export class PropertyTextDisplayComponent implements OnInit {

    @Input() title: string;
    @Input() value: any;
    @Input() type: PropertyTextEditType = PropertyTextEditType.TEXT_FIELD
    @Input() required: boolean = false;

    constructor(private sanitizer: DomSanitizer, private electronService: ElectronService) {
    }

    ngOnInit(): void {
    }

    openUrl() {
        if (this.type === PropertyTextEditType.URL) {
            let url = this.value.toString();
            if (!url.startsWith("http")) {
                url = "http://" + url;
            }
            this.electronService.openUrl(url);
        }
    }
}
