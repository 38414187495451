import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import {I18n, Link, MaterialModifyReq} from "../../../api/types";
import { isNullOrUndefined } from "../../../common/utils";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ElectronService } from "../../../core/services";
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {PropertyTextEditType} from "../property-text/property-text-edit.component";
import { DataService } from "../../services/data.service";

@Component({
    selector: 'app-links-editor',
    templateUrl: './links-editor.component.html',
    styleUrls: ['./links-editor.component.less']
})
export class LinksEditorComponent implements OnInit, AfterViewInit, AfterContentInit {

    @Input()
    links: Link[] = [];

    // editings: boolean[] = [];

    @Input()
    spinning: boolean = false;

    @Output()
    valueChange: EventEmitter<Link[]> = new EventEmitter<Link[]>();

    editLink: Link = new Link();
    editIndex: number; // 下標
    editType: string

    constructor(private notification: NzNotificationService,
                private modalService: NzModalService,
                public dataService: DataService,
                private electronService: ElectronService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        // for (let link of this.links) {
        //     this.editings.push(false);
        // }
    }

    ngAfterContentInit(): void {
        if (isNullOrUndefined(this.links)) {
            this.links = [];
        }
        // for (let link of this.links) {
        //     this.editings.push(false);
        // }
    }

    // onEdit(i: number, editing: boolean) {
    //     this.editings[i] = editing;
    // }

    // onCancel(i: number) {
    //     this.editings[i] = false;
    //     if (isNullOrUndefined(this.links[i].link) && isNullOrUndefined(this.links[i].description)) {
    //         this.editings.splice(i, 1);
    //         this.links.splice(i, 1);
    //     }
    // }

    onSave(i: number) {
        const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (isNullOrUndefined(this.links[i].link) || isNullOrUndefined(this.links[i].description)) {
            this.notification.warning('參數不全', '名稱或鏈接不能為空');
            return;
        }
        // if (!this.links[i].link.startsWith("http://") || !this.links[i].link.startsWith("https://")) {
        if (!reg.test(this.links[i].link)) {
            this.notification.warning('參數錯誤', '鏈接必須以http(s)://開頭');
            return;
        }
        // this.editings[i] = false;
        this.valueChange.emit(this.links);
    }

    onAdd(editorTitle: TemplateRef<any>, editorContent: TemplateRef<any>, editorFooter: TemplateRef<any>): void {
        this.editLink = new Link();
        if (isNullOrUndefined(this.links)) {
            this.links = [];
        }
        this.modalService.create({
            nzCentered: true,
            nzTitle: editorTitle,
            nzContent: editorContent,
            nzFooter: editorFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => console.log('Click ok')
        });
        // this.links.push(this.editLink);
        // this.editings.push(true);
        this.editType = 'creat'
    }

    onDelete(i: number): void {
        this.links.splice(i, 1);
        this.valueChange.emit(this.links);
    }

    openLink(link: Link): void {
        let url = link.link;
        if (!url.startsWith("http")) {
            url = "http://" + url;
        }
        this.electronService.openUrl(url);
    }

    onEditorConfirm(ref: NzModalRef) {
        const arrCopy = JSON.parse(JSON.stringify(this.links));
        const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (isNullOrUndefined(this.editLink.link) || isNullOrUndefined(this.editLink.description)) {
            this.notification.warning('參數不全', '名稱或鏈接不能為空');
            return;
        }
        if (!reg.test(this.editLink.link)) {
            this.notification.warning('參數錯誤', '鏈接必須以http(s)://開頭');
            return;
        }
        ref.destroy();
        if (this.editType == 'edit') {
            arrCopy.splice(this.editIndex, 1 , this.editLink);
            this.links = JSON.parse(JSON.stringify(arrCopy));
        } else {
            this.links.push(this.editLink)
        }
        this.valueChange.emit(this.links);
    }

    onShowEditorModal(i: number, editorTitle: TemplateRef<any>, editorContent: TemplateRef<any>, editorFooter: TemplateRef<any>) {
        this.editType = 'edit'
        this.editLink = this.links[i];
        this.editIndex = i;
        this.editLink.descriptionI18n = this.links[i].descriptionI18n ?  this.links[i].descriptionI18n : new I18n();
        // let file = _.cloneDeep(this.materials[i]);
        //
        // let modifyReq = new MaterialModifyReq();
        // modifyReq.index = i;
        // modifyReq.file = file;

        this.modalService.create({
            nzCentered: true,
            nzTitle: editorTitle,
            nzContent: editorContent,
            nzFooter: editorFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => console.log('Click ok')
        });
    }

    protected readonly PropertyTextEditType = PropertyTextEditType;
}
