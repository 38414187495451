import { Injectable, OnInit } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../api/http.service';
import { ApiResponse, PagedResp, ProductPropertyGroup, ProductPropertyGroupSearch } from "../api/types";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";

@Injectable()
export class ProductPropertyService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    productPropertyDetail(code: string): Observable<ProductPropertyGroup[]> {
        return this.http.get<ApiResponse<ProductPropertyGroup[]>>(this.productUrl + '/property/detail/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    groupList(search: ProductPropertyGroupSearch): Observable<PagedResp<ProductPropertyGroup>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        return this.http.get<ApiResponse<PagedResp<ProductPropertyGroup>>>(this.productUrl + '/property/groups/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

}
