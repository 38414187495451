import { Injectable, OnInit } from '@angular/core';
import { HttpService } from "../../api/http.service";
import { BaseService } from "../../base/base.service";
import { Observable } from "rxjs/internal/Observable";
import { ApiResponse, Currency, MedicalLevel, MedicalRegion, SupplementaryBenefit } from "../../api/types";
import { of } from "rxjs";
import { API } from "../../api/api";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MetadataService extends BaseService implements OnInit {

    _currencies: Currency[] = [];

    _medicalRegions: MedicalRegion[] = [MedicalRegion.WORLDWIDE, MedicalRegion.WORLDWIDE_EXCEPT_US, MedicalRegion.ASIA, MedicalRegion.GREATER_CHINA, MedicalRegion.GREATER_CHINA_HMT];

    _medicalLevels: MedicalLevel[] = [MedicalLevel.BASIC_WARD, MedicalLevel.WARD, MedicalLevel.SEMI_PRIVATE, MedicalLevel.PRIVATE, MedicalLevel.DELUXE, MedicalLevel.VIP, MedicalLevel.SUITE];

    _supplementaryBenefits: SupplementaryBenefit[] = [SupplementaryBenefit.DENTAL, SupplementaryBenefit.MATERNITY, SupplementaryBenefit.OUTPATIENT, SupplementaryBenefit.SMM, SupplementaryBenefit.SMMP];

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    currencies(): Observable<Currency[]> {
        // 返回缓存
        if (this._currencies.length > 0) {
            return of(this._currencies);
        }

        return this.http.get<ApiResponse<Currency[]>>(this.metadataUrl + '/currencies', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            this._currencies = data.body.data;
            return this._currencies
        }))

    }

    medicalRegions(): Observable<MedicalRegion[]> {
        // 返回缓存
        if (this._medicalRegions.length > 0) {
            return of(this._medicalRegions);
        }
    }

    medicalLevels(): Observable<MedicalLevel[]> {
        // 返回缓存
        if (this._medicalLevels.length > 0) {
            return of(this._medicalLevels);
        }
    }

    supplementaryBenefits(): Observable<SupplementaryBenefit[]> {
        // 返回缓存
        if (this._supplementaryBenefits.length > 0) {
            return of(this._supplementaryBenefits);
        }

        return this.http.get<ApiResponse<SupplementaryBenefit[]>>(this.metadataUrl + '/supplementary-benefits', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            this._supplementaryBenefits = data.body.data;
            return this._supplementaryBenefits
        }))

    }

    productTypes(): Observable<MedicalLevel[]> {
        // 返回缓存
        if (this._medicalLevels.length > 0) {
            return of(this._medicalLevels);
        }
    }

}
