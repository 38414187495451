import { Injectable, OnInit } from '@angular/core';
import {
    ApiResponse,
} from "../../api/types";
import { Observable } from "rxjs/internal/Observable";
import { API } from "../../api/api";
import { map } from "rxjs/operators";
import { HttpService } from "../../api/http.service";
import { BaseService } from "../../base/base.service";

@Injectable({
    providedIn: 'root'
})
export class ConsoleService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    productSync(): Observable<String> {
        return this.http.post<ApiResponse<String>>(this.consoleUrl + '/product-sync', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    productCompanySync(): Observable<String> {
        return this.http.post<ApiResponse<String>>(this.consoleUrl + '/product-company-sync', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    productCategorySync(): Observable<String> {
        return this.http.post<ApiResponse<String>>(this.consoleUrl + '/product-category-sync', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    translateInformationImages(): Observable<String> {
        return this.http.post<ApiResponse<String>>(this.consoleUrl + '/translate-information-images', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    translateKnowledgeImages(): Observable<String> {
        return this.http.post<ApiResponse<String>>(this.consoleUrl + '/translate-knowledge-images', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

}
