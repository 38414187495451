<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div *ngIf="accounts" style="margin-top: 60px">
        <div *ngFor="let account of accounts | keyvalue; let i = index">
            <div class="account" nz-tooltip nzTooltipTitle="点击登录" appThrottleClick (throttleClick)="onLogin(account.value)">
                <div class="account-icon">
                    <span>{{firstChar(account.value.account)}}</span>
                </div>
                <div class="account-elem">
                    <div class="account-name">{{account.value.account}}</div>
                    <div class="account-env">
                        {{account.value.env}}
                    </div>
                </div>
                <div class="account-actions">
                    <a nz-button nzType="link" nzSize="small" class="delete-button" appThrottleClick (throttleClick)="onDelete(account)">
                        <em nz-icon nzType="delete" nzTheme="outline"></em>
                    </a>
                </div>
            </div>
        </div>
    </div>
</nz-spin>

<div class="account-menu">
    <button nz-button nzType="default" nzSize="large" nzShape="circle" appThrottleClick (throttleClick)="onShowAddAccountModal()"
            style="margin-right: 40px; width: 60px; height: 60px">
        <i nz-icon nzType="plus" nzTheme="outline"
           style="color: #07367c; font-size: 20px;"></i>
    </button>
</div>
