import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from "../../shared/services/data.service";
import {
    Instance,
    InstanceCompanyDelete,
    InstanceCompanySync,
    InstanceProductDelete,
    ProcessLog
} from "../../api/types";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { InstanceSelectionComponent } from "../../shared/components/instance-selection/instance-selection.component";
import { InstanceService } from "../../shared/services/instance.service";
import { isNullOrUndefined } from "../../common/utils";
import { CompanySelectionComponent } from "../components/company-selection/company-selection.component";
import * as _ from "lodash";
import { ProductCompany } from "../product-types";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
    selector: 'app-company-instance-sync',
    templateUrl: './company-instance-sync.component.html',
    styleUrls: ['./company-instance-sync.component.less']
})
export class CompanyInstanceSyncComponent implements OnInit {

    productCompaniesToSync: ProductCompany[] = [];

    instances: Instance[] = [];

    showProductSelectionDrawer: boolean = false;
ai
    @ViewChild('companySelection')
    CompanySelectionComponent: CompanySelectionComponent;

    constructor(private dataService: DataService,
                private instanceService: InstanceService,
                private modalService: NzModalService,
                private drawerService: NzDrawerService) {
    }

    ngOnInit(): void {

        this.productCompaniesToSync = _.uniqWith(this.dataService.productCompaniesToSync, _.isEqual)

        this.instanceService.instances()
            .subscribe(
                data => {
                    this.instances = data;
                },
                error => {
                });

    }

    openProductSelectionDrawer(): void {
        this.showProductSelectionDrawer = true
    }

    closeProductSelectionDrawer() {
        this.showProductSelectionDrawer = false
    }


    openInstanceSelectionDrawer(): void {
        const drawerRef = this.drawerService.create<InstanceSelectionComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: InstanceSelectionComponent,
            nzContentParams: {
                // logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    checkInstance(instance: Instance) {
        instance.checked = !instance.checked;
    }

    get instanceToSync(): Instance[] {
        let array: Instance[] = []
        if (!isNullOrUndefined(this.instances)) {
            array = this.instances.filter(instance => {
                return instance.checked
            })
        }
        return array;
    }

    onSync() {
        let req = new InstanceCompanySync();
        req.productCompanyCodes = this.productCompaniesToSync.filter(product => {
            return product.checked
        }).map(product => {
            return product.code;
        })
        req.instances = this.instanceToSync.map(instance => {
            return instance.name;
        })
        this.instanceService.companySync(req)
            .subscribe(
                data => {
                    this.openLogsDrawer(data);
                },
                error => {
                });

    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    onProductsSelected(productCompanys: ProductCompany[]) {
        this.showProductSelectionDrawer = false;
        productCompanys.forEach(c => {
            this.productCompaniesToSync?.push(c);
        });
        this.productCompaniesToSync =  _.uniqWith(this.productCompaniesToSync, _.isEqual);
        this.dataService.productCompaniesToSync = this.productCompaniesToSync;
    }

    onProductDeleted(index: number) {
        if (isNullOrUndefined(this.productCompaniesToSync) || this.productCompaniesToSync?.length === 0) return;
        this.productCompaniesToSync.splice(index, 1);
        this.dataService.productCompaniesToSync = this.productCompaniesToSync;
    }

    onDelete(): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除公司？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎操作</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                let req = new InstanceCompanyDelete();
                req.companyCodes = this.productCompaniesToSync
                    .map(company => {
                        return company.code;
                    })
                req.instances = this.instanceToSync.map(instance => {
                    return instance.name;
                })
                this.instanceService.companyDelete(req)
                    .subscribe(
                        data => {
                            this.openLogsDrawer(data);
                        },
                        error => {
                        });
            },
            nzOnCancel: () => {
            }
        });
    }
}
