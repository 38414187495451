import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AgeFormula, I18n, } from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { ProductService } from "../product.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProductCompany, ProductCompanyRegions } from "../product-types";
import { ProductCompanyRegionPipe } from "../../shared/pipes/product-company-region.pipe";
import { DataService } from "../../shared/services/data.service";

@Component({
    selector: 'app-product-company-create',
    templateUrl: './product-company-create.component.html',
    styleUrls: ['./product-company-create.component.less']
})
export class ProductCompanyCreateComponent implements OnInit {

    company: ProductCompany = new ProductCompany();

    ageFormulaOptions: PropertySelectOption[] = []
    regionOptions: PropertySelectOption[] = [];
    businessTypeOptions: PropertySelectOption[] = [];

    @Output()
    companyCreated: EventEmitter<ProductCompany> = new EventEmitter<ProductCompany>();

    constructor(private productService: ProductService,
                private productCompanyRegionPipe: ProductCompanyRegionPipe,
                private notification: NzNotificationService,
                public dataService: DataService,) {
        this.ageFormulaOptions?.push(new PropertySelectOption('周岁', AgeFormula.CURRENT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('上一次生日', AgeFormula.LAST_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('下一次生日', AgeFormula.NEXT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('最近生日', AgeFormula.NEAREST_BIRTHDAY));

        let productCompanyRegions = ProductCompanyRegions.all();
        this.regionOptions = productCompanyRegions.map(region => {
            return new PropertySelectOption(this.productCompanyRegionPipe.transform(region), region)
        })

    }

    ngOnInit(): void {
        this.company.nameI18n = new I18n();
    }

    onCreate() {
        this.productService.companyCreate(this.company)
            .subscribe(
                data => {
                    this.notification.success('保險公司創建成功', '');
                    this.companyCreated.emit(data);
                },
                error => {
                });
    }
}
