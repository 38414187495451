import { Component, Input, OnInit } from '@angular/core';
import { I18n, ProductCategory } from "../../api/types";
import { ProductService } from "../product.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { DataService } from "../../shared/services/data.service";

@Component({
    selector: 'app-product-category-info',
    templateUrl: './product-category-info.component.html',
    styleUrls: ['./product-category-info.component.less']
})
export class ProductCategoryInfoComponent implements OnInit {

    @Input() category: ProductCategory;
    @Input() editing: boolean = false;
    loading: boolean = false

    constructor(private productService: ProductService,
                private notification: NzNotificationService,
                public dataService: DataService,) {
    }

    ngOnInit(): void {
    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    onSave(): void {
        this.loading = true;
        let code = this.category.code;
        this.productService.categoryUpdate(code, this.category)
            .subscribe(
                data => {
                    if (data.nameI18n == null) {
                        data.nameI18n = new I18n();
                    }
                    this.category = data;
                    this.notification.success('保存險種信息成功', '');
                    this.editing = false;
                    this.loading = false;
                },
                error => {
                    this.notification.error('保存險種信息失敗', '');
                    this.loading = false;
                });
    }

}
