<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!informations">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="informations" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="informations.list"
                      [nzPageIndex]="informations.pageNum"
                      [nzPageSize]="informations.pageSize"
                      [nzTotal]="informations.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)">
                <ng-template #tableHeader>
                    <!--            <div>-->
                    <!--                <i nz-icon nzType="search" nzTheme="outline" style="color: #95AAC9;"></i>-->
                    <!--            </div>-->
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">資訊列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="導出Excel"
                                        appThrottleClick (throttleClick)="export()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-download" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="標籤管理"
                                        appThrottleClick (throttleClick)="editTags()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="tags" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="創建資訊"
                                        appThrottleClick (throttleClick)="onCreate()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="plus" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-popover [nzPopoverContent]="filterTemplate"
                                        [(nzPopoverVisible)]="filterPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        style=" margin-left: 10px;">
                                    <img alt="" width="24" src="assets/images/ic_filter.svg">
                                </button>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ informations.totalCount }}</span> 條
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft style="text-align: center"><input type="checkbox" ngModel
                                                                 (ngModelChange)="checkAllEvent($event)"
                                                                 (click)="$event.stopPropagation();" class="check-box"/>
                    </th>
                    <th nzLeft>ID</th>
                    <th>資訊類型</th>
                    <th>資訊標題</th>
                    <th>資訊標籤</th>
                    <th>資訊時間</th>
                    <th>發佈人</th>
                    <!--                    <th>發布範圍</th>-->
                    <th>操作</th>
                    <!--<th style="text-align: center">操作</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let information of informations.list; let i = index" appThrottleClick
                    (throttleClick)="openInfoDrawer(information)">
                    <td nzLeft style="text-align: center" appThrottleClick (throttleClick)="$event.stopPropagation();">
                        <input type="checkbox" [(ngModel)]="information.checked" (click)="$event.stopPropagation();"
                               class="check-box">
                    </td>
                    <td nzLeft>{{ information.id }}</td>
                    <td nzBreakWord>{{ information.type | releaseType }}</td>
                    <td>{{ information.title }}</td>
                    <td>
                        <nz-tag *ngFor="let tag of information.tags; let i = index" [nzMode]="'default'">
                            <span style="font-size: 13px">{{ tag }}</span>
                        </nz-tag>
                    </td>
                    <td>{{ information.addTime | date:'yyyy-MM-dd'}}</td>
                    <td>{{ information.accountName }}</td>
                    <!--                    <td>全體</td>-->
                    <td>
                        <div>
                            <!--                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onDelete(product)" nzSize="small">-->
                            <!--                            <em nz-icon nzType="info-circle" nzTheme="outline"></em>-->
                            <!--                        </a>-->
                            <!--                        <nz-divider nzType="vertical"></nz-divider>-->
                            <a nz-button nzType="link" appThrottleClick
                               (throttleClick)="onDelete(information);$event.stopPropagation();"
                               nzSize="small" class="delete-button">
                                <em nz-icon nzType="delete" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

            <nz-affix [nzOffsetBottom]="40" *ngIf="selectedProducts?.length > 0">
                <div class="product-sync-container">
                    <div class="product-sync-wrapper">
                        <i nz-icon nzType="check-circle" nzTheme="outline"
                           style="font-size: 20px;"></i>
                        <span style="margin-left: 5px;">已選</span>
                        <span
                            style="margin-left: 5px; margin-right: 5px; color: #f3e282; font-size: 25px">{{selectedProducts?.length}}</span>
                        <span>條資訊</span>
                        <button style="margin-left: 20px;" class="product-sync-button" nz-button nzType="primary"
                                nzShape="round" appThrottleClick (throttleClick)="onInformationInstanceSync()">
                            同步至簽約客戶
                        </button>
                    </div>
                </div>
            </nz-affix>

        </div>
        <!-- profile drawer -->

    </nz-spin>

</div>

<app-manage-modal [isVisible]="modalVisible" [(value)]="tags"
                  (valueChange)="tagsChange($event)"
                  (visibleChange)="visibleChange()"></app-manage-modal>

<!-- 多语言气泡Template -->
<ng-template #filterTemplate>
    <div style="width: 255px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'筛选'}}</div>
        <div class="property-bottom-line-blue"></div>

        <app-property-text-edit [title]="'ID'" [(value)]="search.id"></app-property-text-edit>

        <app-property-text-edit [title]="'公告標題'" [(value)]="search.keyword"></app-property-text-edit>

        <!-- <app-property-select-edit [title]="'發布對象'" [(value)]="search.keyword"></app-property-select-edit> -->

        <app-property-select-edit [title]="'公告標籤'" [(value)]="search.tags"
                                  [options]="tagsOptions"></app-property-select-edit>

        <app-property-select-edit [title]="'公告類型'" [(value)]="search.type"
                                  [options]="typeOptions"></app-property-select-edit>

        <app-property-rangetime [title]="'公告時間'" [(value)]="time" [editing]="true" (valueChange)="onTimeChange($event)"
                                [editable]="true"></app-property-rangetime>

        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
                    <span>{{'重置' | translate}}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
                    <span>{{'确定' | translate}}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
