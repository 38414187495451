<div *ngIf="category">
    <div class="profile-large-title">
        <nz-avatar [nzShape]="'square'" [nzSize]="40" [nzSrc]="category.logo"></nz-avatar>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">{{ category.name }}</span>
    </div>
    <nz-tabset [nzSize]="'large'" nzCentered>
        <nz-tab nzTitle="險種詳情">
            <app-property-text [title]="'險種代码'" [(value)]="category.code" [editable]="false"></app-property-text>

            <app-property-text [title]="'險種默認名稱'" [(value)]="category.name" [editable]="true" [editing]="editing"></app-property-text>

            <app-property-i18n [title]="'險種名称（多语言配置）'" [(value)]="category.nameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-text [title]="'險種排序（数值越小排名越前）'" [(value)]="category.rank" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-text [title]="'險種描述'" [(value)]="category.desc" [editing]="editing" [editable]="true"
                               [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

            <div class="footer">
                <a *ngIf="!editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()">
                    <em nz-icon nzType="edit" nzTheme="outline"></em>
                    <span>编 辑</span>
                </a>
                <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="12" class="footer-button">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock>
                            <em nz-icon nzType="close" nzTheme="outline"></em>
                            <span>取 消</span>
                        </a>
                    </div>
                    <div nz-col nzSpan="12" class="footer-button-primary">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock>
                            <em nz-icon nzType="save" nzTheme="outline"></em>
                            <span>保 存</span>
                        </a>
                    </div>
                </div>
            </div>
        </nz-tab>
<!--        <nz-tab nzTitle="相關鏈接">-->
<!--        </nz-tab>-->
    </nz-tabset>
</div>
