import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let ticketStatus = "";
        switch (value) {
            case 'PENDING':
                ticketStatus = "进行中";
                break;
            case 'REPLIED':
                ticketStatus = "已回复";
                break;
            case 'CLOSED':
                ticketStatus = "关闭";
                break;
        }
        return ticketStatus;
    }

}
