import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiResponse, Knowledge, KnowledgeSearch, PagedResp, ProcessLog } from "../../api/types";
import { ProductService } from "../../product/product.service";
import { KnowledgeInfoComponent } from "../knowledge-info.component";
import { KnowledgeService } from "../knowledge.service";
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { KnowledgeCreateComponent } from "../components/knowledge-create.component";
import { ProductCompany } from "../../product/product-types";

@Component({
    selector: 'app-knowledge-product',
    templateUrl: './knowledge-product.component.html',
    styleUrls: ['./knowledge-product.component.less']
})
export class KnowledgeProductComponent implements OnInit {

    companies: ProductCompany[];

    selectedCompany: ProductCompany

    loadingCompanies: boolean = false;
    loadingKnowledge: boolean = false;

    knowledges: PagedResp<Knowledge>;
    search: KnowledgeSearch = new KnowledgeSearch();

    showInfoDrawer: boolean = false;

    @ViewChild('knowledge')
    knowledgeInfoComponent: KnowledgeInfoComponent;

    uploader: FileUploader;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    spinning: boolean = false;

    constructor(private productService: ProductService, private knowledgeService: KnowledgeService, private drawerService: NzDrawerService, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.loadingCompanies = true;
        this.productService.companies()
            .subscribe(
                data => {
                    this.loadingCompanies = false;
                    this.companies = data;
                    if (this.companies?.length > 0) {
                        this.switchCompany(this.companies[0])
                    }
                },
                error => {
                    this.loadingCompanies = false;
                });


        let httpHeaders = this.knowledgeService.getAuthorizationHeaders();
        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            url: this.knowledgeService.knowledgeUrl + "/product/import",
            method: "POST",
            itemAlias: "file",
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: true
        });

        /// 需要bind(this)，才能在对应方法里使用this.
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }

    switchCompany(company: ProductCompany): void {
        this.selectedCompany = company;
        this.search.companyCode = company.code
        this.load();
    }

    load(): void {
        this.loadingKnowledge = true;
        this.knowledgeService.knowledges(this.search)
            .subscribe(
                data => {
                    this.loadingKnowledge = false;
                    this.knowledges = data;
                },
                error => {
                    this.loadingKnowledge = false;
                });
    }

    openInfo(knowledge: Knowledge): void {
        this.knowledgeService.knowledgeInfo(knowledge.id)
            .subscribe(
                data => {
                    this.showInfoDrawer = true;
                    this.knowledgeInfoComponent.editing = false;
                    this.knowledgeInfoComponent.knowledge = data;
                },
                error => {
                });
    }

    closeProfile(): void {
        this.showInfoDrawer = false;
        this.knowledgeInfoComponent.editing = false;
    }


    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let apiResponse = JSON.parse(response) as ApiResponse<ProcessLog[]>;
        this.uploader.clearQueue();

        this.openLogsDrawer(apiResponse.data);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }


    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }


    onCreate(): void {
        const drawerRef = this.drawerService.create<KnowledgeCreateComponent, { value: Knowledge }, string>({
            nzWidth: 800,
            nzContent: KnowledgeCreateComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.drawerRef = drawerRef;
            component.knowledgeCreated
                .subscribe(
                    knowledge => {
                        this.load();
                    }
                );
        });

        drawerRef.afterClose.subscribe(data => {
        });
    }
}
