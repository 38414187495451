import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from "ng2-file-upload";

@Component({
    selector: 'app-product-property-home',
    templateUrl: './product-property-home.component.html',
    styleUrls: ['./product-property-home.component.scss']
})
export class ProductPropertyHomeComponent implements OnInit {

    uploader: FileUploader;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor() {
    }

    ngOnInit(): void {
    }

    selectedImportFileOnChanged(event) {
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    upload(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

}
