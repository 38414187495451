import { Injectable, OnInit } from '@angular/core';
import { BaseService } from "../../base/base.service";
import { Observable } from "rxjs/internal/Observable";
import {
    ApiResponse,
    MaterialAccessToken,
    MaterialAccessTokenReq, MaterialFilterReq, MaterialFilterRes,
    MaterialUploadFileReq,
    MaterialUploadToken,
    MaterialUploadTokenReq
} from "../../api/types";
import { API } from "../../api/api";
import { map } from "rxjs/operators";
import { HttpService } from "../../api/http.service";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../../common/utils";

@Injectable({
    providedIn: 'root'
})
export class MaterialService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    uploadToken(req: MaterialUploadTokenReq): Observable<MaterialUploadToken> {
        let params = new HttpParams();
        if (!isNullOrUndefined(req.uploadTo)) {
            params = params.set('uploadTo', req.uploadTo.toString());
        }
        if (!isNullOrUndefined(req.key)) {
            params = params.set('key', req.key.toString());
        }
        if (!isNullOrUndefined(req.fileName)) {
            params = params.set('fileName', req.fileName.toString());
        }
        return this.http.get<ApiResponse<MaterialUploadToken>>(this.materialUrl + '/upload-token', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }))
    }

    uploadFile(req: MaterialUploadFileReq, formData): Observable<MaterialUploadToken> {
        let params = new HttpParams();
        if (!isNullOrUndefined(req.uploadTo)) {
            params = params.set('uploadTo', req.uploadTo.toString());
        }
        if (!isNullOrUndefined(req.key)) {
            params = params.set('key', req.key.toString());
        }
        return this.http.post<ApiResponse<MaterialUploadToken>>(this.materialUrl + '/upload', formData, {
            headers: this.getFileHeaders(),
            observe: 'response',
            params: params
        }).pipe(map(data => {
            return data.body.data;
        }))
    }

    accessToken(req: MaterialAccessTokenReq): Observable<MaterialAccessToken> {
        let params = new HttpParams();
        if (!isNullOrUndefined(req.bucket)) {
            params = params.set('bucket', req.bucket.toString());
        }
        if (!isNullOrUndefined(req.objectName)) {
            params = params.set('objectName', req.objectName.toString());
        }
        return this.http.get<ApiResponse<MaterialAccessToken>>(this.materialUrl + '/access-token', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }))
    }

    filter(req: MaterialFilterReq): Observable<MaterialFilterRes> {
        return this.http.post<ApiResponse<MaterialFilterRes>>(this.materialUrl + '/filter', req, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }))
    }

}
