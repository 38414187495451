<div class="property">
    <div class="property-title" *ngIf="title">{{ title }}</div>
    <div *ngIf="value" class="property-value">
        <div class="i18n-area">
            <div class="i18n-area-title">简体：</div>
            <div class="i18n-area-value">{{ value.zhHansCN | nullable }}</div>
        </div>
        <div class="i18n-area">
            <div class="i18n-area-title">繁体：</div>
            <div class="i18n-area-value">{{ value.zhHantHK | nullable }}</div>
        </div>
        <div class="i18n-area">
            <div class="i18n-area-title">英文：</div>
            <div class="i18n-area-value">{{ value.enUS | nullable }}</div>
        </div>
    </div>
</div>
