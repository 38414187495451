<div *ngIf="product">
    <div class="profile-large-title">
        <nz-avatar [nzShape]="'square'" [nzSize]="40" [nzSrc]="product.company?.logo"></nz-avatar>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">{{ product.name }}</span>
    </div>
    <nz-tabset [nzSize]="'large'" nzCentered>
        <nz-tab nzTitle="基本信息">

            <!--            <app-property-text [title]="'產品碼'" [(value)]="product.code" [editable]="false"></app-property-text>-->

            <app-property-text [title]="'產品码'" [(value)]="product.code" [editable]="false"
                               [editing]="false"></app-property-text>

            <app-property-text [title]="'產品默認名稱'" [(value)]="product.name" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-i18n [title]="'產品名稱（多語言配置）'" [(value)]="product.nameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-i18n [title]="'產品簡稱（多語言配置）'" [(value)]="product.shortNameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-i18n [title]="'產品合規名稱（多語言配置）'" [(value)]="product.complianceNameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-select [title]="'產品性質'" [name]="product.commonType | productCommonType" [(value)]="product.commonType"
                                 [editable]="true" [editing]="editing" [options]="commonTypeOptions"></app-property-select>

            <app-property-select [title]="'類型'" [name]="product.type | productType" [(value)]="product.type"
                                 [editable]="true" [editing]="editing" [options]="typeOptions"></app-property-select>

            <app-property-select [title]="'保險險種'" [name]="product.category?.name" [(value)]="product.categoryCode"
                                 [editable]="true" [editing]="editing"
                                 [options]="categoryOptions"></app-property-select>

            <app-property-select [title]="'保險公司'" [name]="product.company?.name" [(value)]="product.companyCode"
                                 [editable]="true" [editing]="editing" [options]="companyOptions"></app-property-select>

            <app-property-select [title]="'狀態'" [name]="product.status | productStatus" [(value)]="product.status"
                                 [editable]="true" [editing]="editing" [options]="statusOptions"></app-property-select>

            <app-property-select [title]="'所在地区'" [name]="product.region | productCompanyRegion"
                                 [(value)]="product.region" [editable]="false" [editing]="false" [options]="regionOptions">
            </app-property-select>

            <app-property-switch [title]="'是否標準產品'" [(value)]="product.standard" [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-tags [title]="'產品年期'" [(value)]="product.premiumTerms" [editable]="true"
                               [editing]="editing"></app-property-tags>

            <app-property-select [title]="'貨幣'" [editable]="true" [editing]="editing" [name]="product.currencies"
                                 [(value)]="product.currencies" [mode]="'multiple'"
                                 [options]="currencyOptions"></app-property-select>

            <app-property-select [title]="'繳費頻率'" [editable]="true" [editing]="editing" [name]="frequencyNames"
                                 [(value)]="product.frequencies" [mode]="'multiple'"
                                 [options]="frequencyOptions"></app-property-select>

            <app-property-text [title]="'保障年期'" [(value)]="product.policyPeriod" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-datetime [title]="'上市日期'" [(value)]="product.launchDate" [editable]="true" [editing]="editing" format="yyyy-MM-dd"></app-property-datetime>
            <app-property-datetime [title]="'停售日期'" [(value)]="product.discontinuationDate" [editable]="true" [editing]="editing" format="yyyy-MM-dd"></app-property-datetime>

            <app-property-switch [title]="'是否热门產品'" [(value)]="product.hot" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否可對比'" [(value)]="product.comparable" [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-switch [title]="'是否可試算'" [(value)]="product.quotable" [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-select *ngIf="product.quotable" [title]="'保費試算公式'"
                                 [name]="product.quotationFormula | productQuotationFormula"
                                 [(value)]="product.quotationFormula"
                                 [editable]="true" [editing]="editing"
                                 [options]="quotationFormulaOptions"></app-property-select>

            <app-property-text *ngIf="product.quotable"
                               [title]="'試算數據版本號'"
                               [(value)]="product.quotationVersion" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-switch [title]="'是否適用提取計劃'" [(value)]="product.withdrawalable" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否支持計劃書機器人'" [(value)]="product.proposalRPASupported" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否支持製作規劃書'" [(value)]="product.financialPlanSupported" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否支持保費融資'" [(value)]="product.premiumFinancingSupported" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否支持保費扣除'" [(value)]="product.premiumOffsetSupported" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-switch [title]="'是否支持計劃書預繳'" [(value)]="product.proposalPrepaymentSupported" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-text [title]="'產品排序（數值越小排名越前）'" [(value)]="product.rank" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-text [title]="'產品描述'" [(value)]="product.desc" [editing]="editing" [editable]="true"
                               [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

            <app-property-i18n-area [title]="'產品描述（多語言配置）'" [(value)]="product.descI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n-area>

            <app-property-text [title]="'内部备注'" [(value)]="product.internalRemark" [editing]="editing" [editable]="true"
                               [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

            <div class="footer">
                <div nz-row *ngIf="!editing" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="24" class="footer-button-primary">
                        <a nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()">
                            <em nz-icon nzType="edit" nzTheme="outline"></em>
                            <span>编 辑</span>
                        </a>
                    </div>
                </div>
                <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="12" class="footer-button">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock nzSize="large">
                            <em nz-icon nzType="close" nzTheme="outline"></em>
                            <span>取 消</span>
                        </a>
                    </div>
                    <div nz-col nzSpan="12" class="footer-button-primary">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock nzSize="large">
                            <em nz-icon nzType="save" nzTheme="outline"></em>
                            <span>保 存</span>
                        </a>
                    </div>
                </div>
            </div>
        </nz-tab>
        <nz-tab nzTitle="產品附件">
            <app-material-editor [materials]="product.files" [tags]="product.fileTags" [uploadReq]="uploadReq"
                                 [spinning]="spinning"
                                 (valueChange)="onFilesChange($event)" (materialModified)="onFileModified($event)"></app-material-editor>
        </nz-tab>
        <nz-tab nzTitle="產品特色">
            <app-product-product-features [product]="product"></app-product-product-features>
        </nz-tab>
        <nz-tab nzTitle="產品詳情">
            <app-product-property-detail [code]="product.code" #propertyGroups></app-product-property-detail>
        </nz-tab>
        <nz-tab nzTitle="包含客戶">
            <nz-list *ngIf="customerOfTheProductArr.length" nzBordered nzHeader="包含客戶列表">
                <nz-list-item *ngFor="let item of customerOfTheProductArr">
                    {{item}}
                </nz-list-item>
            </nz-list>
            <nz-empty *ngIf="!customerOfTheProductArr.length" nzNotFoundImage="simple"></nz-empty>
        </nz-tab>
        <nz-tab *ngIf="product.categoryCode == 'MEDICAL'" nzTitle="醫療選項">
            <app-product-product-medical-options [product]="product" style="height: 100%"></app-product-product-medical-options>
        </nz-tab>
        <nz-tab nzTitle="佣金合约">
            <app-product-master-rate-detail [product]="product" style="height: 100%"></app-product-master-rate-detail>
        </nz-tab>
    </nz-tabset>
</div>
