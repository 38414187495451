import {Injectable, OnInit} from '@angular/core';
import {HttpService} from "../api/http.service";
import {ApiResponse, PagedResp, ProposalCustom, ProposalCustomSearch} from "../api/types";
import {Observable} from "rxjs/internal/Observable";
import {HttpParams} from "@angular/common/http";
import {isNullOrUndefined} from "../common/utils";
import {map} from "rxjs/operators";
import {BaseService} from "../base/base.service";

@Injectable({
  providedIn: 'root'
})
export class ProposalCustomService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }


    /**
     * 获取计划书模板列表
     * @param search 搜索参数
     */
    customList(search: ProposalCustomSearch): Observable<PagedResp<ProposalCustom>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.instance)) {
            params = params.set('instance', search.instance.toString());
        }
        if (!isNullOrUndefined(search.bid)) {
            params = params.set('bid', search.bid.toString());
        }

        return this.http.get<ApiResponse<PagedResp<ProposalCustom>>>(this.proposalCustomUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 计划书模板重试
     * @param id 计划书模板id
     */
    retry(id: number): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.proposalCustomUrl + '/' + id + '/retry', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }}
