import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Material, MaterialUploadReq, MaterialUploadTokenReq } from "../../../api/types";
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import { MaterialService } from "../../services/material.service";
import { ToastrService } from "ngx-toastr";
import { isNullOrUndefined } from "../../../common/utils";
import { DownloadService } from "../../services/download.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzImageService } from "ng-zorro-antd/image";

@Component({
  selector: 'app-upload-picture',
  templateUrl: './upload-picture.component.html',
  styleUrls: ['./upload-picture.component.scss']
})
export class UploadPictureComponent implements OnInit {

    @Input()
    uploadReq: MaterialUploadReq;

    @Input()
    material: Material = new Material();

    @Output()
    valueChange: EventEmitter<Material> = new EventEmitter<Material>();

    uploader: FileUploader;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    materialMap: Map<string, Material> = new Map();

    constructor(private downloadService: DownloadService,
                private imageService: NzImageService,
                private materialService: MaterialService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {

        let httpHeaders = this.materialService.getAuthorizationHeaders();

        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: false,
            removeAfterUpload: false,
        });

        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    }

    ngAfterViewInit(): void {
        // const options = this.uploader.options;
        // options.additionalParameter = {
        //     "uploadTo": this.uploadReq.uploadTo,
        //     "key": this.uploadReq.key
        // };
        // this.uploader.setOptions(options);
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    upload(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    uploadFileItem(fileItem: FileItem) {
        const req: MaterialUploadTokenReq = {
            uploadTo: this.uploadReq.uploadTo,
            key: this.uploadReq.key,
            fileName: fileItem.file.name
        }
        this.materialService.uploadToken(req)
            .subscribe(
                data => {
                    fileItem.method = "POST";
                    fileItem.url = data.host;
                    fileItem.onBuildForm = function (form) {
                        // form.append('x-oss-object-acl', data.material.accessControl.toLowerCase());
                        form.append('key', data.material.objectName);
                        form.append('policy', data.policy);
                        form.append('signature', data.signature);
                        form.append('OSSAccessKeyId', data.accessid);
                    };
                    fileItem.upload();
                    this.materialMap.set(fileItem.file.name, data.material);
                    console.info(fileItem);
                },
                error => {
                });
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
        this.uploadFileItem(fileItem);
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

        this.uploader.removeFromQueue(item);

        this.material = this.materialMap.get(item.file.name);
        if (!isNullOrUndefined(this.material)) {
            this.valueChange.emit(this.material);
            this.materialMap.delete(item.file.name);
        }

        // this.uploader.clearQueue();
        // console.info(response + " for " + item.file.name + " status " + status);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.uploader.removeFromQueue(item);

        // this.toastr.warning(response.);
        // this.uploader.clearQueue();
        console.info(response + " for " + item.file.name + " status " + status);
    }

    extension(filePath: string): string {
        if (isNullOrUndefined(filePath) || filePath.length === 0) return ""
        return filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase()
    }

    onDownload(material: Material) {
        this.downloadService.download(material.filePath, material.fileName);
        // this.downloadService.showOpenDialog(material.fileName);
    }

    onDelete() {
        this.modalService.confirm({
            nzTitle: '删除文件',
            nzContent: '<b style="color: #ff0048; font-size: 16px">是否确定删除' + this.material.fileName + '？</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.material = new Material();
                this.valueChange.emit(this.material);
            }
        });

    }

    onPreview(material: Material) {
        const images = [
            {
                src: material.filePath,
                width: 'auto',
                height: 'auto',
                alt: material.fileName
            }
        ];
        this.imageService.preview(images, {nzZoom: 1.0, nzRotate: 0});
    }

}
