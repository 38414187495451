import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'supplementaryBenefit'
})
export class SupplementaryBenefitPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let supplementaryBenefit = "";
        switch (value) {
            case 'OUTPATIENT':
                supplementaryBenefit = "門診";
                break;
            case 'MATERNITY':
                supplementaryBenefit = "產科";
                break;
            case 'DENTAL':
                supplementaryBenefit = "牙科";
                break;
            case 'SMM':
                supplementaryBenefit = "額外醫療保障";
                break;
            case 'SMMP':
                supplementaryBenefit = "特級額外醫療保障";
                break;
        }
        return supplementaryBenefit;
    }

}
