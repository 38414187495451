import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18n } from "../../../api/types";

@Component({
  selector: 'app-property-i18n-area-edit',
  templateUrl: './property-i18n-area-edit.component.html',
  styleUrls: ['./property-i18n-area-edit.component.less']
})
export class PropertyI18nAreaEditComponent implements OnInit {

    @Input() title: string;

    @Output()
    valueChange: EventEmitter<I18n> = new EventEmitter<I18n>();

    i18n: I18n;

    @Input()
    get value() {
        return this.i18n;
    }
    set value(val) {
        this.i18n = val;
        this.valueChange.emit(this.i18n);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
