import { CreditRating, Material, PagedReq } from '../api/types';
import { Transform } from 'class-transformer';
import { plainToArray } from '../common/util/class-transform';

export class TicketListSearch extends PagedReq {
    id: number;
    status: string;
    startReplyDate: string;
    endReplyDate: string;
    startCreateDate: string;
    endCreateDate: string;
}

export class TicketListResp {
    id: number;
    title: string;
    status: string;
    createTime: string;
    lastReplyTime: string;
}


export class TicketReply {
    ticketId: number;
    content: string;
    @Transform((value) => plainToArray(Material, value.value))
    files: Material[]; // 文件列表
    role: string; // CENTRE
}

export class TicketDetailResp {
    title: string;
    status: string;
    initiatorInfo: InitiatorInfo = new InitiatorInfo();
    lastReplyTime: string;
    @Transform((value) => plainToArray(RepliesArr, value.value))
    replies: RepliesArr[] = [];
}

export class InitiatorInfo {
    accountName: string;
    accountType: string;
    avatar: string;
    instance: string;
}

export class RepliesArr {
    role: string;
    content: string;
    @Transform((value) => plainToArray(Material, value.value))
    files: Material[]; // 文件列表
    createTime: string;
}


