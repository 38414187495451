import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    DiscontinuedProductFilterReq,
    Information,
    Material,
    MaterialUploadReq,
    Operator,
    ProductSearch,
    ReleaseType,
} from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { InformationService } from "../information.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { isNotNullOrUndefined } from "../../common/utils";
import { AuthService } from '../../passport/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ProductService } from "../../product/product.service";
import { ReleaseTypePipe } from "../../shared/pipes/release-type.pipe";
import * as _ from "lodash";
import { ProductCompanyRegionPipe } from "../../shared/pipes/product-company-region.pipe";


@Component({
    selector: 'app-release-info',
    templateUrl: './information-info.component.html',
    styleUrls: ['./information-info.component.scss']
})
export class InformationInfoComponent implements OnInit, AfterViewInit {

    @Input() information: Information;
    @Input() editing: boolean = false;

    loading: boolean = false;
    operator: Operator = new Operator()
    typeOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []
    productOptions: PropertySelectOption[] = []
    avatarUrl?: string;
    showProduct: boolean = true;
    productSearch: ProductSearch = new ProductSearch();
    tagsOptions: PropertySelectOption[] = []
    productLoading = false;

    material: Material;
    uploadReq: MaterialUploadReq = MaterialUploadReq.fromInformation(new Information());
    @Output()
    informationUpdated: EventEmitter<Information> = new EventEmitter<Information>();

    constructor(private informationService: InformationService,
                private notification: NzNotificationService,
                private authService: AuthService,
                private msg: NzMessageService,
                private releaseTypePipe: ReleaseTypePipe,
                private productService: ProductService,
                private companyRegionPipe: ProductCompanyRegionPipe) {
    }

    ngOnInit(): void {
        this.operator = this.authService.getOperator();
        // this.typeOptions?.push(new PropertySelectOption('全部', null));
        // this.typeOptions?.push(new PropertySelectOption('行政通知', ReleaseType.ADMINISTRATION_NOTICE));
        // this.typeOptions?.push(new PropertySelectOption('產品動態', ReleaseType.PRODUCT_NEWS));
        // this.typeOptions?.push(new PropertySelectOption('一般資訊', ReleaseType.GENERAL_INFO));
        let releaseTypes: ReleaseType[] = [ReleaseType.ADMINISTRATION_NOTICE, ReleaseType.PRODUCT_NEWS, ReleaseType.GENERAL_INFO, ReleaseType.PRODUCT_PROMOTION]
        this.typeOptions = releaseTypes.map(releaseType => {
            return new PropertySelectOption(this.releaseTypePipe.transform(releaseType), releaseType)
        })
        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });

        this.material = new Material();

        if (this.information?.cover) {
            this.material.fileName = '封面';
            this.material.filePath = this.information.cover;
        }

        if (isNotNullOrUndefined(this.information)) {
            this.productOptions = [];
            for (let i = 0; i < this.information?.productCodes?.length ?? 0; i++) {
                this.productOptions.push(new PropertySelectOption(this.information.productNames[i], this.information.productCodes[i]))
            }
        }

    }

    ngAfterViewInit(): void {
    }

    @Input()
    get value(): Information {
        return this.information;
    }

    set value(val: Information) {
        this.information = val;
        if (isNotNullOrUndefined(this.information)) {
            this.productOptions = [];
            for (let i = 0; i < this.information?.productCodes?.length ?? 0; i++) {
                this.productOptions.push(new PropertySelectOption(this.information.productNames[i], this.information.productCodes[i]))
            }
        }
    }

    informationTypeChange(value: string) {
        switch (value) {
            case "PRODUCT_NEWS":
                this.showProduct = true
                // this.releaseData.companyCode = ""
                // this.releaseData.productCode = ""
                break;
            case "PRODUCT_PROMOTION":
                this.showProduct = true
                // this.releaseData.companyCode = ""
                // this.releaseData.productCodes = []
                break;
            default:
                this.showProduct = true
                break;
        }
    }

    // 公司请求产品
    companyChange(value: any) {
        if (this.information.companyCode === value) return;
        this.information.productCodes = [];
        this.productSearch.pageNum = 1;
        this.productSearch.companyCode = value;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                    this.productOptions = _.uniqWith(this.productOptions, _.isEqual);
                },
                error => {
                    // this.loading = false;
                });
    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    onSave(): void {
        this.loading = true;
        this.informationService.update(this.information)
            .subscribe(
                data => {
                    this.getInfo();
                    this.notification.success('保存成功', '');
                    this.editing = false;
                    this.loading = false;
                    this.informationUpdated.emit(this.value);
                },
                error => {
                    this.notification.error('保存失败', '');
                    this.loading = false;
                });
    }

    getInfo() {
        this.loading = true;
        this.informationService.info(this.information.id)
            .subscribe(
                data => {
                    this.loading = false;
                    this.information = data;
                },
                error => {
                    this.loading = false;
                });
    }


    onFilesChange(files: Material[]) {
        this.information.attachments = files
        this.informationService.update(this.information)
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }

    onProductSearch(value: string) {
        this.productLoading = true;
        this.productSearch.pageNum = 1;
        this.productSearch.name = value;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productLoading = false;
                    this.productOptions = [];
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                },
                error => {
                    this.productLoading = false;
                    // this.loading = false;
                });
    }

    onProductNextPage() {
        this.productLoading = true;
        this.productSearch.pageNum = this.productSearch.pageNum + 1;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productLoading = false;
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                },
                error => {
                    this.productLoading = false;
                    // this.loading = false;
                });
    }

    onPictureChange(material: Material) {
        this.information.cover = material?.filePath
        this.loading = true;
        this.informationService.update(this.information)
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }

    onFilterDiscontinuedProducts() {
        const req = new DiscontinuedProductFilterReq();
        req.productCodes = this.information.productCodes;
        this.loading = true;
        this.informationService.filterDiscontinuedProducts(req)
            .subscribe(
                data => {
                    this.loading = false;
                    const products = data.products;
                    if (products != null && products.length > 0) {
                        const productCodes = products.map(product => {
                            return product.code;
                        });

                        const productNames = products.map(product => {
                            return product.name;
                        });

                        this.information.productCodes = productCodes;
                        this.information.productNames = productNames;

                        this.productOptions = [];
                        for (let i = 0; i < this.information?.productCodes?.length ?? 0; i++) {
                            this.productOptions.push(new PropertySelectOption(this.information.productNames[i], this.information.productCodes[i]))
                        }

                    }
                },
                error => {
                    this.loading = false;
                });
    }
}
