import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Product, ProductRestriction } from "../../api/types";

@Component({
    selector: 'app-product-restriction-create',
    templateUrl: './product-restriction-create.component.html',
    styleUrls: ['./product-restriction-create.component.less']
})
export class ProductRestrictionCreateComponent implements OnInit {

    @Output()
    restrictionCreated: EventEmitter<ProductRestriction> = new EventEmitter<ProductRestriction>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
