import { Component, EventEmitter, OnInit } from '@angular/core';
import { Article, ArticleSearch } from "../article-types";
import { PagedResp } from "../../api/types";
import { ArticleService } from "../article.service";
import { ElectronService } from "../../core/services";
import { NzDrawerRef, NzDrawerService } from "ng-zorro-antd/drawer";

@Component({
    selector: 'app-article-selection',
    templateUrl: './article-selection.component.html',
    styleUrls: ['./article-selection.component.less']
})
export class ArticleSelectionComponent implements OnInit {

    drawerRef: NzDrawerRef<ArticleSelectionComponent, string>;

    spinning = false;
    loading = false;
    search: ArticleSearch = new ArticleSearch();
    articles: PagedResp<Article>;
    filterPopoverVisible = false;
    articleSelected: EventEmitter<Article> = new EventEmitter<Article>();

    constructor(private articleService: ArticleService,
                private electronService: ElectronService,
                private drawerService: NzDrawerService) {
    }

    ngOnInit(): void {
        this.load();
    }

    load(): void {
        this.loading = true;
        this.articleService.list(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.articles = data;
                },
                () => {
                    this.loading = false;
                });
    }

    onReload(): void {
        this.search.pageNum = 1;
        this.load();
    }

    onSearch(pageNum: number): void {
        this.search.pageNum = pageNum
        this.load();
    }

    onResetSearch(): void {
        this.search = new ArticleSearch();
        this.onReload();
    }

    onEdit(article: Article): void {
        const openId = this.articleService.operator?.xiumiOpenId;
        if (openId) {
            const url = `https://xiumi.us/auth/partner/edit?open_id=${openId}&article_id=${article.xiumiId}`;
            this.electronService.openUrl(url);
        }
    }

    onDetail(id: number) {
        this.spinning = true;
        this.articleService.info(id)
            .subscribe(
                article => {
                    this.spinning = false;
                    this.articleSelected.emit(article);
                    this.drawerRef?.close();
                },
                () => {
                    this.spinning = false;
                }
            );
    }

}
