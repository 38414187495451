<app-property-select [title]="'保障區域'" [editable]="true" [editing]="editing" [name]="medicalRegionNames"
                     [(value)]="medicalOptions.regions" [mode]="'multiple'"
                     [options]="medicalRegionOptions"></app-property-select>

<app-property-select [title]="'病房等級'" [editable]="true" [editing]="editing" [name]="medicalLevelNames"
                     [(value)]="medicalOptions.levels" [mode]="'multiple'"
                     [options]="medicalLevelOptions"></app-property-select>

<app-property-select [title]="'附加保障'" [editable]="true" [editing]="editing" [name]="supplementaryBenefitNames"
                     [(value)]="medicalOptions.supplementaryBenefits" [mode]="'multiple'"
                     [options]="supplementaryBenefitOptions"></app-property-select>

<app-deductibles [(value)]="medicalOptions.deductibles" [editing]="editing"></app-deductibles>

<div class="footer">
    <a *ngIf="!editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()" nzSize="large">
        <em nz-icon nzType="edit" nzTheme="outline"></em>
        <span>编 辑</span>
    </a>
    <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
        <div nz-col nzSpan="12" class="footer-button">
            <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock nzSize="large">
                <em nz-icon nzType="close" nzTheme="outline"></em>
                <span>取 消</span>
            </a>
        </div>
        <div nz-col nzSpan="12" class="footer-button-primary">
            <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock nzSize="large">
                <em nz-icon nzType="save" nzTheme="outline"></em>
                <span>保 存</span>
            </a>
        </div>
    </div>
</div>
