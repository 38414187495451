import { Pipe, PipeTransform } from '@angular/core';
import { ProposalAmountType } from "../../api/types";

@Pipe({
  name: 'proposalAmountType'
})
export class ProposalAmountTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let amountType = "";
        switch (value) {
            case ProposalAmountType.BOTH:
                amountType = "兩者都可";
                break;
            case ProposalAmountType.SUM_ASSURED:
                amountType = "保額";
                break;
            case ProposalAmountType.PREMIUM:
                amountType = "保費";
                break;
        }
        return amountType;
    }

}
