import { Component, Input, OnInit } from '@angular/core';
import { I18n } from "../../../api/types";

@Component({
    selector: 'app-property-i18n-display',
    templateUrl: './property-i18n-display.component.html',
    styleUrls: ['./property-i18n-display.component.less']
})
export class PropertyI18nDisplayComponent implements OnInit {

    @Input() title: string;
    @Input() value: I18n;
    @Input() required: boolean = false;
    constructor() {
    }

    ngOnInit(): void {
    }

}
