import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicalLevel'
})
export class MedicalLevelPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let medicalLevel = "";
        switch (value) {
            case 'BASIC_WARD':
                medicalLevel = "基礎病房";
                break;
            case 'WARD':
                medicalLevel = "普通病房";
                break;
            case 'SEMI_PRIVATE':
                medicalLevel = "半私家病房";
                break;
            case 'PRIVATE':
                medicalLevel = "標準私家病房";
                break;
            case 'DELUXE':
                medicalLevel = "豪华病房";
                break;
            case 'VIP':
                medicalLevel = "VIP";
                break;
            case 'SUITE':
                medicalLevel = "套房";
                break;
        }
        return medicalLevel;
    }

}
