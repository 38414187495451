import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-property-rangetime',
  templateUrl: './property-rangetime.component.html',
  styleUrls: ['./property-rangetime.component.less']
})
export class PropertyRangetimeComponent implements OnInit {

    @Input() title: string;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;

    @Output()
    valueChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();

    property: Date[];

    @Input()
    get value() {
        return this.property;
    }
    set value(val) {
        this.property = val;
        this.valueChange.emit(this.property);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange(date: Date) {
        // console.log((new Date()).toISOString());
    }
}
