import { ClassConstructor, ClassTransformOptions } from 'class-transformer/types/interfaces';
import { plainToClass, plainToInstance } from 'class-transformer';

export function plainToArray<T, V>(cls: ClassConstructor<T>, sources: V[], options?: ClassTransformOptions): T[] {
  const array: T[] = [];
  if (sources) {
    for (const source of sources) {
      array.push(plainToInstance(cls, source, options));
    }
  }
  return array;
}
