import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';

import { AuthGuard } from "./passport/auth-guard.service";
import { ProductHomeComponent } from "./product/product/product-home.component";
import { ProductTagListComponent } from "./product/tag/product-tag-list.component";
import { ProductInstanceSyncComponent } from "./product/product/product-instance-sync.component";
import { CompanyInstanceSyncComponent } from "./product/company/company-instance-sync.component";
import { ProductRestrictionsComponent } from "./product/restriction/product-restrictions.component";
import { ProductPropertyHomeComponent } from "./product/property/product-property-home.component";
import { KnowledgeGeneralComponent } from "./knowledge/general/knowledge-general.component";
import { KnowledgeProductComponent } from "./knowledge/product/knowledge-product.component";
import { FinancialPlansComponent } from "./financial-plan/financial-plans.component";
import { ProposalsComponent } from "./proposal/proposals/proposals.component";
import { InformationHomeComponent } from "./information/release/information-home.component";
import { InformationSyncComponent } from "./information/sync/information-sync.component";
import { SystemConsoleComponent } from "./system/console/system-console.component";
import { OperatorComponent } from "./operator/operator.component";
import { ProductComponent } from "./product/product.component";
import { ProposalComponent } from "./proposal/proposal.component";
import { FinancialPlanComponent } from "./financial-plan/financial-plan.component";
import { KnowledgeComponent } from "./knowledge/knowledge.component";
import { InformationComponent } from "./information/information.component";
import { SystemComponent } from "./system/system.component";
import { LoginComponent } from "./passport/login.component";
import { KnowledgesComponent } from "./knowledge/knowledges.component";
import { SystemOtherComponent } from "./system/other/system-other.component";
import { ArticlesComponent } from "./article/articles/articles.component";
import { ArticleComponent } from "./article/article.component";
import { CustomerComponent } from './customer/customer.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { ProposalCompareListComponent } from "./proposal/compare/proposal-compare-list.component";
import { ProposalCustomListComponent } from "./proposal/custom/proposal-custom-list.component";
import {
    ContractMasterRateListComponent
} from "./contract/contract-master-rate-list/contract-master-rate-list.component";
import { ContractComponent } from "./contract/contract.component";
import { PublicKnowledgeComponent } from "./public/public-knowledge/public-knowledge.component";
import { PublicComponent } from "./public/public.component";
import { TicketComponent } from './ticket/ticket.component';
import { TicketListComponent } from './ticket/ticket-list/ticket-list.component';


const productRoutes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', component: ProductHomeComponent},
    // {path: 'products', component: ProductProductsComponent},
    // {path: 'companies', component: ProductCompaniesComponent},
    // {path: 'categories', component: ProductCategoriesComponent},
    {path: 'tags', component: ProductTagListComponent},
    {path: 'instance-sync', component: ProductInstanceSyncComponent},
    {path: 'instance-sync-company', component: CompanyInstanceSyncComponent},
    {path: 'restrictions', component: ProductRestrictionsComponent},
    {path: 'property', component: ProductPropertyHomeComponent},
];

const contractRoutes: Routes = [
    {path: '', redirectTo: 'master-rate-list', pathMatch: 'full'},
    {path: 'master-rate-list', component: ContractMasterRateListComponent},
];

const knowledgeRoutes: Routes = [
    {path: '', redirectTo: 'general', pathMatch: 'full'},
    {path: 'knowledges', component: KnowledgesComponent},
    {path: 'general', component: KnowledgeGeneralComponent},
    {path: 'product', component: KnowledgeProductComponent},
];

const financialPlanRoutes: Routes = [
    {path: '', redirectTo: 'financial-plans', pathMatch: 'full'},
    {path: 'financial-plans', component: FinancialPlansComponent},
];

const proposalRoutes: Routes = [
    {path: '', redirectTo: 'proposals', pathMatch: 'full'},
    {path: 'proposals', component: ProposalsComponent},
    {path: 'compares', component: ProposalCompareListComponent},
    {path: 'customs', component: ProposalCustomListComponent},
];

const informationRoutes: Routes = [
    {path: '', redirectTo: 'information-release', pathMatch: 'full'},
    {path: 'information-release', component: InformationHomeComponent},
    {path: 'instance-sync', component: InformationSyncComponent},

];

const articleRoutes: Routes = [
    {path: '', redirectTo: 'articles', pathMatch: 'full'},
    {path: 'articles', component: ArticlesComponent},
];

const systemRoutes: Routes = [
    {path: '', redirectTo: 'console', pathMatch: 'full'},
    {path: 'console', component: SystemConsoleComponent},
    {path: 'other', component: SystemOtherComponent},
];

const customerRoutes: Routes = [
    {path: '', redirectTo: 'customer-list', pathMatch: 'full'},
    {path: 'customer-list', component: CustomerListComponent},
];


const ticketRoutes: Routes = [
    {path: '', redirectTo: 'ticket-list', pathMatch: 'full'},
    {path: 'ticket-list', component: TicketListComponent},
];

// const operatorRoutes: Routes = [
//     {
//         path: 'operator',
//         component: OperatorComponent,
//         canActivate: [AuthGuard],
//         children: [
//             {path: 'product', component: ProductComponent, children: productRoutes},
//             {path: 'proposal', component: ProposalComponent, children: proposalRoutes},
//             {path: 'financial-plan', component: FinancialPlanComponent, children: financialPlanRoutes},
//             {path: 'knowledge', component: KnowledgeComponent, children: knowledgeRoutes},
//             {path: 'information', component: InformationComponent, children: informationRoutes},
//             {path: 'system', component: SystemComponent, children: systemRoutes},
//         ]
//     }
// ];


const routes: Routes = [
    {path: '', redirectTo: 'operator', pathMatch: 'full'},
    {path: 'auth/login', component: LoginComponent},
    {
        path: 'operator',
        component: OperatorComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'product', component: ProductComponent, children: productRoutes},
            {path: 'contract', component: ContractComponent, children: contractRoutes},
            {path: 'proposal', component: ProposalComponent, children: proposalRoutes},
            {path: 'financial-plan', component: FinancialPlanComponent, children: financialPlanRoutes},
            {path: 'knowledge', component: KnowledgeComponent, children: knowledgeRoutes},
            {path: 'information', component: InformationComponent, children: informationRoutes},
            {path: 'article', component: ArticleComponent, children: articleRoutes},
            {path: 'system', component: SystemComponent, children: systemRoutes},
            {path: 'customer', component: CustomerComponent, children: customerRoutes},
            {path: 'ticket', component: TicketComponent, children: ticketRoutes},
        ]
    },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {path: 'knowledge/:id', component: PublicKnowledgeComponent},
        ]
    },
    {
        path: '**', component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard
    ],
})
export class AppRoutingModule {
}
