<nz-layout style="height: 100%">
    <nz-sider nzWidth="250px" nzTheme="light" nzCollapsible
              [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="sider-menu">
            <div class="logo">
                <div>
                    <img width="75px" height="75px" src="./assets/images/logo.png">
                </div>
            </div>
            <ul style="margin-bottom: 20px" nz-menu nzTheme="light" nzMode="inline">

                <li *ngIf="hasAuthorityOr('PRODUCT_INFO', 'PRODUCT_COMPANY_INFO')" nz-submenu nzTitle="保險產品" nzIcon="product">
                    <ul>
                        <li nz-menu-item>
                            <a routerLink="./product/home">保險產品</a>
                        </li>
                        <li *ngIf="hasAuthority('PRODUCT_SYNC')" nz-menu-item>
                            <a routerLink="./product/instance-sync">產品同步</a>
                        </li>
                        <li *ngIf="hasAuthority('PRODUCT_COMPANY_SYNC')" nz-menu-item>
                            <a routerLink="./product/instance-sync-company">公司同步</a>
                        </li>
                        <li *ngIf="hasAuthority('PRODUCT_INFO')" nz-menu-item>
                            <a routerLink="./product/property">對比資料</a>
                        </li>
                        <li *ngIf="hasAuthority('PRODUCT_INFO')" nz-menu-item>
                            <a routerLink="./product/tags">產品標籤</a>
                        </li>
                        <li *ngIf="hasAuthority('PRODUCT_INFO')" nz-menu-item>
                            <a routerLink="./product/restrictions">投保限制</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('CONTRACT_INFO')" nz-submenu nzTitle="佣金合约" nzIcon="database">
                    <ul>
                        <li nz-menu-item><a routerLink="./contract/master-rate-list">Master Rate Table</a></li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('PROPOSAL_INFO')" nz-submenu nzTitle="計劃書" nzIcon="wallet">
                    <ul>
                        <li nz-menu-item><a routerLink="./proposal/proposals">計劃書庫</a></li>
                        <li nz-menu-item><a routerLink="./proposal/compares">計劃書对比</a></li>
                        <li nz-menu-item><a routerLink="./proposal/customs">計劃書模版</a></li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('KNOWLEDGE_INFO')" nz-submenu nzTitle="知識庫" nzIcon="book">
                    <ul>
                        <li nz-menu-item><a routerLink="./knowledge/knowledges">知識庫列表</a></li>
                        <li nz-menu-item><a routerLink="./knowledge/general">通用知識</a></li>
                        <li nz-menu-item><a routerLink="./knowledge/product">熱門產品</a></li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('INFORMATION_INFO')" nz-submenu nzTitle="新聞資訊" nzIcon="profile">
                    <ul>
                        <li nz-menu-item><a routerLink="./information/information-release">資訊發布</a></li>
                        <li nz-menu-item><a routerLink="./information/instance-sync">资讯同步</a></li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('ARTICLE_INFO')" nz-submenu nzTitle="文章库" nzIcon="container">
                    <ul>
                        <li nz-menu-item><a routerLink="./article/articles">文章列表</a></li>
                    </ul>
                </li>
                <!--                <li nz-submenu nzTitle="系統" nzIcon="setting">-->
                <!--                    <ul>-->
                <!--                        <li nz-menu-item><a routerLink="./system/console">操作臺</a></li>-->
                <!--                        <li nz-menu-item><a routerLink="./system/other">其他</a></li>-->
                <!--                    </ul>-->
                <!--                </li>-->
                <li *ngIf="hasAuthority('INSTANCE_INFO')" nz-submenu nzTitle="客戶" nzIcon="user">
                    <ul>
                        <li nz-menu-item><a routerLink="./customer/customer-list">客戶列表</a></li>
                    </ul>
                </li>
                <li *ngIf="hasAuthority('TICKET_INFO')" nz-submenu nzTitle="工单" nzIcon="calendar">
                    <ul>
                        <li nz-menu-item><a routerLink="./ticket/ticket-list">工单列表</a></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="bottom">
            <div class="account">
                <div style="flex: 1" class="centre-vh">
                    <button nz-button nzType="link" nzSize="small" nzShape="circle" appThrottleClick
                            (throttleClick)="logout()">
                        <i nz-icon nzType="poweroff" nzTheme="outline"
                           style="color: #6E84A3; font-size: 20px;"></i>
                    </button>
                </div>
                <div style="flex: 1" class="centre-vh">
                    <div>
                        <nz-avatar [nzSize]="45" nzIcon="user"
                                   style="background-color: #2C7BE5; color: white"></nz-avatar>
                    </div>
                </div>
                <div style="flex: 1" class="centre-vh">
                    <button nz-button nzType="link" nzSize="small" nzShape="circle">
                        <i nz-icon nzType="cloud-download" nzTheme="outline"
                           style="color: #6E84A3; font-size: 20px;"></i>
                    </button>
                </div>
            </div>
            <div class="env">
                v{{ version }} - {{ env }}
            </div>
        </div>
    </nz-sider>
    <nz-content>
        <div class="component-content">
            <router-outlet></router-outlet>
        </div>

        <!--        <div class="center">-->
        <!--            <div>前端接收服务端数据：</div>-->
        <!--            <div>日期：</div>-->
        <!--            <div>2021-12-23T00:00:00.000+10:00: <span style="font-weight: bold">{{'2021-12-23T00:00:00.000+10:00' | date: 'yyyy-MM-dd' }}</span></div>-->
        <!--            <div>2021-12-23: <span style="font-weight: bold">{{'2021-12-23' | date: 'yyyy-MM-dd' }}</span></div>-->
        <!--            <div>时间：</div>-->
        <!--            <div>2021-12-23T00:00:00.000+10:00: <span style="font-weight: bold">{{'2021-12-23T00:00:00+10:00' | date: 'yyyy-MM-dd HH:mm:ss' }}</span></div>-->
        <!--            <div>2021-12-23T00:00:00.000: <span style="font-weight: bold">{{'2021-12-23T00:00:00.000' | date: 'yyyy-MM-dd HH:mm:ss' }}</span></div>-->
        <!--        </div>-->

    </nz-content>
</nz-layout>

