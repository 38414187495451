<div style="height: 100%; padding-bottom: 60px">
    <div class="search-title">
        <i nz-icon nzType="product" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 20px;">產品選擇</span>
    </div>

    <div style="margin-bottom: 70px">
        <nz-row>
            <nz-col nzSpan="11">
                <app-property-text-edit [title]="'產品碼'" [(value)]="search.code" (valueChange)="onSearchParamChange()"></app-property-text-edit>
            </nz-col>
            <nz-col nzSpan="2"></nz-col>
            <nz-col nzSpan="11">
                <app-property-text-edit [title]="'產品名稱'" [(value)]="search.name" (valueChange)="onSearchParamChange()"></app-property-text-edit>
            </nz-col>
        </nz-row>

        <nz-row>
            <nz-col nzSpan="11">
                <app-property-select-edit [title]="'保險險種'" [(value)]="search.categoryCode" [options]="categoryOptions" (valueChange)="onSearchParamChange()"></app-property-select-edit>
            </nz-col>
            <nz-col nzSpan="2"></nz-col>
            <nz-col nzSpan="11">
                <app-property-select-edit [title]="'保險公司'" [(value)]="search.companyCode" [options]="companyOptions" (valueChange)="onSearchParamChange()"></app-property-select-edit>
            </nz-col>
        </nz-row>

        <nz-row>
            <nz-col nzSpan="11">
                <app-property-select-edit [title]="'類型'" [(value)]="search.type" [options]="typeOptions" (valueChange)="onSearchParamChange()"></app-property-select-edit>
            </nz-col>
            <nz-col nzSpan="2"></nz-col>
            <nz-col nzSpan="11">
                <app-property-select-edit [title]="'狀態'" [(value)]="search.status" [options]="statusOptions" (valueChange)="onSearchParamChange()"></app-property-select-edit>
            </nz-col>
        </nz-row>

        <nz-row style="margin-top: 10px">
            <nz-col nzSpan="24">
                <div *ngIf="!products">
                    <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
                </div>
                <div *ngIf="products">
                    <nz-table
                              [nzShowPagination]="true"
                              [nzLoading]="loading"
                              [nzFrontPagination]="false"
                              [nzData]="products.list"
                              [nzPageIndex]="products.pageNum"
                              [nzPageSize]="products.pageSize"
                              [nzTotal]="products.totalCount"
                              (nzPageIndexChange)="onSearch($event)">
                        <tbody>
                        <tr *ngFor="let product of products.list; let i = index" >
<!--                            appThrottleClick (throttleClick)="checkProduct(product)" -->
                            <td>{{ product.code }}</td>
                            <td nzBreakWord>{{ product.name }}</td>
                            <td *ngIf="mode === 'multiple'">
                                <em *ngIf="!addedProducts[product.code]" nz-icon
                                    style="font-size: 20px; color: #2C7BE5; cursor:pointer" nzType="plus-circle" nzTheme="outline" appThrottleClick (throttleClick)="onAddProduct(product)">
                                </em>
<!--                                <em *ngIf="addedProducts[product.code]" nz-icon-->
<!--                                    style="font-size: 20px; color: #2ce53e" nzType="check-circle" nzTheme="outline">-->
<!--                                </em>-->
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </div>
            </nz-col>
        </nz-row>
    </div>

<!--    <div class="footer" style="z-index: 99">-->
<!--        <div nz-row style="width: 100%; height: 100%;">-->
<!--            <div nz-col nzSpan="24" class="footer-button-primary" >-->
<!--                <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onConfirm()">-->
<!--                    <em nz-icon nzType="check" nzTheme="outline"></em>-->
<!--                    <span>確 定（已選{{selectedProducts.length}}個產品）</span>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
