import { Component, Input, OnInit } from '@angular/core';
import { Product } from "../../api/types";
import { ContractService } from "../../contract/contract.service";

@Component({
    selector: 'app-product-master-rate-detail',
    templateUrl: './product-master-rate-detail.component.html',
    styleUrls: ['./product-master-rate-detail.component.less']
})
export class ProductMasterRateDetailComponent implements OnInit {

    @Input() product: Product

    constructor(private contractService: ContractService) {

    }

    ngOnInit(): void {
        this.contractService.masterRateListByProduct(this.product.code).subscribe(
            rates => {
                console.log(rates);
            },
            error => {
            });
    }

}
