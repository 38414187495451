import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicKnowledgeComponent } from './public-knowledge/public-knowledge.component';
import { PublicComponent } from './public.component';
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { SharedModule } from "../shared/shared.module";
import { NzDrawerService } from "ng-zorro-antd/drawer";


@NgModule({
    declarations: [PublicKnowledgeComponent, PublicComponent],
    imports: [
        CommonModule,
        RouterModule,
        NzButtonModule,
        NzGridModule,
        NzIconModule,
        SharedModule
    ],
})
export class PublicModule {
}
