import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySelectOption } from "./property-select.component";
import { NzSelectModeType } from "ng-zorro-antd/select";

@Component({
    selector: 'app-property-select-edit',
    templateUrl: './property-select-edit.component.html',
    styleUrls: ['./property-select-edit.component.less']
})
export class PropertySelectEditComponent implements OnInit {

    @Input() mode: NzSelectModeType = "default";
    @Input() title: string;
    @Input() placeholder = '';
    @Input() loading: boolean = false;
    @Input() options: PropertySelectOption[];
    @Input() allowClear = true;
    @Input() showSearch = false;
    @Input() required: boolean = false;

    @Input() actionTitle: string;

    @Output()
    valueChange: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();

    @Output()
    scrollToBottom: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    searchChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    innerValue: any | any[];

    @Input()
    get value() {
        return this.innerValue;
    }

    set value(val) {
        this.innerValue = val;
        this.valueChange.emit(this.innerValue);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    onScrollToButton(): void {
        this.scrollToBottom.emit("");
    }

    onSearch(value: string) {
        this.searchChange.emit(value);
    }

    onActionButton() {
        this.onAction.emit('');
    }
}
