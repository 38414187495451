<div>

    <app-property-switch [title]="'是否支持最大提取'" [(value)]="product.features.maxWithdrawal" [editing]="editing" [editable]="true">
    </app-property-switch>

    <app-property-switch [title]="'是否可對衝'" [(value)]="product.features.hedgeable" [editing]="editing" [editable]="true">
    </app-property-switch>

    <app-property-select [title]="'計劃書金額類型'" [name]="product.features.proposalAmountType | proposalAmountType"
                         [(value)]="product.features.proposalAmountType" [editable]="true" [editing]="editing" [options]="proposalAmountTypeOptions">
    </app-property-select>

    <app-property-i18n [title]="'計劃書金額提示语（多語言配置）'" [(value)]="product.features.proposalAmountTip" [editable]="true"
                       [editing]="editing"></app-property-i18n>

    <div class="property-title">產品亮點（多語言）</div>
    <nz-empty *ngIf="!product.features.highlights || product.features.highlights.length == 0 && !editing" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
    <!-- 非编辑状态 -->

    <div *ngIf="product.features.highlights && product.features.highlights.length > 0 && !editing" class="property-value">
        <div class="highlight-i18n" *ngFor="let highlight of product.features.highlights; let i = index">
<!--            <div class="i18n-edit-left">-->
<!--                {{ i + 1 }}.-->
<!--            </div>-->
            <div class="i18n-edit-centre">
                <div class="i18n">
                    <div class="i18n-title">简体：</div>
                    <div class="i18n-value">{{ highlight.zhHansCN }}</div>
                </div>
                <div class="i18n">
                    <div class="i18n-title">繁体：</div>
                    <div class="i18n-value">{{ highlight.zhHantHK }}</div>
                </div>
                <div class="i18n">
                    <div class="i18n-title">英文：</div>
                    <div class="i18n-value">{{ highlight.enUS }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- 编辑状态 -->
    <div *ngIf="product.features.highlights && product.features.highlights.length > 0 && editing" class="property-value">
        <div class="highlight-i18n-edit" *ngFor="let highlight of product.features.highlights; let i = index">
<!--            <div class="i18n-edit-left">-->
<!--                {{ i + 1 }}.-->
<!--            </div>-->
            <div class="i18n-edit-centre">
                <div class="i18n-edit">
                    <div class="i18n-title">简体：</div>
                    <div class="i18n-value">
                        <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="highlight.zhHansCN" placeholder=""/>
                    </div>
                </div>
                <div class="i18n-edit">
                    <div class="i18n-title">繁体：</div>
                    <div class="i18n-value">
                        <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="highlight.zhHantHK" placeholder=""/>
                    </div>
                </div>
                <div class="i18n-edit">
                    <div class="i18n-title">英文：</div>
                    <div class="i18n-value">
                        <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="highlight.enUS" placeholder=""/>
                    </div>
                </div>
            </div>
            <div class="i18n-edit-right">
                <a nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onDelete(i)" nzSize="small"  class="delete-button">
                    <em nz-icon nzType="delete" nzTheme="outline"></em>
                </a>
            </div>
        </div>


    </div>

    <a *ngIf="editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="addHighlight()" nzSize="small" style="margin-top: 20px">
        <em nz-icon nzType="plus" nzTheme="outline"></em>
        <span style="font-size: 11px">添加亮點</span>
    </a>

    <div class="footer">
        <a *ngIf="!editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()" nzSize="large">
            <em nz-icon nzType="edit" nzTheme="outline"></em>
            <span>編 輯</span>
        </a>
        <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
            <div nz-col nzSpan="12" class="footer-button">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock nzSize="large">
                    <em nz-icon nzType="close" nzTheme="outline"></em>
                    <span>取 消</span>
                </a>
            </div>
            <div nz-col nzSpan="12" class="footer-button-primary">
                <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock nzSize="large">
                    <em nz-icon nzType="save" nzTheme="outline"></em>
                    <span>保 存</span>
                </a>
            </div>
        </div>
    </div>
</div>
