import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from "ng2-file-upload";
import { ContentHeaderComponent, PageNotFoundComponent } from './components/';
import { FormsModule } from '@angular/forms';
import { PropertyTextComponent } from "./components/property-text/property-text.component";
import { PropertyTextEditComponent } from "./components/property-text/property-text-edit.component";
import { PropertyTextDisplayComponent } from "./components/property-text/property-text-display.component";
import { PropertyI18nComponent } from "./components/property-i18n/property-i18n.component";
import { PropertyI18nEditComponent } from "./components/property-i18n/property-i18n-edit.component";
import { PropertyI18nDisplayComponent } from "./components/property-i18n/property-i18n-display.component";
import { ProductTypePipe } from './pipes/product-type.pipe';
import { ReleaseTypePipe } from './pipes/release-type.pipe';
import { ProductStatusPipe } from "./pipes/product-status.pipe";
import { PropertySelectComponent } from './components/property-select/property-select.component';
import { PropertySelectDisplayComponent } from './components/property-select/property-select-display.component';
import { PropertySelectEditComponent } from './components/property-select/property-select-edit.component';
import { PropertySwitchComponent } from './components/property-switch/property-switch.component';
import { PropertyTagsComponent } from './components/property-tags/property-tags.component';
import { ProductCompanyAgeFormulaPipe } from "./pipes/product-company-age-formula.pipe";
import { TagEditorComponent } from './components/tag-editor/tag-editor.component';
import { MetadataService } from "./services/metadata.service";
import { ProductQuotationFormulaPipe } from './pipes/product-quotation-formula.pipe';
import { ProductFrequencyPipe } from './pipes/product-frequency.pipe';
import { ProcessLogListComponent } from './components/process-log/process-log-list.component';
import { MaterialEditorComponent } from './components/material-editor/material-editor.component';
import { MedicalRegionPipe } from './pipes/medical-region.pipe';
import { MedicalLevelPipe } from './pipes/medical-level.pipe';
import { SupplementaryBenefitPipe } from './pipes/supplementary-benefit.pipe';
import { MaterialService } from "./services/material.service";
import { NullablePipe } from './pipes/nullable.pipe';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DataService } from "./services/data.service";
import { InstanceSelectionComponent } from './components/instance-selection/instance-selection.component';
import { NzProgressModule } from "ng-zorro-antd/progress";
import { PropertyDatetimeComponent } from './components/property-datetime/property-datetime.component';
import { PropertyRangetimeComponent } from './components/property-rangetime/property-rangetime.component';
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { UploadPictureComponent } from './components/upload-picture/upload-picture.component';
import { ProductRestrictionTypePipe } from './pipes/product-restriction-type.pipe';
import { ManageModalComponent } from './components/manage-modal/manage-modal.component';
import { InformationSelectionComponent } from './components/information-selection/information-selection.component';
import { CompanyStatusPipe } from './pipes/company-status.pipe';
import { ProductCompanyRegionPipe } from "./pipes/product-company-region.pipe";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { LinksEditorComponent } from './components/links-editor/links-editor.component';
import { ProposalAmountTypePipe } from './pipes/proposal-amount-type.pipe';
import { ThrottleClickDirective } from "./directives/throttle-click.directive";
import { HtmlPipe } from './pipes/html.pipe';
import { PropertyEditorComponent } from "./components/property-editor/property-editor.component";
import { EditorModule } from "primeng/editor";
import { TinymceEditorComponent } from "./components/tinymce-editor/tinymce-editor.component";
import { EditorModule as TinyMceEditorModule } from "@tinymce/tinymce-angular";
import { PropertyPickComponent } from "./components/property-pick/property-pick.component";
import { CompanyBusinessTypePipe } from "./pipes/company-business-type.pipe";
import {ProductCommonTypePipe} from "./pipes/product-common-type.pipe";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import { PropertyI18nAreaComponent } from './components/property-i18n-area/property-i18n-area.component';
import { PropertyI18nAreaDisplayComponent } from './components/property-i18n-area/property-i18n-area-display.component';
import { PropertyI18nAreaEditComponent } from './components/property-i18n-area/property-i18n-area-edit.component';
import { ExcelReportComponent } from './components/excel-report/excel-report.component';
import { TicketStatusPipe } from './pipes/ticket-status.pipe';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        ContentHeaderComponent,
        PropertyTextComponent,
        PropertyTextDisplayComponent,
        PropertyTextEditComponent,
        PropertyI18nComponent,
        PropertyI18nDisplayComponent,
        PropertyI18nEditComponent,
        PropertyPickComponent,
        PropertySwitchComponent,
        PropertySelectComponent,
        PropertySelectDisplayComponent,
        PropertySelectEditComponent,
        TagEditorComponent,
        PropertyTagsComponent,
        ProcessLogListComponent,
        MaterialEditorComponent,
        ProductStatusPipe,
        TicketStatusPipe,
        ProductCommonTypePipe,
        ProductTypePipe,
        ReleaseTypePipe,
        ProductCompanyAgeFormulaPipe,
        ProductQuotationFormulaPipe,
        ProductFrequencyPipe,
        MedicalRegionPipe,
        MedicalLevelPipe,
        SupplementaryBenefitPipe,
        NullablePipe,
        ProductRestrictionTypePipe,
        InstanceSelectionComponent,
        PropertyDatetimeComponent,
        PropertyRangetimeComponent,
        PropertyEditorComponent,
        TinymceEditorComponent,
        UploadPictureComponent,
        ManageModalComponent,
        InformationSelectionComponent,
        CompanyStatusPipe,
        ProductCompanyRegionPipe,
        LinksEditorComponent,
        ProposalAmountTypePipe,
        ThrottleClickDirective,
        HtmlPipe,
        CompanyBusinessTypePipe,
        PropertyI18nAreaComponent,
        PropertyI18nAreaDisplayComponent,
        PropertyI18nAreaEditComponent,
        ExcelReportComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        FileUploadModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        NzSpinModule,
        NzTableModule,
        NzDrawerModule,
        NzAffixModule,
        NzSkeletonModule,
        NzStatisticModule,
        NzSelectModule,
        NzEmptyModule,
        NzGridModule,
        NzTabsModule,
        EditorModule,
        NzListModule,
        NzSwitchModule,
        NzTagModule,
        NzCommentModule,
        NzInputModule,
        NzModalModule,
        NzProgressModule,
        NzDatePickerModule,
        NzPopoverModule,
        EditorModule,
        TinyMceEditorModule,
        NzCheckboxModule
    ],
    exports: [
        TranslateModule,
        FormsModule,
        ContentHeaderComponent,
        PropertyTextComponent,
        PropertyTextDisplayComponent,
        PropertyTextEditComponent,
        PropertyI18nComponent,
        PropertyI18nDisplayComponent,
        PropertyI18nEditComponent,
        PropertyPickComponent,
        PropertySwitchComponent,
        PropertySelectComponent,
        PropertySelectDisplayComponent,
        PropertySelectEditComponent,
        TagEditorComponent,
        PropertyTagsComponent,
        ProcessLogListComponent,
        MaterialEditorComponent,
        ProductStatusPipe,
        TicketStatusPipe,
        ProductCommonTypePipe,
        ProductTypePipe,
        ReleaseTypePipe,
        ProductCompanyAgeFormulaPipe,
        ProductQuotationFormulaPipe,
        MedicalRegionPipe,
        MedicalLevelPipe,
        SupplementaryBenefitPipe,
        ProductRestrictionTypePipe,
        PropertyDatetimeComponent,
        PropertyRangetimeComponent,
        PropertyEditorComponent,
        TinymceEditorComponent,
        UploadPictureComponent,
        ManageModalComponent,
        ProductFrequencyPipe,
        CompanyStatusPipe,
        ProductCompanyRegionPipe,
        LinksEditorComponent,
        ProposalAmountTypePipe,
        HtmlPipe,
        CompanyBusinessTypePipe,
        ThrottleClickDirective,
        PropertyI18nAreaComponent,
    ],
    providers: [
        MetadataService,
        MaterialService,
        DataService,
        ProductCommonTypePipe,
        ProductTypePipe,
        ReleaseTypePipe,
        ProductStatusPipe,
        TicketStatusPipe,
        ProductFrequencyPipe,
        ProductQuotationFormulaPipe,
        ProductCompanyAgeFormulaPipe,
        MedicalRegionPipe,
        MedicalLevelPipe,
        SupplementaryBenefitPipe,
        ProductRestrictionTypePipe,
        CompanyStatusPipe,
        ProductCompanyRegionPipe,
        ProposalAmountTypePipe,
        CompanyBusinessTypePipe,
    ]
})
export class SharedModule {
}
