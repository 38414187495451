import { HttpUrlEncodingCodec } from "@angular/common/http";

export class GhQueryEncoder extends HttpUrlEncodingCodec {
    encodeKey(k: string): string {
        k = super.encodeKey(k);
        return k.replace(/\+/gi, '%2B');
    }
    encodeValue(v: string): string {
        v = super.encodeKey(v);
        console.log(v);
        return v.replace(/\+/gi, '%2B');
    }
}
