import { Material, PagedReq } from "../api/types";

export class ContractMasterRate {
    id: number;
    name: string;
    effectiveDate: string
    invalidDate: string
}

export class ContractMasterRateSearch extends PagedReq {
    name: string;
}
