import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    NavigationExtras,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { BaseService } from '../base/base.service';

@Injectable()
export class AuthGuard extends BaseService implements CanActivate, CanActivateChild {

    constructor(private passportService: AuthService, private router: Router) {
        super();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;

        return this.checkAuth(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkAuth(url: string): boolean {
        if (this.getOperator() != null) {
            return true;
        }

        // Store the attempted URL for redirecting
        this.setRedirectUrl(url);

        // Create a dummy session id
        // let sessionId = 123456789;

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            // queryParams: { 'session_id': sessionId, 'phone': '13560376505' },
            // fragment: 'anchor'
        };

        // Navigate to the login page with extras
        this.router.navigate(['/auth/login'], navigationExtras);
        return false;
    }
}
