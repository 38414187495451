import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { isNullOrUndefined } from "../../common/utils";
import { Account } from "../../api/types";
import { Environment } from "../../api/environment";
import { KeyValue } from "@angular/common";
import { NzModalService } from "ng-zorro-antd/modal";
import { AccountAddComponent } from "./account-add.component";
import { ToastrService } from "ngx-toastr";


@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.less']
})
export class AccountsComponent implements OnInit {

    spinning: false;

    accounts: Map<string, Account>;

    accountSelected: EventEmitter<Account> = new EventEmitter<Account>();

    constructor(
        public authService: AuthService,
        private toastr: ToastrService,
        private modalService: NzModalService) {
    }

    ngOnInit(): void {
        this.accounts = this.authService.accounts;
    }

    firstChar(account: string): string {
        if (isNullOrUndefined(account) || account.length === 0) return "";
        return account.charAt(0).toUpperCase();
    }

    onAddAccount() {
        let account = new Account();
        account.env = Environment.BETA;
        account.account = 'beefintech';
        account.password = 'centre2024@BeeFintech';
        this.accounts.set(account.key, account);
        this.authService.accounts = this.accounts;
    }

    onDelete(keyValue: KeyValue<string, Account>) {
        this.accounts.delete(keyValue.key);
        this.authService.accounts = this.accounts;
    }

    onLogin(account: Account) {
        this.accountSelected.emit(account);
    }

    onShowAddAccountModal() {
        let modalRef = this.modalService.create({
            nzCentered: true,
            nzTitle: '添加账号',
            nzContent: AccountAddComponent,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => {
                let account = modalRef.getContentComponent().account;
                if (!account || !account.account || !account.password) {
                    this.toastr.error('帳號密碼不能為空', '添加失败');
                    return false;
                }
                this.accounts.set(account.key, account);
                this.authService.accounts = this.accounts;
            }
        });
    }

    onEditorConfirm(ref: any, params: any) {

    }
}
