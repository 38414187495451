import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { SharedModule } from '../shared/shared.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { CustomerProductComponent } from './customer-list/customer-product.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ProductModule } from '../product/product.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';



@NgModule({
  declarations: [CustomerComponent, CustomerListComponent, CustomerProductComponent],
    imports: [
        CommonModule,
        SharedModule,
        NzLayoutModule,
        RouterModule,
        NzSpinModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzTagModule,
        NzIconModule,
        NzButtonModule,
        NzAffixModule,
        NzSkeletonModule,
        NzEmptyModule,
        NzDrawerModule,
        ProductModule,
        NzPopoverModule,
    ]
})
export class CustomerModule { }
