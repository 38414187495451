import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProductSearch, ProductStatus, ProductType } from "../../../api/types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { ProductService } from "../../product.service";
import { ProductCompanyRegion, ProductCompanyRegions, ProductCompanySearch } from "../../product-types";
import { ProductCompanyRegionPipe } from "../../../shared/pipes/product-company-region.pipe";

@Component({
    selector: 'app-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.less']
})
export class ProductSearchComponent implements OnInit {

    loading: boolean = false;

    search: ProductSearch = new ProductSearch()
    companySearch: ProductCompanySearch = new ProductCompanySearch()

    typeOptions: PropertySelectOption[] = []
    statusOptions: PropertySelectOption[] = []
    categoryOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []
    standardOptions: PropertySelectOption[] = []
    hotOptions: PropertySelectOption[] = []
    comparableOptions: PropertySelectOption[] = []
    quotableOptions: PropertySelectOption[] = []
    withdrawalableOptions: PropertySelectOption[] = []
    proposalRPASupportedOptions: PropertySelectOption[] = []
    financialPlanSupportedOptions: PropertySelectOption[] = []
    premiumFinancingSupportedOptions: PropertySelectOption[] = []
    premiumOffsetSupportedOptions: PropertySelectOption[] = []
    proposalPrepaymentSupportedOptions: PropertySelectOption[] = []
    hasBrochureOptions: PropertySelectOption[] = []
    regionOptions: PropertySelectOption[] = []

    @Output()
    productSearch: EventEmitter<ProductSearch> = new EventEmitter<ProductSearch>();

    constructor(private productService: ProductService,
                private productCompanyRegionPipe: ProductCompanyRegionPipe) {
    }

    ngOnInit(): void {

        this.typeOptions?.push(new PropertySelectOption('全部', null));
        this.typeOptions?.push(new PropertySelectOption('基本計劃', ProductType.BASIC));
        this.typeOptions?.push(new PropertySelectOption('附加計劃', ProductType.RIDER));
        this.statusOptions?.push(new PropertySelectOption('全部', null));
        this.statusOptions?.push(new PropertySelectOption('在售', ProductStatus.SELLING));
        this.statusOptions?.push(new PropertySelectOption('停售', ProductStatus.DISCONTINUED));

        let options : PropertySelectOption[] = [
            new PropertySelectOption('全部', null),
            new PropertySelectOption('是', true),
            new PropertySelectOption('否', false),
        ];

        for (let option of options) {
            this.standardOptions?.push(option);
            this.comparableOptions?.push(option);
            this.quotableOptions?.push(option);
            this.hotOptions?.push(option);
            this.withdrawalableOptions?.push(option);
            this.proposalRPASupportedOptions?.push(option);
            this.financialPlanSupportedOptions?.push(option);
            this.hasBrochureOptions?.push(option);
            this.premiumFinancingSupportedOptions?.push(option);
            this.premiumOffsetSupportedOptions?.push(option);
            this.proposalPrepaymentSupportedOptions?.push(option);
        }

        let productCompanyRegions = ProductCompanyRegions.all();
        this.regionOptions = productCompanyRegions.map(region => {
            return new PropertySelectOption(this.productCompanyRegionPipe.transform(region), region)
        })

        this.loadCategories();
        this.loadCompanies();
    }

    onSearch(): void {
        this.productSearch.emit(this.search);
    }

    loadCategories(): void {
        this.loading = true;
        this.productService.categories()
            .subscribe(
                data => {
                    this.loading = false;
                    this.categoryOptions?.push(new PropertySelectOption('全部險種', null));
                    data.forEach(category => {
                        this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
                    })
                },
                error => {
                    this.loading = false;
                });
    }

    loadCompanies(): void {
        this.loading = true;
        this.productService.companies(this.companySearch)
            .subscribe(
                data => {
                    this.loading = false;
                    this.companyOptions = [];
                    this.companyOptions.push(new PropertySelectOption('全部公司', null));
                    data.forEach(company => {
                        this.companyOptions.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                    this.loading = false;
                });
    }

    regionChange(region: ProductCompanyRegion): void {
        this.search.companyCode = null;
        this.companySearch.region = region;
        this.loadCompanies();
    }
}
