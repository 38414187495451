import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-manage-modal',
  templateUrl: './manage-modal.component.html',
  styleUrls: ['./manage-modal.component.scss']
})
export class ManageModalComponent implements OnInit {
    @Input()  isVisible: boolean = false;
    isOkLoading: boolean = false;

    inputValue: string = '';
    @Output()
    valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Output()
    visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() { }


    ngOnInit(): void {
    }

    innerValue: string[] = [];
    @Input()
    get value() {
        return this.innerValue || [];
    }

    set value(val) {
        this.innerValue = val;
        // this.valueChange.emit(this.innerValue);
    }

    handleOk(): void {
        this.isOkLoading = true;
        setTimeout(() => {
            this.isVisible = false;
            this.isOkLoading = false;
        }, 3000);
    }

    handleCancel(): void {
        this.visibleChange.emit(this.isVisible = false)
    }

    handleClose(index: number): void {
        this.innerValue?.splice(index, 1);
        this.valueChange.emit(this.innerValue);
    }
    addTags():void {
        this.innerValue?.push(this.inputValue);
        this.valueChange.emit(this.innerValue);
        this.inputValue=''
    }
}
