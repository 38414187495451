<div class="property">
    <div class="property-title">
        <label>{{ title }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div class="property-value">
        <div class="property-wrapper" [ngSwitch]="type">
            <input *ngSwitchCase="'TEXT_FIELD'" type="text" class="property-input" nz-input [(ngModel)]="value"
                   placeholder=""/>
            <input *ngSwitchCase="'TEXT_NUMBER'" type="number" class="property-input" nz-input [(ngModel)]="value"
                   placeholder=""/>
            <input *ngSwitchCase="'URL'" type="text" class="property-input" nz-input [(ngModel)]="value"
                   placeholder=""/>
            <textarea *ngSwitchCase="'TEXT_AREA'" nz-input placeholder="" class="property-input-area"
                      [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                      [(ngModel)]="value"></textarea>
            <p-editor *ngSwitchCase="'TEXT_HTML'" [(ngModel)]="value" [style]="{'height':'auto'}">
                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <button type="button" class="ql-bold"></button>
                        <button type="button" class="ql-italic"></button>
                        <button type="button" class="ql-underline"></button>
                        <button type="button" class="ql-strike"></button>
                    </span>
                    <span class="ql-formats">
                        <button type="button" class="ql-list" value="ordered"></button>
                        <button type="button" class="ql-list" value="bullet"></button>
                        <select class="ql-align">
                            <option selected="selected"></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button type="button" class="ql-link"></button>
                    </span>
                </ng-template>
            </p-editor>
            <!--        <i nz-icon class="ant-input-clear-icon clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="value"-->
            <!--           appThrottleClick (throttleClick)="value = null"></i>-->
        </div>
    </div>
</div>
