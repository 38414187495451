import { Component, OnInit } from '@angular/core';
import { TicketService } from '../ticket.service';
import { TicketListResp, TicketListSearch } from '../ticket-types';
import { PagedResp, Product, ProductStatus } from '../../api/types';
import { ProductProductInfoComponent } from '../../product/product/product-product-info.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TicketInfoComponent } from '../ticket-info/ticket-info.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PropertySelectOption } from '../../shared/components/property-select/property-select.component';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.less']
})
export class TicketListComponent implements OnInit {
    spinning: false;
    loading: false;
    searchPopoverVisible: false;
    search: TicketListSearch = new TicketListSearch();
    ticketList: PagedResp<TicketListResp>;

    statusOptions: PropertySelectOption[] = []

    createDate: null;
    replyDate: null;


    constructor(private ticketService: TicketService,
                private drawerService: NzDrawerService,
                private ticketStatus: NzDrawerService,
                private modalService: NzModalService,) {
    }

    ngOnInit(): void {
        this.onSearch(1);
        this.statusOptions?.push(new PropertySelectOption('全部', null));
        this.statusOptions?.push(new PropertySelectOption('进行中', 'PENDING'));
        this.statusOptions?.push(new PropertySelectOption('已回复', 'REPLIED'));
        this.statusOptions?.push(new PropertySelectOption('关闭', 'CLOSED'));
    }

    onSearch(pageNum: number): void {
        this.search.pageNum = pageNum;
        this.ticketService.list(this.search)
            .subscribe(x => {
                this.searchPopoverVisible = false;
                this.ticketList = x;
            });
    }

    onResetSearch(): void {
        this.search = new TicketListSearch();
        this.createDate = null;
        this.replyDate = null;
        this.ticketService.list(this.search)
            .subscribe(x => {
                this.ticketList = x;
            });
    }

    onReloadList(): void {
        this.createDate = null;
        this.replyDate = null;
        this.search = new TicketListSearch();
        this.ticketService.list(this.search)
            .subscribe(x => {
                this.ticketList = x;
            });
    }

    onTicketInfo(info: TicketListResp): void {
        const drawerRef = this.drawerService.create<TicketInfoComponent, { value: Product }, string>({
            nzWidth: 800,
            nzTitle: info.title,
            nzContent: TicketInfoComponent,
            nzContentParams: {
                id: info.id
            }
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
        });
        drawerRef.afterClose.subscribe(data => {
            this.onSearch(this.search.pageNum);
        });

    }

    onClose(id: number): void {
        this.modalService.warning({
            nzCentered: true,
            nzTitle: '是否关闭该工单?',
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => {
                this.ticketService.close(id)
                    .subscribe(x => {
                        this.onSearch(this.search.pageNum);
                    });
            }
        });

    }


    onDateChange(date, type): void {
        console.log(date);
        if (type === 'startCreateDate') {
            this.search.startCreateDate = date && date.length ? dayjs(date[0].getTime()).format('YYYY-MM-DD') : null;
            this.search.endCreateDate = date && date.length ? dayjs(date[1].getTime()).format('YYYY-MM-DD') : null;
        } else {
            this.search.startReplyDate = date && date.length ? dayjs(date[0].getTime()).format('YYYY-MM-DD') : null;
            this.search.endReplyDate = date && date.length ? dayjs(date[1].getTime()).format('YYYY-MM-DD') : null;
        }
    }

}
