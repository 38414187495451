import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import {
    ApiResponse,
    MaterialAccessTokenReq,
    PagedResp,
    ProcessLog,
    Proposal,
    ProposalSearch,
    ProposalStatistic
} from "../../api/types";
import { Router } from "@angular/router";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { ToastrService } from "ngx-toastr";
import { ProposalService } from "../proposal.service";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { ProposalSearchComponent } from "../components/proposal-search/proposal-search.component";
import { MaterialService } from "../../shared/services/material.service";
import { DownloadService } from "../../shared/services/download.service";
import * as _ from "lodash";

@Component({
    selector: 'app-proposals',
    templateUrl: './proposals.component.html',
    styleUrls: ['./proposals.component.less']
})
export class ProposalsComponent implements OnInit {

    loading = false;

    proposalStatistic: ProposalStatistic;

    proposals: PagedResp<Proposal>;

    search: ProposalSearch = new ProposalSearch();

    previewUrl: string;

    showPreviewDrawer: boolean = false;

    showSearchDrawer: boolean = false;

    uploader: FileUploader;

    spinning: boolean = false;

    remarkLoading = false;

    proposalInfo: Proposal = new Proposal();

    @ViewChild('search')
    proposalSearchComponent: ProposalSearchComponent;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor(
        private proposalService: ProposalService,
        private materialService: MaterialService,
        private downloadService: DownloadService,
        private router: Router,
        private drawerService: NzDrawerService,
        private modalService: NzModalService,
        private toastr: ToastrService) {
    }

    ngOnInit(): void {

        this.loading = true;
        this.proposalService.proposalList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.proposals = data;
                },
                error => {
                    this.loading = false;
                });

        this.proposalService.statistic()
            .subscribe(
                data => {
                    this.proposalStatistic = data;
                },
                error => {
                });

        let httpHeaders = this.proposalService.getAuthorizationHeaders();
        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            url: this.proposalService.productUrl + "/import",
            method: "POST",
            itemAlias: "file",
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: true
        });

        /// 需要bind(this)，才能在对应方法里使用this.
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.proposalService.proposalList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.proposals = data;
                },
                error => {
                    this.loading = false;
                });
    }

    /**
     * 計劃書
     * @param proposalSearch 計劃書搜索参数
     * @param resetPageNum 是否重置页数
     */
    onProposalSearch(proposalSearch: ProposalSearch, resetPageNum: boolean = true): void {
        this.showSearchDrawer = false;
        this.search = proposalSearch;
        if (resetPageNum) {
            this.search.pageNum = 1
        }
        this.loading = true;
        this.proposalService.proposalList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.proposals = data;
                },
                error => {
                    this.loading = false;
                });
    }

    rpaProductSync() {
        this.spinning = true;
        this.proposalService.rpaProductsSync()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success("执行成功");
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('执行失败');
                });
    }

    /**
     * 打開詳情抽屜
     * @param proposal 计划书详情
     */
    openPreviewDrawer(proposal: Proposal): void {
        // this.spinning = true
        // this.productService.productInfo(product.code)
        //     .subscribe(
        //         data => {
        //             this.spinning = false
        //             if (data.nameI18n == null) {
        //                 data.nameI18n = new I18n();
        //             }
        //             this.showInfoDrawer = true;
        //             this.productInfoComponent.editing = false;
        //             this.productInfoComponent.value = data;
        //         },
        //         error => {
        //             this.spinning = false
        //         });
    }

    closePreviewDrawer(): void {
        this.showPreviewDrawer = false;
        // this.productInfoComponent.editing = false;
    }

    openSearchDrawer(): void {
        this.showSearchDrawer = true;
    }

    closeSearchDrawer(): void {
        this.showSearchDrawer = false;
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        this.uploader.clearQueue();
        let apiResponse = JSON.parse(response) as ApiResponse<ProcessLog[]>;
        this.openLogsDrawer(apiResponse.data);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    onPreview(proposal: Proposal) {
        this.spinning = true;
        let accessTokenReq = new MaterialAccessTokenReq();
        this.proposalService.info(proposal.id.toString())
            .subscribe(
                data => {
                    console.log(data);
                    this.proposalInfo = JSON.parse(JSON.stringify(data));
                    accessTokenReq.bucket = data.url.bucket;
                    accessTokenReq.objectName = data.url.objectName;
                    this.materialService.accessToken(accessTokenReq)
                        .subscribe(
                            data => {
                                this.spinning = false;
                                this.onPreviewPDF(data.url)
                                // window.open(data.url);
                            },
                            error => {
                                this.spinning = false;
                            });
                },
                error => {
                    this.spinning = false;
                }
            )
    }

    onPreviewPDF(pdfUrl: string) {
        if (pdfUrl) {
            this.previewUrl = pdfUrl;
            this.showPreviewDrawer = true;
        }
    }

    onDownload(proposal: Proposal) {
        this.spinning = true;
        let accessTokenReq = new MaterialAccessTokenReq();
        accessTokenReq.bucket = proposal.url.bucket;
        accessTokenReq.objectName = proposal.url.objectName;
        this.materialService.accessToken(accessTokenReq)
            .subscribe(
                data => {
                    this.spinning = false;
                    this.downloadService.download(data.url, proposal.url.fileName);
                },
                error => {
                    this.spinning = false;
                });

    }

    onClean(proposal: Proposal) {
        this.spinning = true;
        this.proposalService.clean(proposal.id)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (data) {
                        this.toastr.success('清理计划书成功')
                    } else {
                        this.toastr.error('清理计划书失败')
                    }
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('清理计划书失败')
                });

    }

    onSwitchCleaned(proposal: Proposal) {
        proposal.loading = true;
        let cleaned = !proposal.cleaned;
        let p = new Proposal();
        p.cleaned = cleaned;
        this.proposalService.setCleaned(proposal.id, p)
            .subscribe(
                data => {
                    this.spinning = false;
                    proposal.loading = false;
                    proposal.cleaned = !cleaned;
                    this.onReload();
                },
                error => {
                    proposal.loading = false;
                    this.spinning = false;
                });

    }

    onSwitchAbnormal(proposal: Proposal) {
        proposal.loading = true;
        let abnormal = !proposal.abnormal;
        let p = new Proposal();
        p.abnormal = abnormal;
        this.proposalService.setAbnormal(proposal.id, p)
            .subscribe(
                data => {
                    this.spinning = false;
                    proposal.loading = false;
                    proposal.abnormal = !abnormal;
                    this.onReload();
                },
                error => {
                    proposal.loading = false;
                    this.spinning = false;
                });

    }

    onShowRemarkModal(i: number, remarkTitle: TemplateRef<any>, remarkContent: TemplateRef<any>, remarkFooter: TemplateRef<any>) {
        let proposal = _.cloneDeep(this.proposals.list[i]);
        this.modalService.create({
            nzCentered: true,
            nzTitle: remarkTitle,
            nzContent: remarkContent,
            nzFooter: remarkFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzComponentParams: proposal,
            nzOnOk: () => console.log('Click ok')
        });
    }

    onRemarkConfirm(ref: NzModalRef, proposal: Proposal) {
        ref.destroy();
        let p = new Proposal();
        p.id = proposal.id;
        p.remark = proposal.remark;
        this.proposalService.update(p)
            .subscribe(
                data => {
                    this.spinning = false;
                    proposal.loading = false;
                    this.onReload();
                },
                error => {
                    proposal.loading = false;
                    this.spinning = false;
                });

    }

    onReload() {
        this.loading = true;
        this.search.pageNum = 1;
        this.proposalService.proposalList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.proposals = data;
                },
                error => {
                    this.loading = false;
                });

        this.proposalService.statistic()
            .subscribe(
                data => {
                    this.proposalStatistic = data;
                },
                error => {
                });
    }

    onExtract(proposal: Proposal) {
        this.spinning = true;
        this.proposalService.instanceExtract(proposal.id)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (data) {
                        this.toastr.success('操作成功')
                    }
                },
                error => {
                    this.spinning = false;
                });
    }
}
