import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productStatus'
})
export class ProductStatusPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let productStatus = "";
        switch (value) {
            case 'SELLING':
                productStatus = "在售";
                break;
            case 'DISCONTINUED':
                productStatus = "停售";
                break;
        }
        return productStatus;
    }

}
