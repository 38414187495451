import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    ApiResponse,
    PagedResp,
    ProcessLog,
    ProductRestriction,
    ProductRestrictionSearch,
    ProductTag
} from "../../api/types";
import { ProductService } from "../product.service";
import { ProductProductCreateComponent } from "../product/product-product-create.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ProductRestrictionCreateComponent } from "./product-restriction-create.component";
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { API } from "../../api/api";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-product-restrictions',
    templateUrl: './product-restrictions.component.html',
    styleUrls: ['./product-restrictions.component.less']
})
export class ProductRestrictionsComponent implements OnInit {

    loading = false;
    restrictions: PagedResp<ProductRestriction>;
    search: ProductRestrictionSearch = new ProductRestrictionSearch();

    uploader: FileUploader;

    spinning: boolean = false;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor(private productService: ProductService,
                private router: Router,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.productService.restrictionList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.restrictions = data;
                },
                error => {
                    this.loading = false;
                });

        let httpHeaders = this.productService.getAuthorizationHeaders();
        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            url: this.productService.productUrl + "/restriction/import",
            method: "POST",
            itemAlias: "file",
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: true
        });

        /// 需要bind(this)，才能在对应方法里使用this.
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }


    onSearch(page: number) {
        this.search.pageNum = page
        this.loading = true;
        this.productService.restrictionList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.restrictions = data;
                },
                error => {
                    this.loading = false;
                });
    }

    openInfoDrawer(restriction: ProductRestriction): void {
        // this.productService.productInfo(product.code)
        //     .subscribe(
        //         data => {
        //             if (data.nameI18n == null) {
        //                 data.nameI18n = new I18n();
        //             }
        //             this.showInfoDrawer = true;
        //             this.productInfoComponent.editing = false;
        //             this.productInfoComponent.value = data;
        //         },
        //         error => {
        //         });
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        this.uploader.clearQueue();
        let apiResponse = JSON.parse(response) as ApiResponse<ProcessLog[]>;
        this.openLogsDrawer(apiResponse.data);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }


    onCreate() {
        const drawerRef = this.drawerService.create<ProductRestrictionCreateComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProductRestrictionCreateComponent,
            nzContentParams: {
            }
        });

        drawerRef.afterOpen.subscribe(() => {
            drawerRef.getContentComponent().restrictionCreated
                .subscribe(
                    data => {
                        drawerRef.close();
                        this.openInfoDrawer(data);
                    },
                    error => {
                    });
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

}
