<div *ngIf="knowledge">
    <div class="profile-large-title">
        <span style="font-weight: bold; width: 100%; font-size: 28px;">{{ knowledge.title }}</span>
    </div>

    <app-property-text [type]="contentTextType" [title]="''"
                       [(value)]="knowledge.content" [editable]="false"></app-property-text>


</div>
