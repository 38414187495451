<div>
    <nz-table
        #basicTable
        [nzData]="excelReport.errorDetails"
        [nzShowPagination]="true"
    >
        <thead>
        <tr>
            <th>行</th>
            <th>列</th>
            <th>識別到的數據</th>
            <th>錯誤提示</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
            <td>{{data.row}}</td>
            <td>{{data.column}}</td>
            <td>{{data.data}}</td>
            <td>{{data.message}}</td>
        </tr>
        </tbody>
    </nz-table>
    <div>
        <p>識別到的總行數：<span>{{ excelReport?.totalRows ? excelReport?.totalRows : 0 }}</span></p>
        <p>解析出錯的行數：<span>{{ excelReport?.errorRecords ? excelReport?.errorRecords : 0 }}</span></p>
        <p>有效行數：<span>{{ excelReport?.validRows ? excelReport?.validRows : 0 }}</span></p>
        <p>存儲記錄數：<span>{{ excelReport?.storageRecords ? excelReport?.storageRecords : 0 }}</span></p>
    </div>
</div>
