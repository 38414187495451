<div style="width: 100%; padding: 15px;">
    <div>
        <div class="property-title">合約名稱</div>
        <div class="property-value">
            <input type="text" class="property-input" nz-input [(ngModel)]="masterRate.name"
                   style="width: 100%;" placeholder=""/>
        </div>
    </div>
    <div style="margin-top: 15px; width: 100%;">
        <div class="property-title">生效时间</div>
        <div class="property-value">
            <nz-date-picker [nzBorderless]="false" style="margin-top: 5px; width: 100%;"
                            [ngModel]="date" (ngModelChange)="onEffectiveDateChange($event)"
                            [nzFormat]="'yyyy-MM-dd'"></nz-date-picker>
        </div>
    </div>

<!--    <div class="material" *ngIf="masterRate.attachment">-->
<!--        <div class="material-file-icon">-->
<!--            <span>{{extension(masterRate.attachment.filePath)}}</span>-->
<!--        </div>-->
<!--        <div class="material-file-elem">-->
<!--            <div class="material-file-name">{{masterRate.attachment.fileName}}</div>-->
<!--            <div class="material-file-path">-->
<!--                {{masterRate.attachment.filePath}}-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="material-file-actions">-->
<!--        </div>-->
<!--    </div>-->

<!--    <div style="display: flex; align-items: center; justify-content: center; margin-top: 25px; width: 100%; padding: 10px; border: #1D64C4 dashed 1px; border-radius: 4px">-->
<!--        <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"-->
<!--               (change)="fileUploadOnChanged($event)"/>-->
<!--        <a nz-button nzType="link" nzSize="small" style="width: 100%; height: 100%"-->
<!--           appThrottleClick (throttleClick)="onUpload()">-->
<!--            <em nz-icon nzType="cloud-upload" nzTheme="outline"></em>上传文件-->
<!--        </a>-->

<!--    </div>-->
</div>
