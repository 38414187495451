import { Injectable, OnInit } from '@angular/core';
import { ElectronService } from "../../core/services";
import { BaseService } from "../../base/base.service";
import { HttpService } from "../../api/http.service";

@Injectable({
    providedIn: 'root'
})
export class DownloadService extends BaseService implements OnInit {

    constructor(private electronService: ElectronService, private http: HttpService) {
        super();
    }

    ngOnInit(): void {

    }

    showSaveDialog(filename: string) {
        const dialog = this.electronService.remote.dialog;
        const win = this.electronService.win;
        const app = this.electronService.app;
        const path = app.getPath('downloads') + "/" + filename;
        const paths = dialog.showSaveDialogSync(win, {
            title: '選擇保存位置',
            properties: ['showOverwriteConfirmation', 'createDirectory'],
            defaultPath: path,
            buttonLabel: '保存'
        })
    }

    download(url: string, fileName: string) {

        if (this.electronService.isElectron) {
            this.electronService.win.webContents.downloadURL(url);
            return;
        }

        this.http.download(url, {
            headers: null,
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).subscribe(
            response => {
                let headers = response.headers;
                let contentType = headers.get('content-type');
                let contentDisposition= headers.get('content-disposition');
                // if (contentDisposition) {
                //     let filename = contentDisposition.split('filename=')[1];
                // }
                const link = document.createElement('a');
                const blob = new Blob([response.body], {type: contentType});
                const objectURL = window.URL.createObjectURL(blob);
                link.download = decodeURI(fileName);
                link.href = objectURL;
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(objectURL);
            },
            error => {
            }
        )
    }

}
