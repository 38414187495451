<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <div *ngIf="links">
        <div *ngFor="let link of links; let i = index">
            <div class="link">
                <div class="link-file-icon">
                    <span>
                        <i nz-icon nzType="link" nzTheme="outline"></i>
                    </span>
                </div>
                <div class="link-file-elem">
                    <div class="link-file-name">
                        <span>{{link.description}}</span>
<!--                        <input *ngIf="editings[i]" type="text" class="description-input" nz-input [(ngModel)]="link.description" placeholder="連接名稱"/>-->
                    </div>
                    <div class="link-file-path">
                        <span style="cursor: pointer" appThrottleClick (throttleClick)="openLink(link)">{{link.link}}</span>
<!--                        <input *ngIf="editings[i]" type="text" class="link-input" nz-input [(ngModel)]="link.link" placeholder="http(s)://"/>-->
                    </div>
                </div>
                <div class="link-file-actions">
                    <a nz-button nzType="link" nzSize="small"
                       appThrottleClick (throttleClick)="onShowEditorModal(i, editorTitle, editorContent, editorFooter); $event.stopPropagation();">
                        <em nz-icon nzType="edit" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small"
                       appThrottleClick (throttleClick)="onDelete(i); $event.stopPropagation();">
                        <em nz-icon nzType="delete" nzTheme="outline" style="color: #a71d5d"></em>
                    </a>
<!--                    <a nz-button nzType="link" nzSize="small" *ngIf="editings[i]"-->
<!--                       appThrottleClick (throttleClick)="onCancel(i); $event.stopPropagation();">-->
<!--                        <em nz-icon nzType="close-circle" nzTheme="outline" style="color: #a71d5d"></em>-->
<!--                    </a>-->
<!--                    <a nz-button nzType="link" nzSize="small" *ngIf="editings[i]"-->
<!--                       appThrottleClick (throttleClick)="onSave(i); $event.stopPropagation();">-->
<!--                        <em nz-icon nzType="check-circle" nzTheme="outline" style="color: #55a532"></em>-->
<!--                    </a>-->
                </div>
            </div>
        </div>
    </div>

    <a nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onAdd(editorTitle, editorContent, editorFooter)" nzSize="small" style="margin-top: 20px">
        <em nz-icon nzType="plus" nzTheme="outline"></em>
        <span style="font-size: 11px">添加链接</span>
    </a>

</nz-spin>

<ng-template #editorTitle>
    <i nz-icon nzType="edit" nzTheme="outline"
       style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>編輯</span>
</ng-template>
<ng-template #editorContent>
    <app-property-text [title]="'名稱'" [(value)]="editLink.description" [editable]="true"
                       [editing]="true"></app-property-text>

    <app-property-i18n [title]="'名稱（多語言配置）'" [(value)]="editLink.descriptionI18n" [editable]="true"
                       [editing]="true"></app-property-i18n>

    <app-property-text [title]="'鏈接'" [(value)]="editLink.link" [editable]="true"
                       [editing]="true"></app-property-text>

    <app-property-text [title]="'排序值'" [(value)]="editLink.rank" [editable]="true"
                       [type]="dataService.PropertyTextEditType.TEXT_NUMBER"
                       [editing]="true"></app-property-text>
</ng-template>
<ng-template #editorFooter let-ref="modalRef">
    <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
        <i nz-icon nzType="close" nzTheme="outline"
           style="font-size: 13px;"></i><span>關閉</span>
    </button>
    <button nz-button nzType="primary" appThrottleClick (throttleClick)="onEditorConfirm(ref)">
        <i nz-icon nzType="save" nzTheme="outline"
           style="font-size: 13px;"></i><span>確定</span>
    </button>
</ng-template>

