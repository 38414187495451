import { Injectable } from '@angular/core';
import { PropertyTextEditType } from "../components/property-text/property-text-edit.component";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    readonly PropertyTextEditType = PropertyTextEditType;

    LOCAL_STORAGE_KEY_PRODUCTS_TO_SYNC = 'CENTRE-OPERATION-PRODUCTS-TO-SYNC';
    LOCAL_STORAGE_KEY_COMPANIES_TO_SYNC = 'CENTRE-OPERATION-COMPANIES-TO-SYNC';
    LOCAL_STORAGE_KEY_INSTANCES_TO_SYNC = 'CENTRE-OPERATION-INSTANCES-TO-SYNC';
    LOCAL_STORAGE_KEY_INFORMATION_TO_SYNC = 'CENTRE-OPERATION-INFORMATION-TO-SYNC';

    constructor() {
    }

    get productsToSync() {
        const value: string = localStorage.getItem(this.LOCAL_STORAGE_KEY_PRODUCTS_TO_SYNC);
        return JSON.parse(value);
    }
    set productsToSync(val) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY_PRODUCTS_TO_SYNC, JSON.stringify(val));
    }

    get productCompaniesToSync() {
        const value: string = localStorage.getItem(this.LOCAL_STORAGE_KEY_COMPANIES_TO_SYNC);
        return JSON.parse(value);
    }
    set productCompaniesToSync(val) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY_COMPANIES_TO_SYNC, JSON.stringify(val));
    }

    get instancesToSync() {
        const value: string = localStorage.getItem(this.LOCAL_STORAGE_KEY_INSTANCES_TO_SYNC);
        return JSON.parse(value);
    }
    set instancesToSync(val) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY_INSTANCES_TO_SYNC, JSON.stringify(val));
    }

    get informationToSync() {
        const value: string = localStorage.getItem(this.LOCAL_STORAGE_KEY_INFORMATION_TO_SYNC);
        return JSON.parse(value);
    }
    set informationToSync(val) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY_INFORMATION_TO_SYNC, JSON.stringify(val));
    }

}
