import { Component, OnInit } from '@angular/core';
import { PagedResp } from "../../api/types";
import { Article, ArticleSearch } from "../article-types";
import { ArticleService } from "../article.service";
import { ElectronService } from "../../core/services";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ArticleDetailComponent } from "../article-detail/article-detail.component";

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.less']
})
export class ArticlesComponent implements OnInit {

    spinning = false;
    loading = false;
    search: ArticleSearch = new ArticleSearch();
    articles: PagedResp<Article>;
    filterPopoverVisible = false;

    constructor(private articleService: ArticleService,
                private electronService: ElectronService,
                private drawerService: NzDrawerService) {
    }

    ngOnInit(): void {
        this.load();
    }

    load(): void {
        this.loading = true;
        this.articleService.list(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.articles = data;
                },
                () => {
                    this.loading = false;
                });
    }

    onReload(): void {
        this.search.pageNum = 1;
        this.load();
    }

    onSearch(pageNum: number): void {
        this.search.pageNum = pageNum
        this.load();
    }

    onResetSearch(): void {
        this.search = new ArticleSearch();
        this.onReload();
    }

    onEdit(article: Article): void {
        const openId = this.articleService.operator?.xiumiOpenId;
        if (openId) {
            const url = `https://xiumi.us/auth/partner/edit?open_id=${openId}&article_id=${article.xiumiId}`;
            this.electronService.openUrl(url);
        }
    }

    onDetail(id: number) {
        this.spinning = true;
        this.articleService.info(id)
            .subscribe(
                article => {
                    this.spinning = false;
                    const drawerRef = this.drawerService.create<ArticleDetailComponent, { value: any }, string>({
                        nzWidth: 800,
                        nzMaskClosable: true,
                        nzContent: ArticleDetailComponent,
                        nzContentParams: {
                            article
                        },
                    });

                    drawerRef.afterOpen.subscribe(() => {
                        const component = drawerRef.getContentComponent();
                        component.drawerRef = drawerRef;
                    });

                    drawerRef.afterClose.subscribe(data => {
                    });
                },
                () => {
                    this.spinning = false;
                }
            );
    }

    // 将文章发布为知识库
    onKnowledge(article: Article): void {

    }

    onInformation(article: Article) {

    }

}
