import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductService } from "../product.service";
import { ApiResponse, I18n, PagedResp, ProcessLog, Product, ProductSearch, ProductStatistic } from "../../api/types";
import { ProductProductInfoComponent } from "./product-product-info.component";
import { ProductSearchComponent } from "../components/product-search/product-search.component";
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { ToastrService } from "ngx-toastr";
import { ProcessLogListComponent } from "../../shared/components/process-log/process-log-list.component";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../common/utils";
import { DataService } from "../../shared/services/data.service";
import { Router } from "@angular/router";
import { ProductProductCreateComponent } from "./product-product-create.component";
import { OperatorService } from "../../operator/operator.service";
import { ProductTableDisplayConfig } from "../../common/model/table-display-config";
import {
    ContractMasterRateListComponent
} from "../../contract/contract-master-rate-list/contract-master-rate-list.component";

@Component({
    selector: 'app-product-products',
    templateUrl: './product-products.component.html',
    styleUrls: ['./product-products.component.less']
})
export class ProductProductsComponent implements OnInit, AfterViewInit {

    productStatistic: ProductStatistic;

    loading = false;
    products: PagedResp<Product>;
    search: ProductSearch = new ProductSearch();

    showSearchDrawer: boolean = false;

    uploader: FileUploader;

    spinning: boolean = false;

    isTemplate: boolean = false;

    // 列表栏展示配置
    tableDisplayConfig: ProductTableDisplayConfig;

    @ViewChild('search')
    productSearchComponent: ProductSearchComponent;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor(private productService: ProductService,
                private dataService: DataService,
                private operatorService: OperatorService,
                private router: Router,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
        this.tableDisplayConfig = this.operatorService.getProductTableDisplayConfig();
    }

    ngOnInit(): void {
        this.loading = true;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });

        let httpHeaders = this.productService.getAuthorizationHeaders();
        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            url: this.productService.productUrl + "/import",
            method: "POST",
            itemAlias: "file",
            headers: headers,
            // allowedMimeType: ["application/vnd.ms-excel"],
            autoUpload: true
        });

        /// 需要bind(this)，才能在对应方法里使用this.
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }

    ngAfterViewInit(): void {
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });
    }

    /**
     * 产品搜索
     * @param productSearch 产品搜索参数
     * @param resetPageNum 是否重置页数
     */
    onProductSearch(productSearch: ProductSearch, resetPageNum: boolean = true): void {
        this.showSearchDrawer = false;
        this.search = productSearch;
        if (resetPageNum) {
            this.search.pageNum = 1
        }
        this.loading = true;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });
    }

    get selectedProducts(): Product[] {
        let products: Product[] = []
        if (!isNullOrUndefined(this.products) && !isNullOrUndefined(this.products.list)) {
            products = this.products.list.filter(product => {
                return product.checked
            })
        }
        return products;
    }

    // get columnDisplayConfig() : Map<string, TableDisplayConfigColumn> {
    //     const map: Map<string, TableDisplayConfigColumn> = new Map<string, TableDisplayConfigColumn>();
    //     for(let column of this.tableDisplayConfig.product.columns) {
    //         map.set(column.key, column);
    //     }
    //     return map;
    // }
    // set columnDisplayConfig(val) {
    //
    // }

    onProductInfo(product: Product): void {
        this.spinning = true
        this.productService.productInfo(product.code)
            .subscribe(
                data => {
                    this.spinning = false
                    if (data.nameI18n == null) {
                        data.nameI18n = new I18n();
                    }
                    this.openInfoDrawer(data);
                },
                error => {
                    this.spinning = false
                });
    }
    /**
     * 打開詳情抽屜
     * @param product 產品信息
     */
    openInfoDrawer(product: Product): void {
        const drawerRef = this.drawerService.create<ProductProductInfoComponent, { value: Product }, string>({
            nzWidth: 800,
            nzContent: ProductProductInfoComponent,
            nzContentParams: {
                value: product,
                editing: false
            }
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.productUpdated
                .subscribe(product => {
                    this.onProductSearch(this.search, false);
                })
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });

    }

    openSearchDrawer(): void {
        this.showSearchDrawer = true;
    }

    closeSearchDrawer(): void {
        this.showSearchDrawer = false;
    }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        this.uploader.clearQueue();
        let apiResponse = JSON.parse(response) as ApiResponse<ProcessLog[]>;
        this.openLogsDrawer(apiResponse.data);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    onDelete(product: Product): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除' + product.name + '？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎删除，删除后将无法恢复產品</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.productService.productDelete(product.code)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.onSearch(this.search.pageNum);
                        },
                        error => {
                            this.loading = false;
                        });
            },
            nzOnCancel: () => {
            }
        });
    }

    checkAllEvent(checked: boolean) {
        if (isNotNullOrUndefined(this.products) && isNotNullOrUndefined(this.products.list)) {
            this.products.list.forEach(product =>
                product.checked = checked
            )
        }
    }

    onProductInstanceSync() {
        this.dataService.productsToSync = this.selectedProducts;
        this.router.navigate(['/operator/product/instance-sync']);
    }

    exportProduct() {
        this.spinning = true;
        this.productService.productExport()
            .subscribe(
                data => {
                    this.spinning = false;
                },
                error => {
                    this.spinning = false;
                });
    }

    onCreateProduct() {
        const drawerRef = this.drawerService.create<ProductProductCreateComponent, { value: string }, string>({
            nzWidth: 1024,
            nzContent: ProductProductCreateComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            drawerRef.getContentComponent().productCreated
                .subscribe(
                    data => {
                        drawerRef.close();
                        this.openInfoDrawer(data);
                    },
                    error => {
                    });
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    tableDisplayConfigChange(event: any) {
        this.operatorService.setProductTableDisplayConfig(this.tableDisplayConfig);
    }

    onReload() {
        this.loading = true;
        this.search.pageNum = 1;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });

        this.productService.statistic()
            .subscribe(
                data => {
                    this.productStatistic = data;
                },
                error => {
                });
    }

    isTemplateChange(event: boolean) {
        this.isTemplate = event;
    }

    onContractMasterRate() {
        const drawerRef = this.drawerService.create<ContractMasterRateListComponent, { value: string }, string>({
            nzWidth: 1240,
            nzContent: ContractMasterRateListComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
        });

    }

    exportAIProduct() {
        this.spinning = true;
        this.productService.productAIExport()
            .subscribe(
                data => {
                    this.spinning = false;
                },
                error => {
                    this.spinning = false;
                });

    }
}
