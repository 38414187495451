<div class="profile-large-title">
    <i nz-icon nzType="right-square" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>
    <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">执行日志</span>
</div>
<div *ngIf="logs" style="padding: 10px">
    <nz-tabset [nzSize]="'large'">

        <ng-template #successTitle>
            成功
            <span class="statistic-number" *ngIf="successLogs">{{successLogs.length}}</span>
        </ng-template>

        <nz-tab [nzTitle]=successTitle>
            <nz-list [nzDataSource]="successLogs" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                <ng-template #item let-item>
                    <nz-comment [nzAuthor]="item.title" [nzDatetime]="item.datetime | date:'yyyy-MM-dd HH:mm:ss'">
                        <nz-avatar nz-comment-avatar [nzIcon]="icon(item)" [style]="style(item)"></nz-avatar>
                        <nz-comment-content>
                            <p>{{ item.content }}</p>
                        </nz-comment-content>
                        <!--                <nz-comment-action>Reply to</nz-comment-action>-->
                    </nz-comment>
                </ng-template>
            </nz-list>
        </nz-tab>

        <ng-template #failureTitle>
            失敗
            <span class="statistic-number" *ngIf="failureLogs">{{failureLogs.length}}</span>
        </ng-template>

        <nz-tab [nzTitle]=failureTitle>
            <nz-list [nzDataSource]="failureLogs" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                <ng-template #item let-item>
                    <nz-comment [nzAuthor]="item.title" [nzDatetime]="item.datetime | date:'yyyy-MM-dd HH:mm:ss'">
                        <nz-avatar nz-comment-avatar [nzIcon]="icon(item)" [style]="style(item)"></nz-avatar>
                        <nz-comment-content>
                            <p>{{ item.content }}</p>
                        </nz-comment-content>
                        <!--                <nz-comment-action>Reply to</nz-comment-action>-->
                    </nz-comment>
                </ng-template>
            </nz-list>
        </nz-tab>

    </nz-tabset>
</div>
