<nz-tabset [nzSize]="'large'" >

    <nz-tab [nzTitle]=productTitle *ngIf="hasAuthority('PRODUCT_INFO')" >
        <app-product-products></app-product-products>
    </nz-tab>
    <ng-template #productTitle>
        <span>產品</span>
        <span class="statistic-number" *ngIf="productStatistic">{{productStatistic.productNumber}}</span>
    </ng-template>

    <nz-tab [nzTitle]=companyTitle *ngIf="hasAuthority('PRODUCT_COMPANY_INFO')" >
        <app-product-company></app-product-company>
    </nz-tab>
    <ng-template #companyTitle>
        <span>公司</span>
        <span class="statistic-number" *ngIf="productStatistic">{{productStatistic.companyNumber}}</span>
    </ng-template>

    <nz-tab [nzTitle]=categoryTitle *ngIf="hasAuthority('PRODUCT_INFO')" >
        <app-product-categories></app-product-categories>
    </nz-tab>
    <ng-template #categoryTitle>
        <span>險種</span>
        <span class="statistic-number" *ngIf="productStatistic">{{productStatistic.categoryNumber}}</span>
    </ng-template>

</nz-tabset>
