<div class="drawer-container">
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

        <div class="common-title" style="margin-bottom: 40px">
            {{article.title}}
        </div>

        <div class="flex-row" style="padding: 5px 0 5px 0; margin-top: 5px">
            <img alt="" width="16" src="assets/images/ic_article_author.svg">
            <span style="margin-left: 5px">{{article.author}}</span>
        </div>

        <div class="flex-row" style="padding: 5px 0 5px 0">
            <img alt="" width="16" src="assets/images/ic_article_time.svg">
            <span style="margin-left: 5px">{{article.createTime | date:'yyyy-MM-dd HH:mm:SS'}}</span>
        </div>

        <div [innerHTML]="article.content | html"></div>
    </nz-spin>
</div>
