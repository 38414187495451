import { Component, OnInit } from '@angular/core';
import { Article } from "../article-types";
import { NzDrawerRef } from "ng-zorro-antd/drawer";

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    styleUrls: ['./article-detail.component.less']
})
export class ArticleDetailComponent implements OnInit {

    drawerRef: NzDrawerRef<ArticleDetailComponent, string>;

    article: Article;
    loading = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
