<div class="profile-large-title">
    <!--    <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>-->
    <span style="font-weight: bold; width: 100%; font-size: 28px;">添加保險公司</span>
</div>

<div style="margin-top: 50px; padding-bottom: 60px; padding-left: 10px; padding-right: 10px">

    <app-property-text [title]="'公司代码（唯一，大寫字母與數字的組合） * '" [(value)]="company.code" [editable]="true"
                       [editing]="true"></app-property-text>

    <app-property-text [title]="'公司默認名稱 * '" [(value)]="company.name" [editable]="true"
                       [editing]="true"></app-property-text>

    <app-property-text [title]="'英文名称'" [(value)]="company.nameEnglish" [editable]="true" [editing]="true">
    </app-property-text>

    <app-property-i18n [title]="'公司名称（多语言配置）*'" [(value)]="company.nameI18n" [editable]="true"
                       [editing]="true"></app-property-i18n>

    <app-property-select [title]="'所在地区'" [name]="company.region | productCompanyRegion"
                         [(value)]="company.region" [editable]="true" [editing]="true"
                         [options]="regionOptions">
    </app-property-select>

    <app-property-text [title]="'官网地址'" [(value)]="company.website" [editable]="true" [editing]="true"
                       [type]="'URL'">
    </app-property-text>

    <app-property-text [title]="'公司排序（数值越小排名越前）'" [(value)]="company.rank" [editable]="true"
                       [editing]="true"></app-property-text>


    <app-property-select [title]="'年龄计算方式'" [name]="company.ageFormula | productCompanyAgeFormula"
                         [(value)]="company.ageFormula" [editable]="true" [editing]="true"
                         [options]="ageFormulaOptions">
    </app-property-select>

    <app-property-text [title]="'公司描述'" [(value)]="company.desc" [editing]="true" [editable]="true"
                       [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

    <app-property-i18n-area [title]="'公司描述（多语言配置）'" [(value)]="company.descI18n" [editing]="true"
                            [editable]="true">
    </app-property-i18n-area>

    <app-property-text [title]="'内部备注'" [(value)]="company.internalRemark" [editing]="true" [editable]="true"
                       [type]="dataService.PropertyTextEditType.TEXT_AREA"></app-property-text>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onCreate()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzType="save" nzTheme="outline"></em>
                    <span>創 建</span>
                </a>
            </div>
        </div>
    </div>
</div>
