<div style="margin: 30px;">

    <div class="account-env">
        <span>環境：</span>
        <nz-select style="text-align: center" [(ngModel)]="account.env" nzBorderless nzMode="default">
            <nz-option *ngFor="let option of envOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
    </div>

    <div>
        <nz-input-group class="account-group">
            <input required [(ngModel)]="account.account" id="account" name="account"
                   placeholder="账号"
                   class="account-input"/>
        </nz-input-group>
    </div>
    <div>
        <nz-input-group class="account-group">
            <input required [(ngModel)]="account.password" id="password" name="password"
                   type="password"
                   placeholder="密码" class="account-input"/>
        </nz-input-group>
    </div>
</div>
