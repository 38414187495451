import 'reflect-metadata';
import '../polyfills';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
// NG Translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AuthModule } from './passport/auth.module';

import { AppComponent } from './app.component';
import { HttpService } from "./api/http.service";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./passport/auth.service";
import { OperatorModule } from "./operator/operator.module";
import { ProductService } from "./product/product.service";
import { FinancialPlanService } from "./financial-plan/financial-plan.service";
import { KnowledgeService } from "./knowledge/knowledge.service";
import { OperatorService } from "./operator/operator.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { InstanceService } from "./shared/services/instance.service";
import { EditorModule } from "@tinymce/tinymce-angular";
import zh from '@angular/common/locales/zh';
import { registerLocaleData } from "@angular/common";
import { InformationService } from "./information/information.service";
import { ProductPropertyService } from "./product/product-property.service";
import { ProposalService } from "./proposal/proposal.service";
import { ConsoleService } from "./shared/services/console.service";
import { SystemModule } from "./system/system.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppConfig } from '../environments/environment';
import { ArticleModule } from "./article/article.module";
import { NzImageService } from 'ng-zorro-antd/image';
import { CustomerModule } from './customer/customer.module';
import { ContractService } from "./contract/contract.service";
import { ContractModule } from "./contract/contract.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { PublicModule } from "./public/public.module";
import { TicketModule } from './ticket/ticket.module';
import { TicketService } from './ticket/ticket.service';

registerLocaleData(zh);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


// @ts-ignore
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        EditorModule,
        CoreModule,
        SharedModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            // progressBar: true,
            timeOut: 3000
        }), // ToastrModule added
        AuthModule,
        OperatorModule,
        SystemModule,
        CustomerModule,
        ArticleModule,
        ContractModule,
        PublicModule,
        AppRoutingModule,
        TicketModule,
        // NzIconModule.forRoot(icons),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: AppConfig.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        // {provide: ErrorHandler, useClass: AppErrorHandler},
        HttpService,
        AuthService,
        OperatorService,
        InstanceService,
        ProductService,
        ProductPropertyService,
        FinancialPlanService,
        KnowledgeService,
        ProposalService,
        NzModalService,
        NzDrawerService,
        NzMessageService,
        NzImageService,
        NzNotificationService,
        InformationService,
        ConsoleService,
        ContractService,
        TicketService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
