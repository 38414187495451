import { Constant } from "../api/constant";
import { Account, Operator } from "../api/types";
import { HttpHeaders } from "@angular/common/http";
import { Environment } from "../api/environment";

export class BaseService {

    get env(): Environment {
        const env: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_ENV);
        return <Environment>env ?? Environment.DEV;
    }

    set env(val: Environment) {
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_ENV, val);
    }

    get baseUrl(): string {
        let host: string;
        switch (this.env) {
            case Environment.DEV:
                host = 'http://localhost:10002';
                break;
            case Environment.BETA:
                host = 'https://operation.beta.api.centre.beefintech.net';
                break;
            case Environment.PROD:
                host = 'https://operation.api.centre.beefintech.net';
                break;
            default:
                host = 'https://operation.beta.api.centre.beefintech.net';
                break;
        }
        return host;
    }

    get errorUrl(): string {
        return this.baseUrl + '/api/v1/error';
    }

    get metadataUrl(): string {
        return this.baseUrl + '/api/v1/metadata';
    }

    get passportUrl(): string {
        return this.baseUrl + '/api/v1/passport';
    }

    get instanceUrl(): string {
        return this.baseUrl + '/api/v1/instance';
    }

    get productUrl(): string {
        return this.baseUrl + '/api/v1/product';
    }

    get knowledgeUrl(): string {
        return this.baseUrl + '/api/v1/knowledge';
    }

    get materialUrl(): string {
        return this.baseUrl + '/api/v1/material';
    }

    get informationUrl(): string {
        return this.baseUrl + '/api/v1/information';
    }

    get proposalUrl(): string {
        return this.baseUrl + '/api/v1/proposal';
    }

    get proposalCompareUrl(): string {
        return this.baseUrl + '/api/v1/proposal/compare';
    }

    get proposalCustomUrl(): string {
        return this.baseUrl + '/api/v1/proposal/custom';
    }
    get consoleUrl(): string {
        return this.baseUrl + '/api/v1/console';
    }

    get operatorUrl(): string {
        return this.baseUrl + '/api/v1/operator';
    }

    get articleUrl(): string {
        return this.baseUrl + '/api/v1/article';
    }

    get ticketUrl(): string {
        return this.baseUrl + '/api/v1/tickets';
    }

    /// For Test
    get datetimeUrl(): string {
        return this.baseUrl + '/api/v1/datetime';
    }

    get contractMasterRateUrl(): string {
        return this.baseUrl + '/api/v1/contract/master-rate';
    }


    public headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    public fileHeaders: HttpHeaders = new HttpHeaders();

    public operator: Operator;

    constructor() {
        this.operator = this.getOperator();
    }

    public getOperator(): Operator {
        const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_OPERATOR);
        return JSON.parse(value);
    }

    public setOperator(operator: Operator): void {
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_OPERATOR, JSON.stringify(operator));
    }

    public removeOperator(): void {
        localStorage.removeItem(Constant.LOCAL_STORAGE_KEY_OPERATOR);
    }

    public getHeaders(): HttpHeaders {
        const operator: Operator = this.getOperator();
        if (operator != null) {
            this.headers = this.headers.set('Authorization', operator.id + ':' + operator.token);
        }
        return this.headers;
    }

    public getFileHeaders(): HttpHeaders {
        const operator: Operator = this.getOperator();
        if (operator != null) {
            this.fileHeaders = this.fileHeaders.set('Authorization', operator.id + ':' + operator.token);
        }
        return this.fileHeaders;
    }

    public getAuthorizationHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        const operator: Operator = this.getOperator();
        if (operator != null) {
            headers = headers.set('Authorization', operator.id + ':' + operator.token);
        }
        return headers;
    }

    public getRedirectUrl(): string {
        return localStorage.getItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL);
    }

    public setRedirectUrl(url: string) {
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL, url);
    }

    public removeRedirectUrl() {
        localStorage.removeItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL);
    }

    get accounts(): Map<string, Account> {
        const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS);
        return new Map<string, Account>(JSON.parse(value));
    }

    set accounts(val: Map<string, Account>) {
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS, JSON.stringify(Array.from(val.entries())));
    }

}
