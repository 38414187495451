<div class="property">
    <div class="property-title">
        <label>{{ title }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div class="property-value">
        <div class="property-wrapper">
            <app-tag-editor [(value)]="value" [editable]="editing && editable"></app-tag-editor>
        </div>
    </div>
</div>
