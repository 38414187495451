import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagedResp } from "../../../api/types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { ProductService } from "../../product.service";
import { debounceTime } from "rxjs/operators";
import { isNullOrUndefined } from "../../../common/utils";
import { ProductCompany, ProductCompanySearch, ProductCompanyStatus } from 'app/product/product-types';

@Component({
    selector: 'app-company-selection',
    templateUrl: './company-selection.component.html',
    styleUrls: ['./company-selection.component.less']
})
export class CompanySelectionComponent implements OnInit {

    /**
     * single：单选
     * multiple：多选
     */
    @Input()
    mode: 'single' | 'multiple' = 'multiple';

    loading = false;
    productCompanys: PagedResp<ProductCompany>;

    searchParamChange: EventEmitter<any> = new EventEmitter<any>();

    search: ProductCompanySearch = new ProductCompanySearch();

    @Output()
    productsSelected: EventEmitter<ProductCompany[]> = new EventEmitter<ProductCompany[]>();

    @Output()
    productSelected: EventEmitter<ProductCompany> = new EventEmitter<ProductCompany>();

    statusOptions: PropertySelectOption[] = []
    companyOptions: PropertySelectOption[] = []


    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {

        this.statusOptions?.push(new PropertySelectOption('啟用', ProductCompanyStatus.ENABLE));
        this.statusOptions?.push(new PropertySelectOption('禁用', ProductCompanyStatus.DISABLE));

        this.searchParamChange.pipe(debounceTime(500))
            .subscribe(
                data => {
                    this.onSearch(1);
                },
                error => {
                }
            )
    }

    onSearchParamChange() {
        this.searchParamChange.emit("");
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.productService.companyList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.productCompanys = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onSelected(productCompany: ProductCompany) {
        switch (this.mode) {
            case "single":
                this.selectProduct(productCompany);
                break;
            case "multiple":
                this.checkProduct(productCompany);
                break;
        }
    }

    checkProduct(productCompany: ProductCompany) {
        productCompany.checked = !productCompany.checked;
    }

    selectProduct(productCompany: ProductCompany) {
        this.productSelected.emit(productCompany);
    }

    get selectedProducts() : ProductCompany[] {
        let productCompanys : ProductCompany[] = []
        if (!isNullOrUndefined(this.productCompanys) && !isNullOrUndefined(this.productCompanys.list)) {
            productCompanys = this.productCompanys.list.filter(productCompanys => { return productCompanys.checked})
        }
        return productCompanys;
    }

    onConfirm() {
        this.productsSelected.emit(this.selectedProducts);
    }
}
