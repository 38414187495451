import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Information, MaterialUploadFileReq, MaterialUploadReq, } from "../../../api/types";
import { MaterialService } from "../../services/material.service";
import { HttpParams } from "@angular/common/http";
import { HttpService } from "../../../api/http.service";
import { BaseService } from "../../../base/base.service";
import { isNullOrUndefined } from "../../../common/utils";

@Component({
    selector: 'app-tinymce-editor',
    templateUrl: './tinymce-editor.component.html',
    styleUrls: ['./tinymce-editor.component.less']
  })
  export class TinymceEditorComponent extends BaseService implements OnInit{
    editParam: any = {};
    @Input()
    externalHeight: number = 1280;
    constructor(private materialService: MaterialService,
                private http: HttpService) {
        super();
    }
    ngOnInit(): void {
        let _this = this
        this.editParam = {
            selector: 'textarea',
            plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu codesample media',
            // 语言包可以使用tinymce提供的网址,但是墙的原因,会连不上,所以还是自行下载,放到assets里面
            language_url: '../../../../assets/tinymce/langs/zh_TW.js',
            language: 'zh_TW',
            // toolbar定义快捷栏的操作, | 用来分隔显示
            toolbar: 'codesample | bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft'
                + ' aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo '
                + '| link unlink image media code | removeformat | h2 h3 h4s',
            height: this.externalHeight,
            // 这里是代码块的一些语言选择,好像暂时还没支持typescript,所以博文都是js格式
            codesample_languages: [
                { text: 'HTML/XML', value: 'markup' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'CSS', value: 'css' },
                { text: 'Java', value: 'java' }
            ],
            image_caption: true,
            // paset 插件允许粘贴图片
            paste_data_images: true,
            imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
            // 这个便是自定义上传图片方法
            images_upload_handler: function (blobInfo, success, failure) {
                var formData;
                formData = new FormData();
                formData.append("file", blobInfo.blob(), blobInfo.filename());
                _this.uploadFile(formData).subscribe( response => {
                    let url = response['body']['data']['filePath']
                    success(url);
                });
            },

            //想要哪一个图标提供本地文件选择功能，参数可为media(媒体)、image(图片)、file(文件)
            file_picker_types: 'image media',
            file_picker_callback: function(callback, value, meta) {

                if (meta.filetype == 'media') {
                    let input: any = document.createElement('input');//创建一个隐藏的input
                    input.setAttribute('type', 'file');
                    input.setAttribute('id', 'media-file');
                    // let that = this;
                    input.onchange = function(): void{
                        let file = input.files[0]
                        // let file = this.files[0];//选取第一个文件
                        let formData = new FormData();
                        formData.append("file", file);
                        _this.uploadFile(formData).subscribe( response => {
                            let url = response['body']['data']['filePath']
                            callback(url, { title: '测试' });
                        }); // 上传视频拿到url
                    }
                    //触发点击
                    input.click();
                }
            },
            // 自定义视频模板
            video_template_callback: function(data: any) {
                return `<video width="${data.width}" height="${data.height}" controls="controls" src="${data.source}"></video>`;
            }
        };
    }
    @Input()
    uploadReq: MaterialUploadReq = MaterialUploadReq.fromInformation(new Information());
    @Input() title: string;
    @Input() editing: boolean = false;


    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    property: string
    @Input()
    get value() {
        return this.property;
    }
    set value(val) {
        this.property = val;
        this.valueChange.emit(this.property);
    }


    // 上传图片
    private uploadFile(formData: any) {
        const req: MaterialUploadFileReq = {
            uploadTo: this.uploadReq.uploadTo,
            key: this.uploadReq.key,
        }

        let params = new HttpParams();
        if (!isNullOrUndefined(req.uploadTo)) {
            params = params.set('uploadTo', req.uploadTo.toString());
        }
        if (!isNullOrUndefined(req.key)) {
            params = params.set('key', req.key.toString());
        }
        return this.http.post( this.materialService.materialUrl + `/upload`, formData, {
            params: params,
            headers: this.getFileHeaders(),
            observe: 'response'
        });
    }
  }
