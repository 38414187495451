import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { ProductCompany, ProductCompanySearch } from '../product/product-types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from '../common/utils';
import { ApiResponse, PagedResp, Product } from '../api/types';
import { map } from 'rxjs/operators';
import { HttpService } from '../api/http.service';
import { RepliesArr, TicketDetailResp, TicketListResp, TicketListSearch, TicketReply } from './ticket-types';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseService {

  constructor(private http: HttpService) {
      super();
  }


    /**
     * 工单列表
     */
    list(search: TicketListSearch = new TicketListSearch()): Observable<PagedResp<TicketListResp>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.status)) {
            params = params.set('status', search.status.toString());
        }
        if (!isNullOrUndefined(search.startCreateDate)) {
            params = params.set('startCreateDate', search.startCreateDate.toString());
        }
        if (!isNullOrUndefined(search.endCreateDate)) {
            params = params.set('endCreateDate', search.endCreateDate.toString());
        }
        if (!isNullOrUndefined(search.startReplyDate)) {
            params = params.set('startReplyDate', search.startReplyDate.toString());
        }
        if (!isNullOrUndefined(search.endReplyDate)) {
            params = params.set('endReplyDate', search.endReplyDate.toString());
        }
        return this.http.get<ApiResponse<PagedResp<TicketListResp>>>(this.ticketUrl + '/list', {
            params: params,
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    /**
     * 工单详情
     */
    detail(id: any): Observable<TicketDetailResp> {
        let params = new HttpParams();
        params = params.set('id', id.toString());
        return this.http.get<ApiResponse<TicketDetailResp>>(this.ticketUrl + '/info', {
            params: params,
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }


    /**
     * .关闭工单
     */
    close(id: any): Observable<any> {
        return this.http.post<ApiResponse<any>>(this.ticketUrl + '/close', {id},  {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }


    /**
     * .关闭工单
     */
    reply(params: TicketReply): Observable<any> {
        return this.http.post<ApiResponse<any>>(this.ticketUrl + '/reply', params,  {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

}
