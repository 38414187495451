import { Injectable, OnInit } from '@angular/core';
import { ApiResponse, PagedResp, Proposal, ProposalSearch, ProposalStatistic } from "../api/types";
import { Observable } from "rxjs/internal/Observable";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { map } from "rxjs/operators";
import { HttpService } from "../api/http.service";
import { BaseService } from "../base/base.service";
import { plainToInstance } from 'class-transformer';

@Injectable({
    providedIn: 'root'
})
export class ProposalService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    /**
     * 获取计划书列表
     * @param search 搜索参数
     */
    proposalList(search: ProposalSearch): Observable<PagedResp<Proposal>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.id)) {
            params = params.set('id', search.id.toString());
        }
        if (!isNullOrUndefined(search.companyCode)) {
            params = params.set('companyCode', search.companyCode.toString());
        }
        if (!isNullOrUndefined(search.productCode)) {
            params = params.set('productCode', search.productCode.toString());
        }
        if (!isNullOrUndefined(search.instance)) {
            params = params.set('instance', search.instance.toString());
        }
        if (!isNullOrUndefined(search.proposalId)) {
            params = params.set('proposalId', search.proposalId.toString());
        }
        if (!isNullOrUndefined(search.premiumTerm)) {
            params = params.set('premiumTerm', search.premiumTerm.toString());
        }
        if (!isNullOrUndefined(search.age)) {
            params = params.set('age', search.age.toString());
        }
        if (!isNullOrUndefined(search.gender)) {
            params = params.set('gender', search.gender.toString());
        }
        if (!isNullOrUndefined(search.smokeCondition)) {
            params = params.set('smokeCondition', search.smokeCondition.toString());
        }
        if (!isNullOrUndefined(search.currency)) {
            params = params.set('currency', search.currency.toString());
        }
        if (!isNullOrUndefined(search.cleaned)) {
            params = params.set('cleaned', search.cleaned.toString());
        }
        if (!isNullOrUndefined(search.abnormal)) {
            params = params.set('abnormal', search.abnormal.toString());
        }

        return this.http.get<ApiResponse<PagedResp<Proposal>>>(this.proposalUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    rpaProductsSync(): Observable<string> {
        return this.http.post<ApiResponse<string>>(this.proposalUrl + '/rpa/products/sync', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    setCleaned(id: number, proposal: Proposal): Observable<Proposal> {
        return this.http.put<ApiResponse<Proposal>>(this.proposalUrl + '/' + id, proposal, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    setAbnormal(id: number, proposal: Proposal): Observable<Proposal> {
        return this.http.put<ApiResponse<Proposal>>(this.proposalUrl + '/' + id, proposal, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    clean(id: number): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.proposalUrl + '/' + id + '/clean', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    update(proposal: Proposal): Observable<Proposal> {
        return this.http.put<ApiResponse<Proposal>>(this.proposalUrl + '/' + proposal.id, proposal, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    statistic(): Observable<ProposalStatistic> {
        return this.http.get<ApiResponse<ProposalStatistic>>(this.proposalUrl + '/statistic',  {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 計劃書詳情
     * @param proposalId 計劃書編號
     */
    info(proposalId: string): Observable<Proposal> {
        return this.http.get<ApiResponse<Proposal>>(this.proposalUrl + '/' + proposalId, {
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(Proposal, data.body.data);
        }));
    }

    instanceExtract(id: number): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.proposalUrl + '/' + id + '/instance-extract', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

}
