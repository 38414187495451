<div *ngIf="propertyGroups.length > 0">
    <nz-tabset [nzSize]="'small'" nzCentered>
        <nz-tab nzTitle="默認">
            <div  *ngFor="let propertyGroup of propertyGroups">
                <div class="product-property-group-title">
                    {{propertyGroup.name}}
                </div>
                <div *ngIf="propertyGroup.properties && propertyGroup.properties.length > 0">
                    <div *ngFor="let property of propertyGroup.properties" style="margin-left: 15px">
                        <app-property-text-display [title]="property.name" [value]="property.value"></app-property-text-display>
                    </div>
                </div>
                <nz-empty *ngIf="!propertyGroup.properties || propertyGroup.properties.length == 0" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
            </div>
        </nz-tab>
        <nz-tab nzTitle="繁體">
            <div  *ngFor="let propertyGroup of propertyGroups">
                <div class="product-property-group-title">
                    {{propertyGroup.nameI18n?.zhHantHK}}
                </div>
                <div *ngIf="propertyGroup.properties && propertyGroup.properties.length > 0">
                    <div *ngFor="let property of propertyGroup.properties" style="margin-left: 15px">
                        <app-property-text-display [title]="property.nameI18n?.zhHantHK" [value]="property.valueI18n?.zhHantHK"></app-property-text-display>
                    </div>
                </div>
                <nz-empty *ngIf="!propertyGroup.properties || propertyGroup.properties.length == 0" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
            </div>
        </nz-tab>
        <nz-tab nzTitle="簡體">
            <div  *ngFor="let propertyGroup of propertyGroups">
                <div class="product-property-group-title">
                    {{propertyGroup.nameI18n?.zhHansCN}}
                </div>
                <div *ngIf="propertyGroup.properties && propertyGroup.properties.length > 0">
                    <div *ngFor="let property of propertyGroup.properties" style="margin-left: 15px">
                        <app-property-text-display [title]="property.nameI18n?.zhHansCN" [value]="property.valueI18n?.zhHansCN"></app-property-text-display>
                    </div>
                </div>
                <nz-empty *ngIf="!propertyGroup.properties || propertyGroup.properties.length == 0" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
            </div>
        </nz-tab>
        <nz-tab nzTitle="英文">
            <div  *ngFor="let propertyGroup of propertyGroups">
                <div class="product-property-group-title">
                    {{propertyGroup.nameI18n?.enUS}}
                </div>
                <div *ngIf="propertyGroup.properties && propertyGroup.properties.length > 0">
                    <div *ngFor="let property of propertyGroup.properties" style="margin-left: 15px">
                        <app-property-text-display [title]="property.nameI18n?.enUS" [value]="property.valueI18n?.enUS"></app-property-text-display>
                    </div>
                </div>
                <nz-empty *ngIf="!propertyGroup.properties || propertyGroup.properties.length == 0" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
            </div>
        </nz-tab>
    </nz-tabset>
</div>
<nz-empty *ngIf="propertyGroups.length == 0" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
