import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NzModalRef } from "ng-zorro-antd/modal";
import * as dayjs from 'dayjs'
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from "ng2-file-upload";
import { MaterialService } from "../../shared/services/material.service";
import { isNullOrUndefined } from "../../common/utils";
import { Material, MaterialUploadTo, MaterialUploadTokenReq } from "../../api/types";
import { ContractMasterRate } from "../contract-types";

@Component({
    selector: 'app-contract-master-rate-create',
    templateUrl: './contract-master-rate-create.component.html',
    styleUrls: ['./contract-master-rate-create.component.less']
})
export class ContractMasterRateCreateComponent implements OnInit {

    masterRate: ContractMasterRate = new ContractMasterRate();

    date: Date;

    uploader: FileUploader;

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    materialMap: Map<string, Material> = new Map();

    constructor(private modal: NzModalRef,
                private materialService: MaterialService) {
    }

    ngOnInit(): void {

        let httpHeaders = this.materialService.getAuthorizationHeaders();

        let headers: Headers[] = httpHeaders.keys()
            .filter(key => {
                return key !== 'Content-Type'
            })
            .map(key => {
                return {name: key, value: httpHeaders.get(key)}
            });

        this.uploader = new FileUploader({
            headers: headers,
            autoUpload: false,
            removeAfterUpload: false,
        });

        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
        this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);

    }

    onEffectiveDateChange(date: Date) {
        this.date = date;
        const dateStr = dayjs(date.getTime()).format('YYYY-MM-DD');
        this.masterRate.effectiveDate = dateStr;
    }

    onUpload() {
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    uploadFileItem(fileItem: FileItem) {
        const req: MaterialUploadTokenReq = {
            uploadTo: MaterialUploadTo.CONTRACT,
            key: null,
            fileName: fileItem.file.name
        }
        this.materialService.uploadToken(req)
            .subscribe(
                data => {
                    fileItem.method = "POST";
                    fileItem.url = data.host;
                    fileItem.onBuildForm = function (form) {
                        console.error(form)
                        // form.append('x-oss-object-acl', data.material.accessControl.toLowerCase());
                        form.append('key', data.material.objectName);
                        form.append('policy', data.policy);
                        form.append('signature', data.signature);
                        form.append('OSSAccessKeyId', data.accessid);
                    };
                    fileItem.upload();
                    this.materialMap.set(fileItem.file.name, data.material);
                },
                error => {
                });
    }

    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
        this.uploadFileItem(fileItem);
    }


    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

        this.uploader.removeFromQueue(item);

        // this.uploader.clearQueue();
        // console.info(response + " for " + item.file.name + " status " + status);
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        this.uploader.removeFromQueue(item);

        // this.toastr.warning(response.);
        // this.uploader.clearQueue();
        console.info(response + " for " + item.file.name + " status " + status);
    }

    extension(filePath: string): string {
        if (isNullOrUndefined(filePath) || filePath.length === 0) return ""
        return filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase()
    }

}
