import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-property-text-edit',
    templateUrl: './property-text-edit.component.html',
    styleUrls: ['./property-text-edit.component.less']
})
export class PropertyTextEditComponent implements OnInit {

    @Input() type: PropertyTextEditType = PropertyTextEditType.TEXT_FIELD
    @Input() title: string;
    @Input() required: boolean = false;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    property: any;

    @Input()
    get value() {
        return this.property;
    }
    set value(val) {
        this.property = val;
        this.valueChange.emit(this.property);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}

export enum PropertyTextEditType {
    TEXT_FIELD = "TEXT_FIELD",          // 单行文本
    TEXT_NUMBER = "TEXT_NUMBER",          // 数字
    TEXT_AREA = "TEXT_AREA",            // 多行文本
    TEXT_HTML = "TEXT_HTML",             // HTML
    URL = "URL",             // URL
}
