import { Component, Input, OnInit } from '@angular/core';
import { I18n, Product, ProductFeature, ProposalAmountType } from "../../api/types";
import { ProductService } from "../product.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { isNullOrUndefined } from "../../common/utils";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";

@Component({
  selector: 'app-product-product-features',
  templateUrl: './product-product-features.component.html',
  styleUrls: ['./product-product-features.component.less']
})
export class ProductProductFeaturesComponent implements OnInit {
    @Input() product: Product;
    storageHighlights: I18n[];
    editing: boolean = false;
    loading: boolean = false;

    proposalAmountTypeOptions: PropertySelectOption[] = []

    constructor(private productService: ProductService,
                private notification: NzNotificationService) {
    }

    ngOnInit(): void {

        if (isNullOrUndefined(this.product.features)) {
            this.product.features = new ProductFeature();
        }

        if (isNullOrUndefined(this.product.features.highlights)) {
            this.product.features.highlights = [];
        }

        if (isNullOrUndefined(this.product.features.proposalAmountTip)) {
            this.product.features.proposalAmountTip = new I18n();
        }

        this.proposalAmountTypeOptions?.push(new PropertySelectOption('兩者都可', ProposalAmountType.BOTH));
        this.proposalAmountTypeOptions?.push(new PropertySelectOption('保額', ProposalAmountType.SUM_ASSURED));
        this.proposalAmountTypeOptions?.push(new PropertySelectOption('保費', ProposalAmountType.PREMIUM));

    }

    onEdit(): void {
        this.editing = true;
        this.storageHighlights = [...this.product.features.highlights]
    }

    onCancelEdit(): void {
        this.editing = false;
        this.product.features.highlights = this.storageHighlights
    }

    onSave(): void {
        this.loading = true;
        let code = this.product.code;
        console.log(this.product.features)
        this.productService.productFeaturesUpdate(code, this.product.features)
            .subscribe(
                data => {
                    this.notification.success('更新成功', '');
                    this.editing = false;
                    this.loading = false;
                    this.product.features.highlights = data.highlights
                },
                error => {
                    this.notification.error('更新失败', '');
                    this.loading = false;
                });
    }

    addHighlight(): void {
        this.product.features?.highlights?.push(new I18n())
    }

    onDelete(index: number): void {
        this.product.features?.highlights?.splice(index, 1)
    }

    protected readonly JSON = JSON;
}
