import {Injectable, OnInit} from '@angular/core';
import {HttpService} from "../api/http.service";
import {BaseService} from "../base/base.service";
import {ApiResponse, PagedResp, Proposal, ProposalCompare, ProposalCompareSearch, ProposalSearch} from "../api/types";
import {Observable} from "rxjs/internal/Observable";
import {HttpParams} from "@angular/common/http";
import {isNullOrUndefined} from "../common/utils";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProposalCompareService extends BaseService implements OnInit {

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }


    /**
     * 获取计划书对比列表
     * @param search 搜索参数
     */
    compareList(search: ProposalCompareSearch): Observable<PagedResp<ProposalCompare>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.instance)) {
            params = params.set('instance', search.instance.toString());
        }
        if (!isNullOrUndefined(search.bid)) {
            params = params.set('bid', search.bid.toString());
        }

        return this.http.get<ApiResponse<PagedResp<ProposalCompare>>>(this.proposalCompareUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 计划书对比重试
     * @param id 计划书对比id
     */
    retry(id: number): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.proposalCompareUrl + '/' + id + '/retry', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }
}
