import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Knowledge } from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { KnowledgeService } from "../../knowledge/knowledge.service";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ProductService } from "../../product/product.service";
import { InformationService } from "../../information/information.service";
import { ArticleSelectionComponent } from "../../article/article-selection/article-selection.component";

@Component({
    selector: 'app-public-knowledge',
    templateUrl: './public-knowledge.component.html',
    styleUrls: ['./public-knowledge.component.less']
})
export class PublicKnowledgeComponent implements OnInit {

    @Input() knowledge: Knowledge;
    @Input() loading: boolean = false;

    contentTextType = PropertyTextEditType.TEXT_HTML;

    @Output()
    knowledgeUpdated: EventEmitter<Knowledge> = new EventEmitter<Knowledge>();

    constructor(private route: ActivatedRoute,
                private knowledgeService: KnowledgeService,
    ) {
        this.route.params.subscribe(params => {
            console.log(params['id']);
            const id = params['id'];
            this.knowledgeService.knowledgeInfo(id).subscribe(
                data => {
                    this.knowledge = data;
                },
                error => {
                });
        });
    }

    ngOnInit(): void {

    }

}
