import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base/base.component";

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.less']
})
export class KnowledgeComponent extends BaseComponent implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
