import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    ApiResponse,
    I18n,
    Instance,
    PagedResp,
    ProcessLog,
    Product,
    ProductSearch,
    ProductStatistic
} from '../../api/types';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { ProductTableDisplayConfig } from '../../common/model/table-display-config';
import { ProductSearchComponent } from '../../product/components/product-search/product-search.component';
import { ProductService } from '../../product/product.service';
import { DataService } from '../../shared/services/data.service';
import { OperatorService } from '../../operator/operator.service';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../common/utils';
import { ProductProductInfoComponent } from '../../product/product/product-product-info.component';
import { ProcessLogListComponent } from '../../shared/components/process-log/process-log-list.component';
import { ProductProductCreateComponent } from '../../product/product/product-product-create.component';
import { InstanceService } from '../../shared/services/instance.service';
import { BelongingInstance } from '../customer-types';
import { CustomerProductComponent } from './customer-product.component';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.less']
})
export class CustomerListComponent implements OnInit {
    productStatistic: ProductStatistic;

    loading = false;
    products: PagedResp<Product>;
    search: ProductSearch = new ProductSearch();

    showSearchDrawer: boolean = false;

    uploader: FileUploader;

    spinning: boolean = false;
    customerList: Instance[] = [];

    constructor(private productService: ProductService,
                private dataService: DataService,
                private operatorService: OperatorService,
                private router: Router,
                private drawerService: NzDrawerService,
                private instanceService: InstanceService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });

        this.productService.statistic()
            .subscribe(
                data => {
                    this.productStatistic = data;
                },
                error => {
                });

        this.onCustomerList();
    }


    get selectedProducts(): Instance[] {
        let instance: Instance[] = [];
        if (!isNullOrUndefined(this.customerList) && !isNullOrUndefined(this.customerList.length)) {
            instance = this.customerList.filter(item => {
                return item.checked;
            });
        }
        return instance;
    }

    checkAllEvent(checked: boolean) {
        if (this.customerList.length) {
            this.customerList.forEach(item =>
                item.checked = checked
            );
        }
    }

    onProductInstanceSync() {
        this.dataService.productsToSync = this.selectedProducts;
        const param = [];
        if (this.customerList.length != this.selectedProducts.length) {
            param.push(...this.selectedProducts.map(item => item.name));
        }
        this.instanceService.productReport(param)
            .subscribe(
                data => {
                    if (data instanceof Array && data.length) {
                        this.toastr.success(data[0].content, data[0].title);
                    }
                    this.onCustomerList();
                },
                () => {
                    this.toastr.error('通知上報失败');
                }
            );
    }

    onReload() {
        this.loading = true;
        this.search.pageNum = 1;
        this.productService.productList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });

        this.productService.statistic()
            .subscribe(
                data => {
                    this.productStatistic = data;
                },
                error => {
                });
    }

    onCustomerList(): void {
        this.loading = true;
        this.instanceService.instances()
            .subscribe(
                data => {
                    this.customerList = data;
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                }
            );
    }

    onProductInfo(name: string): void {
        if (!name) return;
        const param = new BelongingInstance();
        param.instance = name;
        this.instanceService.instanceProduct(param)
            .subscribe(
                data => {
                    const drawerRef = this.drawerService.create<CustomerProductComponent, { value: any }, string>({
                        nzWidth: 800,
                        nzContent: CustomerProductComponent,
                        nzContentParams: {
                            name,
                            products: data
                        }
                    });
                }
            )
    }

}
