import { Component, OnInit } from '@angular/core';
import { ConsoleService } from "../../shared/services/console.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-system-console',
    templateUrl: './system-console.component.html',
    styleUrls: ['./system-console.component.less']
})
export class SystemConsoleComponent implements OnInit {

    spinning = false;

    constructor(
        private consoleService: ConsoleService,
        private toastr: ToastrService) {
    }

    ngOnInit(): void {
    }

    onProductSync() {
        this.spinning = true;
        this.consoleService.productSync()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success('發送同步產品指令成功')
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('發送同步產品指令失敗')
                }
            );
    }

    onProductCompanySync() {
        this.spinning = true;
        this.consoleService.productCompanySync()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success('發送同步公司指令成功')
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('發送同步公司指令失敗')
                }
            );
    }

    onProductCategorySync() {
        this.spinning = true;
        this.consoleService.productCategorySync()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success('發送同步險種指令成功')
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('發送同步險種指令失敗')
                }
            );
    }

    onInformationTranslateImages(): void {
        this.spinning = true;
        this.consoleService.translateInformationImages()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success('转换成功')
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('转换失败')
                }
            );
    }

    onKnowledgeTranslateImages(): void {
        this.spinning = true;
        this.consoleService.translateKnowledgeImages()
            .subscribe(
                data => {
                    this.spinning = false;
                    this.toastr.success('转换成功')
                },
                error => {
                    this.spinning = false;
                    this.toastr.error('转换失败')
                }
            );
    }
}
