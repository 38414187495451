<div>
    <div class="picture-card" *ngIf="!material.filePath" (click)="upload()">
        <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader" multiple/>
        <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 14px;"></i>
    </div>

    <div *ngIf="material?.filePath">
        <div class="picture-card-upload">
            <div class="picture-img">
                <img [src]="material?.filePath" alt="" style="width: 120px; height: 100%;">
            </div>
            <span class="picture-button">
                    <a nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onPreview(material)">
                        <em nz-icon nzType="eye" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onDownload(material)">
                        <em nz-icon nzType="cloud-download" nzTheme="outline"></em>
                    </a>
                    <a nz-button nzType="link" nzSize="small" class="delete-button" appThrottleClick (throttleClick)="onDelete()">
                        <em nz-icon nzType="delete" nzTheme="outline"></em>
                    </a>
                </span>
        </div>

        <!--        <div *ngFor="let item of uploader.queue; let i = index">-->
        <!--            <div class="material">-->
        <!--                <nz-progress [nzPercent]="item.progress" nzType="circle" [nzWidth]="48"></nz-progress>-->
        <!--                <div class="material-file-elem">-->
        <!--                    <div class="material-file-name">{{item.file.name}}</div>-->
        <!--                    <div class="material-file-path">-->
        <!--                        <span>{{item._file.path}}</span>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

    </div>

    <!--    <div class="material-menu">-->

    <!--        <input #fileUpload hidden=true type="file" ng2FileSelect [uploader]="uploader"-->
    <!--               (change)="selectedImportFileOnChanged($event)" multiple/>-->

    <!--        <button nz-button nzType="default" nzSize="large" nzShape="circle" appThrottleClick (throttleClick)="upload()"-->
    <!--                style="margin-right: 40px; transform: scale(1.5, 1.5)">-->
    <!--            <i nz-icon nzType="cloud-upload" nzTheme="outline"-->
    <!--               style="color: #07367c; font-size: 20px;"></i>-->
    <!--        </button>-->
    <!--    </div>-->

</div>
