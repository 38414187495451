<div style="height: 100%; padding-bottom: 60px">
    <div class="selection-title">
        <i nz-icon nzType="usergroup-add" nzTheme="outline" style="color: #07367c; font-size: 20px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 20px;">签约客户选择</span>
    </div>

    <div *ngIf="instances" style="margin-top: 40px">
        <div *ngFor="let instance of instances">
            <div class="instance-item"
                appThrottleClick (throttleClick)="checkInstance(instance)">
                <app-property-text [title]="instance.name" [value]="instance.remark"
                                   style="flex: 1"></app-property-text>
                <em nz-icon *ngIf="instance.checked" style="font-size: 20px; color: #2C7BE5" nzType="check-circle"
                    nzTheme="outline"></em>
            </div>
        </div>
    </div>

    <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary" appThrottleClick (throttleClick)="onConfirm()">
                <a nz-button nzType="link" nzBlock nzSize="large">
                    <em nz-icon nzTheme="outline"></em>
                    <span>确 定</span>
                </a>
            </div>
        </div>
    </div>
</div>
