import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractMasterRateListComponent } from "./contract-master-rate-list/contract-master-rate-list.component";
import { ContractMasterRateCreateComponent } from "./contract-master-rate-create/contract-master-rate-create.component";
import { ContractComponent } from './contract.component';
import { ContractMasterRateDetailComponent } from './contract-master-rate-detail/contract-master-rate-detail.component';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { FileUploadModule } from "ng2-file-upload";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";


@NgModule({
    declarations: [
        ContractComponent,
        ContractMasterRateListComponent,
        ContractMasterRateCreateComponent,
        ContractMasterRateDetailComponent,
    ],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        SharedModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzPopoverModule,
        NzButtonModule,
        FileUploadModule,
        NzDatePickerModule,
        NzInputModule,
        NzToolTipModule,
        NzIconModule
    ]
})
export class ContractModule {
}
