<div class="property">
    <div class="property-title">
        <label>{{ title }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
        <div style="flex: 1;"> </div>
        <a *ngIf="actionTitle" nz-button nzType="link" appThrottleClick (throttleClick)="onActionButton()">
            <span>{{ actionTitle }}</span>
        </a>
    </div>
    <div class="property-value">
        <nz-select style="display: block" [(ngModel)]="value" class="select" nzBorderless nzAllowClear
                   [nzMode]="mode"
                   (nzScrollToBottom)="onScrollToButton()"
                   [nzLoading]="loading"
                   (nzOnSearch)="onSearch($event)"
                   [nzPlaceHolder]="placeholder"
                   [nzAllowClear]="allowClear"
                   [nzShowSearch]="showSearch"
                   [nzDropdownRender]="dropdownRender">
            <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
        <ng-template #dropdownRender>
            <nz-spin *ngIf="loading"></nz-spin>
        </ng-template>
    </div>
</div>
