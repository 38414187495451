import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productType'
})
export class ProductTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let productType = "";
        switch (value) {
            case 'BASIC':
                productType = "基本計劃";
                break;
            case 'RIDER':
                productType = "附加計劃";
                break;
        }
        return productType;
    }

}
