<div *ngIf="information">
    <div class="profile-large-title">
        <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>
        <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">資訊詳情</span>
    </div>

    <nz-tabset [nzSize]="'large'" nzCentered>
        <nz-tab nzTitle="基本信息">
            <div style="margin-top: 10px; padding-bottom: 60px;">
                <div style="margin-top: 10px; padding-bottom: 60px;">
                    <div class="property-title">封面</div>
                    <div class="property-value">
                        <app-upload-picture [material]="material" [uploadReq]="uploadReq"
                                            (valueChange)="onPictureChange($event)"></app-upload-picture>
                    </div>

                    <app-property-text [title]="'資訊標題 *'"
                                       [(value)]="information.title"
                                       [editable]="true"
                                       [editing]="editing"></app-property-text>

                    <app-property-select [title]="'資訊類型'" [(value)]="information.type"
                                         [name]="information.type | releaseType"
                                         [editing]="editing" [editable]="true" [options]="typeOptions"
                                         (valueChange)="informationTypeChange($event)"></app-property-select>

                    <app-property-datetime [title]="'資訊時間'" [(value)]="information.addTime" [editable]="true"
                                           [editing]="editing" format="yyyy-MM-dd"></app-property-datetime>

                    <app-property-select [title]="'資訊標籤'" [editable]="true" [editing]="editing"
                                         [name]="information.tags"
                                         [(value)]="information.tags" [mode]="'multiple'"
                                         [options]="tagsOptions"></app-property-select>

                    <app-property-datetime [title]="'生效日期'" [(value)]="information.startEffectiveDate"
                                           [editable]="true" [editing]="editing"
                                           format="yyyy-MM-dd"></app-property-datetime>

                    <app-property-datetime [title]="'失效日期'" [(value)]="information.endEffectiveDate"
                                           [editable]="true" [editing]="editing"
                                           format="yyyy-MM-dd"></app-property-datetime>

                    <app-property-select [title]="'公司类别 *'" [(value)]="information.companyCode"
                                         [name]="information.companyName"
                                         *ngIf="showProduct" [editable]="true" [editing]="editing"
                                         [options]="companyOptions"
                                         (valueChange)="companyChange($event)"></app-property-select>

                    <app-property-select [title]="'保險產品 *'" [(value)]="information.productCodes"
                                         [name]="information.productNames"
                                         *ngIf="showProduct" [editable]="true" [editing]="editing"
                                         [options]="productOptions" [mode]="'multiple'" [loading]="productLoading"
                                         (scrollToBottom)="onProductNextPage()"
                                         (searchChange)="onProductSearch($event)"
                                         [actionTitle]="'过滤下架产品'"
                                         (onAction)="onFilterDiscontinuedProducts()"></app-property-select>


                    <!--
                            <app-property-select [title]="'公告標籤 *'" [(value)]="releaseData.tags"
                                                 [editable]="true" [editing]="editing" [options]="companyOptions"></app-property-select> -->

                    <app-tinymce-editor [title]="'資訊內容'" [(value)]="information.content"
                                        [editing]="editing"></app-tinymce-editor>

                    <div class="footer">
                        <a *ngIf="!editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()"
                           nzSize="large">
                            <em nz-icon nzType="edit" nzTheme="outline"></em>
                            <span>編 輯</span>
                        </a>
                        <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
                            <div nz-col nzSpan="12" class="footer-button">
                                <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock
                                   nzSize="large">
                                    <em nz-icon nzType="close" nzTheme="outline"></em>
                                    <span>取 消</span>
                                </a>
                            </div>
                            <div nz-col nzSpan="12" class="footer-button-primary">
                                <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock
                                   nzSize="large">
                                    <em nz-icon nzType="save" nzTheme="outline"></em>
                                    <span>保 存</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nz-tab>
        <nz-tab nzTitle="資訊附件">
            <app-material-editor [materials]="information.attachments" [uploadReq]="uploadReq"
                                 (valueChange)="onFilesChange($event)"></app-material-editor>
        </nz-tab>
    </nz-tabset>
</div>
