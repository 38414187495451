import { Component, OnInit } from '@angular/core';
import { CreditRating } from "../../../api/types";

export class CreditRatingEntity {
    index: number;
    creditRating: CreditRating; // 信用评级

    constructor(index: number, creditRating: CreditRating) {
        this.index = index;
        this.creditRating = creditRating;
    }
}

@Component({
    selector: 'app-company-credit-rating-editor',
    templateUrl: './company-credit-rating-editor.component.html',
    styleUrls: ['./company-credit-rating-editor.component.less']
})
export class CompanyCreditRatingEditorComponent implements OnInit {

    entity: CreditRatingEntity; // 信用评级

    constructor() {
    }

    ngOnInit(): void {
    }

}
