import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'companyBusinessType'
})
export class CompanyBusinessTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let businessType = "";
        switch (value) {
            case 'GENERAL':
                businessType = "一般保险";
                break;
            case 'LONG_TERM':
                businessType = "长期保险";
                break;
            case 'GENERAL_AND_LONG_TERM':
                businessType = "一般&长期保险";
                break;
            case 'TRUST':
                businessType = "信托";
                break;
            case 'OTHER':
                businessType = "其他";
                break;
        }
        return businessType;
    }

}
