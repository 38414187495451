<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!companies">
        <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
    </div>
    <div *ngIf="companies" style="margin-top: 30px">
        <nz-table [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzPageIndex]="companies.pageNum"
                  [nzPageSize]="companies.pageSize"
                  [nzTotal]="companies.totalCount"
                  [nzData]="companies.list"
                  [nzTitle]="tableHeader"
                  (nzPageIndexChange)="onSearch($event)"
                  [nzFooter]="tableFooter"
                  [nzScroll]="{ x: '1600px'}">
            <ng-template #tableHeader>
                <nz-row>
                    <nz-col [nzSpan]="12">
                        <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">
                            公司列表
                            <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新" appThrottleClick
                               (throttleClick)="onReload()">
                                <em nz-icon nzType="reload" nzTheme="outline"></em>
                            </a>
                        </div>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <div style="width: 100%; display: flex; justify-content: flex-end;">

                            <button *ngIf="hasAuthority('PRODUCT_COMPANY_EDIT')"
                                    nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                    nzTooltipTitle="導出供AI使用的產品庫(临时)"
                                    appThrottleClick (throttleClick)="exportAICompanyLinks()"
                                    style=" margin-left: 10px;">
                                <i nz-icon nzType="cloud-download" nzTheme="outline"
                                   style="color: #07367c; font-size: 20px;"></i>
                            </button>

                            <button *ngIf="hasAuthority('PRODUCT_COMPANY_EDIT')"
                                    nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                    nzTooltipTitle="同步公司汇率"
                                    appThrottleClick (throttleClick)="updateExchangeRate()"
                                    style=" margin-left: 10px;">
                                <i nz-icon nzType="exchange-rate" nzTheme="outline"
                                   style="color: #07367c; "></i>
                            </button>
                            <button *ngIf="hasAuthority('PRODUCT_COMPANY_EDIT')"
                                    nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                    nzTooltipTitle="添加保險公司"
                                    appThrottleClick (throttleClick)="onCreateCompany()"
                                    style=" margin-left: 10px;">
                                <i nz-icon nzType="plus" nzTheme="outline"
                                   style="color: #07367c; font-size: 20px;"></i>
                            </button>
                            <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                    nzTooltipTitle="公司搜索"
                                    appThrottleClick (throttleClick)="openSearchDrawer()"
                                    style=" margin-left: 10px;">
                                <i nz-icon nzType="search" nzTheme="outline"
                                   style="color: #07367c; font-size: 20px;"></i>
                            </button>
                        </div>
                    </nz-col>
                </nz-row>
            </ng-template>
            <ng-template #tableFooter>
                <div class="table-title">
                    共 <span style="color: #002140;">{{ companies?.list?.length }}</span> 個公司
                </div>
            </ng-template>

            <thead>
            <tr>
                <th nzLeft nzWidth="80" style="text-align: center"><input type="checkbox" ngModel
                                                                          (ngModelChange)="checkAllEvent($event)"
                                                                          (click)="$event.stopPropagation();"
                                                                          class="check-box"/></th>
                <th nzLeft>公司碼</th>
                <th>公司默認名稱</th>
<!--                <th>公司簡稱簡體</th>-->
<!--                <th>公司簡稱繁體</th>-->
<!--                <th>公司簡稱英文</th>-->
<!--                <th>合規名稱簡體</th>-->
<!--                <th>合規名稱繁體</th>-->
<!--                <th>合規名稱英文</th>-->
                <th nzWidth="200px">所在地區</th>
                <th>公司官网</th>
                <th>年龄计算方式</th>
                <th>狀態</th>
                <th>是否热门</th>
                <th>排序</th>
                <th nzRight>
                    <div style="text-align: center">操作</div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let company of companies.list" appThrottleClick (throttleClick)="openInfoDrawer(company.code)">
                <td nzLeft style="text-align: center" (click)="$event.stopPropagation();">
                    <input type="checkbox" [(ngModel)]="company.checked" (click)="$event.stopPropagation();"
                           class="check-box">
                </td>
                <td nzLeft>
                    <nz-avatar [nzShape]="'square'" [nzSize]="32" [nzSrc]="company.logo"></nz-avatar>
                    {{ company.code }}
                </td>
                <td nzBreakWord>{{ company.name }}</td>
<!--                <td nzBreakWord>{{ company.shortNameI18n?.zhHansCN }}</td>-->
<!--                <td nzBreakWord>{{ company.shortNameI18n?.zhHantHK }}</td>-->
<!--                <td nzBreakWord>{{ company.shortNameI18n?.enUS }}</td>-->
<!--                <td nzBreakWord>{{ company.complianceNameI18n?.zhHansCN }}</td>-->
<!--                <td nzBreakWord>{{ company.complianceNameI18n?.zhHantHK }}</td>-->
<!--                <td nzBreakWord>{{ company.complianceNameI18n?.enUS }}</td>-->
                <td>{{ company.region | productCompanyRegion }}</td>
                <td>{{ company.website }}</td>
                <td>{{ company.ageFormula | productCompanyAgeFormula }}</td>
                <td>{{ company.status | companyStatus }}</td>
                <td>{{ company.hot ? '是' : '否' }}</td>
                <td>{{ company.rank }}</td>
                <td nzRight>
                    <div class="flex-row-center">
                        <a *ngIf="hasAuthority('PRODUCT_COMPANY_DELETE')"
                           nz-button nzType="link" appThrottleClick (throttleClick)="onDelete(company);"
                           nzSize="small" class="delete-button">
                            <em nz-icon nzType="delete" nzTheme="outline"></em>
                        </a>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>

        <nz-affix  [nzOffsetBottom]="40" *ngIf="selectedProductCompanies.length > 0 && hasAuthority('PRODUCT_COMPANY_SYNC')">
            <div class="product-sync-container">
                <div class="product-sync-wrapper">
                    <i nz-icon nzType="check-circle" nzTheme="outline"
                       style="font-size: 20px;"></i>
                    <span style="margin-left: 5px;">已選</span>
                    <span
                        style="margin-left: 5px; margin-right: 5px; color: #f3e282; font-size: 25px">{{ selectedProductCompanies.length }}</span>
                    <span>家公司</span>
                    <button style="margin-left: 20px;" class="product-sync-button" nz-button nzType="primary"
                            nzShape="round" appThrottleClick (throttleClick)="onProductInstanceSync()">
                        同步至簽約客戶
                    </button>
                </div>
            </div>
        </nz-affix>
    </div>
</nz-spin>
<nz-drawer [nzVisible]="showSearchDrawer" [nzMask]="true" [nzWidth]="600" [nzClosable]="true"
           (nzOnClose)="closeSearchDrawer()">
    <ng-container *nzDrawerContent>
        <app-company-search #search (productCompanySearch)="onProductCompanySearch($event)"></app-company-search>
    </ng-container>
</nz-drawer>

