<nz-tabset [nzSize]="'large'" >
    <nz-tab [nzTitle]=groupTitle>
        <app-product-property-groups></app-product-property-groups>
    </nz-tab>
    <ng-template #groupTitle>
        对比分组
    </ng-template>
</nz-tabset>


