import { Injectable, OnInit } from '@angular/core';
import { BaseService } from "../base/base.service";
import { DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG, ProductTableDisplayConfig, } from "../common/model/table-display-config";
import { Cache } from "../common/cache";
import { isNullOrUndefined } from "../common/utils";
import { HttpService } from "../api/http.service";
import { Observable } from "rxjs/internal/Observable";
import { ApiResponse, Operator } from "../api/types";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { GhQueryEncoder } from "../common/encoder/gh-query.encoder";

@Injectable({
    providedIn: 'root'
})
export class OperatorService extends BaseService implements OnInit {

    authorityMap: Map<string, boolean> = new Map<string, boolean>();

    constructor(private httpService: HttpService) {
        super();
        if (this.operator && this.operator.authorities && this.operator.authorities.length > 0) {
            this.authorityMap = this.operator.authorities.reduce((map, item) => {
                map.set(item, true);
                return map;
            }, new Map<string, boolean>());
        }
    }

    ngOnInit(): void {
    }

    public getProductTableDisplayConfig(): ProductTableDisplayConfig {
        const value: string = localStorage.getItem(Cache.KEY_OPERATOR_PRODUCT_TABLE_DISPLAY_CONFIG);
        let config = JSON.parse(value) as ProductTableDisplayConfig;
        if (isNullOrUndefined(config)) {
            config = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG;
        }
        if (isNullOrUndefined(config.zhHansCN)) {
            config.zhHansCN = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.zhHansCN;
        }
        if (isNullOrUndefined(config.zhHantHK)) {
            config.zhHantHK = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.zhHantHK;
        }
        if (isNullOrUndefined(config.enUS)) {
            config.enUS = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.enUS;
        }
        if (isNullOrUndefined(config.category)) {
            config.category = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.category;
        }
        if (isNullOrUndefined(config.company)) {
            config.company = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.company;
        }
        if (isNullOrUndefined(config.type)) {
            config.type = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.type;
        }
        if (isNullOrUndefined(config.region)) {
            config.region = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.region;
        }
        if (isNullOrUndefined(config.policyPeriod)) {
            config.policyPeriod = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.policyPeriod;
        }
        if (isNullOrUndefined(config.status)) {
            config.status = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.status;
        }
        if (isNullOrUndefined(config.premiumTerms)) {
            config.premiumTerms = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.premiumTerms;
        }
        if (isNullOrUndefined(config.currencies)) {
            config.currencies = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.currencies;
        }
        if (isNullOrUndefined(config.frequencies)) {
            config.frequencies = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.frequencies;
        }
        if (isNullOrUndefined(config.standard)) {
            config.standard = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.standard;
        }
        if (isNullOrUndefined(config.hot)) {
            config.hot = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.hot;
        }
        if (isNullOrUndefined(config.comparable)) {
            config.comparable = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.comparable;
        }
        if (isNullOrUndefined(config.quotable)) {
            config.quotable = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.quotable;
        }
        if (isNullOrUndefined(config.withdrawalable)) {
            config.withdrawalable = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.withdrawalable;
        }
        if (isNullOrUndefined(config.proposalRPASupported)) {
            config.proposalRPASupported = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.proposalRPASupported;
        }
        if (isNullOrUndefined(config.financialPlanSupported)) {
            config.financialPlanSupported = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.financialPlanSupported;
        }
        if (isNullOrUndefined(config.hasBrochure)) {
            config.hasBrochure = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.hasBrochure;
        }
        if (isNullOrUndefined(config.rank)) {
            config.rank = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.rank;
        }
        if (isNullOrUndefined(config.internalRemark)) {
            config.internalRemark = DEFAULT_PRODUCT_ABLE_DISPLAY_CONFIG.internalRemark;
        }
        return config;
    }

    public setProductTableDisplayConfig(config: ProductTableDisplayConfig) {
        localStorage.setItem(Cache.KEY_OPERATOR_PRODUCT_TABLE_DISPLAY_CONFIG, JSON.stringify(config));
    }

    datetimeTest(): Observable<any> {
        const body = {
            localDate: '2020-12-09',
            localDateTime: '2021-12-10T11:10:50.000',
            offsetDateTime: '2021-12-10T11:10:50.000+09:00',
        }
        return this.httpService.post<ApiResponse<any>>(this.datetimeUrl + '/test', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    datetimeTestGet(): Observable<any> {
        let params = new HttpParams({encoder: new GhQueryEncoder()});
        params = params.set("localDateTime", '2021-12-10T11:10:50+06:00');
        return this.httpService.get<ApiResponse<any>>(this.datetimeUrl + '/test-get', {
            params: params,
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    info(id: number): Observable<Operator> {
        return this.httpService.get<ApiResponse<Operator>>(this.operatorUrl + `/info/${id}`, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    hasAuthority(authority: string) {
        if (!this.operator) return false;
        if (this.authorityMap.get('ALL')) {
            return true;
        }
        if (this.authorityMap.get(authority)) {
            return true;
        }
        return false;
    }

}

