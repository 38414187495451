import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationComponent } from './information.component';
import { InformationHomeComponent } from "./release/information-home.component";
import { InformationSearchComponent } from "./release/information-search.component";
import { InformationCreateComponent } from "./release/information-create.component";
import { InformationInfoComponent } from "./release/information-info.component";
import { InformationSyncComponent } from  "./sync/information-sync.component"
import { InformationSelectionComponent } from "./compoents/information-selection/information-selection.component"
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzListModule } from "ng-zorro-antd/list";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import {NzPopoverModule} from "ng-zorro-antd/popover";


@NgModule({
    declarations: [
        InformationComponent,
        InformationHomeComponent,
        InformationSearchComponent,
        InformationCreateComponent,
        InformationInfoComponent,
        InformationSyncComponent,
        InformationSelectionComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        NzSpinModule,
        NzTableModule,
        NzDrawerModule,
        NzAffixModule,
        NzSkeletonModule,
        NzStatisticModule,
        NzSelectModule,
        NzEmptyModule,
        NzGridModule,
        NzTabsModule,
        NzListModule,
        NzSwitchModule,
        NzTagModule,
        NzToolTipModule,
        NzUploadModule,
        EditorModule,
        NzPopoverModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class InformationModule {
}
