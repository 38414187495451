import { Component, OnInit } from '@angular/core';
import { Account } from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { Environment } from "../../api/environment";

@Component({
    selector: 'app-account-editor',
    templateUrl: './account-add.component.html',
    styleUrls: ['./account-add.component.less']
})
export class AccountAddComponent implements OnInit {

    account = new Account();

    envOptions: PropertySelectOption[] = []

    constructor() {
    }

    ngOnInit(): void {
        this.envOptions?.push(new PropertySelectOption('DEV', Environment.DEV));
        this.envOptions?.push(new PropertySelectOption('BETA', Environment.BETA));
        this.envOptions?.push(new PropertySelectOption('PROD', Environment.PROD));
        this.account.env = Environment.PROD;
    }

}
