import { Component, OnInit } from '@angular/core';
import { Environment } from "../../api/environment";
import { OperatorService } from "../../operator/operator.service";
import md5 from 'crypto-js/md5';
import { ElectronService } from "../../core/services";
import { Operator } from "../../api/types";

@Component({
    selector: 'app-system-other',
    templateUrl: './system-other.component.html',
    styleUrls: ['./system-other.component.less']
})
export class SystemOtherComponent implements OnInit {

    spinning = false;

    operator: Operator;
    constructor(private operatorService: OperatorService,
                private electronService: ElectronService,
    ) {
    }

    ngOnInit(): void {
        this.operator = this.operatorService.operator;
    }

    get xiumiSecret(): string {
        let secret: string;
        switch (this.operatorService.env) {
            case Environment.DEV:
                secret = '75720fd71bf529964fe769099eafdb22';
                break;
            case Environment.BETA:
                secret = '75720fd71bf529964fe769099eafdb22';
                break;
            case Environment.PROD:
                secret = '9b864a914d9adcc752bd1d1f3fccc591';
                break;
            default:
                secret = '75720fd71bf529964fe769099eafdb22';
                break;
        }
        return secret;
    }

    get xiumiAppId(): string {
        let secret: string;
        switch (this.operatorService.env) {
            case Environment.DEV:
                secret = '9c283fff5e33b1845a0541e9718fc420';
                break;
            case Environment.BETA:
                secret = '9c283fff5e33b1845a0541e9718fc420';
                break;
            case Environment.PROD:
                secret = '2b16d20fe7bccf4875fce77ff95646d9';
                break;
            default:
                secret = '9c283fff5e33b1845a0541e9718fc420';
                break;
        }
        return secret;
    }


    onXiuMiPartnerBind(): void {

        if (this.operator.xiumiOpenId) {
            return;
        }

        const partner_user_id = this.operator.id.toString();
        const secret = this.xiumiSecret;
        const timestamp = (Date.now() / 1000).toFixed(0);
        const nonce = this.nonce();

        const data: string[] = [partner_user_id, secret, timestamp, nonce];
        data.sort();
        let cs = data[0] + data[1] + data[2] + data[3];
        console.log(cs);
        let signature = md5(md5(cs).toString()).toString();
        console.log(signature);

        const url = `https://xiumi.us/auth/partner/bind?signature=${signature}&timestamp=${timestamp}&nonce=${nonce}&partner_user_id=${partner_user_id}&appid=${this.xiumiAppId}`;

        if (this.electronService.isElectron) {
            this.electronService.shell?.openExternal(url);
        } else {
            window.open(url);
        }
    }

    nonce(): string {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
