import { Injectable } from '@angular/core';
import { HttpService } from "../api/http.service";
import { BaseService } from "../base/base.service";
import { ApiResponse, PagedResp } from "../api/types";
import { Observable } from "rxjs/internal/Observable";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { map } from "rxjs/operators";
import { Article, ArticleSearch } from "./article-types";
import { plainToInstance } from "class-transformer";

@Injectable({
    providedIn: 'root'
})
export class ArticleService extends BaseService {

    constructor(private http: HttpService) {
        super();
    }

    /**
     * 获取资讯列表
     * @param search 搜索参数
     */
    list(search: ArticleSearch): Observable<PagedResp<Article>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.title)) {
            params = params.set('title', search.title.toString());
        }
        return this.http.get<ApiResponse<PagedResp<Article>>>(this.articleUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 获取文章詳情
     * @param id 文章ID
     */
    info(id: number): Observable<Article> {
        return this.http.get<ApiResponse<Article>>(this.articleUrl + '/info/' + id, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(Article, data.body.data);
        }))
    }

}
