import { Component, Input, OnInit } from '@angular/core';
import { MedicalOptions, Product } from "../../api/types";
import { ProductService } from "../product.service";
import { MedicalRegionPipe } from "../../shared/pipes/medical-region.pipe";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { MetadataService } from "../../shared/services/metadata.service";
import { MedicalLevelPipe } from "../../shared/pipes/medical-level.pipe";
import { SupplementaryBenefitPipe } from "../../shared/pipes/supplementary-benefit.pipe";
import { isNullOrUndefined } from "../../common/utils";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
    selector: 'app-product-product-medical-options',
    templateUrl: './product-product-medical-options.component.html',
    styleUrls: ['./product-product-medical-options.component.less']
})
export class ProductProductMedicalOptionsComponent implements OnInit {

    @Input() product: Product

    editing: boolean = false
    loading: boolean = false

    medicalRegionOptions: PropertySelectOption[] = []
    medicalLevelOptions: PropertySelectOption[] = []
    supplementaryBenefitOptions: PropertySelectOption[] = []

    constructor(
        private productService: ProductService,
        private metadataService: MetadataService,
        private medicalRegionPipe: MedicalRegionPipe,
        private medicalLevelPipe: MedicalLevelPipe,
        private supplementaryBenefitPipe: SupplementaryBenefitPipe,
        private notification: NzNotificationService) {

    }

    ngOnInit(): void {
        this.metadataService.medicalRegions()
            .subscribe(
                data => {
                    data.forEach(region => {
                        this.medicalRegionOptions?.push(new PropertySelectOption(this.medicalRegionPipe.transform(region), region));
                    })
                },
                error => {
                });
        this.metadataService.medicalLevels()
            .subscribe(
                data => {
                    data.forEach(level => {
                        this.medicalLevelOptions?.push(new PropertySelectOption(this.medicalLevelPipe.transform(level), level));
                    })
                },
                error => {
                });
        this.metadataService.supplementaryBenefits()
            .subscribe(
                data => {
                    data.forEach(supplementaryBenefit => {
                        this.supplementaryBenefitOptions?.push(new PropertySelectOption(this.supplementaryBenefitPipe.transform(supplementaryBenefit), supplementaryBenefit));
                    })
                },
                error => {
                });
    }

    get medicalOptions(): MedicalOptions {
        if (isNullOrUndefined(this.product.medicalOptions)) {
            this.product.medicalOptions = new MedicalOptions();
        }
        return this.product.medicalOptions;
    }

    set medicalOptions(val) {
        this.product.medicalOptions = val;
    }

    get medicalRegionNames(): string[] {

        if (isNullOrUndefined(this.medicalOptions)) return [];
        if (isNullOrUndefined(this.medicalOptions.regions)) return [];

        return this.medicalOptions.regions.map(region => {
            return this.medicalRegionPipe.transform(region)
        });
    }

    get medicalLevelNames(): string[] {

        if (isNullOrUndefined(this.medicalOptions)) return [];
        if (isNullOrUndefined(this.medicalOptions.levels)) return [];

        return this.medicalOptions.levels.map(level => {
            return this.medicalLevelPipe.transform(level)
        });
    }

    get supplementaryBenefitNames(): string[] {

        if (isNullOrUndefined(this.medicalOptions)) return [];
        if (isNullOrUndefined(this.medicalOptions.supplementaryBenefits)) return [];

        return this.medicalOptions.supplementaryBenefits.map(level => {
            return this.supplementaryBenefitPipe.transform(level)
        });
    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    onSave(): void {
        this.loading = true;
        let code = this.product.code;
        let mergedAmounts = {};
        if (this.product.medicalOptions && this.product.medicalOptions.deductibles?.length) {
            this.product.medicalOptions.deductibles.forEach(item => {
                if (!mergedAmounts[item.currency]) {
                    mergedAmounts[item.currency] = new Set(item.amounts);
                } else {
                    item.amounts.forEach(amount => mergedAmounts[item.currency].add(amount));
                }
            });
            for (let currency in mergedAmounts) {
                mergedAmounts[currency] = Array.from(mergedAmounts[currency]);
            }
            let mergedAmountsArray = Object.entries(mergedAmounts).map(([currency, amounts]) => ({
                currency,
                amounts
            }));
            this.product.medicalOptions.deductibles = JSON.parse(JSON.stringify(mergedAmountsArray))
        }

        this.productService.productMedicalOptionsUpdate(code, this.product.medicalOptions)
            .subscribe(
                data => {
                    this.notification.success('更新成功', '');
                    this.editing = false;
                    this.loading = false;
                },
                error => {
                    this.notification.error('更新失败', '');
                    this.loading = false;
                });
    }
}
