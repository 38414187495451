import { Component, Input, OnInit } from '@angular/core';
import { TicketService } from '../ticket.service';
import { TicketDetailResp, TicketReply } from '../ticket-types';
import { Material, MaterialModifyReq, MaterialUploadReq } from '../../api/types';
import { isNullOrUndefined } from '../../common/utils';

@Component({
    selector: 'app-ticket-info',
    templateUrl: './ticket-info.component.html',
    styleUrls: ['./ticket-info.component.less']
})
export class TicketInfoComponent implements OnInit {

    @Input()
    id: number;
    isSpinning = false;
    spinning = false;

    repliesData: TicketDetailResp = new TicketDetailResp();

    reply: TicketReply = new TicketReply();

    constructor(private ticketService: TicketService) {
    }

    ngOnInit(): void {
        this.getTicketInfo()
    }

    getTicketInfo(): void {
        this.isSpinning = true;
        this.ticketService.detail(this.id)
            .subscribe(x => {
                    console.log(x);
                    this.repliesData = x;
                    this.isSpinning = false;
                },
                error => {
                    this.isSpinning = false;
                },
            );

    }


    onReply(): void {
        this.reply.ticketId = this.id;
        this.reply.role = 'CENTRE';
        if (isNullOrUndefined(this.reply.content)) {
            return;
        }
        this.isSpinning = true;
        this.ticketService.reply(this.reply)
            .subscribe(x => {
                    console.log(x);
                    this.reply.content = null;
                    this.reply.files = [];
                    this.getTicketInfo()
                },
                error => {
                },
            );

    }

    onFilesChange(files: Material[]) {
        this.reply.files = files;
    }


    onFileModified(materialModifyReq: MaterialModifyReq) {
        this.reply.files[materialModifyReq.index] = materialModifyReq.file;
    }

    get uploadReq(): MaterialUploadReq {
        return MaterialUploadReq.fromTicket(this.id);
    }

}
