import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * 防多重点击
 */
@Directive({
  selector: '[appThrottleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {

  @Input()
  throttleTime = 1500;

  @Output()
  throttleClick = new EventEmitter<any>();

  private clicks = new Subject<any>();

  private subscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      // .pipe(debounceTime(this.debounceTime))
      .subscribe(e => this.throttleClick.emit(e));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
