import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.less']
})
export class ContentHeaderComponent implements OnInit {

    @Input() module: string = "MODULE"
    @Input() moduleName: string = ""

    constructor() {
    }

    ngOnInit(): void {
    }

}
