<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!ticketList?.list?.length">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="ticketList?.list?.length" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="ticketList.list"
                      [nzTotal]="ticketList.list.length"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">工单列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onSearch(1)">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; justify-content: flex-end;">
                                <a nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-tooltip [nzTooltipTitle]="'ContactSearch' | translate"
                                        nz-popover [nzPopoverContent]="searchTemplate"
                                        [(nzPopoverVisible)]="searchPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        style=" margin-right: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </a>
                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ ticketList.totalCount }}</span> 条工单
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th>工单号</th>
                    <th>标题</th>
                    <th>状态</th>
                    <th>创建时间</th>
                    <th>最后回复时间</th>
                    <th nzRight><span>操作</span></th>
                </tr>
                </thead>
                <tbody>
<!--                -->
                <tr *ngFor="let item of ticketList.list; let i = index"
                    appThrottleClick (throttleClick)="onTicketInfo(item)">
                    <td>{{ item.id }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.status | ticketStatus}}</td>
                    <td>{{ item.createTime }}</td>
                    <td>{{ item.lastReplyTime }}</td>
                    <td nzRight>
                        <div>
                            <a *ngIf="item.status !== 'CLOSED'" nz-button nzType="link" appThrottleClick
                               (throttleClick)="onClose(item.id);$event.stopPropagation();"
                               nz-tooltip [nzTooltipTitle]="'关闭工单'"
                               nzSize="small" class="delete-button">
                                <em nz-icon nzType="stop" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

        </div>
    </nz-spin>
</div>

<ng-template #searchTemplate>
    <div style="width: 300px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'工单搜索'}}</div>
        <div class="property-bottom-line-blue"></div>

        <app-property-text-edit [title]="'工单号'" [(value)]="search.id"></app-property-text-edit>
        <app-property-select-edit [title]="'状态'" [(value)]="search.status"
                                  [options]="statusOptions"></app-property-select-edit>

        <div>
            <div class="property-title">创建时间</div>
            <nz-range-picker [(ngModel)]="createDate" [nzFormat]="'yyyy-MM-dd'"
                            (ngModelChange)="onDateChange($event, 'startCreateDate')"
                            style="width: 100%;"
                            nzBorderless></nz-range-picker>
        </div>

        <div>
            <div class="property-title">最后回复时间</div>
            <nz-range-picker [(ngModel)]="replyDate" [nzFormat]="'yyyy-MM-dd'"
                            (ngModelChange)="onDateChange($event, 'endReplyDate')"
                            style="width: 100%;"
                            nzBorderless></nz-range-picker>
        </div>


        <!-- 名字 -->
        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
                    <span>{{'重置'}}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
                    <span>{{'搜索'}}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
