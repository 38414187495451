import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyStatus'
})
export class CompanyStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
      let companyStatus = "";
      switch (value) {
          case 'ENABLE':
              companyStatus = "啟用";
              break;
          case 'DISABLE':
              companyStatus = "禁用";
              break;
      }
      return companyStatus;
  }

}
