import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemComponent } from './system.component';
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { FileUploadModule } from "ng2-file-upload";
import { EditorModule } from "@tinymce/tinymce-angular";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { SystemConsoleComponent } from "./console/system-console.component";
import { SystemOtherComponent } from './other/system-other.component';


@NgModule({
  declarations: [SystemComponent, SystemConsoleComponent, SystemOtherComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        FileUploadModule,
        EditorModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        NzSpinModule,
        NzTableModule,
        NzDrawerModule,
        NzAffixModule,
        NzSkeletonModule,
        NzStatisticModule,
        NzSelectModule,
        NzEmptyModule,
        NzGridModule,
        NzTabsModule,
        NzListModule,
        NzSwitchModule,
        NzTagModule,
        NzToolTipModule
    ]
})
export class SystemModule { }
