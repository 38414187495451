import {Component, Input, OnInit} from '@angular/core';
import {NzSelectModeType} from "ng-zorro-antd/select";
import {PropertySelectOption} from "./property-select.component";

@Component({
    selector: 'app-property-select-display',
    templateUrl: './property-select-display.component.html',
    styleUrls: ['./property-select-display.component.less']
})
export class PropertySelectDisplayComponent implements OnInit {

    @Input() mode: NzSelectModeType = "default";
    @Input() title: string;
    @Input() value: any | any[];
    // @Input() name: string | string[];                          /// 用于显示
    @Input() options: PropertySelectOption[];
    @Input() required: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    sliceTagName(tag: string): string {
        if(!tag) {
            return '';
        }
        const isLongTag = tag.length > 20;
        return isLongTag ? `${tag.slice(0, 20)}...` : tag;
    }

    toLabel(v: any) {
        let ret = "N/A"
        this.options.forEach(
            option => {
                if (option.value == v) {
                    ret = option.label
                }
            }
        )
        return ret
    }

    get name() {
        switch (this.mode) {
            case 'default':
                return this.toLabel(this.value)
            case 'multiple':
                break;
        }
        return "N/A";
    }

}
