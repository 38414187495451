<div *ngIf="!categories">
    <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
</div>
<div *ngIf="categories" style="margin-top: 30px; background-color: white">
    <ul nz-list [nzDataSource]="categories" nzBordered nzSize="large">
        <nz-list-header><span style="font-size: 18px">保险类别列表</span></nz-list-header>
        <nz-list-item *ngFor="let category of categories" appThrottleClick (throttleClick)="openInfoDrawer(category)">
            <nz-list-item-meta>
                <!--<img width="100" alt="logo" [src]="item.logo" />-->
                <nz-list-item-meta-avatar [nzSrc]="category.logo"></nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                    {{ category.name }}
                </nz-list-item-meta-title>
                <nz-list-item-meta-description>
                    {{ category.code }}
                </nz-list-item-meta-description>
            </nz-list-item-meta>
            <!--            {{ item.content }}-->
            <ul nz-list-item-actions>
            </ul>
            <nz-list-item-extra>{{ category.desc }}</nz-list-item-extra>
        </nz-list-item>
    </ul>
</div>

<nz-drawer [nzVisible]="showInfoDrawer" [nzMask]="true" [nzWidth]="650" [nzClosable]="true"
           (nzOnClose)="closeInfoDrawer()">
    <ng-container *nzDrawerContent>
        <app-product-category-info #category></app-product-category-info>
    </ng-container>
</nz-drawer>
