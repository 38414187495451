import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from "../../shared/services/data.service";
import {Instance, InstanceProductDelete, InstanceProductSync, ProcessLog, Product} from "../../api/types";
import {ProcessLogListComponent} from "../../shared/components/process-log/process-log-list.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {InstanceSelectionComponent} from "../../shared/components/instance-selection/instance-selection.component";
import {InstanceService} from "../../shared/services/instance.service";
import {isNullOrUndefined} from "../../common/utils";
import {ProductSelectionComponent} from "../components/product-selection/product-selection.component";
import * as _ from "lodash";
import {NzModalService} from "ng-zorro-antd/modal";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-product-instance-sync',
    templateUrl: './product-instance-sync.component.html',
    styleUrls: ['./product-instance-sync.component.less']
})
export class ProductInstanceSyncComponent implements OnInit, AfterViewInit {

    productsToSync: Product[] = [];

    instances: Instance[] = [];

    constructor(private dataService: DataService,
                private instanceService: InstanceService,
                private modalService: NzModalService,
                private toastrService: ToastrService,
                private drawerService: NzDrawerService) {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {

        this.productsToSync = _.uniqWith(this.dataService.productsToSync, _.isEqual)


        this.instanceService.instances()
            .subscribe(
                data => {
                    this.instances = data;
                },
                error => {
                });

    }

    onProductSelection(): void {
        const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
            nzWidth: 800,
            nzContent: ProductSelectionComponent,
            nzContentParams: {
                mode: 'multiple'
            }
        });
        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.setAddedProducts(this.productsToSync);
            component.productsSelected.subscribe(
                products => {
                    this.onProductsSelected(products);
                }
            )
        });
        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    openInstanceSelectionDrawer(): void {
        const drawerRef = this.drawerService.create<InstanceSelectionComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: InstanceSelectionComponent,
            nzContentParams: {
                // logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    checkInstance(instance: Instance) {
        instance.checked = !instance.checked;
    }

    onCheckAllInstance(checked: boolean) {
        for (let instance of this.instances) {
            instance.checked = checked;
        }
    }

    get instanceToSync(): Instance[] {
        let array: Instance[] = []
        if (!isNullOrUndefined(this.instances)) {
            array = this.instances.filter(instance => {
                return instance.checked
            })
        }
        return array;
    }

    onSync() {
        let req = new InstanceProductSync();
        req.productCodes = this.productsToSync
            // .filter(product => {return product.checked})
            .map(product => {
                return product.code;
            });

        req.instances = this.instanceToSync.map(instance => {
            return instance.name;
        })
        this.instanceService.productSync(req)
            .subscribe(
                data => {
                    this.openLogsDrawer(data);
                },
                error => {
                });

    }


    onDelete() {
        this.modalService.confirm({
            nzTitle: '是否确定删除產品？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎操作</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                let req = new InstanceProductDelete();
                req.productCodes = this.productsToSync
                    // .filter(product => {
                    //     return product.checked
                    // })
                    .map(product => {
                        return product.code;
                    })
                req.instances = this.instanceToSync.map(instance => {
                    return instance.name;
                })
                this.instanceService.productDelete(req)
                    .subscribe(
                        data => {
                            this.openLogsDrawer(data);
                        },
                        error => {
                        });
            },
            nzOnCancel: () => {
            }
        });

    }

    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    onProductsSelected(products: Product[]) {
        // this.showProductSelectionDrawer = false;
        products.forEach(p => {
            this.productsToSync?.push(p);
        });
        this.productsToSync = _.uniqWith(this.productsToSync, _.isEqual);
        this.dataService.productsToSync = this.productsToSync;
    }

    onProductDeleted(index: number) {
        if (isNullOrUndefined(this.productsToSync) || this.productsToSync.length === 0) return;
        this.productsToSync.splice(index, 1);
        this.dataService.productsToSync = this.productsToSync;
    }

    onClear() {
        this.productsToSync = [];
        this.dataService.productsToSync = this.productsToSync;
    }

}
