<nz-row [nzGutter]="16">
    <div class="knowledge-menu">
        <div style="flex: 1">
            <span style="font-size: 20px; font-weight: bolder; margin-left: 15px">熱門產品</span>
        </div>

        <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip nzTooltipTitle="添加知識文章"
                style=" margin-left: 10px;" appThrottleClick (throttleClick)="onCreate()">
            <i nz-icon nzType="plus" nzTheme="outline"
               style="color: #07367c; font-size: 20px;"></i>
        </button>

        <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
               (change)="selectedImportFileOnChanged($event)"/>

        <button nz-button nzType="default" nzSize="large" nzShape="circle"
                appThrottleClick (throttleClick)="selectImportFile()"
                style="margin-left: 10px; margin-right: 10px">
            <i nz-icon nzType="cloud-upload" nzTheme="outline" nz-tooltip nzTooltipTitle="上傳产品详解知識庫"
               style="color: #07367c; font-size: 20px;"></i>
        </button>
    </div>
</nz-row>

<ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
<nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

    <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="6">
            <div *ngIf="!companies">
                <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
            </div>
            <div *ngIf="companies" style="margin-top: 15px">
                <nz-table [nzBordered]="true"
                          [nzShowPagination]="false"
                          [nzLoading]="loadingCompanies"
                          [nzData]="companies">
                    <thead>
                    </thead>
                    <tbody>
                    <tr *ngFor="let company of companies" appThrottleClick (throttleClick)="switchCompany(company)">
                        <td style="text-align: center;"
                            [ngStyle]="{'font-weight':company.code === selectedCompany.code ? 'bold' : 'normal' }">
                            <span>{{ company.name }}</span>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </div>
        </nz-col>
        <nz-col [nzSpan]="18">
            <div *ngIf="knowledges" style="margin-top: 15px">
                <div style="margin-bottom: 20px; font-size: 30px">
                    <span style="border-right: #2C7BE5 4px solid; margin-right: 10px; font-size: 25px"> </span>
                    <span style="">{{selectedCompany.name}}</span>
                </div>

                <div *ngIf="loadingKnowledge">
                    <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
                </div>
                <div *ngIf="!loadingKnowledge">
                    <nz-list nzItemLayout="vertical" [nzBordered]="true">
                        <nz-list-item style="background-color: white" *ngFor="let knowledge of knowledges.list" appThrottleClick (throttleClick)="openInfo(knowledge)">
                            <nz-list-item-meta>
                                <!--                        <nz-list-item-meta-avatar [nzSrc]="knowledge.cover"></nz-list-item-meta-avatar>-->
                                <nz-list-item-meta-title>
                                    {{ knowledge.title }}
                                </nz-list-item-meta-title>
                                <nz-list-item-meta-description>
                            <span *ngFor="let tag of knowledge.tags">
                                <nz-tag>{{tag}}</nz-tag>
                            </span>
                                </nz-list-item-meta-description>
                            </nz-list-item-meta>
                            {{ knowledge.issueDate | date:'yyyy-MM-dd' }}
                            <ul nz-list-item-actions>
                                <nz-list-item-action><i nz-icon nzType="eye"
                                                        style="margin-right: 8px;"></i> {{knowledge.visits}}
                                </nz-list-item-action>
                                <nz-list-item-action><i nz-icon nzType="share-alt"
                                                        style="margin-right: 8px;"></i> {{knowledge.shares}}
                                </nz-list-item-action>
                            </ul>
                            <nz-list-item-extra>
                                <img width="100" alt="cover"
                                     [src]="knowledge.cover || './assets/images/default_image.svg'"/>
                            </nz-list-item-extra>
                        </nz-list-item>
                    </nz-list>
                </div>
            </div>
        </nz-col>
    </nz-row>
</nz-spin>
<nz-drawer [nzVisible]="showInfoDrawer" [nzMask]="true" [nzWidth]="800" [nzClosable]="true"
           (nzOnClose)="closeProfile()">
    <ng-container *nzDrawerContent>
        <app-knowledge-info #knowledge></app-knowledge-info>
    </ng-container>
</nz-drawer>
