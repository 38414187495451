<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <!--        <div *ngIf="productStatistic">-->
        <!--            <nz-row [nzGutter]="16">-->
        <!--                <nz-col [nzSpan]="8">-->
        <!--                    <nz-statistic [nzValue]="(productStatistic.productNumber | number)"-->
        <!--                                  [nzTitle]="'保險產品'"></nz-statistic>-->
        <!--                </nz-col>-->
        <!--                <nz-col [nzSpan]="8">-->
        <!--                    <nz-statistic [nzValue]="(productStatistic.companyNumber | number)"-->
        <!--                                  [nzTitle]="'保險公司'"></nz-statistic>-->
        <!--                </nz-col>-->
        <!--                <nz-col [nzSpan]="8">-->
        <!--                    <nz-statistic [nzValue]="(productStatistic.categoryNumber | number)"-->
        <!--                                  [nzTitle]="'保險種類'"></nz-statistic>-->
        <!--                </nz-col>-->
        <!--            </nz-row>-->
        <!--        </div>-->

        <div *ngIf="!groups">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="groups" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="groups.list"
                      [nzPageIndex]="groups.pageNum"
                      [nzPageSize]="groups.pageSize"
                      [nzTotal]="groups.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '1150px'}">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">分组列表</div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
                                       (change)="selectedImportFileOnChanged($event)"/>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip nzTooltipTitle="上傳對比資料"
                                        appThrottleClick (throttleClick)="selectImportFile()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-upload" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

<!--                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip nzTooltipTitle="產品搜索"-->
<!--                                        appThrottleClick (throttleClick)="openSearchDrawer()"-->
<!--                                        style=" margin-left: 10px;">-->
<!--                                    <i nz-icon nzType="search" nzTheme="outline"-->
<!--                                       style="color: #07367c; font-size: 20px;"></i>-->
<!--                                </button>-->

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ groups.totalCount }}</span> 個分组
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th>分组KEY</th>
                    <th><span nz-tooltip nzTooltipTitle="分组名稱">分组名稱</span></th>
                    <th><span nz-tooltip nzTooltipTitle="國際化名稱">國際化名稱</span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let group of groups.list; let i = index">
                    <td>{{ group.key }}</td>
                    <td nzBreakWord><span nz-tooltip [nzTooltipTitle]="group.name">{{ group.name }}</span></td>
                    <td>
                        <div *ngIf="group.nameI18n">
                            <div>繁體：{{group.nameI18n.zhHantHK}}</div>
                            <div>中文：{{group.nameI18n.zhHansCN}}</div>
                            <div>英文：{{group.nameI18n.enUS}}</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>

    </nz-spin>

</div>

<!-- profile drawer -->
<!--<nz-drawer [nzVisible]="showInfoDrawer" [nzMask]="true" [nzWidth]="650" [nzClosable]="true"-->
<!--           (nzOnClose)="closeInfoDrawer()">-->
<!--    <app-product-product-info #product></app-product-product-info>-->
<!--</nz-drawer>-->

<!--<nz-drawer [nzVisible]="showSearchDrawer" [nzMask]="true" [nzWidth]="520" [nzClosable]="true"-->
<!--           (nzOnClose)="closeSearchDrawer()">-->
<!--    <app-product-search #search (productSearch)="onProductSearch($event)"></app-product-search>-->
<!--</nz-drawer>-->
