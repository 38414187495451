<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!customerList.length">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="customerList.length" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="false"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="customerList"
                      [nzTotal]="customerList.length"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">客戶列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onCustomerList()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ customerList.length }}</span> 個客戶
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft style="text-align: center">
                        <input type="checkbox" ngModel (ngModelChange)="checkAllEvent($event)"
                               (click)="$event.stopPropagation();" class="check-box"/>
                    </th>
                    <th nzLeft>客户名称</th>
                    <th nzLeft>備注</th>
<!--                    <th nzRight>-->
<!--                        <span>操作</span>-->
<!--                    </th>-->
                    <!--<th style="text-align: center">操作</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of customerList; let i = index"
                    appThrottleClick (throttleClick)="onProductInfo(item.name)">
                    <td nzLeft style="text-align: center" (click)="$event.stopPropagation();">
                        <input type="checkbox" [(ngModel)]="item.checked" (click)="$event.stopPropagation();"
                               class="check-box">
                    </td>
                    <td nzLeft>{{ item.name }}</td>
                    <td nzLeft>{{ item.remark }}</td>
<!--                    <td nzRight>-->
<!--                        <div>-->
<!--                            <a nz-button nzType="link"-->
<!--                               nzSize="small" class="delete-button">-->
<!--                                <em nz-icon nzType="delete" nzTheme="outline"></em>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </td>-->
                </tr>
                </tbody>
            </nz-table>

            <nz-affix [nzOffsetBottom]="40" *ngIf="selectedProducts.length > 0">
                <div class="product-sync-container">
                    <div class="product-sync-wrapper">
                        <i nz-icon nzType="check-circle" nzTheme="outline"
                           style="font-size: 20px;"></i>
                        <span style="margin-left: 5px;">已選</span>
                        <span
                            style="margin-left: 5px; margin-right: 5px; color: #f3e282; font-size: 25px">{{selectedProducts.length}}</span>
                        <span>個客戶</span>
                        <button style="margin-left: 20px;" class="product-sync-button" nz-button nzType="primary"
                                nzShape="round" appThrottleClick (throttleClick)="onProductInstanceSync()">
                            通知客戶上報
                        </button>
                    </div>
                </div>
            </nz-affix>

        </div>

    </nz-spin>

</div>
