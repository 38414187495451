import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { ProductCompaniesComponent } from "./company/product-companies.component";
import { ProductProductsComponent } from "./product/product-products.component";
import { ProductCategoriesComponent } from "./category/product-categories.component";
import { ProductCompanyInfoComponent } from "./company/product-company-info.component";
import { ProductProductInfoComponent } from "./product/product-product-info.component";
import { ProductPropertyDetailComponent } from './property/product-property-detail.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { ProductTagListComponent } from './tag/product-tag-list.component';
import { FileUploadModule } from "ng2-file-upload";
import { ProductProductMedicalOptionsComponent } from './product/product-product-medical-options.component';
import { DeductiblesComponent } from './components/deductible/deductibles.component';
import { DeductibleComponent } from './components/deductible/deductible.component';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTagModule } from "ng-zorro-antd/tag";
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { CompanySelectionComponent } from './components/company-selection/company-selection.component';
import { ProductInstanceSyncComponent } from './product/product-instance-sync.component';
import { CompanyInstanceSyncComponent } from './company/company-instance-sync.component';
import { ProductHomeComponent } from './product/product-home.component';
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ProductProductCreateComponent } from './product/product-product-create.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { ProductCategoryInfoComponent } from './category/product-category-info.component';
import { ProductCompanyCreateComponent } from './company/product-company-create.component';
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { ProductRestrictionsComponent } from './restriction/product-restrictions.component';
import { ProductRestrictionSearchComponent } from './restriction/product-restriction-search.component';
import { ProductRestrictionCreateComponent } from './restriction/product-restriction-create.component';
import { ProductPropertyHomeComponent } from './property/product-property-home.component';
import { ProductPropertyGroupsComponent } from './property/product-property-groups.component';
import { ProductProductFeaturesComponent } from './product/product-product-features.component';
import { ProductRestrictionInfoComponent } from './restriction/product-restriction-info.component';
import { ProductMasterRateDetailComponent } from './contract/product-master-rate-detail.component';
import { PremiumTableImportComponent } from './components/premium-table-import/premium-table-import.component';
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from "ng-zorro-antd/input";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { CompanyCreditRatingEditorComponent } from './components/company-credit-rating-editor/company-credit-rating-editor.component';

@NgModule({
    declarations: [
        ProductComponent,
        ProductCompaniesComponent,
        ProductCompanyInfoComponent,
        ProductCategoriesComponent,
        ProductProductsComponent,
        ProductProductInfoComponent,
        ProductPropertyDetailComponent,
        ProductSearchComponent,
        CompanySearchComponent,
        ProductTagListComponent,
        ProductProductMedicalOptionsComponent,
        DeductiblesComponent,
        DeductibleComponent,
        ProductSelectionComponent,
        ProductInstanceSyncComponent,
        ProductHomeComponent,
        ProductProductCreateComponent,
        ProductCategoryInfoComponent,
        ProductCompanyCreateComponent,
        ProductRestrictionsComponent,
        ProductRestrictionSearchComponent,
        ProductRestrictionCreateComponent,
        CompanyInstanceSyncComponent,
        CompanySelectionComponent,
        ProductPropertyHomeComponent,
        ProductPropertyGroupsComponent,
        ProductProductFeaturesComponent,
        ProductRestrictionInfoComponent,
        ProductMasterRateDetailComponent,
        PremiumTableImportComponent,
        CompanyCreditRatingEditorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        FileUploadModule,
        EditorModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        NzSpinModule,
        NzTableModule,
        NzDrawerModule,
        NzAffixModule,
        NzSkeletonModule,
        NzStatisticModule,
        NzSelectModule,
        NzEmptyModule,
        NzGridModule,
        NzTabsModule,
        NzListModule,
        NzSwitchModule,
        NzTagModule,
        NzDividerModule,
        NzSpaceModule,
        NzToolTipModule,
        NzDropDownModule,
        NzCheckboxModule,
        NzModalModule,
        NzInputModule,
        NzDatePickerModule,
        NzPopoverModule
    ],
    exports: [
        ProductSearchComponent
    ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ]
})
export class ProductModule {
}
