import { Injectable, OnInit } from '@angular/core';
import { HttpService } from "../../api/http.service";
import { BaseService } from "../../base/base.service";
import { Observable } from "rxjs/internal/Observable";
import {
    ApiResponse,
    Instance,
    InstanceInformationSync,
    InstanceProductSync,
    InstanceCompanySync,
    Operator,
    ProcessLog,
    InstanceProductDelete,
    InstanceCompanyDelete,
    ProductTagSearch,
    PagedResp,
    ProductTag,
    InstanceInformationDelete
} from "../../api/types";
import { API } from "../../api/api";
import { map } from "rxjs/operators";
import { HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from '../../common/utils';
import { BelongingInstance, InstanceProduct } from '../../customer/customer-types';

@Injectable({
    providedIn: 'root'
})
export class InstanceService extends BaseService implements OnInit {

    _instances: Instance[] = [];

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    instances(): Observable<Instance[]> {
        return this.http.get<ApiResponse<Instance[]>>(this.instanceUrl + '/all', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            this._instances = data.body.data;
            return this._instances
        }))
    }

    productSync(body: InstanceProductSync): Observable<ProcessLog[]> {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/product-sync', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    productDelete(body: InstanceProductDelete): Observable<ProcessLog[]> {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/product-delete', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    companySync(body: InstanceCompanySync): Observable<ProcessLog[]> {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/company-sync', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    companyDelete(body: InstanceCompanyDelete) {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/company-delete', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    informationSync(body: InstanceInformationSync): Observable<ProcessLog[]> {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/information-sync', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    informationDelete(body: InstanceInformationDelete): Observable<ProcessLog[]> {
        return this.http.post<ApiResponse<ProcessLog[]>>(this.instanceUrl + '/information-delete', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    /*
        body -> 客戶name組成的數組，全選傳空數組
     */
    productReport(body: any): Observable<any> {
        return this.http.post<ApiResponse<any>>(this.instanceUrl + '/product-report', body, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    instanceProduct(search: BelongingInstance): Observable<PagedResp<InstanceProduct[]>> {
        let params = new HttpParams();
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.instance)) {
            params = params.set('instance', search.instance.toString());
        }
        if (!isNullOrUndefined(search.name)) {
            params = params.set('name', search.name.toString());
        }
        return this.http.get<ApiResponse<PagedResp<InstanceProduct[]>>>(this.productUrl + '/list-by-instance', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

}
