import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorComponent } from './operator.component'
import { ProductModule } from "../product/product.module";
import { KnowledgeModule } from "../knowledge/knowledge.module";
import { FinancialPlanModule } from "../financial-plan/financial-plan.module";
import { InformationModule } from "../information/information.module";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzTagModule } from "ng-zorro-antd/tag";
import { ProposalModule } from "../proposal/proposal.module";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";


@NgModule({
    declarations: [OperatorComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        ProductModule,
        KnowledgeModule,
        ProposalModule,
        FinancialPlanModule,
        InformationModule,
        NzTagModule,
        RouterModule,
        SharedModule
    ],
})
export class OperatorModule {
}
