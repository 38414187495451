import { Injectable } from '@angular/core';
import { SwUpdate } from "@angular/service-worker";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class PwaService {

    constructor(private swUpdate: SwUpdate,
                private toastr: ToastrService) {
        swUpdate.available.subscribe(event => {
            toastr.info('远端页面有更新，请刷新页面，以使用最新功能', '页面更新');
            location.reload();
        });
    }
}
