import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KnowledgeService} from "./knowledge.service";
import {Knowledge} from "../api/types";
import {PropertyTextEditType} from "../shared/components/property-text/property-text-edit.component";
import {ArticleSelectionComponent} from "../article/article-selection/article-selection.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {PropertySelectOption} from "../shared/components/property-select/property-select.component";
import {ProductService} from "../product/product.service";
import {InformationService} from "../information/information.service";

@Component({
    selector: 'app-knowledge-info',
    templateUrl: './knowledge-info.component.html',
    styleUrls: ['./knowledge-info.component.less']
})
export class KnowledgeInfoComponent implements OnInit {

    @Input() knowledge: Knowledge;
    @Input() editing: boolean = false;
    @Input() loading: boolean = false;

    typeOptions: PropertySelectOption[] = [];
    companyOptions: PropertySelectOption[] = [];
    productOptions: PropertySelectOption[] = [];
    tagsOptions: PropertySelectOption[] = []

    contentTextType = PropertyTextEditType.TEXT_HTML;

    @Output()
    knowledgeUpdated: EventEmitter<Knowledge> = new EventEmitter<Knowledge>();

    constructor(private knowledgeService: KnowledgeService,
                private drawerService: NzDrawerService,
                private productService: ProductService,
                private informationService: InformationService,
    ) {
    }

    ngOnInit(): void {
        this.knowledgeService.types()
            .subscribe(
                types => {
                    for (let type of types) {
                        this.typeOptions?.push(new PropertySelectOption(type.name, type.id));
                    }
                },
                error => {
                });

        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });

        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });

    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    onSave(): void {
        this.loading = true;
        this.knowledgeService.update(this.knowledge)
            .subscribe(
                data => {
                    this.editing = false;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }

    onImportFromArticle(): void {
        const drawerRef = this.drawerService.create<ArticleSelectionComponent, { value: any }, string>({
            nzWidth: 1024,
            nzContent: ArticleSelectionComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.drawerRef = drawerRef;
            component.articleSelected
                .subscribe(
                    article => {
                        this.knowledge.title = article.title;
                        this.knowledge.cover = article.cover;
                        this.knowledge.content = article.content;
                    })
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    typeChange(typeId: number): void {
    }

}
