<div class="property-title">自付额</div>
<!--<div *ngFor="let deductible of deductibles">-->
<!--    <app-deductible [deductible]="deductible" [editing]="editing"></app-deductible>-->
<!--</div>-->

<div *ngIf="deductibles && deductibles?.length > 0" style="margin-top: 15px">
    <nz-table
        nzSize="small"
        [nzTemplateMode]="true"
        [nzBordered]="false"
        [nzShowPagination]="false"
        [nzData]="deductibles">
        <thead>
        <tr >
            <th nzLeft nzWidth="100px">货币</th>
            <th>金额列表</th>
            <th nzWidth="60px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let deductible of deductibles; let i = index">
            <td nzLeft>
                <app-property-select [editable]="true" [editing]="editing" [name]="deductible.currency"
                                     [(value)]="deductible.currency" [options]="currencyOptions"></app-property-select>
            </td>
            <td>
                <app-property-tags [(value)]="deductible.amounts" [editable]="true"
                                   [editing]="editing"></app-property-tags>
            </td>
            <td>
                <a *ngIf="editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onDelete(i)" nzSize="small"  class="delete-button">
                    <em nz-icon nzType="delete" nzTheme="outline"></em>
                </a>
            </td>
        </tr>
        </tbody>
    </nz-table>
</div>
<a *ngIf="editing" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onAdd()" nzSize="small" style="margin-top: 20px">
    <em nz-icon nzType="plus" nzTheme="outline"></em>
    <span style="font-size: 11px">添加自付额</span>
</a>

