import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InformationSearch, PagedResp, ReleaseType, Information, Product} from "../../../api/types";
import {PropertySelectOption} from "../../../shared/components/property-select/property-select.component";
import { InformationService } from "../../information.service";
import {debounceTime} from "rxjs/operators";
import {isNullOrUndefined} from "../../../common/utils";

@Component({
  selector: 'app-information-selection',
  templateUrl: './information-selection.component.html',
  styleUrls: ['./information-selection.component.less']
})
export class InformationSelectionComponent implements OnInit {
    search: InformationSearch = new InformationSearch();
    loading = false;
    typeOptions: PropertySelectOption[] = []
    tagsOptions: PropertySelectOption[] = []
    informations: PagedResp<Information>;

    constructor(private informationService: InformationService) { }


    searchParamChange: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    informationsSelected: EventEmitter<Information[]> = new EventEmitter<Information[]>();

    ngOnInit(): void {
        // this.typeOptions?.push(new PropertySelectOption('全部', null));
        this.typeOptions?.push(new PropertySelectOption('行政通知', ReleaseType.ADMINISTRATION_NOTICE));
        this.typeOptions?.push(new PropertySelectOption('產品動態', ReleaseType.PRODUCT_NEWS));
        this.typeOptions?.push(new PropertySelectOption('一般資訊', ReleaseType.GENERAL_INFO));
        this.typeOptions?.push(new PropertySelectOption('產品優惠', ReleaseType.PRODUCT_PROMOTION));
        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });
        this.searchParamChange.pipe(debounceTime(500))
            .subscribe(
                data => {
                    this.onSearch(1);
                },
                error => {
                }
            )
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.informationService.list(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.informations = data;
                },
                error => {
                    this.loading = false;
                });
    }

    get selectedInformations() : Information[] {
        let information : Information[] = []
        if (!isNullOrUndefined(this.informations) && !isNullOrUndefined(this.informations.list)) {
            information = this.informations.list.filter(product => { return product.checked})
        }
        return information;
    }


    onSearchParamChange() {
        this.searchParamChange.emit("");
    }

    checkInformation(information: Information) {
        information.checked = !information.checked;
    }

    onConfirm() {
        this.informationsSelected.emit(this.selectedInformations);
    }
}
