import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.less']
})
export class TicketComponent extends BaseComponent implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
