import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContentTypesService {

    contentTypes: Map<string, string> = new Map();

    constructor() {
        this.contentTypes.set(".001", "application/x-001");
        this.contentTypes.set(".301", "application/x-301");
        this.contentTypes.set(".323", "text/h323");
        this.contentTypes.set(".906", "application/x-906");
        this.contentTypes.set(".907", "drawing/907");
        this.contentTypes.set(".a11", "application/x-a11");
        this.contentTypes.set(".acp", "audio/x-mei-aac");
        this.contentTypes.set(".ai", "application/postscript");
        this.contentTypes.set(".aif", "audio/aiff");
        this.contentTypes.set(".aifc", "audio/aiff");
        this.contentTypes.set(".aiff", "audio/aiff");
        this.contentTypes.set(".anv", "application/x-anv");
        this.contentTypes.set(".apk", "application/vnd.android.package-archive");
        this.contentTypes.set(".asa", "text/asa");
        this.contentTypes.set(".asf", "video/x-ms-asf");
        this.contentTypes.set(".asp", "text/asp");
        this.contentTypes.set(".asx", "video/x-ms-asf");
        this.contentTypes.set(".au", "audio/basic");
        this.contentTypes.set(".avi", "video/avi");
        this.contentTypes.set(".awf", "application/vnd.adobe.workflow");
        this.contentTypes.set(".biz", "text/xml");
        this.contentTypes.set(".bmp", "application/x-bmp");
        this.contentTypes.set(".bot", "application/x-bot");
        this.contentTypes.set(".c4t", "application/x-c4t");
        this.contentTypes.set(".c90", "application/x-c90");
        this.contentTypes.set(".cal", "application/x-cals");
        this.contentTypes.set(".cat", "application/vnd.ms-pki.seccat");
        this.contentTypes.set(".cdf", "application/x-netcdf");
        this.contentTypes.set(".cdr", "application/x-cdr");
        this.contentTypes.set(".cel", "application/x-cel");
        this.contentTypes.set(".cer", "application/x-x509-ca-cert");
        this.contentTypes.set(".cg4", "application/x-g4");
        this.contentTypes.set(".cgm", "application/x-cgm");
        this.contentTypes.set(".cit", "application/x-cit");
        this.contentTypes.set(".class", "java/*");
        this.contentTypes.set(".cml", "text/xml");
        this.contentTypes.set(".cmp", "application/x-cmp");
        this.contentTypes.set(".cmx", "application/x-cmx");
        this.contentTypes.set(".cot", "application/x-cot");
        this.contentTypes.set(".crl", "application/pkix-crl");
        this.contentTypes.set(".crt", "application/x-x509-ca-cert");
        this.contentTypes.set(".csi", "application/x-csi");
        this.contentTypes.set(".css", "text/css");
        this.contentTypes.set(".cut", "application/x-cut");
        this.contentTypes.set(".dbf", "application/x-dbf");
        this.contentTypes.set(".dbm", "application/x-dbm");
        this.contentTypes.set(".dbx", "application/x-dbx");
        this.contentTypes.set(".dcd", "text/xml");
        this.contentTypes.set(".dcx", "application/x-dcx");
        this.contentTypes.set(".der", "application/x-x509-ca-cert");
        this.contentTypes.set(".dgn", "application/x-dgn");
        this.contentTypes.set(".dib", "application/x-dib");
        this.contentTypes.set(".dll", "application/x-msdownload");
        this.contentTypes.set(".doc", "application/msword");
        this.contentTypes.set(".dot", "application/msword");
        this.contentTypes.set(".drw", "application/x-drw");
        this.contentTypes.set(".dtd", "text/xml");
        this.contentTypes.set(".dwf", "application/x-dwf");
        this.contentTypes.set(".dwg", "application/x-dwg");
        this.contentTypes.set(".dxb", "application/x-dxb");
        this.contentTypes.set(".dxf", "application/x-dxf");
        this.contentTypes.set(".edn", "application/vnd.adobe.edn");
        this.contentTypes.set(".emf", "application/x-emf");
        this.contentTypes.set(".eml", "message/rfc822");
        this.contentTypes.set(".ent", "text/xml");
        this.contentTypes.set(".epi", "application/x-epi");
        this.contentTypes.set(".eps", "application/x-ps");
        this.contentTypes.set(".exe", "application/x-msdownload");
        this.contentTypes.set(".etd", "application/x-ebx");
        this.contentTypes.set(".fdf", "application/vnd.fdf");
        this.contentTypes.set(".fax", "image/fax");
        this.contentTypes.set(".fo", "text/xml");
        this.contentTypes.set(".fif", "application/fractals");
        this.contentTypes.set(".frm", "application/x-frm");
        this.contentTypes.set(".g4", "application/x-g4");
        this.contentTypes.set(".gbr", "application/x-gbr");
        this.contentTypes.set(".gif", "image/gif");
        this.contentTypes.set(".gl2", "application/x-gl2");
        this.contentTypes.set(".gp4", "application/x-gp4");
        this.contentTypes.set(".hgl", "application/x-hgl");
        this.contentTypes.set(".hmr", "application/x-hmr");
        this.contentTypes.set(".hpg", "application/x-hpgl");
        this.contentTypes.set(".hpl", "application/x-hpl");
        this.contentTypes.set(".hqx", "application/mac-binhex40");
        this.contentTypes.set(".hrf", "application/x-hrf");
        this.contentTypes.set(".hta", "application/hta");
        this.contentTypes.set(".htc", "text/x-component");
        this.contentTypes.set(".htm", "text/html");
        this.contentTypes.set(".html", "text/html");
        this.contentTypes.set(".htt", "text/webviewhtml");
        this.contentTypes.set(".htx", "text/html");
        this.contentTypes.set(".icb", "application/x-icb");
        this.contentTypes.set(".ico", "image/x-icon");
        this.contentTypes.set(".iff", "application/x-iff");
        this.contentTypes.set(".ig4", "application/x-g4");
        this.contentTypes.set(".igs", "application/x-igs");
        this.contentTypes.set(".iii", "application/x-iphone");
        this.contentTypes.set(".img", "application/x-img");
        this.contentTypes.set(".ins", "application/x-internet-signup");
        this.contentTypes.set(".ipa", "application/vnd.iphone");
        this.contentTypes.set(".isp", "application/x-internet-signup");
        this.contentTypes.set(".ivf", "video/x-ivf");
        this.contentTypes.set(".java", "java/*");
        this.contentTypes.set(".jfif", "image/jpeg");
        this.contentTypes.set(".jpe", "image/jpeg");
        this.contentTypes.set(".jpeg", "image/jpeg");
        this.contentTypes.set(".jpg", "image/jpeg");
        this.contentTypes.set(".jsp", "text/html");
        this.contentTypes.set(".js", "application/x-javascript");
        this.contentTypes.set(".la1", "audio/x-liquid-file");
        this.contentTypes.set(".lar", "application/x-laplayer-reg");
        this.contentTypes.set(".latex", "application/x-latex");
        this.contentTypes.set(".lavs", "audio/x-liquid-secure");
        this.contentTypes.set(".lbm", "application/x-lbm");
        this.contentTypes.set(".lmsff", "audio/x-la-lms");
        this.contentTypes.set(".ls", "application/x-javascript");
        this.contentTypes.set(".ltr", "application/x-ltr");
        this.contentTypes.set(".m1v", "video/x-mpeg");
        this.contentTypes.set(".m2v", "video/x-mpeg");
        this.contentTypes.set(".m3u", "audio/mpegurl");
        this.contentTypes.set(".m4e", "video/mpeg4");
        this.contentTypes.set(".mac", "application/x-mac");
        this.contentTypes.set(".man", "application/x-troff-man");
        this.contentTypes.set(".math", "text/xml");
        this.contentTypes.set(".mdb", "application/msaccess");
        this.contentTypes.set(".mfp", "application/x-shockwave-flash");
        this.contentTypes.set(".mht", "message/rfc822");
        this.contentTypes.set(".mhtml", "message/rfc822");
        this.contentTypes.set(".mi", "application/x-mi");
        this.contentTypes.set(".mid", "audio/mid");
        this.contentTypes.set(".midi", "audio/mid");
        this.contentTypes.set(".mil", "application/x-mil");
        this.contentTypes.set(".mml", "text/xml");
        this.contentTypes.set(".mnd", "audio/x-musicnet-download");
        this.contentTypes.set(".mns", "audio/x-musicnet-stream");
        this.contentTypes.set(".mocha", "application/x-javascript");
        this.contentTypes.set(".movie", "video/x-sgi-movie");
        this.contentTypes.set(".mp1", "audio/mp1");
        this.contentTypes.set(".mp2", "audio/mp2");
        this.contentTypes.set(".mp2v", "video/mpeg");
        this.contentTypes.set(".mp3", "audio/mp3");
        this.contentTypes.set(".mp4", "video/mpeg4");
        this.contentTypes.set(".mpa", "video/x-mpg");
        this.contentTypes.set(".mpd", "application/vnd.ms-project");
        this.contentTypes.set(".mpe", "video/x-mpeg");
        this.contentTypes.set(".mpeg", "video/mpg");
        this.contentTypes.set(".mpg", "video/mpg");
        this.contentTypes.set(".mpga", "audio/rn-mpeg");
        this.contentTypes.set(".mpp", "application/vnd.ms-project");
        this.contentTypes.set(".mps", "video/x-mpeg");
        this.contentTypes.set(".mpt", "application/vnd.ms-project");
        this.contentTypes.set(".mpv", "video/mpg");
        this.contentTypes.set(".mpv2", "video/mpeg");
        this.contentTypes.set(".mpw", "application/vnd.ms-project");
        this.contentTypes.set(".mpx", "application/vnd.ms-project");
        this.contentTypes.set(".mtx", "text/xml");
        this.contentTypes.set(".mxp", "application/x-mmxp");
        this.contentTypes.set(".net", "image/pnetvue");
        this.contentTypes.set(".nrf", "application/x-nrf");
        this.contentTypes.set(".nws", "message/rfc822");
        this.contentTypes.set(".odc", "text/x-ms-odc");
        this.contentTypes.set(".out", "application/x-out");
        this.contentTypes.set(".p10", "application/pkcs10");
        this.contentTypes.set(".p12", "application/x-pkcs12");
        this.contentTypes.set(".p7b", "application/x-pkcs7-certificates");
        this.contentTypes.set(".p7c", "application/pkcs7-mime");
        this.contentTypes.set(".p7m", "application/pkcs7-mime");
        this.contentTypes.set(".p7r", "application/x-pkcs7-certreqresp");
        this.contentTypes.set(".p7s", "application/pkcs7-signature");
        this.contentTypes.set(".pc5", "application/x-pc5");
        this.contentTypes.set(".pci", "application/x-pci");
        this.contentTypes.set(".pcl", "application/x-pcl");
        this.contentTypes.set(".pcx", "application/x-pcx");
        this.contentTypes.set(".pdf", "application/pdf");
        this.contentTypes.set(".pdx", "application/vnd.adobe.pdx");
        this.contentTypes.set(".pfx", "application/x-pkcs12");
        this.contentTypes.set(".pgl", "application/x-pgl");
        this.contentTypes.set(".pic", "application/x-pic");
        this.contentTypes.set(".pko", "application/vnd.ms-pki.pko");
        this.contentTypes.set(".pl", "application/x-perl");
        this.contentTypes.set(".plg", "text/html");
        this.contentTypes.set(".pls", "audio/scpls");
        this.contentTypes.set(".plt", "application/x-plt");
        this.contentTypes.set(".png", "image/png");
        this.contentTypes.set(".pot", "application/vnd.ms-powerpoint");
        this.contentTypes.set(".ppa", "application/vnd.ms-powerpoint");
        this.contentTypes.set(".ppm", "application/x-ppm");
        this.contentTypes.set(".pps", "application/vnd.ms-powerpoint");
        this.contentTypes.set(".ppt", "application/x-ppt");
        this.contentTypes.set(".pr", "application/x-pr");
        this.contentTypes.set(".prf", "application/pics-rules");
        this.contentTypes.set(".prn", "application/x-prn");
        this.contentTypes.set(".prt", "application/x-prt");
        this.contentTypes.set(".ps", "application/x-ps");
        this.contentTypes.set(".ptn", "application/x-ptn");
        this.contentTypes.set(".pwz", "application/vnd.ms-powerpoint");
        this.contentTypes.set(".r3t", "text/vnd.rn-realtext3d");
        this.contentTypes.set(".ra", "audio/vnd.rn-realaudio");
        this.contentTypes.set(".ram", "audio/x-pn-realaudio");
        this.contentTypes.set(".ras", "application/x-ras");
        this.contentTypes.set(".rat", "application/rat-file");
        this.contentTypes.set(".rdf", "text/xml");
        this.contentTypes.set(".rec", "application/vnd.rn-recording");
        this.contentTypes.set(".red", "application/x-red");
        this.contentTypes.set(".rgb", "application/x-rgb");
        this.contentTypes.set(".rjs", "application/vnd.rn-realsystem-rjs");
        this.contentTypes.set(".rjt", "application/vnd.rn-realsystem-rjt");
        this.contentTypes.set(".rlc", "application/x-rlc");
        this.contentTypes.set(".rle", "application/x-rle");
        this.contentTypes.set(".rm", "application/vnd.rn-realmedia");
        this.contentTypes.set(".rmf", "application/vnd.adobe.rmf");
        this.contentTypes.set(".rmi", "audio/mid");
        this.contentTypes.set(".rmj", "application/vnd.rn-realsystem-rmj");
        this.contentTypes.set(".rmm", "audio/x-pn-realaudio");
        this.contentTypes.set(".rmp", "application/vnd.rn-rn_music_package");
        this.contentTypes.set(".rms", "application/vnd.rn-realmedia-secure");
        this.contentTypes.set(".rmvb", "application/vnd.rn-realmedia-vbr");
        this.contentTypes.set(".rmx", "application/vnd.rn-realsystem-rmx");
        this.contentTypes.set(".rnx", "application/vnd.rn-realplayer");
        this.contentTypes.set(".rp", "image/vnd.rn-realpix");
        this.contentTypes.set(".rpm", "audio/x-pn-realaudio-plugin");
        this.contentTypes.set(".rsml", "application/vnd.rn-rsml");
        this.contentTypes.set(".rt", "text/vnd.rn-realtext");
        this.contentTypes.set(".rtf", "application/msword");
        this.contentTypes.set(".rv", "video/vnd.rn-realvideo");
        this.contentTypes.set(".sam", "application/x-sam");
        this.contentTypes.set(".sat", "application/x-sat");
        this.contentTypes.set(".sdp", "application/sdp");
        this.contentTypes.set(".sdw", "application/x-sdw");
        this.contentTypes.set(".sit", "application/x-stuffit");
        this.contentTypes.set(".slb", "application/x-slb");
        this.contentTypes.set(".sld", "application/x-sld");
        this.contentTypes.set(".slk", "drawing/x-slk");
        this.contentTypes.set(".smi", "application/smil");
        this.contentTypes.set(".smil", "application/smil");
        this.contentTypes.set(".smk", "application/x-smk");
        this.contentTypes.set(".snd", "audio/basic");
        this.contentTypes.set(".sol", "text/plain");
        this.contentTypes.set(".sor", "text/plain");
        this.contentTypes.set(".spc", "application/x-pkcs7-certificates");
        this.contentTypes.set(".spl", "application/futuresplash");
        this.contentTypes.set(".spp", "text/xml");
        this.contentTypes.set(".ssm", "application/streamingmedia");
        this.contentTypes.set(".sst", "application/vnd.ms-pki.certstore");
        this.contentTypes.set(".stl", "application/vnd.ms-pki.stl");
        this.contentTypes.set(".stm", "text/html");
        this.contentTypes.set(".sty", "application/x-sty");
        this.contentTypes.set(".svg", "text/xml");
        this.contentTypes.set(".swf", "application/x-shockwave-flash");
        this.contentTypes.set(".tdf", "application/x-tdf");
        this.contentTypes.set(".tg4", "application/x-tg4");
        this.contentTypes.set(".tga", "application/x-tga");
        this.contentTypes.set(".tif", "image/tiff");
        this.contentTypes.set(".tiff", "image/tiff");
        this.contentTypes.set(".tld", "text/xml");
        this.contentTypes.set(".top", "drawing/x-top");
        this.contentTypes.set(".torrent", "application/x-bittorrent");
        this.contentTypes.set(".tsd", "text/xml");
        this.contentTypes.set(".txt", "text/plain");
        this.contentTypes.set(".uin", "application/x-icq");
        this.contentTypes.set(".uls", "text/iuls");
        this.contentTypes.set(".vcf", "text/x-vcard");
        this.contentTypes.set(".vda", "application/x-vda");
        this.contentTypes.set(".vdx", "application/vnd.visio");
        this.contentTypes.set(".vml", "text/xml");
        this.contentTypes.set(".vpg", "application/x-vpeg005");
        this.contentTypes.set(".vsd", "application/x-vsd");
        this.contentTypes.set(".vss", "application/vnd.visio");
        this.contentTypes.set(".vst", "application/vnd.visio");
        this.contentTypes.set(".vsw", "application/vnd.visio");
        this.contentTypes.set(".vsx", "application/vnd.visio");
        this.contentTypes.set(".vtx", "application/vnd.visio");
        this.contentTypes.set(".vxml", "text/xml");
        this.contentTypes.set(".wav", "audio/wav");
        this.contentTypes.set(".wax", "audio/x-ms-wax");
        this.contentTypes.set(".wb1", "application/x-wb1");
        this.contentTypes.set(".wb2", "application/x-wb2");
        this.contentTypes.set(".wb3", "application/x-wb3");
        this.contentTypes.set(".wbmp", "image/vnd.wap.wbmp");
        this.contentTypes.set(".wiz", "application/msword");
        this.contentTypes.set(".wk3", "application/x-wk3");
        this.contentTypes.set(".wk4", "application/x-wk4");
        this.contentTypes.set(".wkq", "application/x-wkq");
        this.contentTypes.set(".wks", "application/x-wks");
        this.contentTypes.set(".wm", "video/x-ms-wm");
        this.contentTypes.set(".wma", "audio/x-ms-wma");
        this.contentTypes.set(".wmd", "application/x-ms-wmd");
        this.contentTypes.set(".wmf", "application/x-wmf");
        this.contentTypes.set(".wml", "text/vnd.wap.wml");
        this.contentTypes.set(".wmv", "video/x-ms-wmv");
        this.contentTypes.set(".wmx", "video/x-ms-wmx");
        this.contentTypes.set(".wmz", "application/x-ms-wmz");
        this.contentTypes.set(".wp6", "application/x-wp6");
        this.contentTypes.set(".wpd", "application/x-wpd");
        this.contentTypes.set(".wpg", "application/x-wpg");
        this.contentTypes.set(".wpl", "application/vnd.ms-wpl");
        this.contentTypes.set(".wq1", "application/x-wq1");
        this.contentTypes.set(".wr1", "application/x-wr1");
        this.contentTypes.set(".wri", "application/x-wri");
        this.contentTypes.set(".wrk", "application/x-wrk");
        this.contentTypes.set(".ws", "application/x-ws");
        this.contentTypes.set(".ws2", "application/x-ws");
        this.contentTypes.set(".wsc", "text/scriptlet");
        this.contentTypes.set(".wsdl", "text/xml");
        this.contentTypes.set(".wvx", "video/x-ms-wvx");
        this.contentTypes.set(".xap", "application/x-silverlight-app");
        this.contentTypes.set(".xdp", "application/vnd.adobe.xdp");
        this.contentTypes.set(".xdr", "text/xml");
        this.contentTypes.set(".xfd", "application/vnd.adobe.xfd");
        this.contentTypes.set(".xfdf", "application/vnd.adobe.xfdf");
        this.contentTypes.set(".xhtml", "text/html");
        this.contentTypes.set(".xls", "application/vnd.ms-excel");
        this.contentTypes.set(".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        this.contentTypes.set(".xlw", "application/x-xlw");
        this.contentTypes.set(".xml", "text/xml");
        this.contentTypes.set(".xpl", "audio/scpls");
        this.contentTypes.set(".xq", "text/xml");
        this.contentTypes.set(".xql", "text/xml");
        this.contentTypes.set(".xquery", "text/xml");
        this.contentTypes.set(".xsd", "text/xml");
        this.contentTypes.set(".xsl", "text/xml");
        this.contentTypes.set(".xslt", "text/xml");
        this.contentTypes.set(".xwd", "application/x-xwd");
        this.contentTypes.set(".x_b", "application/x-x_b");
        this.contentTypes.set(".x_t", "application/x-x_t");
    }

    get(filePath: string): string {
        const extension = this.getExtension(filePath);
        return this.contentTypes.get(extension.toLowerCase()) ?? "application/octet-stream";
    }

    getExtension(filePath: string): string {
        let fileName = filePath ?? '';
        fileName = fileName.split('?')[0];
        return fileName.substr(fileName.lastIndexOf('.'))?.toLowerCase();
    }

}
