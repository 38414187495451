export class Constant {

    static PAGE_SIZE = 10;

    static LOCAL_STORAGE_KEY_ENV = 'CENTRE-OPERATION-ENV';

    static LOCAL_STORAGE_KEY_REDIRECT_URL = 'CENTRE-OPERATION-REDIRECT-URL';

    static LOCAL_STORAGE_KEY_OPERATOR = 'CENTRE-OPERATION-OPERATOR';

    static LOCAL_STORAGE_KEY_ACCOUNTS = 'CENTRE-OPERATION-ACCOUNTS';

    /// 运营人员产品列表表格列是否顯示配置信息
    static LOCAL_STORAGE_KEY_OPERATOR_PRODUCTS_COLUMN_DISPLAY_CONFIG = 'CENTRE-OPERATION-OPERATOR-PRODUCTS-COLUMN-DISPLAY-CONFIG';

}
