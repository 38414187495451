import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyTextEditType } from "./property-text-edit.component";

@Component({
    selector: 'app-property-text',
    templateUrl: './property-text.component.html',
    styleUrls: ['./property-text.component.less']
})
export class PropertyTextComponent implements OnInit {

    @Input() title: string;
    @Input() required: boolean = false;
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() type: PropertyTextEditType = PropertyTextEditType.TEXT_FIELD;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    property: any;

    @Input()
    get value() {
        return this.property;
    }
    set value(val) {
        this.property = val;
        this.valueChange.emit(this.property);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
