<!--<nz-tabset>-->
<!--<nz-tab nzTitle="Tab 1">-->
<!--Content of Tab Pane 1-->
<!--</nz-tab>-->
<!--<nz-tab nzTitle="Tab 2">-->
<!--Content of Tab Pane 2-->
<!--</nz-tab>-->
<!--<nz-tab nzTitle="Tab 3">-->
<!--Content of Tab Pane 3-->
<!--</nz-tab>-->
<!--</nz-tabset>-->
<nz-layout>
    <div class="content-header">
        <app-content-header moduleName="新聞資訊模塊"></app-content-header>
    </div>
    <nz-content>
        <div class="inner-content">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
    <nz-footer>Centre Project ©{{ fullYear }} Bee·Fintech</nz-footer>
</nz-layout>
