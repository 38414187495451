<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!articles">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="articles" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="articles.list"
                      [nzPageIndex]="articles.pageNum"
                      [nzPageSize]="articles.pageSize"
                      [nzTotal]="articles.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '1280px'}">
            <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">
                                資訊列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'刷新' | translate"
                                   style="margin-left: 5px"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-popover [nzPopoverContent]="filterTemplate"
                                        [(nzPopoverVisible)]="filterPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        nz-tooltip nzTooltipTitle="資訊搜索"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ articles.totalCount }}</span> 條
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft nzWidth="180px">ID</th>
                    <th>標題</th>
                    <th>作者</th>
                    <th>来源</th>
                    <th>時間</th>
                    <th nzRight nzWidth="150px" style="text-align: center;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let article of articles.list; let i = index" appThrottleClick (throttleClick)="onDetail(article.id)">
                    <td nzLeft>{{ article.id }}</td>
                    <td nzBreakWord>{{ article.title }}</td>
                    <td><span class="tag-outline-sm">{{ article.author }}</span></td>
                    <td><span class="tag-sm">{{ article.source }}</span></td>
                    <td>{{ article.createTime | date:'yyyy-MM-dd HH:mm'}}</td>
                    <td nzRight>
                        <div class="flex-row-center">
                            <a *ngIf="article.xiumiId" nz-button nzType="link" appThrottleClick (throttleClick)="onEdit(article);" nzSize="small"
                                nz-tooltip [nzTooltipTitle]="'编辑' | translate">
                                <em nz-icon nzType="edit" nzTheme="outline"></em>
                            </a>
<!--                            <a *ngIf="article.xiumiId" nz-button nzType="link" appThrottleClick (throttleClick)="onKnowledge(article);" nzSize="small"-->
<!--                                nz-tooltip [nzTooltipTitle]="'發布為知識庫' | translate">-->
<!--                                <em nz-icon nzType="book" nzTheme="outline"></em>-->
<!--                            </a>-->
<!--                            <a *ngIf="article.xiumiId" nz-button nzType="link" appThrottleClick (throttleClick)="onInformation(article);" nzSize="small"-->
<!--                                nz-tooltip [nzTooltipTitle]="'發布為資訊' | translate">-->
<!--                                <em nz-icon nzType="profile" nzTheme="outline"></em>-->
<!--                            </a>-->
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </nz-spin>
</div>

<!-- 气泡Template -->
<ng-template #filterTemplate>
    <div style="width: 400px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'搜索'}}</div>
        <div class="property-bottom-line-blue"></div>
        <!-- 名字 -->
        <app-property-text-edit [title]="'标题'" [(value)]="search.title"></app-property-text-edit>

        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
                    <span>{{'重置' | translate}}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
                    <span>{{'确定' | translate}}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
