import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzSelectModeType } from "ng-zorro-antd/select";

@Component({
    selector: 'app-property-select',
    templateUrl: './property-select.component.html',
    styleUrls: ['./property-select.component.less']
})
export class PropertySelectComponent implements OnInit {

    @Input() mode: NzSelectModeType = "default";
    @Input() title: string;
    @Input() placeholder = '';
    @Input() name: string | string[];                          /// 用于显示
    @Input() editing: boolean = false;
    @Input() editable: boolean = false;
    @Input() loading: boolean = false;
    @Input() allowClear = true;
    @Input() options: PropertySelectOption[];
    @Input() required: boolean = false;

    @Input() actionTitle: string;

    @Output()
    valueChange: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();

    @Output()
    scrollToBottom: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    searchChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    onAction: EventEmitter<string> = new EventEmitter<string>();

    innerValue: any | any[];

    @Input()
    get value() {
        return this.innerValue;
    }
    set value(val : any | any[]) {
        this.innerValue = val;
        this.valueChange.emit(this.innerValue);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    onScrollToButton(): void {
        this.scrollToBottom.emit("");
    }

    onSearch(value: string) {
        this.searchChange.emit(value);
    }

    onActionButton(): void {
        this.onAction.emit('');
    }
}

export class PropertySelectOption {
    label: string;
    value: any;
    constructor(label: string, value: any) {
        this.label = label;
        this.value = value;
    }
}
