import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, Deductible, I18n } from "../../../api/types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { MetadataService } from "../../../shared/services/metadata.service";
import { isNullOrUndefined } from "../../../common/utils";

@Component({
    selector: 'app-deductibles',
    templateUrl: './deductibles.component.html',
    styleUrls: ['./deductibles.component.less']
})
export class DeductiblesComponent implements OnInit, AfterViewInit {

    @Input() deductibles: Deductible[]

    @Input() editing : boolean = false

    currencyOptions: PropertySelectOption[] = []

    constructor(private metadataService: MetadataService) {
    }

    ngOnInit(): void {
        this.metadataService.currencies()
            .subscribe(
                data => {
                    data.forEach(currency => {
                        this.currencyOptions?.push(new PropertySelectOption(currency, currency));
                    })
                },
                error => {
                });
    }

    ngAfterViewInit(): void {
    }

    @Output()
    valueChange: EventEmitter<Deductible[]> = new EventEmitter<Deductible[]>();

    @Input()
    get value() {
        return this.deductibles;
    }
    set value(val) {
        this.deductibles = val;
        this.valueChange.emit(this.deductibles);
    }

    onAdd(): void {
        let deductible = new Deductible();
        deductible.currency = Currency.HKD;
        deductible.amounts = [];
        if (isNullOrUndefined(this.value)) {
            this.value = [];
        }
        this.value?.push(deductible);
    }

    onDelete(index: number) {
        if (isNullOrUndefined(this.value)) {
            this.value = [];
            return;
        }
        this.value.splice(index, 1);
    }

}
