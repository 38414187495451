<div>
    <nz-modal
        [(nzVisible)]="modalVisible"
        [nzTitle]="modalTitle"
        [nzContent]="modalContent"
        [nzFooter]="modalFooter"
        (nzOnCancel)="handleCancel()"
    >
        <ng-template #modalTitle>導入保費試算</ng-template>

        <ng-template #modalContent>
            <div>
                <div>您需要准备：</div>
                <div>1、下载Excel模板：
                    <span class="text-btn" appThrottleClick (throttleClick)="excelTemplate(false)">重疾保险模板</span>，
                    <span class="text-btn" appThrottleClick (throttleClick)="excelTemplate(true)">医疗保险模板</span>
                </div>
                <div>2、将不同险种的数据分别复制到对应的Excel模板中</div>
                <div>3、将填写好的Excel表格上传</div>
            </div>
        </ng-template>

        <ng-template #modalFooter>
            <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
                   (change)="selectedImportFileOnChanged($event)" [accept]="'.xlsx,.xlsm'"/>
            <button nz-button nzType="primary" appThrottleClick (throttleClick)="selectImportFile()">上傳表格</button>
        </ng-template>
    </nz-modal>

    <nz-modal
        [(nzVisible)]="responseVisible"
        [nzTitle]="tipsTitle"
        [nzContent]="tipsContent"
        [nzFooter]="null"
        [nzMask]="false"
        (nzOnCancel)="tipsCancel()"
    >
        <ng-template #tipsTitle>導入信息</ng-template>

        <ng-template #tipsContent>
            <div>
                <nz-table
                    #basicTable
                    [nzData]="dataSet"
                    [nzShowPagination]="true"
                >
                    <thead>
                    <tr>
                        <th>行</th>
                        <th>列</th>
                        <th>識別到的數據</th>
                        <th>錯誤提示</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td>{{data.row}}</td>
                        <td>{{data.column}}</td>
                        <td>{{data.data}}</td>
                        <td>{{data.message}}</td>
                    </tr>
                    </tbody>
                </nz-table>
                <div>
                    <p>識別到的總行數：<span>{{ excelReport?.totalRows ? excelReport?.totalRows : 0 }}</span></p>
                    <p>解析出錯的行數：<span>{{ excelReport?.errorRecords ? excelReport?.errorRecords : 0 }}</span></p>
                    <p>有效行數：<span>{{ excelReport?.validRows ? excelReport?.validRows : 0 }}</span></p>
                    <p>存儲記錄數：<span>{{ excelReport?.storageRecords ? excelReport?.storageRecords : 0 }}</span></p>
                </div>
            </div>
        </ng-template>
    </nz-modal>
</div>
