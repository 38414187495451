import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productCommonType'
})
export class ProductCommonTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let productType = "";
        switch (value) {
            case 'LIFE':
                productType = "壽險";
                break;
            case 'GI':
                productType = "一般保險";
                break;
            case 'MPF':
                productType = "強積金";
                break;
        }
        return productType;
    }

}
