import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProductService } from '../../product.service';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { ApiResponse, ExcelError, ExcelReport, ProcessLog } from '../../../api/types';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-premium-table-import',
  templateUrl: './premium-table-import.component.html',
  styleUrls: ['./premium-table-import.component.less']
})
export class PremiumTableImportComponent implements OnInit {

    @Input() modalVisible: boolean = false;

    responseVisible: boolean = false;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    uploader: FileUploader;

    dataSet: ExcelError[] = [];

    excelReport: ExcelReport = new ExcelReport();

    @ViewChild('fileUpload')
    fileUpload: ElementRef;

    constructor(private productService: ProductService,
                private toastr: ToastrService) { }

  ngOnInit(): void {
      let httpHeaders = this.productService.getAuthorizationHeaders();
      let headers: Headers[] = httpHeaders.keys()
          .filter(key => {
              return key !== 'Content-Type'
          })
          .map(key => {
              return {name: key, value: httpHeaders.get(key)}
          });
      console.log(headers);
      this.uploader = new FileUploader({
          url: this.productService.productUrl + "/premium_rate/import",
          method: "POST",
          itemAlias: "file",
          headers: headers,
          // allowedMimeType: ["application/vnd.ms-excel"],
          autoUpload: true
      });

      /// 需要bind(this)，才能在对应方法里使用this.
      this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
      this.uploader.onErrorItem = this.onUploadError.bind(this);
      this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
  }

    // https://www.cnblogs.com/gavin-cn/p/7256852.html
    selectImportFile(): any {
        // this.responseVisible = true
        if (this.fileUpload) {
            this.fileUpload.nativeElement.click();
        }
    }

    selectedImportFileOnChanged(event) {
    }


    handleCancel() {
        this.modalVisible = false;
        this.valueChange.emit(this.modalVisible);
    }

    excelTemplate(flag: boolean) {
        this.productService.downloadTemplate(flag)
            .subscribe(
                data => {
                    console.log(data);
                },
                error => {
                })

    }

    onUploadSuccess(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        // this.spinning = false;
        this.excelReport = JSON.parse(response);
        this.excelReport.errorDetails = this.excelReport.errorDetails ? this.excelReport.errorDetails : [];
        this.dataSet = this.excelReport.errorDetails;
        this.uploader.clearQueue();
        let apiResponse = JSON.parse(response) as ApiResponse<ProcessLog[]>;
        // this.openLogsDrawer(apiResponse.data);
        this.responseVisible = true;
    }

    onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        // this.spinning = false;
        let error = JSON.parse(response) as ApiResponse<any>;
        this.toastr.warning(error.msg);
        this.uploader.clearQueue();
    }


    onAfterAddingFile(fileItem: FileItem): any {
        fileItem.withCredentials = false;
        // this.spinning = true;
        if (this.fileUpload) {
            // 重置文件选择，否则无法重复上传同一个文件
            this.fileUpload.nativeElement.value = ''
        }
    }

    tipsCancel() {
        this.responseVisible = false;
    }

}
