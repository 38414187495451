import { Component, OnInit } from '@angular/core';
import { I18n, PagedResp, Product, ProductTag, ProductTagSearch } from "../../api/types";
import { ProductService } from "../product.service";

@Component({
    selector: 'app-product-tag-list',
    templateUrl: './product-tag-list.component.html',
    styleUrls: ['./product-tag-list.component.less']
})
export class ProductTagListComponent implements OnInit {

    loading = false;
    productTagList: PagedResp<ProductTag>;
    search: ProductTagSearch = new ProductTagSearch();

    constructor(private productService: ProductService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.productService.productTagList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.productTagList = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onSearch(page: number) {
        this.search.pageNum = page
        this.loading = true;
        this.productService.productTagList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.productTagList = data;
                },
                error => {
                    this.loading = false;
                });
    }

    openInfoDrawer(productTag: ProductTag): void {
        // this.productService.productInfo(product.code)
        //     .subscribe(
        //         data => {
        //             if (data.nameI18n == null) {
        //                 data.nameI18n = new I18n();
        //             }
        //             this.showInfoDrawer = true;
        //             this.productInfoComponent.editing = false;
        //             this.productInfoComponent.value = data;
        //         },
        //         error => {
        //         });
    }

}
