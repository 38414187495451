<div class="property">
    <div class="property-title" *ngIf="title">{{ title }}</div>
    <div *ngIf="value" class="property-value">
        <div class="i18n-area-edit">
            <div class="i18n-area-title">简体：</div>
            <div class="i18n-area-value">
<!--                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.zhHansCN" placeholder=""/>-->
                <textarea nz-input placeholder="" class="property-input-area"
                          [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                          [(ngModel)]="value.zhHansCN"></textarea>
            </div>
        </div>
        <div class="i18n-area-edit">
            <div class="i18n-area-title">繁体：</div>
            <div class="i18n-area-value">
<!--                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.zhHantHK" placeholder=""/>-->
                <textarea nz-input placeholder="" class="property-input-area"
                          [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                          [(ngModel)]="value.zhHantHK"></textarea>
            </div>
        </div>
        <div class="i18n-area-edit">
            <div class="i18n-area-title">英文：</div>
            <div class="i18n-area-value">
<!--                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="value.enUS" placeholder=""/>-->
                <textarea nz-input placeholder="" class="property-input-area"
                          [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                          [(ngModel)]="value.enUS"></textarea>
            </div>
        </div>
    </div>
</div>
