import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productQuotationFormula'
})
export class ProductQuotationFormulaPipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let quotationFormula = "";
        switch (value) {
            case 'PREMIUM_RATE':
                quotationFormula = "保费率（重疾险，储蓄险）";
                break;
            case 'PREMIUM_TABLE':
                quotationFormula = "保费表（医疗险）";
                break;
        }
        return quotationFormula;
    }

}
