<nz-modal
    [(nzVisible)]="isVisible"
    nzTitle="標籤管理"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    [nzOkLoading]="isOkLoading"
>
    <p *nzModalContent>
        <span class="release-tags" *ngFor="let tag of innerValue; let i = index">
           <nz-tag  [nzMode]="'closeable'"
                   (nzOnClose)="handleClose(i)">
            <span style="font-size: 13px">{{ tag }}</span>
           </nz-tag>
        </span>
        <span class="release-input">
            <input nz-input placeholder="請輸入標籤後點擊右側添加按鈕" [(ngModel)]="inputValue" />
            <button nz-button nzType="primary" appThrottleClick (throttleClick)="addTags()">添加標籤</button>
        </span>
    </p>

</nz-modal>
