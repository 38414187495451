<div class="property">
    <div class="property-title">
        <label>{{ title }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div class="property-value">
        <div [ngSwitch]="mode">
            <div *ngSwitchCase="'default'" class="property-display-value">
                {{ name | nullable  }}
            </div>
            <div *ngSwitchCase="'multiple'" class="property-display-value">
                <nz-tag *ngFor="let v of value; let i = index" [nzMode]="'default'">
                    <span style="font-size: 13px">{{ sliceTagName(toLabel(v)) }}</span>
                </nz-tag>
                <div *ngIf="!name || name.length == 0">
                    N/A
                </div>
            </div>
        </div>
    </div>
</div>
