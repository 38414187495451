import { PagedReq, Product } from '../api/types';

export class BelongingInstance extends PagedReq {
    instance: string; // 必传
    name: string;
    code: string;
    type: string; // 產品類型 BASIC :主險 RIDER :附加險
}

export class InstanceProduct extends Product {
    id: number; // id
    code: string;
}
