import { Information, Material, Model, PagedReq, ReleaseType } from "../api/types";

export class ArticleSearch extends PagedReq {
    title: string;
}

export enum ArticleSource {
    XIUMI = 'XIUMI',
    WEIXIN = 'WEIXIN'
}

export class Article extends Model {
    operatorId: number; // 运营ID
    title: string; // 标题
    author: string; // 作者
    cover: string; // 封面
    content: string; // 内容
    summary: string; // 摘要
    originUrl: string; // 源url
    source: ArticleSource; // 来源平台
    xiumiId: number; // 来源平台

    public toInformation() : Information {
        const info = new Information();
        info.title = this.title;
        info.cover = this.cover;
        info.content = this.content;
        info.link = this.originUrl;
        return info;
    }
}
