import {Component, OnInit, ViewChild} from '@angular/core';
import {
    Information,
    Instance, InstanceInformationDelete,
    InstanceInformationSync,
    InstanceProductDelete,
    ProcessLog,
    Product
} from "../../api/types";
import {isNullOrUndefined} from "../../common/utils";
import {DataService} from "../../shared/services/data.service";
import {InstanceService} from "../../shared/services/instance.service";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import * as _ from "lodash";
import {ProcessLogListComponent} from "../../shared/components/process-log/process-log-list.component";
import { InformationSelectionComponent } from "../compoents/information-selection/information-selection.component"
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: 'app-information-sync',
  templateUrl: './information-sync.component.html',
  styleUrls: ['./information-sync.component.less']
})
export class InformationSyncComponent implements OnInit {
    informationToSync: Information[] = [];
    showProductSelectionDrawer: boolean = false;
    instances: Instance[] = [];


    constructor( private dataService: DataService,
                 private instanceService: InstanceService,
                 private modalService: NzModalService,
                 private drawerService: NzDrawerService ) {

    }

    ngOnInit(): void {
        this.informationToSync = _.uniqWith(this.dataService.informationToSync, _.isEqual)

        this.instanceService.instances()
            .subscribe(
                data => {
                    this.instances = data;
                },
                error => {
                });
    }

    @ViewChild('productSelection')
    InformationSelectionComponent: InformationSelectionComponent;

    // 同步
    onSync() {
        let req = new InstanceInformationSync();
        req.informationIds = this.informationToSync.filter(information => {
            return information.checked
        }).map(information => {
            return information.id;
        })
        req.instances = this.instanceToSync.map(instance => {
            return instance.name;
        })
        this.instanceService.informationSync(req)
            .subscribe(
                data => {
                    this.openLogsDrawer(data);
                },
                error => {
                });

    }

    onDelete() {
        this.modalService.confirm({
            nzTitle: '是否确定删除資訊？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎操作</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                let req = new InstanceInformationDelete();
                req.informationIds = this.informationToSync
                    // .filter(product => {
                    //     return product.checked
                    // })
                    .map(information => {
                        return information.id;
                    })
                req.instances = this.instanceToSync.map(instance => {
                    return instance.name;
                })
                this.instanceService.informationDelete(req)
                    .subscribe(
                        data => {
                            this.openLogsDrawer(data);
                        },
                        error => {
                        });
            },
            nzOnCancel: () => {
            }
        });

    }
    // 選擇客戶
    checkInstance(instance: Instance) {
        instance.checked = !instance.checked;
    }

    get instanceToSync(): Instance[] {
        let array: Instance[] = []
        if (!isNullOrUndefined(this.instances)) {
            array = this.instances.filter(instance => {
                return instance.checked
            })
        }
        return array;
    }

    openProductSelectionDrawer(): void {
        this.showProductSelectionDrawer = true
    }

    // 打開日誌drawer
    openLogsDrawer(logs: ProcessLog[]): void {
        const drawerRef = this.drawerService.create<ProcessLogListComponent, { value: string }, string>({
            nzWidth: 600,
            nzContent: ProcessLogListComponent,
            nzContentParams: {
                logs: logs
            }
        });

        drawerRef.afterOpen.subscribe(() => {
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }

    closeInformationSelectionDrawer() {
        this.showProductSelectionDrawer = false
    }

    onInformationSelected(information: Information[]) {
        this.showProductSelectionDrawer = false;
        information.forEach(p => {
            this.informationToSync?.push(p);
        });
        this.informationToSync =  _.uniqWith(this.informationToSync, _.isEqual);
        this.dataService.informationToSync = this.informationToSync;
    }

    onProductDeleted(index: number) {
        if (isNullOrUndefined(this.informationToSync) || this.informationToSync?.length === 0) return;
        this.informationToSync.splice(index, 1);
        this.dataService.informationToSync = this.informationToSync;
    }

    onClear() {
        this.informationToSync = [];
        this.dataService.informationToSync = this.informationToSync;
    }


}
