<div class="profile-large-title">
    <!--    <i nz-icon nzType="plus" nzTheme="outline" style="color: #07367c; font-size: 25px;"></i>-->
    <span style="font-weight: bold; flex: 1; font-size: 28px;">合約管理</span>
</div>

<!--<div style="margin-top: 30px; padding-bottom: 60px; padding-left: 10px; padding-right: 10px">-->

<!--    <div style="display: flex; align-items: center; justify-content: start">-->
<!--        <div style="width: 30px; flex: 1"></div>-->
<!--        <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip-->
<!--                nzTooltipTitle="上传合約"-->
<!--                appThrottleClick (throttleClick)="onUpload()"-->
<!--                style=" margin-right: 20px;">-->
<!--            <i nz-icon nzType="cloud-upload" nzTheme="outline"-->
<!--               style="color: #07367c; font-size: 20px;"></i>-->
<!--        </button>-->
<!--    </div>-->

<!--</div>-->

<input type="file" hidden="hidden" (change)="onFileSelected($event)" #fileUpload [accept]="'.xlsx,.xlsm'">

<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!masterRates">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="masterRates" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="masterRates.list"
                      [nzPageIndex]="masterRates.pageNum"
                      [nzPageSize]="masterRates.pageSize"
                      [nzTotal]="masterRates.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)">
                <ng-template #tableHeader>
                    <!--            <div>-->
                    <!--                <i nz-icon nzType="search" nzTheme="outline" style="color: #95AAC9;"></i>-->
                    <!--            </div>-->
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">合約列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="创建新合约"
                                        appThrottleClick (throttleClick)="onCreate()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="plus" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-popover [nzPopoverContent]="filterTemplate"
                                        [(nzPopoverVisible)]="filterPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        style=" margin-left: 10px;">
                                    <img alt="" width="24" src="../../../assets/images/ic_filter.svg">
                                </button>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ masterRates.totalCount }}</span> 條
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft>ID</th>
                    <th>合約名稱</th>
                    <th>生效時間</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let masterRate of masterRates.list; let i = index" appThrottleClick
                    (throttleClick)="onInfo(masterRate)">
                    <td nzLeft>{{ masterRate.id }}</td>
                    <td>{{ masterRate.name }}</td>
                    <td>{{ masterRate.effectiveDate }}</td>
                    <td>
                        <div>
                            <a nz-button nzType="link" nz-tooltip nzTooltipTitle="上传"
                               appThrottleClick (throttleClick)="onUpload(masterRate);$event.stopPropagation();"
                               nzSize="small">
                                <em nz-icon nzType="cloud-upload" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nz-tooltip nzTooltipTitle="下载"
                               appThrottleClick (throttleClick)="onDownload(masterRate);$event.stopPropagation();"
                               nzSize="small">
                                <em nz-icon nzType="download" nzTheme="outline"></em>
                            </a>
                            <a nz-button nzType="link" nz-tooltip nzTooltipTitle="删除"
                               appThrottleClick (throttleClick)="onDelete(masterRate);$event.stopPropagation();"
                               nzSize="small" class="delete-button">
                                <em nz-icon nzType="delete" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

        </div>
        <!-- profile drawer -->

    </nz-spin>
</div>

<ng-template #filterTemplate>
    <div style="width: 255px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{ '筛选' }}</div>
        <div class="property-bottom-line-blue"></div>

        <app-property-text-edit [title]="'名稱'" [(value)]="search.name"></app-property-text-edit>

        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                        (throttleClick)="onResetSearch()">
                    <span>{{ '重置' | translate }}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                        (throttleClick)="onSearch(1)">
                    <span>{{ '确定' | translate }}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
