import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleComponent } from './article.component';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleSelectionComponent } from './article-selection/article-selection.component';



@NgModule({
  declarations: [ArticlesComponent, ArticleComponent, ArticleDetailComponent, ArticleSelectionComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        RouterModule,
        NzSpinModule,
        NzIconModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzButtonModule,
        NzPopoverModule,
        NzToolTipModule
    ]
})
export class ArticleModule { }
