<nz-layout>
    <div class="content-header">
        <app-content-header moduleName="工单"></app-content-header>
    </div>
    <nz-content>
        <div class="inner-content">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
    <nz-footer>Centre Project ©{{ fullYear }} Bee·Fintech</nz-footer>
</nz-layout>
