<div class="property">
    <div class="property-title">
        <label>{{ title | nullable }}</label>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
    </div>
    <div class="property-value" style="min-height: 32px; padding-top: 5px">
        <div class="property-wrapper" [ngSwitch]="type" appThrottleClick (throttleClick)="openUrl()">
            <div *ngSwitchCase="'TEXT_FIELD'">
                {{ value | nullable }}
            </div>
            <div *ngSwitchCase="'TEXT_AREA'">
                {{ value | nullable  }}
            </div>
            <div *ngSwitchCase="'URL'" style="color: #2C7BE5; text-underline: #2C7BE5; cursor:pointer">
                {{ value | nullable  }}
            </div>
            <div *ngSwitchCase="'TEXT_HTML'" style="width: 100%;">
                <div [innerHTML]="value | html"></div>
            </div>
        </div>
    </div>

</div>
