import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Information, Material, MaterialUploadReq, Operator, ProductSearch, ReleaseType } from "../../api/types";
import { PropertySelectOption } from "../../shared/components/property-select/property-select.component";
import { InformationService } from "../information.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AuthService } from '../../passport/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MaterialService } from "../../shared/services/material.service";
import { ProductService } from "../../product/product.service";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { ArticleSelectionComponent } from "../../article/article-selection/article-selection.component";
import {ProductCompanyRegionPipe} from "../../shared/pipes/product-company-region.pipe";

@Component({
    selector: 'app-release-create',
    templateUrl: './information-create.component.html',
    styleUrls: ['./information-create.component.less']
})
export class InformationCreateComponent implements OnInit {

    information: Information = new Information();
    productSearch: ProductSearch = new ProductSearch();
    operator: Operator = new Operator();
    typeOptions: PropertySelectOption[] = [];
    companyOptions: PropertySelectOption[] = [];
    productOptions: PropertySelectOption[] = [];
    material: Material = new Material();

    productLoading = false;

    files: NzUploadFile[] = [];

    showProduct: boolean = true;
    loading: boolean = false;
    uploadReq: MaterialUploadReq = MaterialUploadReq.fromInformation(this.information);
    tagsOptions: PropertySelectOption[] = []

    @Output()
    informationCreated: EventEmitter<Information> = new EventEmitter<Information>();

    constructor(private informationService: InformationService,
                private notification: NzNotificationService,
                private authService: AuthService,
                private messageService: NzMessageService,
                private drawerService: NzDrawerService,
                private materialService: MaterialService,
                private productService: ProductService,
                private companyRegionPipe: ProductCompanyRegionPipe) {
    }

    ngOnInit(): void {
        this.operator = this.authService.getOperator();
        this.operator.nickname = "CENTRE"
        // this.typeOptions?.push(new PropertySelectOption('全部', null));
        this.typeOptions?.push(new PropertySelectOption('行政通知', ReleaseType.ADMINISTRATION_NOTICE));
        this.typeOptions?.push(new PropertySelectOption('產品動態', ReleaseType.PRODUCT_NEWS));
        this.typeOptions?.push(new PropertySelectOption('一般資訊', ReleaseType.GENERAL_INFO));
        this.typeOptions?.push(new PropertySelectOption('產品優惠', ReleaseType.PRODUCT_PROMOTION));
        this.productService.companies()
            .subscribe(
                data => {
                    data.forEach(company => {
                        this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
                    })
                },
                error => {
                });
        this.informationService.tags()
            .subscribe(
                data => {
                    data.name.forEach(tag => {
                        this.tagsOptions?.push(new PropertySelectOption(tag, tag));
                    })
                },
                error => {
                });
    }

    onCreate() {
        this.informationService.create(this.information)
            .subscribe(
                data => {
                    this.notification.success('資訊創建成功', '');
                    this.informationCreated.emit(data);
                },
                error => {
                });
    }

    typeChange(value: string) {
        switch (value) {
            case "PRODUCT_NEWS":
                this.showProduct = true
                this.information.companyCode = ""
                this.information.productCodes = []
                break;
            case "PRODUCT_PROMOTION":
                this.showProduct = true
                this.information.companyCode = ""
                this.information.productCodes = []
                break;
            default:
                this.showProduct = true
                break;
        }
    }

    // 公司请求产品
    companyChange(value: any) {
        this.information.productCodes = []
        this.productSearch.pageNum = 1
        this.productSearch.companyCode = value
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productOptions = [];
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                },
                error => {
                    // this.loading = false;
                });
    }

    onFilesChange(files: Material[]) {
        this.information.attachments = files
        // if(this.releaseData.productCodes.length == 0) {
        //     this.msg.error('請選擇產品!');
        //     return
        // }
        // this.productService.productFilesUpdate(this.releaseData.productCodes[0], files)
        //     .subscribe(
        //         data => {
        //             // this.getProductInfo();
        //         },
        //         error => {
        //             // this.getProductInfo();
        //         });
    }

    onPictureChange(material: Material) {
        this.information.cover = material?.filePath
    }

    onProductSearch(value: string) {
        this.productLoading = true;
        this.productSearch.pageNum = 1
        this.productSearch.name = value
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productLoading = false;
                    this.productOptions = [];
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                },
                error => {
                    this.productLoading = false;
                    // this.loading = false;
                });
    }

    onProductNextPage() {
        this.productLoading = true;
        this.productSearch.pageNum = this.productSearch.pageNum + 1;
        this.productService.productList(this.productSearch)
            .subscribe(
                data => {
                    // this.loading = false;
                    this.productLoading = false;
                    data.list.forEach(product => {
                        this.productOptions?.push(new PropertySelectOption(product.name + ' [' + this.companyRegionPipe.transform(product.region) + ']', product.code));
                    })
                },
                error => {
                    this.productLoading = false;
                    // this.loading = false;
                });
    }

    onImportFromArticle(): void {
        const drawerRef = this.drawerService.create<ArticleSelectionComponent, { value: any }, string>({
            nzWidth: 1024,
            nzContent: ArticleSelectionComponent,
            nzContentParams: {}
        });

        drawerRef.afterOpen.subscribe(() => {
            let component = drawerRef.getContentComponent()
            component.drawerRef = drawerRef;
            component.articleSelected
                .subscribe(
                    article => {
                        this.information.title = article.title;
                        this.information.cover = article.cover;
                        this.information.content = article.content;
                        this.information.link = article.originUrl;

                        this.material.filePath = article.cover;
                    })
        });

        drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
        });
    }
}
