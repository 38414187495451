import { Component, OnInit, ViewChild } from '@angular/core';
import { PagedResp, Product, ProductSearch } from '../../api/types';
import { ProductSearchComponent } from '../../product/components/product-search/product-search.component';
import { InstanceService } from '../../shared/services/instance.service';
import { BelongingInstance, InstanceProduct } from '../customer-types';

@Component({
    selector: 'app-customer-product',
    templateUrl: './customer-product.component.html',
    styleUrls: ['./customer-product.component.less']
})
export class CustomerProductComponent implements OnInit {
    name: string;
    loading = false;
    products: PagedResp<InstanceProduct[]>;
    spinning = false;
    search: BelongingInstance = new BelongingInstance();
    filterPopoverVisible = false;
    @ViewChild('search')
    productSearchComponent: ProductSearchComponent;

    constructor(private instanceService: InstanceService) {
    }

    ngOnInit(): void {
        this.search.instance = this.name
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.instanceService.instanceProduct(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onReload(): void {
        this.loading = true;
        this.search = new BelongingInstance();
        this.search.instance = this.name
        this.instanceService.instanceProduct(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.products = data;
                },
                error => {
                    this.loading = false;
                });
    }


}
