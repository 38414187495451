import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from "../../common/utils";

@Pipe({
    name: 'nullable'
})
export class NullablePipe implements PipeTransform {

    transform(value: any, ...args: any[]): string {
        let result = value;
        if (isNullOrUndefined(result)) {
            result = "N/A";
        }
        return result;
    }

}
