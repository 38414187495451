import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProductService } from "../../product.service";
import {CompanyBusinessType, ProductCompanyRegions, ProductCompanySearch} from "../../product-types";
import { PropertySelectOption } from "../../../shared/components/property-select/property-select.component";
import { ProductCompanyRegionPipe } from "../../../shared/pipes/product-company-region.pipe";
import {CompanyBusinessTypePipe} from "../../../shared/pipes/company-business-type.pipe";

@Component({
    selector: 'app-company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.less']
})
export class CompanySearchComponent implements OnInit {

    search: ProductCompanySearch = new ProductCompanySearch()

    @Output()
    productCompanySearch: EventEmitter<ProductCompanySearch> = new EventEmitter<ProductCompanySearch>();

    regionOptions: PropertySelectOption[] = []

    businessTypeOptions: PropertySelectOption[] = [];

    constructor(private productService: ProductService,
                private productCompanyRegionPipe: ProductCompanyRegionPipe,
                private companyBusinessTypePipe: CompanyBusinessTypePipe,) {
    }

    ngOnInit(): void {
        let productCompanyRegions = ProductCompanyRegions.all();
        this.regionOptions = productCompanyRegions.map(region => {
            return new PropertySelectOption(this.productCompanyRegionPipe.transform(region), region)
        })

        let businessTypes: CompanyBusinessType[] = [CompanyBusinessType.GENERAL, CompanyBusinessType.LONG_TERM, CompanyBusinessType.GENERAL_AND_LONG_TERM, CompanyBusinessType.TRUST, CompanyBusinessType.OTHER]
        this.businessTypeOptions = businessTypes.map(productType => {
            return new PropertySelectOption(this.companyBusinessTypePipe.transform(productType), productType)
        })

    }

    onSearch(): void {
        this.productCompanySearch.emit(this.search);
    }

}
