import {Component, OnInit} from '@angular/core';
import {PagedResp, Proposal, ProposalCompare, ProposalCompareSearch, ProposalSearch} from "../../api/types";
import {Router} from "@angular/router";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NzModalService} from "ng-zorro-antd/modal";
import {ToastrService} from "ngx-toastr";
import {ProposalCompareService} from "../proposal-compare.service";
import {ProposalComponent} from "../proposal.component";
import {ArticleSearch} from "../../article/article-types";

@Component({
    selector: 'app-proposal-compare-list',
    templateUrl: './proposal-compare-list.component.html',
    styleUrls: ['./proposal-compare-list.component.less']
})
export class ProposalCompareListComponent implements OnInit {

    loading = false;

    compares: PagedResp<ProposalCompare>;

    search: ProposalCompareSearch = new ProposalCompareSearch();

    spinning: boolean = false;

    filterPopoverVisible = false;

    constructor(private router: Router,
                private drawerService: NzDrawerService,
                private modalService: NzModalService,
                private toastr: ToastrService,
                private proposalCompareService: ProposalCompareService,
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.proposalCompareService.compareList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.compares = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onSearch(pageNum: number) {
        this.search.pageNum = pageNum
        this.loading = true;
        this.proposalCompareService.compareList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.compares = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onReload() {
        this.search.pageNum = 1;
        this.loading = true;
        this.proposalCompareService.compareList(this.search)
            .subscribe(
                data => {
                    this.loading = false;
                    this.compares = data;
                },
                error => {
                    this.loading = false;
                });
    }

    onResetSearch(): void {
        this.search = new ProposalCompareSearch();
        this.onReload();
    }

    onRetry(compare: ProposalCompare) {
        this.spinning = true;
        this.proposalCompareService.retry(compare.id)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (data) {
                        this.toastr.success('操作成功')
                    }
                },
                error => {
                    this.spinning = false;
                });
    }


}
