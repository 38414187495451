<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
        <div *ngIf="!products">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="products" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="products.list"
                      [nzPageIndex]="products.pageNum"
                      [nzPageSize]="products.pageSize"
                      [nzTotal]="products.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '2100px'}">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">產品列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>
                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="導出供AI使用的產品庫(临时)"
                                        appThrottleClick (throttleClick)="exportAIProduct()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-download" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
                                       (change)="selectedImportFileOnChanged($event)" [accept]="'.xls,.xlsx,.xlsm'"/>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="保費試算導入"
                                        appThrottleClick (throttleClick)="isTemplate = true"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="calculator" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="上傳產品"
                                        appThrottleClick (throttleClick)="selectImportFile()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-upload" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="導出產品庫"
                                        appThrottleClick (throttleClick)="exportProduct()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="cloud-download" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>


                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="添加產品"
                                        appThrottleClick (throttleClick)="onCreateProduct()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="plus" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                                        nzTooltipTitle="產品搜索"
                                        appThrottleClick (throttleClick)="openSearchDrawer()"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>

                                <div style=" margin-left: 10px;display: flex;align-items: center">
                                    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="columnDisplayMenu" [nzClickHide]="false">
                                        显示列
                                        <i nz-icon nzType="down"></i>
                                    </a>
                                    <nz-dropdown-menu #columnDisplayMenu="nzDropdownMenu">
                                        <ul nz-menu>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.zhHansCN.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.zhHansCN.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.zhHantHK.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.zhHantHK.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.enUS.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.enUS.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.shortNameCN.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.shortNameCN.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.shortNameHK.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.shortNameHK.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.shortNameUS.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.shortNameUS.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.complianceNameCN.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.complianceNameCN.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.complianceNameHK.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.complianceNameHK.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.complianceNameUS.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.complianceNameUS.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.category.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.category.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.company.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.company.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.type.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.type.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.region.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.region.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.policyPeriod.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.policyPeriod.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.status.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.status.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.premiumTerms.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.premiumTerms.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.currencies.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.currencies.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.frequencies.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.frequencies.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.standard.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.standard.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.hot.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.hot.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.comparable.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.comparable.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.quotable.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.quotable.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small"
                                                           [(ngModel)]="tableDisplayConfig.proposalRPASupported.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.proposalRPASupported.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small"
                                                           [(ngModel)]="tableDisplayConfig.financialPlanSupported.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.financialPlanSupported.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.withdrawalable.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.withdrawalable.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.hasBrochure.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.hasBrochure.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.rank.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.rank.name}}
                                            </li>
                                            <li nz-menu-item>
                                                <nz-switch nzSize="small" [(ngModel)]="tableDisplayConfig.internalRemark.display"
                                                           (ngModelChange)="tableDisplayConfigChange($event)"></nz-switch>
                                                {{tableDisplayConfig.internalRemark.name}}
                                            </li>
                                        </ul>
                                    </nz-dropdown-menu>
                                </div>

                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ products.totalCount }}</span> 個產品
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft style="text-align: center">
                        <input type="checkbox" ngModel (ngModelChange)="checkAllEvent($event)"
                               (click)="$event.stopPropagation();" class="check-box"/>
                    </th>
                    <th nzWidth="180px">產品碼</th>
                    <th nzWidth="200px"><span nz-tooltip nzTooltipTitle="產品默認名稱">產品默認名稱</span></th>
                    <th *ngIf="tableDisplayConfig.zhHansCN.display"><span nz-tooltip nzTooltipTitle="名稱简体">名稱简体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.zhHantHK.display"><span nz-tooltip nzTooltipTitle="名稱繁体">名稱繁体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.enUS.display"><span nz-tooltip nzTooltipTitle="名稱英文">名稱英文</span></th>
                    <th *ngIf="tableDisplayConfig.shortNameCN.display"><span nz-tooltip nzTooltipTitle="簡稱简体">簡稱简体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.shortNameHK.display"><span nz-tooltip nzTooltipTitle="簡稱繁体">簡稱繁体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.shortNameUS.display"><span nz-tooltip nzTooltipTitle="簡稱英文">簡稱英文</span></th>
                    <th *ngIf="tableDisplayConfig.complianceNameCN.display"><span nz-tooltip nzTooltipTitle="合規名稱简体">合規名稱简体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.complianceNameHK.display"><span nz-tooltip nzTooltipTitle="合規名稱繁体">合規名稱繁体</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.complianceNameUS.display"><span nz-tooltip nzTooltipTitle="合規名稱英文">合規名稱英文</span></th>
                    <th *ngIf="tableDisplayConfig.category.display"><span nz-tooltip nzTooltipTitle="險種">險種</span></th>
                    <th *ngIf="tableDisplayConfig.company.display"><span nz-tooltip nzTooltipTitle="公司">公司</span></th>
                    <th *ngIf="tableDisplayConfig.type.display"><span nz-tooltip nzTooltipTitle="類型">類型</span></th>
                    <th *ngIf="tableDisplayConfig.region.display" nzWidth="200px"><span nz-tooltip nzTooltipTitle="所在地區">所在地區</span></th>
                    <th *ngIf="tableDisplayConfig.policyPeriod.display"><span nz-tooltip
                                                                              nzTooltipTitle="保障年期">保障年期</span></th>
                    <th *ngIf="tableDisplayConfig.status.display"><span nz-tooltip nzTooltipTitle="狀態">狀態</span></th>
                    <th *ngIf="tableDisplayConfig.premiumTerms.display"><span nz-tooltip
                                                                              nzTooltipTitle="產品年期">產品年期</span></th>
                    <th *ngIf="tableDisplayConfig.currencies.display"><span nz-tooltip nzTooltipTitle="貨幣">貨幣</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.frequencies.display"><span nz-tooltip
                                                                             nzTooltipTitle="付款頻率">付款頻率</span></th>
                    <th *ngIf="tableDisplayConfig.standard.display"><span nz-tooltip
                                                                          nzTooltipTitle="是否标准产品">是否标准产品</span></th>
                    <th *ngIf="tableDisplayConfig.hot.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.hot.tooltip">{{tableDisplayConfig.hot.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.comparable.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.comparable.tooltip">{{tableDisplayConfig.comparable.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.quotable.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.quotable.tooltip">{{tableDisplayConfig.quotable.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.proposalRPASupported.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.proposalRPASupported.tooltip">{{tableDisplayConfig.proposalRPASupported.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.financialPlanSupported.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.financialPlanSupported.tooltip">{{tableDisplayConfig.financialPlanSupported.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.withdrawalable.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.withdrawalable.tooltip">{{tableDisplayConfig.withdrawalable.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.hasBrochure.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.hasBrochure.tooltip">{{tableDisplayConfig.hasBrochure.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.rank.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.rank.tooltip">{{tableDisplayConfig.rank.name}}</span>
                    </th>
                    <th *ngIf="tableDisplayConfig.internalRemark.display">
                        <span nz-tooltip
                              [nzTooltipTitle]="tableDisplayConfig.internalRemark.tooltip">{{tableDisplayConfig.internalRemark.name}}</span>
                    </th>
                    <th nzRight>
                    </th>
                    <!--<th style="text-align: center">操作</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of products.list; let i = index" appThrottleClick
                    (throttleClick)="onProductInfo(product)">
                    <td nzLeft style="text-align: center" (click)="$event.stopPropagation();">
                        <input type="checkbox" [(ngModel)]="product.checked" (click)="$event.stopPropagation();"
                               class="check-box">
                    </td>
                    <td>{{ product.code }}</td>
                    <td nzBreakWord><span nz-tooltip [nzTooltipTitle]="product.name">{{ product.name }}</span></td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.zhHansCN?.display"><span nz-tooltip
                                                                                        [nzTooltipTitle]="product.nameI18n?.zhHansCN || 'N/A'">{{ product.nameI18n?.zhHansCN || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.zhHantHK?.display"><span nz-tooltip
                                                                                        [nzTooltipTitle]="product.nameI18n?.zhHantHK || 'N/A'">{{ product.nameI18n?.zhHantHK || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.enUS?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.nameI18n?.enUS || 'N/A'">{{ product.nameI18n?.enUS || 'N/A' }}</span>
                    </td>

                    <td nzBreakWord *ngIf="tableDisplayConfig?.shortNameCN?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.shortNameI18n?.zhHansCN || 'N/A'">{{ product.shortNameI18n?.zhHansCN || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.shortNameHK?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.shortNameI18n?.zhHantHK || 'N/A'">{{ product.shortNameI18n?.zhHantHK || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.shortNameUS?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.shortNameI18n?.enUS || 'N/A'">{{ product.shortNameI18n?.enUS || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.complianceNameCN?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.complianceNameI18n?.zhHansCN || 'N/A'">{{ product.complianceNameI18n?.zhHansCN || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.complianceNameHK?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.complianceNameI18n?.zhHantHK || 'N/A'">{{ product.complianceNameI18n?.zhHantHK || 'N/A' }}</span>
                    </td>
                    <td nzBreakWord *ngIf="tableDisplayConfig?.complianceNameUS?.display"><span nz-tooltip
                                                                                    [nzTooltipTitle]="product.complianceNameI18n?.enUS || 'N/A'">{{ product.complianceNameI18n?.enUS || 'N/A' }}</span>
                    </td>



                    <td *ngIf="tableDisplayConfig.category.display">{{ product.category?.name }}</td>
                    <td *ngIf="tableDisplayConfig.company.display">{{ product.company?.name }}</td>
                    <td *ngIf="tableDisplayConfig.type.display">{{ product.type | productType }}</td>
                    <td *ngIf="tableDisplayConfig.region?.display">{{ product.region | productCompanyRegion }}</td>
                    <td *ngIf="tableDisplayConfig.policyPeriod?.display">{{ product.policyPeriod }}</td>
                    <td *ngIf="tableDisplayConfig.status?.display">{{ product.status | productStatus }}</td>
                    <td *ngIf="tableDisplayConfig.premiumTerms?.display">
                        <nz-tag *ngFor="let premiumTerm of product.premiumTerms; let i = index" [nzMode]="'default'">
                            <span style="font-size: 12px; padding: 2px;">{{ premiumTerm }}</span>
                        </nz-tag>
                    </td>
                    <td *ngIf="tableDisplayConfig.currencies.display">
                        <nz-tag *ngFor="let currency of product.currencies; let i = index" [nzMode]="'default'">
                            <span style="font-size: 12px; padding: 2px;">{{ currency }}</span>
                        </nz-tag>
                    </td>
                    <td *ngIf="tableDisplayConfig.frequencies.display">
                        <nz-tag *ngFor="let frequency of product.frequencies; let i = index" [nzMode]="'default'">
                            <span style="font-size: 12px; padding: 2px;">{{ frequency | productFrequency }}</span>
                        </nz-tag>
                    </td>
                    <td *ngIf="tableDisplayConfig.standard.display">{{ product.standard ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.hot.display">{{ product.hot ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.comparable.display">{{ product.comparable ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.quotable.display">{{ product.quotable ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.proposalRPASupported.display">{{ product.proposalRPASupported ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.financialPlanSupported.display">{{ product.financialPlanSupported ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.withdrawalable.display">{{ product.withdrawalable ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.hasBrochure.display">{{ product.hasBrochure ? '是' : '否' }}</td>
                    <td *ngIf="tableDisplayConfig.rank.display">{{ product.rank }}</td>
                    <td *ngIf="tableDisplayConfig.internalRemark.display">{{ product.internalRemark }}</td>
                    <td nzRight>
                        <div>
                            <a nz-button nzType="link" appThrottleClick
                               (throttleClick)="onDelete(product);$event.stopPropagation();"
                               nzSize="small" class="delete-button">
                                <em nz-icon nzType="delete" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>

            <nz-affix [nzOffsetBottom]="40" *ngIf="selectedProducts.length > 0">
                <div class="product-sync-container">
                    <div class="product-sync-wrapper">
                        <i nz-icon nzType="check-circle" nzTheme="outline"
                           style="font-size: 20px;"></i>
                        <span style="margin-left: 5px;">已選</span>
                        <span
                            style="margin-left: 5px; margin-right: 5px; color: #f3e282; font-size: 25px">{{selectedProducts.length}}</span>
                        <span>個產品</span>
                        <button style="margin-left: 20px;" class="product-sync-button" nz-button nzType="primary"
                                nzShape="round" appThrottleClick (throttleClick)="onProductInstanceSync()">
                            同步至簽約客戶
                        </button>
                    </div>
                </div>
            </nz-affix>

        </div>

    </nz-spin>

    <app-premium-table-import [modalVisible]="isTemplate"
                         (valueChange)="isTemplateChange($event)"></app-premium-table-import>

</div>

<nz-drawer [nzVisible]="showSearchDrawer" [nzMask]="true" [nzWidth]="600" [nzClosable]="true"
           (nzOnClose)="closeSearchDrawer()">
    <ng-container *nzDrawerContent>
        <app-product-search #search (productSearch)="onProductSearch($event)"></app-product-search>
    </ng-container>
</nz-drawer>
