import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnowledgeComponent } from './knowledge.component';
import { KnowledgeGeneralComponent } from './general/knowledge-general.component';
import { KnowledgeProductComponent } from './product/knowledge-product.component';
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { KnowledgeInfoComponent } from './knowledge-info.component';
import { FileUploadModule } from "ng2-file-upload";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTagModule } from "ng-zorro-antd/tag";
import { KnowledgeCreateComponent } from './components/knowledge-create.component';
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { KnowledgesComponent } from './knowledges.component';
import {NzPopoverModule} from "ng-zorro-antd/popover";


@NgModule({
    declarations: [KnowledgeComponent, KnowledgeGeneralComponent, KnowledgeProductComponent, KnowledgeInfoComponent, KnowledgeCreateComponent, KnowledgesComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        FileUploadModule,
        EditorModule,
        NzLayoutModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzMenuModule,
        NzSpinModule,
        NzTableModule,
        NzDrawerModule,
        NzAffixModule,
        NzSkeletonModule,
        NzStatisticModule,
        NzSelectModule,
        NzEmptyModule,
        NzGridModule,
        NzTabsModule,
        NzListModule,
        NzSwitchModule,
        NzTagModule,
        NzToolTipModule,
        NzPopoverModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class KnowledgeModule {
}
