<div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">

        <div *ngIf="!customs">
            <nz-skeleton [nzParagraph]="{ rows: 8 }"></nz-skeleton>
        </div>
        <div *ngIf="customs" style="margin-top: 30px; ">
            <nz-table [nzBordered]="false"
                      [nzOuterBordered]="true"
                      [nzShowPagination]="true"
                      [nzLoading]="loading"
                      [nzFrontPagination]="false"
                      [nzData]="customs.list"
                      [nzPageIndex]="customs.pageNum"
                      [nzPageSize]="customs.pageSize"
                      [nzTotal]="customs.totalCount"
                      [nzTitle]="tableHeader"
                      [nzFooter]="tableFooter"
                      nzTableLayout="fixed"
                      (nzPageIndexChange)="onSearch($event)"
                      [nzScroll]="{ x: '1024px'}">
                <ng-template #tableHeader>
                    <nz-row>
                        <nz-col [nzSpan]="12">
                            <div style="display: flex; height: 100%; align-items: center; font-weight: 450;">計劃書模板列表
                                <a nz-button nzType="link" nzSize="small" nz-tooltip nzTooltipTitle="刷新"
                                   appThrottleClick (throttleClick)="onReload()">
                                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                                </a>
                            </div>
                        </nz-col>

                        <nz-col [nzSpan]="12">
                            <div style="width: 100%; display: flex; justify-content: flex-end;">
                                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                                        nz-popover [nzPopoverContent]="filterTemplate"
                                        [(nzPopoverVisible)]="filterPopoverVisible"
                                        nzPopoverTrigger="click"
                                        nzPopoverPlacement="bottomRight"
                                        nz-tooltip nzTooltipTitle="計劃書對比搜索"
                                        style=" margin-left: 10px;">
                                    <i nz-icon nzType="search" nzTheme="outline"
                                       style="color: #07367c; font-size: 20px;"></i>
                                </button>
                            </div>
                        </nz-col>
                    </nz-row>
                </ng-template>
                <ng-template #tableFooter>
                    <div class="table-title">
                        共 <span style="color: #002140;">{{ customs.totalCount }}</span> 份計劃書模板
                    </div>
                </ng-template>

                <thead>
                <tr>
                    <th nzLeft nzWidth="160px"><span>ID</span></th>
                    <th><span>客户</span></th>
                    <th><span>LifeBee编号</span></th>
                    <th><span>計劃書编号</span></th>
                    <th><span>账号名</span></th>
                    <th><span>状态</span></th>
                    <th nzRight nzWidth="200px"><span nz-tooltip nzTooltipTitle="預覽|下載">操作</span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let custom of customs.list; let i = index" appThrottleClick>
                    <td nzLeft>
                        <span style="font-size: 11px">
                            {{ custom.id }}
                        </span>
                    </td>
                    <td>{{ custom.instance }}</td>
                    <td>#{{ custom.bid }}</td>
                    <td>{{ custom.proposalId }}</td>
                    <td>{{ custom.accountName }}</td>
                    <td>{{ custom.status }}</td>
                    <td nzRight>
                        <div>
                            <a nz-button nzType="link" nzSize="small"
                               nz-tooltip nzTooltipTitle="重试"
                               appThrottleClick (throttleClick)="onRetry(custom)">
                                <em nz-icon nzType="reload" nzTheme="outline"></em>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </nz-spin>
</div>

<!-- 气泡Template -->
<ng-template #filterTemplate>
    <div style="width: 400px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'搜索'}}</div>
        <div class="property-bottom-line-blue"></div>
        <!-- 客戶實例 -->
        <app-property-text-edit [title]="'客戶實例'" [(value)]="search.instance"></app-property-text-edit>

        <app-property-text-edit [title]="'模板编号'" [(value)]="search.bid"></app-property-text-edit>

        <nz-row [nzGutter]="5">
            <nz-col nzSpan="12">
                <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
                    <span>{{'重置' | translate}}</span>
                </button>
            </nz-col>
            <nz-col nzSpan="12">
                <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
                    <span>{{'确定' | translate}}</span>
                </button>
            </nz-col>
        </nz-row>
    </div>
</ng-template>
